import React from 'react';
import styled, { css } from 'styled-components';
import { CodeWrapper } from './Response';
import { Heading } from '~/elements/Heading';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Prism as SyntaxHighlighterProps } from 'react-syntax-highlighter';

const Wrapper = styled.div`
  margin-bottom: 36px;

  p {
    margin: 0;
  }
`;

//

const StyledViewport = styled(ScrollArea.Viewport)<any>`
  max-width: 100%;
  color: #fff;
  white-space: nowrap;

  ${({ fullHeight }) =>
    fullHeight === 'true' &&
    css<SyntaxHighlighterProps>`
      max-height: ${(props) => props.maxHeight || '216px'};
    `}

  ${({ fullHeight }) =>
    fullHeight === 'false' &&
    css<SyntaxHighlighterProps>`
      max-height: none;
    `}
`;

const StyledScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 8px;

  z-index: 10;
  &[data-orientation='vertical'] {
    width: SCROLLBAR_SIZE;
    flex-direction: row;
    height: auto;
    width: 20px;
  }
  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: SCROLLBAR_SIZE;
    height: 20px;
  }
`;

const StyledThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background: rgba(var(--rgb-white), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--rgb-white), 0.65);
  border-radius: var(--radii-pill);
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    z-index: 10;
  }
`;

export const MarkdownRequest: React.FC = (props) => {
  return (
    <Wrapper>
      <Heading size="200" weight="semibold" color="white">
        Request
      </Heading>
      <CodeWrapper>
        <ScrollArea.Root>
          <StyledViewport {...props}>{props.children}</StyledViewport>
          <StyledScrollbar orientation="horizontal">
            <StyledThumb />
          </StyledScrollbar>
          <StyledScrollbar orientation="vertical">
            <StyledThumb />
          </StyledScrollbar>
        </ScrollArea.Root>
      </CodeWrapper>
    </Wrapper>
  );
};
