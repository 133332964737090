import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconX: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <path
        d="M4 4L12 12"
        stroke="var(--icon-base-color)"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M12 4L4 12"
        stroke="var(--icon-base-color)"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
