import { css } from 'styled-components';

export const Base = css`
  /* Custom property for animating text-decoration */

  @property --offset {
    syntax: '<length>';
    inherits: false;
    initial-value: 0;
  }

  body {
    font-family: 'SuisseIntl', sans-serif;
    font-feature-settings: 'salt' 2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    background-color: white;
  }

  .inline-link {
    color: var(--link-color);
    font-weight: var(--font-medium);
    transition: colors 0.2s;

    &:hover,
    &:focus {
      color: var(--link-color-hover, currentColor);
      text-decoration: underline;
    }
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  button,
  a {
    cursor: pointer;
  }

  svg {
    overflow: visible;
  }

  em,
  address {
    font-style: normal;
  }

  .bg-primary {
    background-color: var(--bg-color-primary);
  }

  a:hover {
    cursor: pointer;
  }

  /* Forms reset */

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: rgb(var(--rgb-gray-500));
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(var(--rgb-blue-600));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    border-color: rgb(var(--rgb-blue-600));
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: rgb(var(--rgb-gray-500));
    opacity: 1;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: rgb(var(--rgb-gray-500));
    opacity: 1;
  }

  input::placeholder,
  textarea::placeholder {
    color: rgb(var(--rgb-gray-500));
    opacity: 1;
  }

  ::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }

  ::-webkit-date-and-time-value {
    min-height: 1.5em;
  }

  select {
    background-repeat: no-repeat;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  [multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    -webkit-print-color-adjust: unset;
    color-adjust: unset;
  }

  [type='checkbox'],
  [type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
  }

  [type='checkbox'] {
    border-radius: 4px;
  }

  [type='radio'] {
    border-radius: 100%;
  }

  [type='checkbox']:focus,
  [type='radio']:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(var(--rgb-blue-600));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  [type='checkbox']:checked,
  [type='radio']:checked {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  [type='checkbox']:indeterminate {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  [type='file'] {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit;
  }

  [type='file']:focus {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
