import React, {
  PropsWithChildren,
  createRef,
  forwardRef,
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import classNames from 'classnames';
import { mergeRefs } from '~/utils/mergeRefs';

export interface InputStyleProps {
  isDisabled: boolean;
}

export interface InputProps extends Partial<InputStyleProps> {
  onChange?: (value: string) => void;
  onClick?: () => void;
  onFocusChange?: (value: boolean) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  type?:
    | 'text'
    | 'email'
    | 'password'
    | 'tel'
    | 'date'
    | 'month'
    | 'datetime-local'
    | 'number'
    | 'search'
    | 'time'
    | 'week'
    | 'url';
  value?: string;
  maxLength?: number;
  readOnly?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  autoBlur?: boolean;
  pattern?: string;
}

export const Input = forwardRef<{ focus: () => void }, PropsWithChildren<InputProps>>((props, ref) => {
  const onChange =
    props.onChange ??
    (() => {
      /* NOOP */
    });
  const onFocus =
    props.onFocus ??
    (() => {
      /* NOOP */
    });
  const onBlur =
    props.onBlur ??
    (() => {
      /* NOOP */
    });
  const onFocusChange =
    props.onFocusChange ??
    (() => {
      /* NOOP */
    });
  const onKeyDown =
    props.onKeyDown ??
    (() => {
      /* NOOP */
    });

  const inputRef = createRef<HTMLInputElement>();

  const styleProps: InputStyleProps = {
    isDisabled: props.isDisabled ?? false,
  };

  const staticClasses: StaticClasses = ['form-input', props.className];

  const conditionalClasses: ConditionalClasses = {};

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (props.onClick) {
      props.onClick();
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && props.autoBlur) {
      inputRef.current?.blur();
    }
  };

  let pattern = props.pattern;
  if (props.type === 'email') {
    pattern = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
  }

  return (
    <input
      onClick={handleClick}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      onFocus={(e: FocusEvent<HTMLInputElement>) => {
        onFocusChange(true);
        onFocus(e);
      }}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        onFocusChange(false);
        onBlur(e);
      }}
      onKeyDown={onKeyDown}
      onKeyPress={handleKeyPress}
      autoFocus={props.autoFocus}
      ref={mergeRefs([ref, inputRef])}
      disabled={styleProps.isDisabled}
      value={props.value}
      type={props.type ?? 'text'}
      pattern={props.pattern}
      readOnly={props.readOnly ?? false}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      className={classNames(staticClasses, conditionalClasses)}
    />
  );
});
