import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/ach/_hero';
import { SectionSpeed } from './products/ach/_speed';
import { SectionReturnsDisputes } from './products/ach/_returns-disputes';
import { SectionCloser } from './index/_closer';
import { Testimonials } from '~/components/Testimonials';
import { AllProducts } from '~/components/AllProducts';
import { Layout } from '~/components/Layout';
import { Footer } from '~/components/Footer';

const ACHPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>ACH — Column</title>
        <meta property="og:title" content="Column - ACH" />

        <meta
          name="description"
          content="Originate ACH credits and debits with a single API request. Take advantage of all five settlement windows, configure every field, get updated with webhooks, and own the return and dispute process."
        />
      </Helmet>

      <SectionHero />
      <SectionSpeed />
      <SectionReturnsDisputes />
      <Testimonials />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />

      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default ACHPage;
