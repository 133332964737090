import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsFaq: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M14 4.5H6C4.9 4.5 4 5.4 4 6.5V16.5C4 16.91 4.47 17.15 4.8 16.9L8 14.5H14C15.1 14.5 16 13.6 16 12.5V6.5C16 5.4 15.1 4.5 14 4.5ZM10 12.35C9.61 12.35 9.29 12.03 9.29 11.64C9.29 11.25 9.61 10.94 10 10.94C10.39 10.94 10.71 11.25 10.71 11.64C10.71 12.03 10.39 12.35 10 12.35ZM12.05 8.32C11.93 8.98 11.46 9.31 11.09 9.57C10.78 9.78 10.59 9.92 10.5 10.16C10.42 10.36 10.23 10.48 10.03 10.48C9.97 10.48 9.91 10.47 9.85 10.45C9.59 10.34 9.46 10.05 9.57 9.8C9.78 9.26 10.19 8.97 10.52 8.75C10.85 8.52 11.02 8.39 11.07 8.14C11.11 7.92 11.01 7.75 10.92 7.64C10.73 7.41 10.4 7.26 10.1 7.26C9.64 7.26 9.24 7.59 9.15 8.03C9.1 8.3 8.83 8.48 8.56 8.42C8.29 8.37 8.12 8.1 8.17 7.83C8.36 6.92 9.17 6.26 10.1 6.26C10.7 6.26 11.31 6.55 11.68 7C12.01 7.38 12.14 7.85 12.05 8.32Z"
        fill="url(#paint0_linear_3204_756)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_756"
          x1="6.64"
          y1="4.01"
          x2="11.97"
          y2="15.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
