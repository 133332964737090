import React from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Br } from '~/elements/Br';
import { Typing } from '~/elements/Typing';
import { md } from '~/ds/mixins/breakpoints';

const TwentyFour = styled(Flex)`
  margin-top: var(--space-6);

  @media ${md} {
    position: absolute;
    top: -16px;
    width: 100%;
    height: 100%;
  }
`;

export const SectionGraph: React.FC = () => {
  return (
    <Section>
      <Container>
        <Grid gridTemplateColumns={12} mb={18}>
          <Column gridColumn={{ _: 12, sm: 10 }} gridColumnStart={{ _: 0, sm: 2 }} position="relative">
            <img src="/images/products/protected-deposits/twenty-four.svg" className="w-full" />

            <TwentyFour alignItems="center" justifyContent="center">
              <div>
                <Heading as="h2" size="900" weight="semibold" color="purple-800" textAlign="center">
                  24/7 access
                  <Br display={{ _: 'none', md: 'block' }} /> and fully
                  <Typing text=" liquid" />
                </Heading>
                <Text size="300" mt={2} color="rgba(var(--rgb-purple-800),0.7)" textAlign="center" maxWidth="400px">
                  Guaranteed next day liquidity on 100% of your funds with up to $5 million immediately available.
                </Text>
              </div>
            </TwentyFour>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};
