import { css } from 'styled-components';

export const Orange = css`
  .color-palette--orange {
    --bg-gradient-primary: linear-gradient(
      165deg,
      rgba(var(--rgb-red-500), 0.75) 0%,
      rgba(var(--rgb-orange-500), 0.75) 92%
    );
  }

  .color-palette-inverted--orange {
    --bg-color-primary: var(--color-orange-700);
    --bg-color-secondary: var(--color-orange-800);
  }
`;
