import React, { CSSProperties, ReactNode, useState, useEffect } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { Flex } from '~/elements/Flex';
import { Text } from '~/elements/NewText';
import { LegalData } from '~/data/legal';
import { sm } from '~/ds/mixins/breakpoints';

export interface NavProps {
  className?: string;
  style?: CSSProperties;
}

const Wrapper = styled.aside`
  width: 100%;
  position: sticky;
  padding: var(--space-8) 0 var(--space-4) 0;
  top: 0;

  @media ${sm} {
    padding: var(--space-24) var(--space-4) 0 0;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: var(--space-8);
`;

const NavOverview = styled.ul<any>`
  display: flex;
  gap: var(--space-4);
  flex-direction: column;
`;

const NavItem = styled.li<any>``;

const PrimaryLink = styled.a<{ active?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: var(--radii-1);
  transition: background-color 0.1s, color 0.1s, box-shadow 0.1s;

  &:hover {
    background-color: var(--color-blue-50);
    box-shadow: -4px 0 0 4px var(--color-blue-50), 4px 0 0 4px var(--color-blue-50);
    ${Text} {
      color: var(--color-blue-900);
    }
  }

  &.active {
    background-color: var(--color-blue-700);
    box-shadow: -4px 0 0 4px var(--color-blue-700), 4px 0 0 4px var(--color-blue-700);
    ${Text} {
      color: var(--color-blue-50);
    }
  }

  ${Text} {
    color: var(--color-gray-500);
  }
`;

export const Sidebar: React.FC<NavProps> = () => {
  return (
    <Wrapper as="aside">
      <Nav>
        <section>
          <NavOverview>
            {LegalData.map((item, index: number) => {
              return (
                <NavItem key={index}>
                  <PrimaryLink to={item.url} as={Link} activeClassName="active">
                    <Flex gap={2} width="full">
                      <Text size="200" weight="medium">
                        {item.title}
                      </Text>
                    </Flex>
                  </PrimaryLink>
                </NavItem>
              );
            })}
          </NavOverview>
        </section>
      </Nav>
    </Wrapper>
  );
};
