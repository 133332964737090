import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './index/_hero';
import { SectionAdvantage } from './index/_advantage';
import { SectionDemos } from './index/_demos';
import { SectionWhyColumn } from './index/_why-column';
import { SectionPartners } from './index/_partners';
import { SectionCloser } from './index/_closer';
import { AllProducts } from '~/components/AllProducts';
import { Layout } from '~/components/Layout';
import { Testimonials } from '~/components/Testimonials';
import { Footer } from '~/components/Footer';

const IndexPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Column — The developer infrastructure bank</title>
        <meta
          name="description"
          content="The only nationally chartered bank built to enable developers and builders to create new financial services."
        />
      </Helmet>

      <SectionHero />
      <SectionAdvantage />
      <Testimonials ids={['brex']} />
      <SectionDemos />
      <SectionWhyColumn />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />
      <SectionPartners />
      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default IndexPage;
