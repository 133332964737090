import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductDebtFinancing: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductDebtFinancing {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a5" gradientUnits="userSpaceOnUse" x1="4" x2="14" y1="22.5" y2="22.5">
        <stop offset=".5" stopColor="var(--color-blue-600)" stopOpacity=".1" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity=".5" />
      </linearGradient>
      <linearGradient id="b5" x1="18" x2="28" href="#a5" y1="6.5" y2="6.5" />
      <linearGradient id="c5" gradientUnits="userSpaceOnUse" x1="18" x2="28" y1="13.25" y2="13.25">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity=".5" />
        <stop offset=".33" stopColor="var(--color-blue-600)" stopOpacity=".25" />
        <stop offset=".66" stopColor="var(--color-blue-600)" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity=".75" />
      </linearGradient>
      <linearGradient id="d5" x1="11" x2="21" href="#a5" y1="14.5" y2="14.5" />
      <linearGradient id="e5" x1="4" x2="14" href="#c5" y1="25.25" y2="25.25" />
      <linearGradient id="f5" x1="11" x2="21" href="#c5" y1="19.25" y2="19.25" />
      <path
        d="m14 22.5c0 .56-.38 1.08-1.01 1.5-.91.61-2.36 1-3.99 1s-3.08-.39-3.99-1c-.63-.42-1.01-.94-1.01-1.5 0-1.38 2.24-2.5 5-2.5s5 1.12 5 2.5z"
        fill="url(#a5)"
      />
      <ellipse cx="23" cy="6.5" fill="url(#b5)" rx="5" ry="2.5" />
      <path
        d="m28 6.5v11c0 1.37-2.24 2.5-5 2.5-.71 0-1.39-.07-2-.21v-5.29c0-1.02-1.24-1.91-3-2.29v-5.71c0 1.37 2.24 2.5 5 2.5s5-1.13 5-2.5z"
        fill="url(#c5)"
      />
      <ellipse cx="16" cy="14.5" fill="url(#d5)" rx="5" ry="2.5" />
      <path
        d="m14 22.5v3c0 1.37-2.24 2.5-5 2.5s-5-1.13-5-2.5v-3c0 .56.38 1.08 1.01 1.5.91.61 2.36 1 3.99 1s3.08-.39 3.99-1c.63-.42 1.01-.94 1.01-1.5z"
        fill="url(#e5)"
      />
      <path
        d="m21 14.5v7c0 1.37-2.24 2.5-5 2.5-.71 0-1.39-.07-2-.21v-1.29c0-1.02-1.24-1.91-3-2.29v-5.71c0 1.37 2.24 2.5 5 2.5s5-1.13 5-2.5z"
        fill="url(#f5)"
      />
    </IconBase>
  );
};
