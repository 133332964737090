import { createGlobalStyle } from 'styled-components';
import { Reset } from './reset';
import { SpaceTokens } from './tokens/space';
import { TypographyTokens } from './tokens/typography';
import { LayoutTokens } from './tokens/layout';
import { BorderTokens } from './tokens/border';
import { ScreenTokens } from './tokens/screen';
import { BoxShadow } from './box-shadow';
import { Base } from './base';
import { Typography } from './typography';
import { Syntax } from './syntax';
import { Components } from './components';
import { Forms } from './forms';
import { Pages } from './pages';
import { Static } from './static';
import { ColorTokens } from '~/ds/tokens/color';

const DesignSystem = createGlobalStyle`
  ${Reset}
  ${SpaceTokens}
  ${ColorTokens}
  ${SpaceTokens}
  ${TypographyTokens}
  ${LayoutTokens}
  ${BorderTokens}
  ${ScreenTokens}
  ${Typography}
  ${BoxShadow}
  ${Base}
  ${Syntax}
  ${Components}
  ${Forms}
  ${Pages}

  ${Static}
`;

export default DesignSystem;
