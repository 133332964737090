import React from 'react';
import styled from 'styled-components';
import { Section } from '~/elements/Section';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { InlineLink } from '~/elements/InlineLink';
import { Text } from '~/elements/NewText';

const EmphasizedText = styled.div`
  padding-left: var(--space-8);
  margin: var(--space-2) 0 var(--space-2) var(--space-n8);
  border-left: 2px solid var(--color-blue-300);
`;

const PhotoCaption = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  margin-right: 12px;
  border-radius: 4px;
  z-index: 3;
  padding: 8px 12px 8px 12px;
  background-color: rgba(0, 0, 0, 0.65);
  max-width: 400px;
  opacity: 0;
  backdrop-filter: blur(8px);
  transition: opacity 0.3s;

  p {
    color: #fff;
  }
`;

const PhotoWrap = styled.div`
  z-index: 2;
  position: relative;

  &:hover ${PhotoCaption} {
    display: block;
    opacity: 1;
  }
`;

const Photo = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: var(--color-gray-100);
  z-index: 1;
  position: relative;
  height: ${(props) => props.height || '520px'}; //520px is just the default height in case a height prop isn't set
`;

export const SectionStory: React.FC = () => {
  return (
    <Section className="pt-0">
      <Container>
        <Grid gapY={16} zIndex="10" position="relative">
          <Column gridColumn={{ _: '1 / span 12', md: '2 / span 7', lg: '2 / span 6' }}>
            <Grid gridTemplateColumns={1} gapY={8}>
              <Text size="300" color="gray-700">
                Financial services have changed, are changing, and will continue to change — for the better. Now is the
                time for finance to be customer and internet first. But in order to do this —{' '}
                <span className="px-2 py-1 text-gray-900 rounded-sm bg-blue-50">it needs a new backbone.</span>
              </Text>

              <EmphasizedText>
                <Text size="500" weight="medium" color="blue-700">
                  We were always told that banks can't be software companies.
                </Text>
              </EmphasizedText>

              <Text size="300" color="gray-700">
                Everyone tried fixing the problem by building abstractions, middleware, and additional layers of
                technical bloat. We have spent our careers founding and building companies like Plaid, Square, and
                Affirm experiencing this problem first hand. The existing solutions don't work.
              </Text>

              <Text size="300" color="gray-700">
                We believe that a bank needs to be a software company. But we had to start from ground zero.
              </Text>
            </Grid>
          </Column>

          <Column gridColumn={{ _: '1 / span 12' }}>
            <Grid alignItems="center" gap={4}>
              <Column gridColumn={{ _: '1 / span 12', sm: '1 / span 5' }}>
                <PhotoWrap>
                  <PhotoCaption>
                    <Text size="200">
                      We highly recommend Central Banking 101 by Joseph Wang — double points for reading upside down.
                    </Text>
                  </PhotoCaption>
                  <Photo
                    src="/images/company/book.jpg"
                    height="420px"
                    alt="Column teammate reading Banking 101 by Joseph Wang which happens to also be upside down."
                  />
                </PhotoWrap>
              </Column>
              <Column gridColumn={{ _: '1 / span 12', sm: '6 / span 7' }}>
                <PhotoWrap>
                  <PhotoCaption>
                    <Text size="200">Caption contest! Email hi@column.com to submit.</Text>
                  </PhotoCaption>
                  <Photo src="/images/company/wide.jpg" height="420px" alt="The San Francisco Column office." />
                </PhotoWrap>
              </Column>
            </Grid>
          </Column>

          <Column gridColumn={{ _: '1 / span 12', md: '2 / span 7', lg: '2 / span 6' }}>
            <Grid gridTemplateColumns={1} gapY={8}>
              <Text size="300" color="gray-700">
                We started building Column in 2019 and{' '}
                <InlineLink to="/blog/announcing-column">launched in 2022</InlineLink>. Every single component has been
                built from the ground up — with no middleware or legacy software. We are purpose-built to power
                financial services for the next hundred years.
              </Text>

              <EmphasizedText>
                <Text size="500" weight="medium" color="blue-700">
                  We are a bit of a weird company — not your standard tech or finance company.
                </Text>
              </EmphasizedText>

              <Text size="300" color="gray-700">
                We are 100% <InlineLink to="/blog/funding-column">founder and employee owned</InlineLink>, profitable,
                and <InlineLink to="/blog/hiring-at-column">we don't hire a lot</InlineLink>. Over time, we will make
                this particular page more impressive, but at this time we are focused on shipping for our customers.
              </Text>

              <Text size="300" color="gray-700">
                If you'd like to work together <InlineLink to="/careers">check out our open roles</InlineLink>.
              </Text>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionStory;
