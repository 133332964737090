import React from 'react';

export const DateTime = [
  {
    param: 'created.gt',
    type: 'date-time',
    required: false,
    description: <p>Return results where the specified time field is greater than this value.</p>,
  },
  {
    param: 'created.gte',
    type: 'date-time',
    required: false,
    description: <p>Return results where the specified time field is greater than or equal to this value.</p>,
  },
  {
    param: 'created.lt',
    type: 'date-time',
    required: false,
    description: <p>Return results where the specified time field is less than this value.</p>,
  },
  {
    param: 'created.lte',
    type: 'date-time',
    required: false,
    description: <p>Return results where the specified time field is less than or equal to this value.</p>,
  },
];
