import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Br } from '~/elements/Br';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';

const ProductSection = styled(Section)`
  overflow: hidden;
`;

export const SectionInstantTransfers: React.FC = () => {
  return (
    <ProductSection>
      <Container>
        <Grid gridTemplateColumns={12} gap={6} alignItems="center">
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Flex justifyContent="center" mb={{ _: 8, md: 0 }}>
              <ProductScreen maxWidth="430px">
                <img src="/images/products/wire/new-counterparty.jpg" />
              </ProductScreen>
            </Flex>
          </Column>

          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 0, md: 7 }} position="relative" zIndex={2}>
            <Grid gridTemplateColumns={6} gapX={7} gapY={8} mb={8}>
              <Column gridColumn={{ _: 6, md: 5 }}>
                <Heading as="h2" weight="semibold" size="700">
                  Instant transfers <Br display={{ _: 'none', md: 'block' }} />
                  with full control
                </Heading>
                <Text size="300" color="gray-500" mt={2}>
                  Programmatically send wire transfers in one API call. Funds are transferred instantly to any U.S. bank
                  account.
                </Text>
              </Column>
            </Grid>

            <Code title="Create new counterparty" fullHeight mt={6}>
              {`curl 'https://api.column.com/counterparties' \\
     -XPOST \\
     -u :<YOUR API KEY> \\
     -d routing_number="121144269" \\
     -d account_number="1234567890" \\
     -d "wire[beneficiary_name]"="John Smith" \\
     -d "wire[beneficiary_address][line_1]"="101 Market St" \\
     -d "wire[beneficiary_address][city]"="San Francisco" \\
     -d "wire[beneficiary_address][state]"="CA" \\
     -d "wire[beneficiary_address][postal_code]"="94105" \\
     -d "wire[beneficiary_address][country_code]"="USA"`}
            </Code>
          </Column>
        </Grid>
      </Container>
    </ProductSection>
  );
};
