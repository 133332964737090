import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { MarkdownInfo } from '../Markdown/Info';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { MarkdownStyles } from '~/elements/Markdown';
import { Heading } from '~/elements/Heading';
import { Layout } from '~/components/docs/Layout';

const components = { Info: MarkdownInfo };
const url = typeof window !== 'undefined' ? window.location.href : 'https://column.com/docs';

const Entry: React.FC = (props: any) => {
  return (
    <Layout>
      <Helmet>
        <title>{props.data.mdx.frontmatter.title} - Column Documentation</title>
      </Helmet>

      <Container width="medium" pt="62px">
        <Grid gap={8} justifyContent="center">
          <Column gridColumn={12} pt={12}>
            <Heading size="600" weight="semibold">
              {props.data.mdx.frontmatter.title}
            </Heading>
            <MarkdownStyles mt={3} mb={18}>
              <MDXProvider components={components}>
                <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
              </MDXProvider>
            </MarkdownStyles>
          </Column>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Entry;

export const query = graphql`
  query query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
