import { css } from 'styled-components';

export const Infrastructure = css`
  .color-palette--infrastructure {
    --list-item-bullet: var(--color-seafoam-500);
    --list-item-number: var(--color-seafoam-500);
  }

  .color-palette-inverted--infrastructure {
    --list-item-bullet: rgba(var(--rgb-white), 0.5);
    --list-item-number: rgba(var(--rgb-white), 0.5);
  }
`;
