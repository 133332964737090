import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconChangelogNew: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="2" width="17" height="16">
        <path
          d="M17.499 9.5c-2.5 0-4.18-1.18-5-2-.82-.82-2-2.5-2-5 0-.28-.22-.5-.5-.5s-.5.22-.5.5c0 2.5-1.18 4.18-2 5-.82.82-2.5 2-5 2-.28 0-.5.22-.5.5s.22.5.5.5c2.5 0 4.18 1.18 5 2 .82.82 2 2.5 2 5 0 .28.22.5.5.5s.5-.22.5-.5c0-2.5 1.18-4.18 2-5 .82-.82 2.5-2 5-2 .28 0 .5-.22.5-.5s-.22-.5-.5-.5Z"
          fill="url(#20_new__a)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M19.999 0h-20v20h20V0Z" fill="url(#20_new__c)" />
      </g>
      <defs>
        <linearGradient id="20_new__a" x1="19.785" y1="14.926" x2="7.785" y2="8.886" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="20_new__c" x1="-.001" y1="0" x2="19.999" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--blog-700, #12161E)" />
          <stop offset="1" stopColor="var(--blog-800, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
