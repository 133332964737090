import { css } from 'styled-components';

export const Infrastructure = css`
  .color-palette--infrastructure {
    --icon-color: var(--color-gray-400);
  }

  .color-palette-inverted--infrastructure {
    --icon-color: var(--color-gray-300);
  }
`;
