import React from 'react';
import styled from 'styled-components';
import { TextGroup } from '~/elements/TextGroup';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Container } from '~/elements/Container';
import { InlineLink } from '~/elements/InlineLink';
import { Link } from '~/elements/Link';
import { Text } from '~/elements/Text';

const PhotoCaption = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  border-radius: 4px;
  z-index: 3;
  padding: 8px 12px 8px 12px;
  background-color: rgba(0, 0, 0, 0.65);
  max-width: 400px;
  opacity: 0;
  backdrop-filter: blur(8px);
  transition: opacity 0.3s;

  p {
    color: #fff;
  }
`;

const PhotoWrap = styled.div`
  z-index: 2;
  position: relative;

  &:hover ${PhotoCaption} {
    display: block;
    opacity: 1;
  }
`;

const Photo = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: var(--color-gray-100);
  z-index: 1;
  position: relative;
`;

export const SectionFounders: React.FC = () => {
  return (
    <Section>
      <Container>
        <Grid gap={8}>
          <Column gridColumn={{ _: '1 / span 12', md: '1 / span 5', lg: '1 / span 6' }}>
            <PhotoWrap>
              <PhotoCaption>
                <Text size="200">We have fun here!</Text>
              </PhotoCaption>
              <Photo
                src="/images/company/polaroids.jpg"
                alt="Three polaroid prints spread on a table, each showing teammates working and smiling."
              />
            </PhotoWrap>
          </Column>
          <Column gridColumn={{ _: '1 / span 12', md: '7 / span 5', lg: '8 / span 5' }}>
            <Grid gridTemplateColumns={1} gapY={12} zIndex="10" position="relative" className="content-center h-full">
              <TextGroup size="400">
                <Text size="500" variant="heading">
                  Meet the founder
                </Text>
                <Text>
                  William Hockey is the CEO of Column. Prior to Column he co-founded Plaid, a $13b+ financial
                  infrastructure company. He sits on the board of{' '}
                  <InlineLink href="https://plaid.com">Plaid</InlineLink> and{' '}
                  <InlineLink href="https://scale.com">Scale</InlineLink>. Rumors abound that he exclusively eats carbs
                  — confirmation outstanding.
                </Text>
              </TextGroup>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionFounders;
