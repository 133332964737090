import React from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Section } from '~/elements/Section';
import { Br } from '~/elements/Br';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

const ProductSection = styled(Section)`
  overflow: hidden;
`;

const ScreenCrop = styled.div`
  overflow: hidden;
  margin: -10% -10% 0;
  padding: 10% 10% 0;
  box-shadow: 0 1px 1px 0 rgba(var(--rgb-black), 0.05);
`;

export const SectionAccounts: React.FC = () => {
  return (
    <ProductSection my={18}>
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column
            gridColumn={{ _: 12, md: 8, lg: 6 }}
            gridColumnStart={{ _: 1, md: 3, lg: 4 }}
            position="relative"
            zIndex={2}
          >
            <Heading as="h4" size="300" weight="medium" color="blue-500" textAlign="center">
              Accounts
            </Heading>
            <Heading as="h2" size="700" color="blue-800" weight="semibold" textAlign="center" mt={4}>
              Seamless <Br />
              account opening
            </Heading>
            <Text size="300" color="gray-600" m="8px auto" maxWidth="430px" textAlign="center">
              Instantly open up new accounts online. Immediately create sub-accounts to easily reconcile transactions.
            </Text>
          </Column>

          {/* <Column
            gridColumn={{ _: 10, md: 6 }}
            gridColumnStart={{ _: 2, md: 4 }}
            position="relative"
            zIndex="2"
          ></Column> */}

          <Column gridColumn={12}>
            <ScreenCrop>
              <ProductScreen mb="-13%">
                <img src="/images/specialty-deposits/screen-account.png" />
              </ProductScreen>
            </ScreenCrop>
          </Column>
        </Grid>
      </Container>
    </ProductSection>
  );
};
