import React from 'react';

export const Charges = [
  {
    param: 'charges',
    type: 'object',
    description: <p>List of fees charged by financial institutions involved in this transfer. These charges are inclusive of both the wire the wire return if applicable. Charges are only listed here if the intermediary and beneficiary banks are part of the SWIFT GPI tracking system.</p>,
    object: [
      {
        param: 'amount',
        type: 'int64',
        description: (
          <>
            <p>Charge amount (in the smallest unit of the currency).</p>
            <p>
              e.g., <code>1756</code> means 1.756 in KWD, 17.56 in USD, or 1756 in JPY.
            </p>
          </>
        ),
      },
      {
        param: 'currency_code',
        type: 'string',
        description: (
          <p>
            Charge currency. The three-letter currency code defined in ISO 4217 (e.g. <code>USD</code>)
          </p>
        ),
      },
      {
        param: 'agent',
        type: 'string',
        description: <p>The Swift BIC code of the financial institution that applied the charge. It may be empty as some financial institutions do not provide it.</p>,
      },
    ],
  },
];
