import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsAuthentication: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M7.5 8.5C5.01 8.5 3 10.51 3 13C3 15.49 5.01 17.5 7.5 17.5C9.99 17.5 12 15.49 12 13C12 10.51 9.99 8.5 7.5 8.5ZM7.5 14C6.95 14 6.5 13.55 6.5 13C6.5 12.45 6.95 12 7.5 12C8.05 12 8.5 12.45 8.5 13C8.5 13.55 8.05 14 7.5 14Z"
        fill="url(#paint0_linear_3204_743)"
      />
      <path
        d="M17 4V6C17 6.28 16.78 6.5 16.5 6.5H15.5C15.22 6.5 15 6.72 15 7V8C15 8.28 14.78 8.5 14.5 8.5H13.5C13.22 8.5 13 8.72 13 9V10.29C13 10.43 12.95 10.55 12.85 10.65L11.81 11.69C11.37 10.26 10.24 9.13 8.81 8.69L13.85 3.65C13.95 3.55 14.07 3.5 14.21 3.5H16.5C16.78 3.5 17 3.72 17 4Z"
        fill="url(#paint1_linear_3204_743)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_743"
          x1="9.27"
          y1="4.25"
          x2="6.45"
          y2="18.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_743"
          x1="0.549998"
          y1="3.17"
          x2="14.97"
          y2="7.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
