import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Typing } from '~/elements/Typing';
import CheckIcon from '~/assets/svg/fednow/check.svg';

const UseCaseSection = styled(Section)`
  position: relative;
  z-index: 1;
  padding: 100px 0 100px 0;
`;

const StyledEyebrow = styled(Heading)`
  background-color: var(--color-cyan-800);
  color: var(--color-white);
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  margin: 0 0 48px 0;
  box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.05);
  line-height: 1;

  .typing {
    &::after {
      display: none;
    }
  }
`;

const UseCaseCard = styled.div`
  background-color: rgba(var(--rgb-cyan-600), 0.075);
  backdrop-filter: blur(32px);
  box-shadow: inset 0 1px 1px rgba(var(--rgb-white), 1), inset 0 -1px 1px rgba(var(--rgb-cyan-800), 0.05);
  padding: 24px 32px;
  border-radius: 16px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

  background-color: rgba(var(--rgb-white), 0.4);
  backdrop-filter: blur(32px);
  box-shadow: inset 0 1px 1px rgba(var(--rgb-white), 1), 0 40px 80px rgba(var(--rgb-cyan-700), 0.03);
`;

export const SectionUseCases: React.FC = () => {
  return (
    <UseCaseSection>
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column gridColumn={12}>
            <Flex flexDirection="column" alignItems="center" justifyContent="center">
              <StyledEyebrow size="200" weight="medium">
                Us
                <Typing text="e Cases" />
              </StyledEyebrow>
              <Heading as="h2" weight="semibold" size="1000" color="cyan-800" textAlign="center">
                Game changing possibilities
              </Heading>
              <Text size="500" weight="semibold" mt={4} color="cyan-800" textAlign="center">
                Endless opportunity to improve the payments experience.
              </Text>
            </Flex>
          </Column>
          <Column gridColumn={{ _: 12, md: 10 }} gridColumnStart={{ _: 1, md: 2 }} mt="12">
            <Grid gridTemplateColumns={{ _: 1, sm: 2 }} gap={6}>
              <UseCaseCard>
                <CheckIcon viewportSize={20} />
                <Heading as="h3" pt="4" pb="2" weight="semibold" size="400" color="cyan-800">
                  Accounts Payable
                </Heading>
                <Text maxWidth="280px" size="200" color="rgba(var(--rgb-cyan-800), 0.75)">
                  Immediately pay invoices to avoid penalties, or pay last minute to manage working capital.
                </Text>
              </UseCaseCard>
              <UseCaseCard>
                <CheckIcon viewportSize={20} />
                <Heading as="h3" pt="4" pb="2" weight="semibold" size="400" color="cyan-800">
                  Accounts Receivable
                </Heading>
                <Text maxWidth="280px" size="200" color="rgba(var(--rgb-cyan-800), 0.75)">
                  Request instant payment for services with no risk of returns.
                </Text>
              </UseCaseCard>
              <UseCaseCard>
                <CheckIcon viewportSize={20} />
                <Heading as="h3" pt="4" pb="2" weight="semibold" size="400" color="cyan-800">
                  Reimbursements
                </Heading>
                <Text maxWidth="280px" size="200" color="rgba(var(--rgb-cyan-800), 0.75)">
                  Pay your employees in real time or facilitate other non-recurring disbursements.
                </Text>
              </UseCaseCard>
              <UseCaseCard>
                <CheckIcon viewportSize={20} />
                <Heading as="h3" pt="4" pb="2" weight="semibold" size="400" color="cyan-800">
                  Digital Wallets
                </Heading>
                <Text maxWidth="280px" size="200" color="rgba(var(--rgb-cyan-800), 0.75)">
                  Fund in and cash out of virtual wallets instantly.
                </Text>
              </UseCaseCard>
              <UseCaseCard>
                <CheckIcon viewportSize={20} />
                <Heading as="h3" pt="4" pb="2" weight="semibold" size="400" color="cyan-800">
                  Cash Management
                </Heading>
                <Text maxWidth="280px" size="200" color="rgba(var(--rgb-cyan-800), 0.75)">
                  Treasury management done right. Move your money when you need to. Instantly.
                </Text>
              </UseCaseCard>
              <UseCaseCard>
                <CheckIcon viewportSize={20} />
                <Heading as="h3" pt="4" pb="2" weight="semibold" size="400" color="cyan-800">
                  P2P Payments
                </Heading>
                <Text maxWidth="280px" size="200" color="rgba(var(--rgb-cyan-800), 0.75)">
                  Instantly send and receive money across banks to friends, family, and acquaintances.
                </Text>
              </UseCaseCard>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </UseCaseSection>
  );
};

export default SectionUseCases;
