import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconChangelogFixed: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="2" width="15" height="15">
        <path
          d="M15.389 12.39a5.492 5.492 0 0 1-5.98 1.2l-2 2c-.78.78-2.04.78-2.82 0l-.18-.18c-.78-.78-.78-2.04 0-2.82l2-2a5.492 5.492 0 0 1 1.2-5.98 5.492 5.492 0 0 1 5.98-1.2L9.999 7l.5 2.5 2.5.5 3.59-3.59c.82 1.99.42 4.36-1.2 5.98Z"
          fill="url(#20_fix__a)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M19.999 0h-20v20h20V0Z" fill="url(#20_fix__c)" />
      </g>
      <defs>
        <linearGradient id="20_fix__a" x1="-5.026" y1="6.74" x2="15.108" y2="10.847" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="20_fix__c" x1="-.001" y1="0" x2="19.999" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--blog-700, #12161E)" />
          <stop offset="1" stopColor="var(--blog-800, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
