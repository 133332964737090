import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductBookTransfers: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductBookTransfers {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a2">
        <stop offset="0" stopColor="#1e4199" />
        <stop offset="1" stopColor="#1e4199" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="b2" gradientUnits="userSpaceOnUse" x1="10.38" x2="46.93" href="#a2" y1="19" y2="19" />
      <linearGradient id="c2" gradientUnits="userSpaceOnUse" x1="3.16" x2="39.71" href="#a2" y1="19" y2="19" />
      <linearGradient id="d2" x1="-11.67" x2="26.63" href="#b2" y1="13" y2="13" />
      <linearGradient id="e2" x1="-14.59" x2="19.57" href="#c2" y1="13" y2="13" />
      <path
        d="m26.55 9.11-15.45 7.72c-.67.34-1.1 1.03-1.1 1.79v9.38c0 .74.78 1.22 1.45.89l15.44-7.72c.68-.34 1.11-1.03 1.11-1.79v-9.38c0-.74-.78-1.23-1.45-.89zm-7.55 12.98c-1.66.65-3-.2-3-1.91s1.34-3.62 3-4.27 3 .2 3 1.91-1.34 3.62-3 4.27z"
        fill="url(#b2)"
      />
      <path d="m22 17.82c0 1.7-1.34 3.62-3 4.27s-3-.2-3-1.91 1.34-3.62 3-4.27 3 .2 3 1.91z" fill="url(#c)" />
      <path
        d="m20.55 3.11-15.45 7.72c-.67.34-1.1 1.03-1.1 1.79v9.38c0 .74.78 1.22 1.45.89l4.55-2.27v-2c0-.76.43-1.45 1.1-1.79l1.06-.53c-1.25.12-2.16-.71-2.16-2.12 0-1.7 1.34-3.62 3-4.27s3 .2 3 1.91c0 1.19-.65 2.48-1.61 3.37l7.61-3.81v-7.38c0-.74-.78-1.23-1.45-.89z"
        fill="url(#d2)"
      />
      <path
        d="m16 11.82c0 1.19-.65 2.48-1.61 3.37l-2.23 1.11c-1.25.12-2.16-.71-2.16-2.12 0-1.7 1.34-3.62 3-4.27s3 .2 3 1.91z"
        fill="url(#e2)"
      />
    </IconBase>
  );
};
