import React from 'react';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { Flex } from '~/elements/Flex';

export const SectionDataTransparency: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }} mb={18}>
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 4 }} position="relative" zIndex={2}>
              <Heading as="h2" size="700" weight="semibold" textAlign="center">
                Full data transparency
              </Heading>
              <Text size="300" color="gray-500" mt={2} textAlign="center">
                Control every aspect of the data sent and received in domestic and international wires. We expose all
                the raw details so you can reconcile and have full visibility.
              </Text>
            </Column>

            <Column gridColumn={12} position="relative" zIndex="2">
              <Grid gridTemplateColumns={{ _: 1, md: 2 }} gap={8}>
                <Column>
                  <Code maxHeight="275px">
                    {`{
  "account_number_id": "acno_25nacNsLD8qLI1Vc6x67sxFU27c",
  "allow_overdraft": false,
  "amount": "100000",
  "bank_account_id": "bacc_25nVQr05nZybpyEzw8j0wV6VRUh",
  "beneficiary_account_number": "256783259046169",
  "beneficiary_name": "Column account",
  "completed_at": "2022-03-02T00:05:54Z",
  "counterparty_id": "cpty_25QihOzMbXhGQVNHQmeIp6UJNnj",
  "created_at": "2022-03-02T00:05:54Z",
  "currency_code":  "USD",
  "description": "incoming CTR wire transfer",
  "id": "wire_25o3LGGbxLjpabTm6zHquAM1ti2",
  "idempotency_key": "",
  "imad": "20220301CHASE001000001",
  "initiated_at": "2022-03-02T00:05:54Z",
  "is_incoming": true,
  "is_on_us": false,
  "manual_review_at": null,
  "omad": "20220301MMQFMC2U000001",
  "originator_account_number": "1234567890",
  "originator_name": "Chase account",
  "pending_submission_at": null,
  "previous_message_reference": "",
  "raw_beneficiary_address": "",
  "raw_originator_address": "",
  "receiver_di_name": "Column Bank",
  "receiver_di_routing_number": "091000019",
  "rejected_at": null,
  "sender_di_name": "TestBank",
  "sender_di_routing_number": "322271627",
  "sender_reference": "",
  "status": "COMPLETED",
  "submitted_at": null,
  "subtype_code": "00",
  "type_code": "10",
  "updated_at": "2022-03-02T00:05:54Z"
}`}
                  </Code>
                </Column>

                <Column>
                  <Flex flexDirection="column" gap={4}>
                    <div>
                      <Code title="Get wire transfers list">
                        {`curl 'https://api.column.com/transfers/wire' \\
       -u :<YOUR API KEY>`}
                      </Code>
                    </div>

                    <div>
                      <Code title="Get single wire transfer" mb={0}>
                        {`curl 'https://api.column.com/transfers/wire/<wire_transfer_id>' \\
    -u :<YOUR API KEY>`}
                      </Code>
                    </div>
                  </Flex>
                </Column>
              </Grid>
            </Column>

            <Column gridColumn={{ _: 12, md: 10 }} gridColumnStart={{ _: 1, md: 2 }}>
              <ProductScreen mt={6} mb={'-38%'} backgroundColor="#fbfcfd">
                <img src="/images/products/wire/new-wire-transfer.png" />
              </ProductScreen>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};
