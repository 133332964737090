import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductDayOne: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductDayOne {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
        <path
          d="M28 5.8v20.3c0 .6-1.6 1.2-3.5 1.2s-3.5-.5-3.5-1.1V5.8c0 .7 1.6 1.2 3.5 1.2S28 6.5 28 5.8z"
          fill="url(#day-one__paint0_linear)"
        />
        <path
          d="M19.5 13v13.2c0 .6-1.6 1.2-3.5 1.2s-3.5-.5-3.5-1.2V13c0 .6 1.6 1.2 3.5 1.2s3.5-.6 3.5-1.2z"
          fill="url(#day-one__paint1_linear)"
        />
        <path
          d="M16 14.2c1.933 0 3.5-.537 3.5-1.2 0-.663-1.567-1.2-3.5-1.2s-3.5.537-3.5 1.2c0 .663 1.567 1.2 3.5 1.2z"
          fill="url(#day-one__paint2_linear)"
        />
        <path
          d="M7.5 21.3c1.933 0 3.5-.537 3.5-1.2 0-.663-1.567-1.2-3.5-1.2S4 19.437 4 20.1c0 .663 1.567 1.2 3.5 1.2z"
          fill="url(#day-one__paint3_linear)"
        />
        <path
          d="M24.5 7c1.933 0 3.5-.537 3.5-1.2 0-.663-1.567-1.2-3.5-1.2S21 5.137 21 5.8c0 .663 1.567 1.2 3.5 1.2z"
          fill="url(#day-one__paint4_linear)"
        />
        <path
          d="M11 20.1v6.1c0 .6-1.6 1.2-3.5 1.2S4 26.8 4 26.2v-6.1c0 .6 1.6 1.2 3.5 1.2s3.5-.6 3.5-1.2z"
          fill="url(#day-one__paint5_linear)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#day-one__paint6_linear)" />
      </g>
      <defs>
        <linearGradient
          id="day-one__paint0_linear"
          x1="21"
          y1="16.583"
          x2="28"
          y2="16.583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity=".1" />
          <stop offset=".401" />
          <stop offset=".8" stopOpacity=".2" />
          <stop offset="1" stopOpacity=".7" />
        </linearGradient>
        <linearGradient
          id="day-one__paint1_linear"
          x1="12.5"
          y1="20.146"
          x2="19.5"
          y2="20.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity=".1" />
          <stop offset=".401" />
          <stop offset=".8" stopOpacity=".2" />
          <stop offset="1" stopOpacity=".7" />
        </linearGradient>
        <linearGradient
          id="day-one__paint2_linear"
          x1="16"
          y1="10.42"
          x2="16"
          y2="14.332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="day-one__paint3_linear"
          x1="7.5"
          y1="17.545"
          x2="7.5"
          y2="21.458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="day-one__paint4_linear"
          x1="24.5"
          y1="3.295"
          x2="24.5"
          y2="7.208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="day-one__paint5_linear"
          x1="4"
          y1="23.708"
          x2="11"
          y2="23.708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity=".1" />
          <stop offset=".401" />
          <stop offset=".8" stopOpacity=".2" />
          <stop offset="1" stopOpacity=".7" />
        </linearGradient>
        <linearGradient id="day-one__paint6_linear" x1="32" y1="0" x2="0" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-gradient-stop-1, #12161E)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
