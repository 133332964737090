import { IconChangelogNew } from './ChangelogNew';
import { IconChangelogImproved } from './ChangelogImproved';
import { IconChangelogFixed } from './ChangelogFixed';
import { IconBlogHome } from './BlogHome';
import { IconFormError } from './FormError';
import { IconFormSuccess } from './FormSuccess';

export const IconBlog = {
  ChangelogNew: IconChangelogNew,
  ChangelogImproved: IconChangelogImproved,
  ChangelogFixed: IconChangelogFixed,
  BlogHome: IconBlogHome,
  FormError: IconFormError,
  FormSuccess: IconFormSuccess,
};
