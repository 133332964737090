export default {
  'code[class*="language-"]': {
    fontFamily: 'SFMono, SF Mono, ui-monospace, Menlo, Consolas, Liberation Mono, monospace',
    fontSize: '0.75rem',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    background: 'rgba(var(--rgb-blue-800), 1)',
    color: 'white',
  },
  'pre[class*="language-"]': {
    fontFamily: 'SFMono, SF Mono, ui-monospace, Menlo, Consolas, Liberation Mono, monospace',
    fontSize: '0.75rem',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    overflow: 'auto',
    marginButton: 0,
  },
  'pre[class*="language-"]::-moz-selection': {
    background: '#304259',
  },
  'pre[class*="language-"] ::-moz-selection': {
    background: '#304259',
  },
  'code[class*="language-"]::-moz-selection': {
    background: '#304259',
  },
  'code[class*="language-"] ::-moz-selection': {
    background: '#304259',
  },
  'pre[class*="language-"]::selection': {
    background: '#304259',
  },
  'pre[class*="language-"] ::selection': {
    background: '#304259',
  },
  'code[class*="language-"]::selection': {
    background: '#304259',
  },
  'code[class*="language-"] ::selection': {
    background: '#304259',
  },
  ':not(pre) > code[class*="language-"]': {
    background: '#111b27',
    padding: '0.1em 0.3em',
    borderRadius: '0.3em',
    whiteSpace: 'normal',
  },
  comment: {
    color: 'rgba(var(--rgb-white), 0.4)',
    textAlign: 'left',
  },
  prolog: {
    color: '#ccd6e4',
    fontStyle: 'italic',
  },
  doctype: {
    color: '#ccd6e4',
    fontStyle: 'italic',
  },
  cdata: {
    color: '#ccd6e4',
    fontStyle: 'italic',
  },
  punctuation: {
    color: '#e3e9f2',
  },
  selector: {
    color: '#5dc2c2',
  },
  tag: {
    color: '#5dc2c2',
  },
  'attr-name': {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  // eslint-disable-next-line id-blacklist
  boolean: {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  // eslint-disable-next-line id-blacklist
  number: {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  constant: {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  'pseudo-class': {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  'pseudo-element': {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  'selector.attribute': {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  'class-name': {
    color: 'rgba(var(--rgb-blue-200), 1)',
  },
  key: {
    color: 'rgba(var(--rgb-blue-200), 1)',
  },
  parameter: {
    color: 'rgba(var(--rgb-blue-200), 1)',
  },
  property: {
    color: 'rgba(var(--rgb-blue-200), 1)',
  },
  'property-access': {
    color: 'rgba(var(--rgb-blue-200), 1)',
  },
  variable: {
    color: 'rgba(var(--rgb-cyan-300), 1)',
  },
  'attr-value': {
    color: 'rgba(var(--rgb-seafoam-500), 1)',
  },
  inserted: {
    color: 'rgba(var(--rgb-seafoam-500), 1)',
  },
  color: {
    color: 'rgba(var(--rgb-seafoam-500), 1)',
  },
  'selector.value': {
    color: 'rgba(var(--rgb-seafoam-500), 1)',
  },
  // eslint-disable-next-line id-blacklist
  string: {
    color: 'rgba(var(--rgb-orange-300), 1)',
  },
  'string.url-link': {
    color: 'rgba(var(--rgb-seafoam-500), 1)',
  },
  builtin: {
    color: '#ea89ea',
  },
  'keyword-array': {
    color: '#ea89ea',
  },
  package: {
    color: '#ea89ea',
  },
  regex: {
    color: '#ea89ea',
  },
  function: {
    color: 'rgba(var(--rgb-purple-300), 1)',
  },
  'selector.class': {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  'selector.id': {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  'atrule.rule': {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  combinator: {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  keyword: {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  operator: {
    color: 'rgba(var(--rgb-orange-300), 1)',
  },
  'selector.token.operator': {
    color: 'rgba(var(--rgb-orange-300), 1)',
  },
  unit: {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  deleted: {
    color: '#f57a73',
  },
  important: {
    color: '#f57a73',
    fontWeight: 'bold',
  },
  'keyword-this': {
    color: 'rgba(var(--rgb-blue-200), 1)',
    fontWeight: 'bold',
  },
  this: {
    color: 'rgba(var(--rgb-blue-200), 1)',
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  entity: {
    cursor: 'help',
  },
  '.language-markdown .token.title': {
    color: 'rgba(var(--rgb-blue-200), 1)',
    fontWeight: 'bold',
  },
  '.language-markdown .token.title .token.punctuation': {
    color: 'rgba(var(--rgb-blue-200), 1)',
    fontWeight: 'bold',
  },
  '.language-markdown .token.code': {
    color: '#5dc2c2',
  },
  '.language-markdown .token.url .token.content': {
    color: 'rgba(var(--rgb-seafoam-500), 1)',
  },
  '.language-markdown .token.url-link': {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  '.language-markdown .token.list.punctuation': {
    color: '#ea89ea',
  },
  '.language-markdown .token.table-header': {
    color: '#e3e9f2',
  },
  '.language-json .token.operator': {
    color: '#e3e9f2',
  },
  'tab:not(:empty):before': {
    color: '#ccd6e4',
  },
  'cr:before': {
    color: '#ccd6e4',
  },
  'lf:before': {
    color: '#ccd6e4',
  },
  'space:before': {
    color: '#ccd6e4',
  },
  'div.code-toolbar > .toolbar a': {
    color: '#111b27',
    background: 'rgba(var(--rgb-blue-200), 1)',
  },
  'div.code-toolbar > .toolbar button': {
    color: '#111b27',
    background: 'rgba(var(--rgb-blue-200), 1)',
  },
  'div.code-toolbar > .toolbar a:hover': {
    color: '#111b27',
    background: 'rgba(var(--rgb-blue-200), 1)da',
    textDecoration: 'none',
  },
  'div.code-toolbar > .toolbar a:focus': {
    color: '#111b27',
    background: 'rgba(var(--rgb-blue-200), 1)da',
    textDecoration: 'none',
  },
  'div.code-toolbar > .toolbar button:hover': {
    color: '#111b27',
    background: 'rgba(var(--rgb-blue-200), 1)da',
    textDecoration: 'none',
  },
  'div.code-toolbar > .toolbar button:focus': {
    color: '#111b27',
    background: 'rgba(var(--rgb-blue-200), 1)da',
    textDecoration: 'none',
  },
  'div.code-toolbar > .toolbar span': {
    color: '#111b27',
    background: '#ccd6e4',
  },
  'div.code-toolbar > .toolbar span:hover': {
    color: '#111b27',
    background: '#ccd6e4',
  },
  'div.code-toolbar > .toolbar span:focus': {
    color: '#111b27',
    background: '#ccd6e4',
  },
  '.line-highlight': {
    background: 'linear-gradient(to right, #ccd6e41f 70%, #ccd6e415)',
  },
  '.line-highlight:before': {
    backgroundColor: '#ccd6e4',
    color: '#111b27',
    boxShadow: '0 1px #304259',
  },
  '.line-highlight[data-end]:after': {
    backgroundColor: '#ccd6e4',
    color: '#111b27',
    boxShadow: '0 1px #304259',
  },
  'pre[id].linkable-line-numbers span.line-numbers-rows > span:hover:before': {
    backgroundColor: '#ccd6e418',
  },
  '.line-numbers .line-numbers-rows': {
    borderRight: '1px solid #0b121b',
    background: '#0b121b7a',
  },
  '.line-numbers-rows > span:before': {
    color: '#e3e9f296',
  },
  '.rainbow-braces .token.punctuation.brace-level-1': {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  '.rainbow-braces .token.punctuation.brace-level-5': {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  '.rainbow-braces .token.punctuation.brace-level-9': {
    color: 'rgba(var(--rgb-pink-200), 1)',
  },
  '.rainbow-braces .token.punctuation.brace-level-2': {
    color: '#ea89ea',
  },
  '.rainbow-braces .token.punctuation.brace-level-6': {
    color: '#ea89ea',
  },
  '.rainbow-braces .token.punctuation.brace-level-10': {
    color: '#ea89ea',
  },
  '.rainbow-braces .token.punctuation.brace-level-3': {
    color: 'rgba(var(--rgb-blue-200), 1)',
  },
  '.rainbow-braces .token.punctuation.brace-level-7': {
    color: 'rgba(var(--rgb-blue-200), 1)',
  },
  '.rainbow-braces .token.punctuation.brace-level-11': {
    color: 'rgba(var(--rgb-blue-200), 1)',
  },
  '.rainbow-braces .token.punctuation.brace-level-4': {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  '.rainbow-braces .token.punctuation.brace-level-8': {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  '.rainbow-braces .token.punctuation.brace-level-12': {
    color: 'rgba(var(--rgb-orange-400), 1)',
  },
  'pre.diff-highlight > code .token.deleted:not(.prefix)': {
    backgroundColor: '#f57a731f',
  },
  'pre > code.diff-highlight .token.deleted:not(.prefix)': {
    backgroundColor: '#f57a731f',
  },
  'pre.diff-highlight > code .token.inserted:not(.prefix)': {
    backgroundColor: 'rgba(var(--rgb-seafoam-500), 1)1f',
  },
  'pre > code.diff-highlight .token.inserted:not(.prefix)': {
    backgroundColor: 'rgba(var(--rgb-seafoam-500), 1)1f',
  },
  '.command-line-prompt': {
    borderRight: '1px solid #0b121b',
  },
  '.command-line-prompt > span:before': {
    color: '#e3e9f296',
  },
};
