import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Hero } from '~/components/Hero';
import { Typing } from '~/elements/Typing';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { TextGroup } from '~/elements/TextGroup';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

const PhotoCaption = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  margin-right: 12px;
  border-radius: 4px;
  z-index: 3;
  padding: 8px 12px 8px 12px;
  background-color: rgba(0, 0, 0, 0.65);
  max-width: 400px;
  opacity: 0;
  backdrop-filter: blur(8px);
  transition: opacity 0.3s;

  p {
    color: #fff;
  }
`;

const PhotoWrap = styled.div`
  z-index: 2;
  position: relative;

  &:hover ${PhotoCaption} {
    display: block;
    opacity: 1;
  }
`;

const Photo = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: var(--color-gray-100);
  z-index: 1;
  position: relative;
  height: ${(props) => props.height || '520px'}; //520px is just the default height in case a height prop isn't set
`;

export const SectionHero: React.FC = () => {
  const [gradientRef, heroInView] = useInView({
    threshold: 0,
  });

  const heroClasses: ConditionalClasses = {
    'inview--gradient': heroInView,
  };

  return (
    <Hero className={classNames(heroClasses)}>
      <Grid alignItems="center" gap={8} zIndex="10" position="relative">
        <Column gridColumn={{ _: '1 / span 12', md: '2 / span 6' }}>
          <Heading as="h4" weight="semibold" size="400" mb={8}>
            Careers
          </Heading>
          <TextGroup>
            <Heading weight="semibold" as="h1" size="600">
              Come help build the backbone of modern financial services.
            </Heading>
            <Heading as="p" weight="semibold" size="400" mt={4}>
              But be ready for it to be a
              <Typing text=" bit weird..." />
            </Heading>
          </TextGroup>
        </Column>
      </Grid>

      <Grid alignItems="center" gap={4} mt={16}>
        <Column gridColumn={'1 / span 12'} mt={0}>
          <PhotoWrap>
            <PhotoCaption>
              <Text size="200">All Column employees subject to duplication. Join at your own risk.</Text>
            </PhotoCaption>
            <Photo
              src="/images/careers/many.jpg"
              height="480px"
              alt="Digitally altered image of the same Column employee working in multiple parts of the office."
            />
          </PhotoWrap>
        </Column>
      </Grid>

      <div className="absolute bottom-0 left-0 right-0 overflow-hidden -top-96" style={{ height: 1200 }}>
        <div className="background-gradient" ref={gradientRef}>
          <div className="background-gradient-pattern" />
        </div>
      </div>
    </Hero>
  );
};

export default SectionHero;
