import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Sidebar } from './Sidebar';
import { Header } from './Header';
import DesignSystem from '~/ds';
import columnDS from '~/ds/ds';
import { Flex } from '~/elements/Flex';
import { Box } from '~/elements/Box';

const DocsBody = styled(Flex)`
  position: relative;
  z-index: 0;
`;

const url = typeof window !== 'undefined' ? window.location.href : 'https://column.com/docs';

export const Layout = (props: any) => {
  return (
    <ThemeProvider theme={columnDS}>
      <DesignSystem />

      <Helmet htmlAttributes={{ lang: 'en-us' }}>
        <link
          rel="preload"
          as="font"
          href="/fonts/SuisseIntl-Regular.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link rel="preload" as="font" href="/fonts/SuisseIntl-Book.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/SuisseIntl-Medium.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link
          rel="preload"
          as="font"
          href="/fonts/SuisseIntl-SemiBold.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <meta name="viewport" content="width=1390, height=device-height" />

        <meta property="og:site_name" content="Column Documentation" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content="Column Documentation - The developer infrastructure bank" />
        <meta
          property="og:description"
          content="The only nationally chartered bank built to enable developers and builders to create new financial products"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://column.com/images/social/column-og.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter share */}
        <meta property="twitter:site_name" content="Column - The developer infrastructure bank" />
        <meta property="twitter:site" content="@columnbank" />
        <meta property="twitter:url" content="https://column.com" />
        <meta
          property="twitter:description"
          content="The only nationally chartered bank built to enable developers and builders to create new financial products"
        />
        <meta name="twitter:image:src" content="https://column.com/images/social/column-og.png" />
      </Helmet>

      <Header />

      <DocsBody className="docs-body">
        <Sidebar />
        <Box as="main" width="full" position="relative" overflow="hidden">
          {props.children}
        </Box>
      </DocsBody>
    </ThemeProvider>
  );
};
