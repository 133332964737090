import React from 'react';
import { Section } from '~/elements/Section';
import { Button } from '~/elements/Button';
import { Grid } from '~/elements/Grid';
import { Container } from '~/elements/Container';
import { Column } from '~/elements/Column';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

export const SectionBounty: React.FC = () => {
  return (
    <Section>
      <Container>
        <Grid gap={8}>
          <Column gridColumn={{ _: 12, md: '2 / span 7' }}>
            <Text size="500" color="gray-900" weight="medium">
              We recognize that security vulnerabilities are a threat to all businesses today. Through our bug bounty
              program, we encourage and welcome researchers to look for issues and report them. Valid and in-scope
              reports are eligible for payment.
            </Text>

            <Heading as="h3" mt={8} weight="medium">
              To participate in the Column bug bounty program, contact us.
            </Heading>
            <ButtonGroup className="mt-4">
              <Button to="mailto:security@column.com" variant="primary">
                security@column.com
              </Button>
            </ButtonGroup>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionBounty;
