import { css } from 'styled-components';
import { Primary } from './primary/list';
import { Blue } from './blue/list';
import { Green } from './green/list';
import { Orange } from './orange/list';
import { Cyan } from './cyan/list';
import { Infrastructure } from './infrastructure/list';
import { Lending } from './lending/list';

export const List = css`
  ${Primary}
  ${Blue}
  ${Green}
  ${Orange}
  ${Cyan}
  ${Infrastructure}
  ${Lending}
`;
