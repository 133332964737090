import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductLoanPurchase: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductLoanPurchase {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a9">
        <stop offset="0" stopColor="var(--color-blue-600)" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="b9" gradientUnits="userSpaceOnUse" x1="38.08" x2="-.59" href="#a9" y1="9.15" y2="9.15" />
      <linearGradient id="c9" gradientUnits="userSpaceOnUse" x1="46.32" x2="13.41" y1="44.09" y2="11.18">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity="0" />
        <stop offset="1" stopColor="var(--color-blue-600)" />
      </linearGradient>
      <linearGradient id="d9" gradientUnits="userSpaceOnUse" x1="7" x2="19" y1="23.98" y2="23.98">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity=".5" />
        <stop offset=".33" stopColor="var(--color-blue-600)" stopOpacity=".25" />
        <stop offset=".66" stopColor="var(--color-blue-600)" stopOpacity=".75" />
        <stop offset="1" stopColor="var(--color-blue-600)" />
      </linearGradient>
      <linearGradient id="e9" gradientUnits="userSpaceOnUse" x1="32.51" x2="-6.16" href="#a9" y1="9.23" y2="9.23" />
      <linearGradient id="f9" x1="-12.8" x2="9.83" href="#e9" y1="34.3" y2="11.67" />
      <linearGradient id="g9" gradientUnits="userSpaceOnUse" x1="17" x2="29" y1="12.98" y2="12.98">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity=".2" />
        <stop offset=".5" stopColor="var(--color-blue-600)" stopOpacity=".1" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity=".5" />
      </linearGradient>
      <linearGradient id="h9" x1="7" x2="19" href="#g9" y1="20.98" y2="20.98" />
      <linearGradient id="i9" x1="17" x2="29" href="#d9" y1="18.48" y2="18.48" />
      <path d="m15.51 9.15c0 .56-1.35 1.01-3 1.01s-3-.45-3-1.01 1.35-1.02 3-1.02 3 .46 3 1.02z" fill="url(#b9)" />
      <path
        d="m22 9.59v1.42c-2.84.16-5 .98-5 1.97v6.52c-1.06-.33-2.46-.52-4-.52-.85 0-1.67.06-2.4.17v-6.84l11.04-3.68c.23.26.36.6.36.96z"
        fill="url(#c9)"
      />
      <path d="m19 20.98v4c0 1.1-2.69 2-6 2s-6-.9-6-2v-4c0 1.1 2.69 2 6 2s6-.9 6-2z" fill="url(#d9)" />
      <path
        d="m20.97 8.17-5.62-1.87c-.62-.21-1.28-.21-1.9 0l-9.42 3.14c-.27.09-.51.25-.68.46l7.25 2.41 11.04-3.68c-.16-.21-.4-.37-.67-.46zm-8.46 1.99c-1.65 0-3-.45-3-1.01s1.35-1.02 3-1.02 3 .46 3 1.02-1.35 1.01-3 1.01z"
        fill="url(#e9)"
      />
      <path
        d="m10.6 12.31v6.84c-2.12.31-3.6 1.01-3.6 1.83v.06l-2.97-.99c-.62-.2-1.03-.78-1.03-1.42v-7.77c0-.36.13-.7.35-.96z"
        fill="url(#f9)"
      />
      <ellipse cx="23" cy="12.98" fill="url(#g9)" rx="6" ry="2" />
      <ellipse cx="13" cy="20.98" fill="url(#h9)" rx="6" ry="2" />
      <path
        d="m29 12.98v9c0 1.1-2.69 2-6 2-1.54 0-2.94-.19-4-.52v-2.48c0-.59-.77-1.12-2-1.48v-6.52c0 1.1 2.69 2 6 2s6-.9 6-2z"
        fill="url(#i9)"
      />
    </IconBase>
  );
};
