import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { Icon } from '~/elements/Icon';
import { Link } from '~/elements/Link';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

export interface CardProps extends LayoutProps {
  to: string;
  subtitle?: string;
  title: string;
  description: string;
  cta?: string;
}

const CardIcon = styled(Icon.Guides)`
  width: 24px;
  height: 24px;
  margin-bottom: var(--space-6);
  color: var(--color-blue-600);
`;

const Subtitle = styled(Heading)``;

const Description = styled(Text)``;

const Cta = styled.div`
  .btn-link {
    color: var(--color-blue-800);
  }
`;

const CardWrapper = styled(Link)`
  background-color: var(--color-white);
  padding: var(--space-6);
  border-radius: var(--space-3);
  top: var(--space-4);
  height: 100%;
  min-height: 250px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 0 1px var(--color-gray-100), 0 1px 3px rgba(var(--rgb-black), 0.05);
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 0 1px var(--color-gray-200), 0 4px 6px rgba(var(--rgb-black), 0.1);
  }

  ${Cta} {
    .btn-link {
      color: var(--color-gray-900);
    }
  }

  ${layout}
`;

export const Card: React.FC<CardProps> = (props) => {
  return (
    <CardWrapper {...props}>
      <Column flexGrow="1">
        <CardIcon />
        {props.subtitle ? (
          <Subtitle size="200" weight="book" color="gray-400">
            {props.subtitle}
          </Subtitle>
        ) : null}

        <Heading size="300" weight="medium" as="h3" color="blue-700">
          {props.title}
        </Heading>

        <Description size="200" color="gray-600" mt={2}>
          {props.description}
        </Description>
      </Column>
    </CardWrapper>
  );
};

Card.defaultProps = {
  cta: 'Read more',
};
