import React, { useState, FormEvent, ChangeEvent, useMemo } from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Input } from '~/elements/Input';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Button } from '~/elements/Button';
import { Icon } from '~/elements/Icon';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FormLabel = styled.label`
  display: flex;
  flex-direction: column;
  line-height: 16px;
  font-size: 14px;
  font-weight: var(--font-medium);
  gap: 4px;

  small {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-gray-500);
  }
`;

const FormField = styled.div`
  display: block;
`;

const ErrorText = styled(Text)`
  padding: 12px 16px;
  border-radius: 8px;
  background: var(--color-red-100);
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid var(--color-red-200);
  color: var(--color-red-600);
  display: flex;
`;

const SuccessText = styled(ErrorText)`
  background: var(--color-seafoam-100);
  color: var(--color-seafoam-700);
  border-color: var(--color-seafoam-500);
`;

const validateEmail = (value: string) => {
  return /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/.test(
    value
  );
};

const reCaptcha = (success: (reCaptchaToken: string) => void, error: (error: any) => void) => {
  (window as any).grecaptcha.ready(() => {
    (window as any).grecaptcha
      .execute('6Leh8AQfAAAAAEPSCKOFfX053-T22KKBqtd5YB0U', { action: 'submit' })
      .then(success)
      .catch(error);
  });
};

export const SectionContact: React.FC = (props: any) => {
  const [currentState, setCurrentState] = useState<'success' | 'error' | 'touched' | 'untouched'>('untouched');
  const [name, setName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [volume, setVolume] = useState<string>('');
  const [comments, setComments] = useState<string>('');

  // prepare custom error states on inputs
  // const isFormTouched = useMemo(() => currentState !== 'untouched', [currentState]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setCurrentState('touched');

    if (email.length < 1 || !validateEmail(email) || name.length < 1 || company.length < 1 || volume.length < 1) {
      setCurrentState('error');
      return;
    }

    reCaptcha(
      (reCaptchaToken) => {
        fetch('https://api.column.com/website/contact-us', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'g-recaptcha-response': reCaptchaToken,
            'Live-Mode': 'Yes',
          },
          body: JSON.stringify({
            fields: {
              email,
              name,
              company,
              volume,
              comments,
              origin: 'website-specialty-deposits',
            },
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              setCurrentState('success');
              setName('');
              setEmail('');
              setCompany('');
              setVolume('');
              setComments('');
              return;
            }
            setCurrentState('error');
          })
          .catch(() => {
            setCurrentState('error');
          });
      },
      () => setCurrentState('error')
    );
  };

  return (
    <Container py={18} id="contact-form" mt={18}>
      <Grid gridTemplateColumns={12} position="relative">
        <Column gridColumn={{ _: 12, nav: '4 / span 6' }}>
          <Heading as="h2" size="800" color="blue-700" weight="semibold" textAlign="center">
            Contact us
          </Heading>
          <Text size="300" color="gray-600" textAlign="center" m="8px auto 0" maxWidth="480px">
            We’d love to hear from you! Please reach out with any product questions or request additional integrations
            so we can continue to build and improve our product suite.
          </Text>
        </Column>

        <Column gridColumn={{ _: 12, nav: '4 / span 6' }} mt={10}>
          {currentState === 'error' && (
            <ErrorText size="200">
              <Icon.FormError />
              There was an error sending your message.
            </ErrorText>
          )}

          {currentState === 'success' && (
            <SuccessText size="200">
              <Icon.FormSuccess />
              Thank you for your inquiry. We'll be in touch
            </SuccessText>
          )}

          <form onSubmit={onSubmit}>
            <FormGroup>
              <FormElement>
                <FormLabel>Full name*</FormLabel>
                <FormField>
                  <Input className="form-input" placeholder="Full name" type="text" value={name} onChange={setName} />
                </FormField>
              </FormElement>

              <FormElement>
                <FormLabel>Company*</FormLabel>
                <FormField>
                  <Input
                    className="form-input"
                    placeholder="Company"
                    type="text"
                    value={company}
                    onChange={setCompany}
                  />
                </FormField>
              </FormElement>

              <FormElement>
                <FormLabel>Email address*</FormLabel>
                <FormField>
                  <Input
                    className="form-input"
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={setEmail}
                  />
                </FormField>
              </FormElement>

              <FormElement>
                <FormLabel>Total Deposit Volume*</FormLabel>
                <FormField>
                  <Input className="form-input" type="number" placeholder="$0" onChange={setVolume} value={volume} />
                </FormField>
              </FormElement>

              <FormElement>
                <FormLabel>Additional comments</FormLabel>
                <FormField>
                  <textarea
                    placeholder="Details, desired features and integrations, etc."
                    className="form-textarea"
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComments(event.target.value)}
                    value={comments}
                  />
                </FormField>
              </FormElement>
              <Button variant="primary" type="submit">
                Send
              </Button>
            </FormGroup>
          </form>
        </Column>
      </Grid>

      <script src="https://www.google.com/recaptcha/api.js?render=6Leh8AQfAAAAAEPSCKOFfX053-T22KKBqtd5YB0U" async />
    </Container>
  );
};
