import React from 'react';

export const RemittanceInfo = [
  {
    param: 'remittance_info',
    type: 'object',
    required: false,
    description: (
      <p>
        Information supplied to enable the matching of an entry with the items that the transfer is intended to settle,
        such as commercial invoices in an account's receivable system. We will support more structured information soon
        (e.g., tax, invoicer, invoicee, etc.). They will be sent in <code>F70</code> in <code>MT103</code> messages,
        or <code>RmtInf</code> in <code>pacs.008.001.xx</code> messages. <a href="/docs/international-wires/country-specific-details#remittance-information">More details</a>. Must adhere to <a href="/docs/international-wires#valid-characters-permitted">International Wire character validation </a>.
      </p>
    ),
    object: [
      {
        param: 'general_info',
        type: 'string',
        required: false,
        description: (
          <p>
            General information for the beneficiary in an unstructured form. Maximum length: <code>140</code> characters.
          </p>
        ),
      },
      {
        param: 'beneficiary_reference',
        type: 'string',
        required: false,
        description: (
          <p>
            Reference for the beneficiary (e.g., invoice number) to reconcile this transfer with their internal records. Maximum length: <code>30</code> characters.
          </p>
        ),
      },
    ],
  },
];
