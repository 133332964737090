// For use with Styled system
export const breakpoints = ['640px', '768px', '870px', '1112px', '1280px'];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.nav = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

export default {
  breakpoints,
  screens: {
    nav: '870px',
    sm: '640px',
    md: '768px',
    lg: '1112px',
    xl: '1280px',
  },
  space: {
    auto: 'auto',
    px: '1px',
    0: '0px',
    0.5: '0.125rem', // 2
    1: '0.25rem', // 4
    15: '0.375rem', // 6
    2: '0.5rem', // 8
    2.5: '0.625rem', // 10
    3: '0.75rem', // 12
    3.5: '0.875rem', // 14
    4: '1rem', // 16
    5: '1.25rem', // 20
    6: '1.5rem', // 24
    7: '1.75rem', // 28
    8: '2rem', // 32
    9: '2.25rem', // 36
    10: '2.5rem', // 40
    11: '2.75rem', // 44
    12: '3rem', // 48
    14: '3.5rem', // 56
    16: '4rem', // 64
    18: '4.5rem', // 72
    20: '5rem', // 80
    24: '6rem', // 96
    28: '7rem', // 112
    32: '8rem', // 128
    36: '9rem', // 144
    40: '10rem', // 160
    44: '11rem', // 176
    48: '12rem', // 192
    52: '13rem', // 208
    56: '14rem', // 224
    60: '15rem', // 240
    64: '16rem', // 256
    72: '18rem', // 288
    80: '20rem', // 320
    96: '24rem', // 384
  },

  sizes: {
    half: '50%',
    full: '100%',
    auto: 'auto',
    px: '1px',
    0: '0px',
    0.5: '0.125rem', // 2
    1: '0.25rem', // 4
    15: '0.375rem', // 6
    2: '0.5rem', // 8
    2.5: '0.625rem', // 10
    3: '0.75rem', // 12
    3.5: '0.875rem', // 14
    4: '1rem', // 16
    5: '1.25rem', // 20
    6: '1.5rem', // 24
    7: '1.75rem', // 28
    8: '2rem', // 32
    9: '2.25rem', // 36
    10: '2.5rem', // 40
    11: '2.75rem', // 44
    12: '3rem', // 48
    14: '3.5rem', // 56
    16: '4rem', // 64
    18: '4.5rem', // 72
    20: '5rem', // 80
    24: '6rem', // 96
    28: '7rem', // 112
    32: '8rem', // 128
    36: '9rem', // 144
    40: '10rem', // 160
    44: '11rem', // 176
    48: '12rem', // 192
    52: '13rem', // 208
    56: '14rem', // 224
    60: '15rem', // 240
    64: '16rem', // 256
    72: '18rem', // 288
    80: '20rem', // 320
    96: '24rem', // 384
  },

  colors: {
    rgb: {
      0: '255, 255, 255',
      1000: '0, 0, 0',

      'purple-50': '247, 241, 254',
      'purple-100': '234, 225, 248',
      'purple-200': '213, 200, 241',
      'purple-300': '193, 170, 241',
      'purple-400': '159, 122, 238',
      'purple-500': '132, 94, 219',
      'purple-600': '82, 31, 167',
      'purple-700': '60, 25, 119',
      'purple-800': '34, 7, 78',
      'purple-900': '22, 6, 48',

      'blue-50': '238, 244, 251',
      'blue-100': '212, 227, 247',
      'blue-200': '196, 214, 242',
      'blue-300': '162, 193, 237',
      'blue-400': '126, 167, 233',
      'blue-500': '69, 117, 205',
      'blue-600': '30, 65, 153',
      'blue-700': '27, 45, 112',
      'blue-800': '17, 26, 74',
      'blue-900': '8, 11, 50',

      'cyan-50': '238, 247, 249',
      'cyan-100': '219, 239, 243',
      'cyan-200': '193, 232, 239',
      'cyan-300': '171, 230, 238',
      'cyan-400': '136, 222, 235',
      'cyan-500': '113, 210, 240',
      'cyan-600': '47, 143, 197',
      'cyan-700': '12, 105, 151',
      'cyan-800': '2, 50, 71',
      'cyan-900': '1, 24, 33',

      'seafoam-50': '240, 250, 238',
      'seafoam-100': '219, 245, 227',
      'seafoam-200': '197, 242, 212',
      'seafoam-300': '178, 245, 199',
      'seafoam-400': '148, 239, 183',
      'seafoam-500': '124, 229, 168',
      'seafoam-600': '68, 180, 139',
      'seafoam-700': '22, 126, 108',
      'seafoam-800': '9, 63, 47',
      'seafoam-900': '2, 31, 21',

      'green-50': '247, 252, 234',
      'green-100': '233, 247, 198',
      'green-200': '220, 240, 177',
      'green-300': '205, 237, 147',
      'green-400': '185, 226, 122',
      'green-500': '156, 217, 93',
      'green-600': '114, 172, 63',
      'green-700': '77, 123, 48',
      'green-800': '41, 79, 28',
      'green-900': '7, 32, 3',

      'yellow-50': '252, 248, 232',
      'yellow-100': '252, 245, 210',
      'yellow-200': '247, 237, 181',
      'yellow-300': '247, 235, 129',
      'yellow-400': '252, 250, 232',
      'yellow-500': '244, 223, 105',
      'yellow-600': '243, 190, 52',
      'yellow-700': '182, 118, 7',
      'yellow-800': '82, 45, 0',
      'yellow-900': '46, 21, 0',

      'orange-50': '253, 247, 231',
      'orange-100': '250, 233, 207',
      'orange-200': '243, 214, 174',
      'orange-300': '245, 198, 140',
      'orange-400': '239, 170, 101',
      'orange-500': '236, 132, 57',
      'orange-600': '214, 86, 32',
      'orange-700': '148, 49, 7',
      'orange-800': '72, 20, 0',
      'orange-900': '51, 13, 0',

      'red-50': '255, 241, 243',
      'red-100': '247, 216, 218',
      'red-200': '243, 191, 198',
      'red-300': '245, 162, 174',
      'red-400': '238, 110, 134',
      'red-500': '214, 66, 96',
      'red-600': '164, 23, 66',
      'red-700': '113, 10, 41',
      'red-800': '62, 3, 24',
      'red-900': '40, 1, 19',

      'pink-50': '253, 240, 254',
      'pink-100': '238, 220, 243',
      'pink-200': '229, 201, 235',
      'pink-300': '225, 173, 232',
      'pink-400': '201, 119, 209',
      'pink-500': '171, 75, 177',
      'pink-600': '119, 28, 134',
      'pink-700': '84, 16, 101',
      'pink-800': '42, 2, 56',
      'pink-900': '25, 0, 34',

      'gray-50': '246, 246, 248',
      'gray-100': '238, 239, 242',
      'gray-200': '227, 228, 232',
      'gray-300': '207, 208, 216',
      'gray-400': '169, 172, 182',
      'gray-500': '124, 127, 136',
      'gray-600': '87, 90, 100',
      'gray-700': '59, 62, 71',
      'gray-800': '35, 39, 48',
      'gray-900': '18, 22, 30',
    },

    1000: 'var(--color-1000)',
    0: 'var(--color-0)',

    'gray-50': 'var(--color-gray-50)',
    'gray-100': 'var(--color-gray-100)',
    'gray-200': 'var(--color-gray-200)',
    'gray-300': 'var(--color-gray-300)',
    'gray-400': 'var(--color-gray-400)',
    'gray-500': 'var(--color-gray-500)',
    'gray-600': 'var(--color-gray-600)',
    'gray-700': 'var(--color-gray-700)',
    'gray-800': 'var(--color-gray-800)',
    'gray-900': 'var(--color-gray-900)',

    'blue-50': 'var(--color-blue-50)',
    'blue-100': 'var(--color-blue-100)',
    'blue-200': 'var(--color-blue-200)',
    'blue-300': 'var(--color-blue-300)',
    'blue-400': 'var(--color-blue-400)',
    'blue-500': 'var(--color-blue-500)',
    'blue-600': 'var(--color-blue-600)',
    'blue-700': 'var(--color-blue-700)',
    'blue-800': 'var(--color-blue-800)',
    'blue-900': 'var(--color-blue-900)',

    'cyan-50': 'var(--color-cyan-50)',
    'cyan-100': 'var(--color-cyan-100)',
    'cyan-200': 'var(--color-cyan-200)',
    'cyan-300': 'var(--color-cyan-300)',
    'cyan-400': 'var(--color-cyan-400)',
    'cyan-500': 'var(--color-cyan-500)',
    'cyan-600': 'var(--color-cyan-600)',
    'cyan-700': 'var(--color-cyan-700)',
    'cyan-800': 'var(--color-cyan-800)',
    'cyan-900': 'var(--color-cyan-900)',

    'seafoam-50': 'var(--color-seafoam-50)',
    'seafoam-100': 'var(--color-seafoam-100)',
    'seafoam-200': 'var(--color-seafoam-200)',
    'seafoam-300': 'var(--color-seafoam-300)',
    'seafoam-400': 'var(--color-seafoam-400)',
    'seafoam-500': 'var(--color-seafoam-500)',
    'seafoam-600': 'var(--color-seafoam-600)',
    'seafoam-700': 'var(--color-seafoam-700)',
    'seafoam-800': 'var(--color-seafoam-800)',
    'seafoam-900': 'var(--color-seafoam-900)',

    'green-50': 'var(--color-green-50)',
    'green-100': 'var(--color-green-100)',
    'green-200': 'var(--color-green-200)',
    'green-300': 'var(--color-green-300)',
    'green-400': 'var(--color-green-400)',
    'green-500': 'var(--color-green-500)',
    'green-600': 'var(--color-green-600)',
    'green-700': 'var(--color-green-700)',
    'green-800': 'var(--color-green-800)',
    'green-900': 'var(--color-green-900)',

    'yellow-50': 'var(--color-yellow-50)',
    'yellow-100': 'var(--color-yellow-100)',
    'yellow-200': 'var(--color-yellow-200)',
    'yellow-300': 'var(--color-yellow-300)',
    'yellow-400': 'var(--color-yellow-400)',
    'yellow-500': 'var(--color-yellow-500)',
    'yellow-600': 'var(--color-yellow-600)',
    'yellow-700': 'var(--color-yellow-700)',
    'yellow-800': 'var(--color-yellow-800)',
    'yellow-900': 'var(--color-yellow-900)',

    'orange-50': 'var(--color-orange-50)',
    'orange-100': 'var(--color-orange-100)',
    'orange-200': 'var(--color-orange-200)',
    'orange-300': 'var(--color-orange-300)',
    'orange-400': 'var(--color-orange-400)',
    'orange-500': 'var(--color-orange-500)',
    'orange-600': 'var(--color-orange-600)',
    'orange-700': 'var(--color-orange-700)',
    'orange-800': 'var(--color-orange-800)',
    'orange-900': 'var(--color-orange-900)',

    'red-50': 'var(--color-red-50)',
    'red-100': 'var(--color-red-100)',
    'red-200': 'var(--color-red-200)',
    'red-300': 'var(--color-red-300)',
    'red-400': 'var(--color-red-400)',
    'red-500': 'var(--color-red-500)',
    'red-600': 'var(--color-red-600)',
    'red-700': 'var(--color-red-700)',
    'red-800': 'var(--color-red-800)',
    'red-900': 'var(--color-red-900)',

    'pink-50': 'var(--color-pink-50)',
    'pink-100': 'var(--color-pink-100)',
    'pink-200': 'var(--color-pink-200)',
    'pink-300': 'var(--color-pink-300)',
    'pink-400': 'var(--color-pink-400)',
    'pink-500': 'var(--color-pink-500)',
    'pink-600': 'var(--color-pink-600)',
    'pink-700': 'var(--color-pink-700)',
    'pink-800': 'var(--color-pink-800)',
    'pink-900': 'var(--color-pink-900)',

    'purple-50': 'var(--color-purple-50)',
    'purple-100': 'var(--color-purple-100)',
    'purple-200': 'var(--color-purple-200)',
    'purple-300': 'var(--color-purple-300)',
    'purple-400': 'var(--color-purple-400)',
    'purple-500': 'var(--color-purple-500)',
    'purple-600': 'var(--color-purple-600)',
    'purple-700': 'var(--color-purple-700)',
    'purple-800': 'var(--color-purple-800)',
    'purple-900': 'var(--color-purple-900)',
  },

  opacity: {
    0: 0,
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1.0',
  },

  fontFamily: {
    sans: "SuisseIntl, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    mono: "SuisseIntlMono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
  },

  fontSizes: {
    100: '0.75rem', // 12
    200: '0.875rem', // 14
    300: '1rem', // 16
    350: '1.125rem', // 18
    400: '1.25rem', // 20
    500: '1.5rem', // 24
    550: '1.75rem', // 28
    600: '2rem', // 32
    700: '2.25rem', // 36
    750: '2.5rem', // 40
    800: '2.75rem', // 44
    900: '3.5rem', // 56
    1000: '4.5rem', // 72
  },

  fontWeights: {
    regular: 300,
    book: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },

  letterSpacings: {
    '-1': '-0.01em',
    '-2': '-0.02em',
    '-3': '-0.03em',
  },

  lineHeights: {
    'b-100': '1rem',
    'b-200': '1.25rem',
    'b-300': '1.375rem',
    'b-350': '1.375rem',
    'b-400': '1.625rem',
    'b-500': '2rem',
    'b-600': '2.5rem',

    'h-100': '0.875rem',
    'h-200': '1rem',
    'h-300': '1.25rem',
    'h-350': '1.375rem',
    'h-400': '1.5rem',
    'h-500': '1.75rem',
    'h-550': '2rem',
    'h-600': '2.25rem',
    'h-700': '2.5rem',
    'h-750': '2.75rem',
    'h-800': '3rem',
    'h-900': '3.75rem',
    'h-1000': '4.5rem',
  },

  textTransforms: {
    uppercase: 'uppercase',
    capitalize: 'capitalize',
    lowercase: 'lowercase',
  },

  radii: {
    0: '0',
    1: '0.25rem', // 4
    2: '0.5rem', // 8
    4: '1rem', // 16
    default: '0.5rem', // 8
    pill: '9999px',
    round: '9999px',
  },

  borders: {
    '1-gray-200': '1px solid var(--color-gray-200)',
  },

  whiteSpaces: {
    nowrap: 'noWrap',
    normal: 'normal',
    pre: 'pre',
    'pre-line': 'pre-line',
    'pre-wrap': 'pre-wrap',
    initial: 'initial',
    inherit: 'inherit',
  },

  gridTemplateColumns: {
    none: 'none',
    1: 'repeat(1, minmax(0, 1fr))',
    2: 'repeat(2, minmax(0, 1fr))',
    3: 'repeat(3, minmax(0, 1fr))',
    4: 'repeat(4, minmax(0, 1fr))',
    5: 'repeat(5, minmax(0, 1fr))',
    6: 'repeat(6, minmax(0, 1fr))',
    7: 'repeat(7, minmax(0, 1fr))',
    8: 'repeat(8, minmax(0, 1fr))',
    9: 'repeat(9, minmax(0, 1fr))',
    10: 'repeat(10, minmax(0, 1fr))',
    11: 'repeat(11, minmax(0, 1fr))',
    12: 'repeat(12, minmax(0, 1fr))',
  },

  gridColumn: {
    auto: 'auto',

    1: 'span 1 / span 1',
    2: 'span 2 / span 2',
    3: 'span 3 / span 3',
    4: 'span 4 / span 4',
    5: 'span 5 / span 5',
    6: 'span 6 / span 6',
    7: 'span 7 / span 7',
    8: 'span 8 / span 8',
    9: 'span 9 / span 9',
    10: 'span 10 / span 10',
    11: 'span 11 / span 11',
    12: 'span 12 / span 12',

    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-7': 'span 7 / span 7',
    'span-8': 'span 8 / span 8',
    'span-9': 'span 9 / span 9',
    'span-10': 'span 10 / span 10',
    'span-11': 'span 11 / span 11',
    'span-12': 'span 12 / span 12',
    full: '1 / -1',
  },

  gridColumnStartEnd: {
    auto: 'auto',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
  },
};
