import { css } from 'styled-components';

export const Cyan = css`
  .color-palette--cyan {
    /* Primary */
    --btn-text-color-primary: var(--color-0);

    --btn-bg-primary: var(--color-cyan-600);
    --btn-bg-primary-hover: var(--color-cyan-700);
    --btn-bg-primary-active: var(--color-cyan-800);

    --btn-border-primary: var(--color-cyan-700);
    --btn-border-primary-hover: var(--color-cyan-800);
    --btn-border-primary-active: var(--color-cyan-900);

    --btn-focus-ring-primary: var(--color-cyan-200);

    /* Secondary */
    --btn-text-color-secondary: var(--color-cyan-700);

    --btn-bg-secondary: var(--color-cyan-100);
    --btn-bg-secondary-hover: var(--color-cyan-100);
    --btn-bg-secondary-active: var(--color-cyan-100);

    --btn-border-secondary: var(--color-cyan-300);
    --btn-border-secondary-hover: var(--color-cyan-400);
    --btn-border-secondary-active: var(--color-cyan-500);

    --btn-focus-ring-secondary: var(--color-cyan-200);

    /* Link */
    --btn-text-color-link: var(--color-0);
  }

  .color-palette-inverted--cyan {
    /* Primary */
    --btn-text-color-primary: var(--color-cyan-900);

    --btn-bg-primary: var(--color-0);
    --btn-bg-primary-hover: var(--color-0);
    --btn-bg-primary-active: var(--color-0);

    --btn-border-primary: var(--color-cyan-800);
    --btn-border-primary-hover: var(--color-cyan-900);
    --btn-border-primary-active: var(--color-cyan-900);

    --btn-focus-ring-primary: var(--color-cyan-500);

    /* Secondary */
    --btn-text-color-secondary: var(--color-0);

    --btn-bg-secondary: rgba(var(--rgb-cyan-600), 0.75);
    --btn-bg-secondary-hover: var(--color-cyan-600);
    --btn-bg-secondary-active: var(--color-cyan-700);

    --btn-border-secondary: rgba(var(--rgb-cyan-800), 0.5);
    --btn-border-secondary-hover: var(--color-cyan-800);
    --btn-border-secondary-active: var(--color-cyan-900);

    --btn-focus-ring-secondary: var(--color-cyan-500);
  }
`;
