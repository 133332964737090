import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconCircleCheck: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.03662 10.2824L8.84245 12.0883L8.83079 12.0766L12.905 8.00244"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
