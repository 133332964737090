import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDown: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke icon--chevron-down', props.className];

  return (
    <IconBase {...props} className={classNames(staticClasses)}>
      <path
        d="M4 6L8 10L12 6"
        style={{ stroke: 'var(--icon-base-color)' }}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
