import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { InView } from 'react-intersection-observer';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Layout } from '~/components/Layout';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { List } from '~/elements/List';
import { Icon } from '~/elements/Icon';
import { VideoModal } from '~/elements/VideoModal';

const ChangelogPage: React.FC = (props: any) => {
  const [mount, setMount] = useState<boolean>(false);
  const list = props.data.allContentfulChangelogEntry.nodes;

  useEffect(() => {
    setTimeout(() => setMount(true), 50);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Changelog — Column</title>
        <meta name="description" content="Stay up to date with the latest improvements." />
      </Helmet>

      <Container className="blog blog-container blog-theme--blue">
        <Heading as="h1" size="800" weight="semibold" mt={16} color="blue-800" className="col-span-8">
          Changelog
        </Heading>

        {/* Navigation */}

        <List className="blog-nav blog-theme--blue">
          <Text as="li" className="blog-nav-blog">
            <Link to="/blog" getProps={({ isPartiallyCurrent }) => isPartiallyCurrent && { className: 'active' }}>
              Blog
            </Link>
          </Text>
          <Text as="li" className="blog-nav-changelog">
            <Link to="/changelog" getProps={({ isPartiallyCurrent }) => isPartiallyCurrent && { className: 'active' }}>
              Changelog
            </Link>
          </Text>
        </List>

        {/* Entry content */}

        <div className="col-span-12 pt-8 pb-24 border-t border-gray-100">
          {list.map((post: any, index: number) => (
            <InView
              key={`${post.slug}-${index}`}
              id={post.slug}
              className="blog-card-changelog"
              rootMargin="0px 0px -100% 0px"
              onChange={(inView) => inView && mount && history.replaceState(undefined, '', `#${post.slug}`)}
            >
              <div className="blog-card-sticky">
                <Text size="200" className="blog-date">
                  {post.date}
                </Text>
              </div>

              <div className="relative blog-card-content">
                <Heading as="h2" weight="semibold" size="500">
                  {post.title}
                </Heading>

                {/* optional image */}

                {post?.image?.title && post?.image?.gatsbyImageData && (
                  <GatsbyImage
                    loading="eager"
                    alt={post.image.title}
                    image={post.image.gatsbyImageData}
                    className="changelog-image"
                  />
                )}

                {/* Video component */}

                {post?.video && post?.video?.title && post?.video?.file?.file?.url && (
                  <VideoModal
                    title={post?.video?.title}
                    type={post.video.file.file.contentType}
                    src={post.video.file.file.url}
                    posterFrame={post?.video?.poster?.gatsbyImageData}
                    length={post.video.length}
                    alt={post?.video?.poster?.title}
                  />
                )}

                {/* Entry content cont. */}

                {post.intro && (
                  <Text as="p" className="text-body">
                    {renderRichText(post.intro)}
                  </Text>
                )}

                {post.new && (
                  <>
                    <div className="blog-card-subhead">
                      <Icon.ChangelogNew />
                      <Heading as="h5" weight="semibold">
                        New
                      </Heading>
                    </div>
                    <List className="text-body">{renderRichText(post.new)}</List>
                  </>
                )}

                {post.improved && (
                  <>
                    <div className="blog-card-subhead">
                      <Icon.ChangelogImproved />
                      <Heading as="h5" weight="semibold">
                        Improved
                      </Heading>
                    </div>
                    <List className="text-body">{renderRichText(post.improved)}</List>
                  </>
                )}

                {post.fixed.raw && (
                  <>
                    <div className="blog-card-subhead">
                      <Icon.ChangelogFixed />
                      <Heading as="h5" weight="semibold">
                        Fixed
                      </Heading>
                    </div>
                    <List className="text-body">{renderRichText(post.fixed.raw)}</List>
                  </>
                )}
              </div>
            </InView>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default ChangelogPage;

export const pageQuery = graphql`
  query ChangelogIndexQuery {
    allContentfulChangelogEntry(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        slug
        date(formatString: "MMM Do, YYYY")
        summary
        intro {
          raw
        }
        new {
          raw
        }
        improved {
          raw
        }
        fixed {
          ... on RichText {
            raw
          }
        }
        image {
          ... on ContentfulAsset {
            title
            gatsbyImageData(width: 1200, quality: 75, placeholder: BLURRED)
          }
        }
        video {
          ... on ContentfulVideo {
            title
            length
            poster {
              ... on ContentfulAsset {
                title
                gatsbyImageData(width: 1200, quality: 75, placeholder: BLURRED)
              }
            }
            file {
              file {
                url
                contentType
              }
            }
          }
        }
      }
    }
  }
`;
