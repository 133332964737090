import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Container } from '~/elements/Container';
import { PaletteProps, getPaletteClassnames } from '~/utils/getPaletteClassnames';

export interface HeroStyleProps extends PaletteProps {
  inverted?: boolean;
}

export interface HeroProps extends HeroStyleProps {
  className?: string;
  style?: CSSProperties;
}

const Wrapper = styled.section`
  padding-top: var(--space-18);
  padding-bottom: var(--space-18);
  position: relative;
`;

export const Hero: React.FC<HeroProps> = (props) => {
  const styleProps: HeroStyleProps = {
    palette: props.palette ?? 'inherit',
    inverted: props.inverted ?? false,
  };

  let conditionalClasses: ConditionalClasses = {
    ...getPaletteClassnames(styleProps.palette),
  };

  if (styleProps.inverted === true) {
    conditionalClasses = getPaletteClassnames(styleProps.palette, conditionalClasses, true);
  }

  return (
    <Wrapper className={classNames(props.className, conditionalClasses)} style={props.style}>
      <Container>{props.children}</Container>
    </Wrapper>
  );
};
