import React from 'react';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Box } from '~/elements/Box';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

export const MarkdownPrivacyNotice: React.FC = (props) => {
  return (
    <Box mt={8} mb={12}>
      <Grid gap={8} alignItems="baseline" py={8}>
        <Column gridColumn={{ _: 'span-12', lg: 'span-2' }}>
          <Heading size="700" as="h3" weight="medium">
            Facts
          </Heading>
        </Column>
        <Column gridColumn={{ _: 'span-12', lg: 'span-10' }}>
          <Heading size="400">What does Column, N.A. do with your personal information?</Heading>
        </Column>
      </Grid>

      <Box borderTop="1px solid rgba(var(--rgb-gray-200), 1)">
        <Grid gap={8} justifyContent="flex-start" py={4}>
          <Column gridColumn={{ _: 'span-12', lg: 'span-2' }}>
            <Heading size="500" weight="medium" as="h4">
              Why?
            </Heading>
          </Column>
          <Column gridColumn={{ _: 'span-12', lg: 'span-10' }}>
            <Text size="300">
              Financial companies choose how they share your personal information. Federal law gives consumers the right
              to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and
              protect your personal information. Please read this notice carefully to understand what we do.
            </Text>
          </Column>
        </Grid>
      </Box>

      <Box borderTop="1px solid rgba(var(--rgb-gray-200), 1)">
        <Grid gap={8} justifyContent="flex-start" py={4}>
          <Column gridColumn={{ _: 'span-12', lg: 'span-2' }}>
            <Heading size="500" weight="medium" as="h4">
              What?
            </Heading>
          </Column>
          <Column gridColumn={{ _: 'span-12', lg: 'span-10' }}>
            <Text size="300">
              The types of personal information we collect and share depend on the product or service you have with us.
              This information can include:
            </Text>
            <ul>
              <li>
                <strong>Social Security number and account balances</strong>
              </li>
              <li>
                <strong>Transaction history and payment history</strong>
              </li>
              <li>
                <strong>Account transactions and income</strong>
              </li>
            </ul>
            <Text>
              When you are no longer our customer, we continue to share your information as described in this notice.
            </Text>
          </Column>
        </Grid>
      </Box>

      <Box borderTop="1px solid rgba(var(--rgb-gray-200), 1)">
        <Grid gap={8} justifyContent="flex-start" py={4}>
          <Column gridColumn={{ _: 'span-12', lg: 'span-2' }}>
            <Heading size="500" weight="medium" as="h4">
              How?
            </Heading>
          </Column>
          <Column gridColumn={{ _: 'span-12', lg: 'span-10' }}>
            <Text size="300">
              All financial companies need to share personal information to run their everyday customers' business. In
              the section below, we list the reasons financial companies can share their customers' Column, N.A.
              personal information; the reasons chooses to share; and whether you can limit this sharing.
            </Text>
          </Column>
        </Grid>
      </Box>

      <Box borderTop="1px solid rgba(var(--rgb-gray-200), 1)">
        <Grid gap={8} justifyContent="flex-start" alignItems="baseline" py={4}>
          <Column gridColumn={{ _: 'span-12', lg: 'span-2' }}>
            <Heading size="500" weight="medium" as="h4">
              Questions?
            </Heading>
          </Column>
          <Column gridColumn={{ _: 'span-12', lg: 'span-10' }}>
            <p>
              Call{' '}
              <strong>
                <a href="tel:415-702-2703">415-702-2703</a>
              </strong>{' '}
              or visit <a href="https://questions.column.com">questions.column.com</a>
            </p>
          </Column>
        </Grid>
      </Box>

      <Box py={4} my={18}>
        <table>
          <thead>
            <Heading weight="medium" as="th">
              Reasons we can share your personal information
            </Heading>
            <Heading weight="medium" as="th">
              Does Column, N.A. share?
            </Heading>
            <Heading weight="medium" as="th">
              Can you limit this sharing?
            </Heading>
          </thead>

          <tbody>
            <tr>
              <td width="50%">
                <Heading size="300" weight="medium" as="span">
                  For our everyday business purposes—
                </Heading>

                <Text>
                  such as to process your transactions, maintain your account(s), respond to court orders and legal
                  investigations, or report to credit bureaus
                </Text>
              </td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                <Heading size="300" weight="medium" as="span">
                  For our marketing purposes—
                </Heading>
                <Text>to offer our products and services to you</Text>
              </td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                <Heading size="300" weight="medium" as="span">
                  For joint marketing with other financial companies
                </Heading>
              </td>
              <td>No</td>
              <td>We don't share</td>
            </tr>
            <tr>
              <td>
                <Heading size="300" weight="medium" as="span">
                  For our affiliates' everyday business purposes—
                </Heading>
                <Text>information about your transactions and experiences</Text>
              </td>
              <td>No</td>
              <td>We don't share</td>
            </tr>
            <tr>
              <td>
                <Heading size="300" weight="medium" as="span">
                  For our affiliates' everyday business purposes—
                </Heading>
                <Text>information about your creditworthiness</Text>
              </td>
              <td>No</td>
              <td>We don't share</td>
            </tr>
            <tr>
              <td>
                <Heading size="300" weight="medium" as="span">
                  For nonaffiliates to market to you
                </Heading>
              </td>
              <td>No</td>
              <td>We don't share</td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box pt={4}>
        <table>
          <thead>
            <th>
              <Heading size="500" as="h4">
                Who we are
              </Heading>
            </th>
            <th />
          </thead>
          <tbody>
            <tr>
              <td width="50%">
                <Heading size="300" weight="medium" as="span">
                  Who is providing this notice?
                </Heading>
              </td>
              <td>Column, N.A.</td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box pt={4}>
        <table>
          <thead>
            <th>
              <Heading size="500" as="h4">
                What we do
              </Heading>
            </th>
            <th />
          </thead>
          <tbody>
            <tr>
              <td width="50%">
                <Heading size="300" weight="medium" as="span">
                  How does Column, N.A. protect my personal information?
                </Heading>
              </td>
              <td>
                To protect your personal information from unauthorized access and use, we use security measures that
                comply with federal law. These measures include computer safeguards and secured files and buildings.{' '}
                <Text as="strong">
                  We also limit access to information to those employes for whom access is appropriate
                </Text>
              </td>
            </tr>

            <tr>
              <td>
                <Heading size="300" weight="medium" as="span">
                  How does Column, N.A. collect my personal information?
                </Heading>
              </td>
              <td>
                We collect your personal information, for example, when you
                <ul>
                  <li>
                    <strong>Open an account or apply for a loan</strong>
                  </li>
                  <li>
                    <strong>Made deposits/withdrawals or provide payment instructions</strong>
                  </li>
                  <li>
                    <strong>Use your credit or debit card</strong>
                  </li>
                </ul>
                We also collect your personal information from others, such as credit bureaus, affiliates, or other
                companies.
              </td>
            </tr>

            <tr>
              <td>
                <Heading size="300" weight="medium">
                  Why can't I limit all sharing?
                </Heading>
              </td>
              <td>
                Federal law gives you the right to limit only
                <ul>
                  <li>
                    <strong>
                      sharing for affiliates' everyday business purposes — information about your creditworthiness
                    </strong>
                  </li>
                  <li>
                    <strong>affiliates from using your information to market to you</strong>
                  </li>
                  <li>
                    <strong>sharing for nonaffiliates to market to you</strong>
                  </li>
                </ul>
                State laws and individual companies may give you additional rights to limit sharing.{' '}
                <strong>See below for more on your rights under state law.</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box pt={4}>
        <table>
          <thead>
            <th>
              <Heading size="500" as="h4">
                Definitions
              </Heading>
            </th>
            <th />
          </thead>
          <tbody>
            <tr>
              <td width="50%">
                <Heading size="300" weight="medium" as="span">
                  Affiliates
                </Heading>
              </td>
              <td>
                Companies related by common ownership or control. They can be financial and nonfinancial companies.
                <ul>
                  <li>
                    <strong>Column, N.A. has no affiliates</strong>
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <Heading size="300" weight="medium" as="span">
                  Nonaffiliates
                </Heading>
              </td>
              <td>
                Companies not related by common ownership or control. They can be financial and nonfinancial companies.
                <ul>
                  <li>
                    <strong>
                      Column, N.A. does not share your personal information with non-affiliates for marketing purposes.
                    </strong>
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <Heading size="300" weight="medium" as="span">
                  Joint marketing
                </Heading>
              </td>
              <td>
                A formal agreement between nonaffi liated financial companies that together market financial products or
                services to you.
                <ul>
                  <li>
                    <strong>Column, N.A. does not jointly market.</strong>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box pt={4}>
        <table>
          <thead>
            <th>
              <Heading size="500" as="h4">
                Other important information
              </Heading>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>
                Column, N.A. does not share personal information except as allowed by California state law for everyday
                business purposes.
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
};
