import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { Flex } from '~/elements/Flex';
import { Br } from '~/elements/Br';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
`;

export const SectionWorkflows: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }}>
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 6 }} position="relative" zIndex={2}>
              <Grid gridTemplateColumns={6}>
                <Column gridColumn={{ _: 6, md: 5 }}>
                  <Heading as="h2" weight="semibold" size="700">
                    Complex payment workflows simplified
                  </Heading>
                  <Text size="300" mt={2}>
                    With instant bank account creation and book transfers, you can{' '}
                    <Text as="span" color="gray-900" size="inherit">
                      create complex payment and settlement flows
                    </Text>{' '}
                    with just a couple of API calls.
                  </Text>
                </Column>
              </Grid>

              <Code title="Create a book transfer" mt={6}>
                {`curl 'https://api.column.com/transfers/book' \\
    -XPOST \\
    -u :<YOUR API KEY> \\
    -d amount="10000" \\
    -d currency_code=USD \\
    -d sender_bank_account_id="<bank_account_id>" \\
    -d receiver_bank_account_id="<bank_account_id>"`}
              </Code>
              <Grid gridTemplateColumns={6}>
                <Column gridColumn={{ _: 6, md: 5 }}>
                  <Flex gap={2} mt={2}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <div>
                      <Heading as="h3" size="300" weight="medium">
                        Always available
                      </Heading>
                      <Text size="200" color="gray-500" mt={1}>
                        Book transfers can happen 24/7, every day of the year when both accounts are at Column
                      </Text>
                    </div>
                  </Flex>
                </Column>
              </Grid>
            </Column>

            <Column gridColumn={{ _: 12, md: 5 }} gridColumnStart={{ _: 0, md: 8 }}>
              <ProductScreen width="200%" maxWidth="900px" mb={'-50%'} backgroundColor="#fbfcfd">
                <img src="/images/products/book-transfers/new-book-transfer.png" />
              </ProductScreen>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionWorkflows;
