import { css } from 'styled-components';

export const Blue = css`
  .color-palette--blue {
    --icon-gradient-stop-1: var(--color-blue-600);
    --icon-gradient-stop-2: var(--color-blue-400);
  }
  .color-palette-inverted--blue {
    --icon-gradient-stop-1: var(--color-blue-400);
    --icon-gradient-stop-2: var(--color-blue-300);
  }
`;
