import React from 'react';
import { Icon } from '~/elements/Icon';

export const DocsData = [
  {
    icon: <Icon.DocsHome />,
    title: 'Home',
    url: '/docs',
  },
  {
    icon: <Icon.DocsGuides />,
    title: 'Guides',
    url: '/docs/guides',
  },
  {
    icon: <Icon.DocsAPI />,
    title: 'Working with the API',
    url: '/docs/workingwithapi',
    children: [
      {
        title: 'Authentication',
        url: '',
      },
      {
        title: 'Pagination',
        url: 'pagination',
      },
      {
        title: 'Idempotency',
        url: 'idempotency',
      },
      {
        title: 'Errors',
        url: 'errors',
      },
      {
        title: 'Events and webhooks',
        url: 'events-and-webhooks',
      },
    ],
  },
  {
    icon: <Icon.DocsDataModels />,
    title: 'Data Models',
    url: '/docs/data-models',
    children: [
      {
        title: 'Platform',
        url: '',
      },
      {
        title: 'Entity',
        url: 'entity',
      },
      {
        title: 'Bank Account',
        url: 'account',
      },
      {
        title: 'Account Number',
        url: 'account-number',
      },
      {
        title: 'Loan',
        url: 'loan',
      },
      {
        title: 'Counterparty',
        url: 'counterparty',
      },
      {
        title: 'Transfer',
        url: 'transfer',
      },
      {
        title: 'Event and Webhook',
        url: 'event-and-webhook',
      },
    ],
  },

  {
    icon: <Icon.DocsACH />,
    title: 'ACH',
    url: '/docs/ach',
    children: [
      {
        title: 'Overview',
        url: '',
      },
      {
        title: 'Types',
        url: 'types',
      },
      {
        title: 'Notifications and States',
        url: 'notifications-and-states',
      },
      {
        title: 'Timing',
        url: 'timing',
      },
      {
        title: 'Returns',
        url: 'returns',
      },
      {
        title: 'Reversals',
        url: 'reversals',
      },
    ],
  },
  {
    icon: <Icon.DocsWire />,
    title: 'Wire',
    url: '/docs/wire',
    children: [
      {
        title: 'Overview',
        url: '',
      },
      {
        title: 'Notifications and States',
        url: 'notifications-and-states',
      },
      {
        title: 'Reversals',
        url: 'reversals',
      },
      {
        title: 'Wire Drawdowns',
        url: 'wire-drawdown-requests',
      },
    ],
  },
  {
    icon: <Icon.DocsInternationalWire />,
    title: 'International Wires',
    url: '/docs/international-wires',
    children: [
      {
        title: 'Overview',
        url: '',
      },
      {
        title: 'Outgoing Transfers',
        url: 'outgoing',
      },
      {
        title: 'Incoming Transfers',
        url: 'incoming',
      },
      {
        title: 'Notifications and States',
        url: 'notifications-and-states',
      },
      {
        title: 'Funds Transfer Fees',
        url: 'transfer-fees',
      },
      {
        title: 'Returns',
        url: 'returns',
      },
      {
        title: 'Cancellations',
        url: 'cancellations',
      },
      {
        title: 'Tracking',
        url: 'tracking',
      },
      {
        title: 'Supported Currencies',
        url: 'supported-currencies',
      },
      {
        title: 'Country-specific Details',
        url: 'country-specific-details',
      },
      {
        title: 'Sandbox Simulations',
        url: 'simulation',
      },
    ],
  },
  {
    icon: <Icon.DocsInternationalWire />,
    title: 'Realtime',
    url: '/docs/realtime',
    children: [
      {
        title: 'Overview',
        url: '',
      },
      // {
      //   title: 'Payment Returns',
      //   url: 'payment-returns',
      // },
      {
        title: 'Notifications and States',
        url: 'notifications-and-states',
      },
      {
        title: 'Request For Payment (RFP)',
        url: 'request-for-payment',
      },
    ],
  },
  {
    icon: <Icon.DocsCheck />,
    title: 'Checks',
    url: '/docs/checks',
    children: [
      {
        title: 'Overview',
        url: '',
      },
      {
        title: 'Issuing, Printing and Mailing',
        url: 'issuing',
      },
      {
        title: 'Check Deposits',
        url: 'deposits',
      },
      {
        title: 'Notifications and States',
        url: 'notifications-and-states',
      },
      {
        title: 'Returns',
        url: 'returns',
      },
    ],
  },
  {
    icon: <Icon.DocsChangelog />,
    title: 'Changelog',
    url: '/changelog',
  },
];
