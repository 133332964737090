import React from 'react';
import styled from 'styled-components';
import { compose, color, typography, ColorProps, TypographyProps } from 'styled-system';
import { Link } from '~/elements/Link';

export interface InlineLinkProps extends ColorProps, TypographyProps {}

const StyledLink = styled(Link)<InlineLinkProps>`
  color: var(--link-color);
  font-weight: var(--font-medium);
  text-underline-offset: var(--offset, 0);
  text-decoration: underline 0.08em;
  text-decoration-color: rgba(var(--rgb-blue-600), 0);
  transition: --offset 0.2s, text-decoration-color 0.2s;

  &:hover,
  &:focus {
    color: var(--link-color-hover, currentColor);
    text-decoration: underline;
  }

  @supports (contain: paint) {
    transition: text-underline-offset 0.2s, text-decoration-color 0.2s;

    &:hover,
    &:focus {
      --offset: 0.1em;
      text-decoration-color: currentColor;
      text-underline-offset: 0.1em;
    }
  }

  ${compose(color, typography)};
`;

export const InlineLink: React.FC<any> = (props) => {
  return <StyledLink {...props}>{props.children}</StyledLink>;
};
