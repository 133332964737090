import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/bank-accounts/_hero';
import { SectionScale } from './products/bank-accounts/_scale';
import { SectionDynamicNumbers } from './products/bank-accounts/_dynamic-numbers';
import { SectionSafety } from './products/bank-accounts/_safety';
import { SectionCloser } from './index/_closer';
import { Layout } from '~/components/Layout';
import { AllProducts } from '~/components/AllProducts';
import { Testimonials } from '~/components/Testimonials';
import { Footer } from '~/components/Footer';

const BankDepositsPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Bank Accounts — Column</title>
        <meta property="og:title" content="Column - Bank Accounts" />
        <meta
          name="description"
          content="Spin up programmable and scalable FDIC insured bank accounts in one API call. All from our robust, easy to use developer platform."
        />
        <link rel="canonical" href="https://column.com/bank-accounts" />
      </Helmet>
      <SectionHero />
      <SectionScale />
      <SectionDynamicNumbers />
      <SectionSafety />

      <Testimonials />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />

      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default BankDepositsPage;
