import React, { createRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { PerspectiveCamera, AmbientLight, DirectionalLight, Color, Scene, WebGLRenderer } from 'three';
import earthData from './assets/earth-data.json';
import data from './assets/big-arcs.json';
import { Flex } from '~/elements/Flex';
import { md, lg } from '~/ds/mixins/breakpoints';

const fadeIn = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: 1;
  }
`;

const Wrapper = styled(Flex)`
  height: 225px;
  align-items: center;
  justify-content: center;
  position: relative;
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  @media ${md} {
    height: 450px;
  }

  canvas {
    mask: radial-gradient(closest-side, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0));
    background: radial-gradient(closest-side, rgba(var(--rgb-gray-500), 0.2) 25%, rgba(var(--rgb-gray-500), 0));
    transform: scale(0.5) translateX(0);

    @media ${md} {
      transform: scale(1) translateX(0);
    }
  }
`;

export const BigGlobe: React.FC = () => {
  const canvasRef = createRef<HTMLCanvasElement>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const ThreeGlobe = (await import('three-globe')).default;

      const renderer = new WebGLRenderer({ antialias: true, alpha: true, canvas: canvasRef.current as HTMLElement });

      renderer.setPixelRatio(2);
      renderer.setSize(1500, 600);

      const scene = new Scene();
      scene.add(new AmbientLight(0xffffff, 0.975));

      const camera = new PerspectiveCamera();

      camera.aspect = 1500 / 600;

      camera.position.x = 0;
      camera.position.y = 0;
      camera.position.z = 400;

      camera.zoom = 1;

      camera.fov = 10;

      camera.updateProjectionMatrix();

      const light = new DirectionalLight(0xffffff, 0.1);
      light.position.set(0, 0, 0);
      camera.add(light);

      scene.add(camera);

      const globe = new ThreeGlobe().showAtmosphere(false);

      globe
        .arcsData(data.data)
        .arcColor((e: any) => {
          if (e.reverseColor) {
            return ['#4575CD', '#4575CD'].reverse();
          }
          return ['#4575CD', '#4575CD'];
        })
        .arcStroke(0.25)
        .arcDashLength(0)
        .arcDashGap(0)
        .arcDashInitialGap((e: any) => e.order)
        .arcDashAnimateTime(1200)
        .arcAltitude(0.35)
        .hexPolygonsData(earthData.features)
        .hexPolygonResolution(4)
        .hexPolygonMargin(0.65)
        .showAtmosphere(true)
        .atmosphereAltitude(0.075)
        .atmosphereColor('#A9ACB6')
        .hexPolygonColor((e) => {
          return 'rgba(0,0,0, 0.25)';
        });

      const globeMaterial: any = globe.globeMaterial();
      globeMaterial.color = new Color(0xf0f2f6);
      globeMaterial.emissive = new Color(0xf0f2f6);
      globeMaterial.emissiveIntensity = 0;
      globeMaterial.shininess = 0.7;
      globeMaterial.transparent = true;
      globeMaterial.opacity = 1;

      scene.add(globe);

      globe.rotation.x = -0.3;
      globe.rotation.y = 1.7;
      globe.rotation.z = 0;

      globe.position.x = 0;
      globe.position.y = -85;
      globe.position.z = 0;

      globe.onGlobeReady(() => {
        setLoading(false);
      });

      let speedY = 0.00015;

      const animate = () => {
        camera.lookAt(scene.position);
        renderer.render(scene, camera);

        if (globe.rotation.y > 2 || globe.rotation.y < 1.4) {
          speedY = speedY * -1;
        }

        globe.rotation.y += speedY;

        requestAnimationFrame(animate);
      };

      animate();
    })();
  }, []);

  return (
    <Wrapper>
      <canvas className={`globe ${loading ? '' : 'show'}`} ref={canvasRef} />
    </Wrapper>
  );
};
