import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/wire/_hero';
import { SectionBusinessHours } from './products/wire/_business-hours';
import { SectionInstantTransfers } from './products/wire/_instant-transfers';
import { SectionDataTransparency } from './products/wire/_data-transparency';
import { SectionCloser } from './index/_closer';
import { Layout } from '~/components/Layout';
import { AllProducts } from '~/components/AllProducts';
import { Testimonials } from '~/components/Testimonials';
import { Footer } from '~/components/Footer';

const WirePage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Wire — Column</title>
        <meta property="og:title" content="Column - Wire" />
        <meta
          name="description"
          content="Instantly send funds to any bank account with FedWire via API. Receive domestic and international wires with realtime updates and full raw data."
        />
      </Helmet>
      <SectionHero />
      <SectionBusinessHours />
      <SectionInstantTransfers />
      <SectionDataTransparency />
      <Testimonials />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />

      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default WirePage;
