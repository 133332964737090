import React from 'react';
import styled from 'styled-components';
import {
  layout,
  color,
  space,
  borderColor,
  LayoutProps,
  ColorProps,
  SpaceProps,
  BorderColorProps,
} from 'styled-system';
import { Box } from '~/elements/Box';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

interface MarkdownEndpointProps extends LayoutProps, ColorProps, SpaceProps, BorderColorProps {
  type?: string;
  url: string;
}

const Wrapper = styled.div<any>`
  background: var(--color-blue-50);
  display: flex;
  align-items: baseline;
  gap: var(--space-2);
  padding: var(--space-3);
  margin-top: var(--space-4);
  border-radius: var(--radii-default);

  ${layout} ${color} ${space} ${borderColor}

  p {
    margin: 0;
  }
`;

export const MarkdownEndpoint: React.FC<MarkdownEndpointProps> = (props) => {
  return (
    <Wrapper mt={4} mb={4}>
      <Box borderRadius="pill" py={2} px={3} bg="blue-700">
        <Heading size="200" color="blue-100">
          {props.type ?? 'POST'}
        </Heading>
      </Box>
      <Text size="300" color="blue-800" truncate>
        {props.url}
      </Text>
    </Wrapper>
  );
};
