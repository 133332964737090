import { css } from 'styled-components';
import { Primary } from './primary/button';
import { Blue } from './blue/button';
import { Cyan } from './cyan/button';
import { Green } from './green/button';
import { Orange } from './orange/button';
import { Pink } from './pink/button';
import { Purple } from './purple/button';
import { Red } from './red/button';
import { Seafoam } from './seafoam/button';
import { Yellow } from './yellow/button';
import { Gray } from './gray/button';
import { Lending } from './lending/button';
import { Infrastructure } from './infrastructure/button';

export const Button = css`
  ${Primary}
  ${Blue}
  ${Cyan}
  ${Green}
  ${Orange}
  ${Pink}
  ${Purple}
  ${Red}
  ${Seafoam}
  ${Yellow}
  ${Gray}
  ${Lending}
  ${Infrastructure}
`;
