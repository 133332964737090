import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Flex } from '~/elements/Flex';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Code } from '~/elements/Code/Code';
import { md, lg } from '~/ds/mixins/breakpoints';

const fadeInHalf = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: .5;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  transform: scale(0.8) translateX(0);
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  @media ${md} {
    transform: scale(0.9) translateX(0);
  }

  @media ${lg} {
    transform: scale(1) translateX(0);
  }

  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(246, 247, 250, 1) 0%,
      rgba(246, 247, 250, 0) 90%,
      rgba(246, 247, 250, 1) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -30%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: -80%;
    left: var(--pattern-offset);
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    top: -20%;
    right: -10%;
    bottom: -20%;
    left: -10%;
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
    filter: blur(30px);
    opacity: 0;
    z-index: 0;
    animation: 2.5s ${fadeInHalf} forwards;
  }
`;

const flyInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(16px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const flyInLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-16px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const FlyLeft = styled(Heading)`
  opacity: 0;
  transform: translateX(-16px);
  animation: 0.5s var(--animation-curve) ${flyInLeft} 0.9s forwards;
`;

const FlyRight = styled(Text)`
  opacity: 0;
  transform: translateX(-16px);
  animation: 0.5s var(--animation-curve) ${flyInRight} 0.9s forwards;
`;

const receivedIn = keyframes`
  0% {
    transform: scale(1.2) translateX(-50%) translateY(80px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(-50%) translateY(0px);
    opacity: 1;
  }
`;

const AccountReceived = styled.div`
  position: absolute;
  left: 50%;
  bottom: 32px;
  background: rgba(var(--rgb-white), 0.9);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  box-shadow: var(--shadow-product-border), var(--shadow-product);
  min-width: 430px;
  transform: scale(0.7) translateX(-50%) translateY(80px);
  transform-origin: left;
  z-index: 3;
  opacity: 0;
  animation: 0.5s var(--animation-curve) ${receivedIn} 1.5s forwards;

  ${FlyRight} {
    animation: 0.5s var(--animation-curve) ${flyInRight} 0.7s forwards;
  }

  ${FlyLeft} {
    animation: 0.5s var(--animation-curve) ${flyInRight} 0.7s forwards;
  }
`;

const sentIn = keyframes`
  0% {
    background: rgba(var(--rgb-white), 1);
    transform: scale(1.2) translateX(-50%) translateY(80px);
    opacity: 0;
  }
  100% {
    background: rgba(var(--rgb-white), 1);
    transform: scale(1) translateX(-50%) translateY(0);
    opacity: 1;
  }
`;

const sentBehind = keyframes`
  0% {
    background: rgba(var(--rgb-white), 1);
    transform: scale(1) translateX(-50%) translateY(0);
    opacity: 1;
  }
  100% {
    background: rgba(var(--rgb-white), 0.8);
    transform: scale(.88) translateX(-50%) translateY(-48px);
    opacity: 1;
  }
`;

const AccountSent = styled(AccountReceived)`
  transform: scale(1.2) translateX(-50%) translateY(80px);
  animation: 0.5s var(--animation-curve) ${sentIn} 0.75s forwards,
    0.5s var(--animation-curve) ${sentBehind} 1.5s forwards;
`;

const responseIn = keyframes`
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Response = styled.div`
  position: relative;
  height: 320px;
  width: 100%;
  z-index: 2;
  transform: scale(0.7);
  opacity: 0;
  animation: 0.5s var(--animation-curve) ${responseIn} 0.5s forwards;

  div:nth-child(1),
  div:nth-child(2) {
    height: 100%;
  }
`;

export const Transfer: React.FC = () => {
  return (
    <Wrapper>
      <Response>
        <Code language="json" maxHeight="auto">{`{
  "id": "string",
  "created_at": "2021-11-29T20:18:34.844Z",
  "updated_at": "2021-11-29T20:18:34.844Z",
  "idempotency_key": "string",
  "sender_bank_account_id": "string",
  "sender_account_number_id": "string",
  "receiver_bank_account_id": "string",
  "receiver_account_number_id": "string",
  "amount": int64,
  "currency_code": "string",
  "description": "string",
  "status": "NONE",
  "allow_overdraft": true,
  "details": {}
}`}</Code>
      </Response>

      <AccountSent>
        <Flex justifyContent="space-between" alignItems="center" pt={6} pb={5} px={6}>
          <FlyLeft size="200" weight="medium">
            Funds sent
          </FlyLeft>
          <FlyRight size="100" color="gray-500">
            Jan 27, 2022 8:35:32AM
          </FlyRight>
        </Flex>

        <Flex
          px={6}
          py={5}
          backgroundColor="#FBFBFB"
          gapY={5}
          flexDirection="column"
          borderTop="1px solid var(--color-gray-200)"
          borderRadius="0 0 8px 8px"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <FlyLeft as="h4" size="500" weight="semibold" color="red-600">
              $2,532.32
            </FlyLeft>
            <FlyRight as="h4" size="200" weight="book">
              Transfer to Alex
            </FlyRight>
          </Flex>
        </Flex>
      </AccountSent>

      <AccountReceived>
        <Flex justifyContent="space-between" alignItems="center" pt={6} pb={5} px={6}>
          <FlyLeft size="200" weight="medium">
            Funds receieved
          </FlyLeft>
          <FlyRight size="100" color="gray-500">
            Jan 27, 2022 8:35:32AM
          </FlyRight>
        </Flex>

        <Flex
          px={6}
          py={5}
          backgroundColor="#FBFBFB"
          gapY={5}
          flexDirection="column"
          borderTop="1px solid var(--color-gray-200)"
          borderRadius="0 0 8px 8px"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <FlyLeft as="h4" size="500" weight="semibold" color="seafoam-600">
              $2,532.32
            </FlyLeft>
            <FlyRight as="h4" size="200" weight="book">
              Transfer from John
            </FlyRight>
          </Flex>
        </Flex>
      </AccountReceived>
    </Wrapper>
  );
};
