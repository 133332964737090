import { css } from 'styled-components';

export const Seafoam = css`
  .color-palette--seafoam {
    --icon-color: var(--color-seafoam-500);
    --btn-focus-ring-primary: var(--color-seafoam-200);
    --btn-focus-ring-secondary: var(--color-seafoam-100);
  }

  .color-palette-seafoam--orange {
    --icon-color: var(--color-seafoam-500);
    --btn-focus-ring-primary: var(--color-seafoam-200);
    --btn-focus-ring-secondary: var(--color-seafoam-100);
  }
`;
