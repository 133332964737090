import { css } from 'styled-components';

const formInput = `
  border-radius: var(--radii-default);
  width: 100%;
  display: block;
  padding: 0 15px;
  border: 1px solid var(--form-input-border);
  height: 38px;
  background-color: var(--form-input-bg);
  transition: 0.2s box-shadow, 0.2s border-color;
  font-size: var(--text-200);

  &:hover {
    border-color: var(--form-input-border-hover);
  }

  &:focus {
    border-color: var(--form-input-border-focus);
    box-shadow: 0 0 0 4px var(--btn-focus-ring-primary);
  }
`;

export const Forms = css`
  :root {
    --form-label: var(--color-gray-900);

    --form-input-bg: var(--color-0);

    --form-input-border: var(--color-gray-300);
    --form-input-border-hover: var(--color-gray-500);
    --form-input-border-focus: var(--color-blue-600);
    --form-input-focus-ring: var(--color-blue-100);

    --form-placeholder-text: var(--color-gray-500);
    --form-input-file-path: var(--color-gray-500);
  }

  .form-label,
  .form-label-text {
    font-weight: var(--font-medium);

    color: var(--form-label);
  }

  .form-label-text {
    font-weight: var(--font-regular);
  }

  .form-label-item {
    display: inline-flex;
    align-items: flex-start;
    gap: var(--space-2);
    position: relative;
  }

  .form-input {
    ${formInput}
  }

  .form-input--sm {
    ${formInput}
    height: 38px;
    padding: 0 10px;
  }

  .form-input--file {
    margin-top: var(--space-2);
    display: flex;
    align-items: center;
    display: block;
    position: relative;

    &:hover {
      .btn-primary {
        background-color: var(--btn-bg-primary-hover);
        box-shadow: 0 0 0 1px var(--btn-border-primary-hover), 0 2px 4px rgba(var(--rgb-1000), 0.25);
      }
    }
    &:focus {
      .btn-primary {
        background-color: var(--btn-bg-primary-hover);
        box-shadow: 0 0 0 1px var(--btn-border-primary-hover), 0 1px 3px rgba(var(--rgb-1000), 0.15),
          0 0 0 4px var(--btn-focus-ring-primary);
      }
    }
    &:active {
      .btn-primary {
        background-color: var(--btn-bg-primary-active);
        box-shadow: 0 0 0 1px var(--btn-border-primary-active), 0 1px 3px rgba(var(--rgb-1000), 0.15),
          0 0 0 0 var(--btn-focus-ring-primary);
      }
    }

    input[type='file'] {
      padding-top: var(--space-1);
      padding-bottom: var(--space-1);
      border: 1px solid transparent;
      outline: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      text-indent: -6.75em;
      border-left: 9.2em solid transparent;
      color: var(--form-input-file-path);
      z-index: 10;
      cursor: pointer;

      &:focus {
        + .btn-secondary {
          background-color: var(--btn-bg-secondary-hover);
          box-shadow: 0 1px 1px rgba(var(--rgb-1000), 0.2), 0 0 0 1px var(--btn-border-secondary-hover),
            0 0 0 4px var(--btn-focus-ring-secondary);
        }
      }
    }

    &.btn-group-sm {
      input[type='file'] {
        border-left: 8.4em solid transparent;
        font-size: var(--text-200);
      }
    }
  }

  .form-multiselect,
  .form-select {
    ${formInput}
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6l4 4 4-4' stroke='%23232730' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: right 0.75rem center;

    &:invalid {
      color: var(--form-placeholder-text);
    }

    &--sm {
      ${formInput}
      height: 38px;
      padding: 0 10px;
    }
  }

  .form-checkbox,
  .form-radio {
    background-color: var(--color-0);
    width: 20px;
    height: 20px;
    position: relative;
    border: 1px solid var(--form-input-border);
    box-shadow: 0 var(--btn-bg-primary) inset;
    transition: box-shadow 0.2s, border-color 0.2s, background-color 0.2s;

    &:checked {
      background: var(--btn-bg-primary);
      border-color: var(--btn-border-primary);
      box-shadow: 0 0 0 10px var(--btn-bg-primary) inset;

      &::after {
        content: '';
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
        display: block;
        opacity: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        border-radius: 3px;
        z-index: 1;
        transition: opacity 0.5s;
      }

      &:focus,
      &:focus:active {
        box-shadow: 0 0 0 10px var(--btn-bg-primary) inset, 0 0 0 4px var(--btn-focus-ring-primary);
      }

      &:active {
        box-shadow: 0 0 0 10px var(--btn-bg-primary) inset, 0 0 0 0 var(--btn-focus-ring-primary);
      }
    }

    &:focus {
      border-color: var(--btn-border-primary-focus);
      box-shadow: 0 0 0 4px var(--btn-focus-ring-primary);
    }

    &:active {
      box-shadow: 0 var(--btn-bg-primary) inset;
      border-color: var(--btn-border-primary-active);
      transition: box-shadow 0.2s, border-color 0.2s, background-color 0.2s;
    }

    .form-label-item & {
      position: relative;
      top: 0.1em;
    }
  }

  .form-checkbox {
    border-radius: 4px;

    &:checked {
      + .form-checkbox-check path {
        animation: check 0.15s linear forwards;
      }
    }
  }

  .form-checkbox-check {
    --icon-size: 20px;
    --icon-color: white;

    position: absolute;
    top: 0.1em;
    left: 0;

    path {
      stroke-dasharray: 13;
      stroke-dashoffset: 13;
    }
  }

  @keyframes check {
    to {
      stroke-dashoffset: 0;
    }
  }

  .form-radio {
    border-radius: var(--radii-full);

    &:checked {
      background-color: var(--color-0);
      box-shadow: 0 0 0 4px var(--btn-bg-primary) inset;

      &:focus,
      &:focus:active {
        box-shadow: 0 0 0 4px var(--btn-bg-primary) inset, 0 0 0 4px var(--btn-focus-ring-primary);
      }

      &:active {
        box-shadow: 0 0 0 4px var(--btn-bg-primary) inset;
      }
    }

    &:focus {
      box-shadow: 0 0 0 4px var(--btn-bg-primary) inset, 0 0 0 4px var(--btn-focus-ring-primary);
    }

    &:active {
      box-shadow: 0 0 0 4px var(--btn-bg-primary) inset, 0 0 0 0 var(--btn-focus-ring-primary);
    }
  }

  .form-textarea {
    ${formInput}
    padding-right: var(--space-3);
    padding-left: var(--space-3);
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
    min-height: 160px;
  }
`;
