import React from 'react';
import styled from 'styled-components';
import { Hero } from '~/components/Hero';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Br } from '~/elements/Br';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Button } from '~/elements/Button';
import { Icon } from '~/elements/Icon';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Typing } from '~/elements/Typing';
import { Reader } from './_reader';

const ProductHero = styled(Hero)`
  &:before {
    content: '';
    position: absolute;
    top: -82px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, #edf2f7 0%, rgba(237, 242, 247, 0) 100%);
  }
`;

export const SectionHero: React.FC = () => {
  return (
    <ProductHero>
      <Grid
        gridTemplateColumns={12}
        alignItems="center"
        minHeight="406px"
        position="relative"
        zIndex="1"
        justifyContent={{ _: 'center', nav: 'start' }}
      >
        <Column gridColumn={{ _: 12, nav: 7 }} position="relative" zIndex={2}>
          <Flex gap={2} alignItems="center" justifyContent={{ _: 'center', nav: 'start' }}>
            <Icon.ProductCardPrograms />
            <Heading as="h4" size="300" weight="medium" color="blue-800)">
              <Typing text="Card Programs" />
            </Heading>
          </Flex>

          <Heading as="h1" size="900" weight="semibold" mt={4} textAlign={{ _: 'center', nav: 'left' }}>
            Take your program <Br />
            from code to card
          </Heading>
          <Text size="400" color="gray-500" mt={2} textAlign={{ _: 'center', nav: 'left' }} pr={{ _: 0, lg: 20 }}>
            Build a customized card program that fits your stage. With a dedicated BIN and the choice of issuing
            processors, you maintain control.
          </Text>

          <ButtonGroup mt={6} justifyContent={{ _: 'center', nav: 'start' }}>
            <Button href="https://dashboard.column.com/register" type="link" variant="primary">
              Start building <Icon.AnimatedArrowRight />
            </Button>
            <Button to="/docs/guides/card-programs" variant="secondary">
              Documentation
            </Button>
          </ButtonGroup>
        </Column>

        <Column gridColumn={{ _: 12, nav: 5 }} height={{ _: 'auto', nav: '400px' }}>
          <Flex justifyContent={{ _: 'center', nav: 'end' }} height="100%" alignItems="center">
            <Reader />
          </Flex>
        </Column>
      </Grid>
    </ProductHero>
  );
};
