import React from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Flex } from '~/elements/Flex';
import { Section } from '~/elements/Section';
import { md } from '~/ds/mixins/breakpoints';

const CompareItem = styled(Grid)`
  border-top: 1px solid var(--color-purple-800);
  padding: 16px 0 48px;
  gap: var(--space-6);

  @media ${md} {
    gap: var(--space-8);
    padding: 16px 0 72px;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const SectionCompare: React.FC = () => {
  return (
    <Section>
      <Container>
        <CompareItem gridTemplateColumns={12} gap={8}>
          <Column gridColumn={{ _: 12, md: 6 }} position="relative" zIndex={2}>
            <Flex justifyContent="space-between">
              <Heading as="h4" weight="medium" size="400" color="purple-800">
                Traditional banks
              </Heading>
              <Heading as="h4" weight="medium" size="400" color="purple-800">
                $250,000
              </Heading>
            </Flex>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} pt={0.5} position="relative" zIndex={2}>
            <img src="/images/products/protected-deposits/compare-traditional.svg" />
          </Column>
        </CompareItem>

        <CompareItem gridTemplateColumns={12} gap={8}>
          <Column gridColumn={{ _: 12, md: 6 }} position="relative" zIndex={2}>
            <Flex justifyContent="space-between">
              <Heading as="h4" weight="medium" size="400" color="purple-800">
                Traditional Sweep Network
              </Heading>
              <Heading as="h4" weight="medium" size="400" color="purple-800">
                $3,500,000
              </Heading>
            </Flex>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} pt={0.5} position="relative" zIndex={2}>
            <img src="/images/products/protected-deposits/compare-sweep.svg" />
          </Column>
        </CompareItem>

        <CompareItem gridTemplateColumns={12} gap={8}>
          <Column gridColumn={{ _: 12, md: 6 }} position="relative" zIndex={2}>
            <Flex justifyContent="space-between">
              <Heading as="h4" weight="medium" size="400" color="purple-800">
                Column
              </Heading>
              <Heading as="h4" weight="medium" size="400" color="purple-800">
                $150,000,000
              </Heading>
            </Flex>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} pt={0.5} position="relative" zIndex={2}>
            <img src="/images/products/protected-deposits/compare-column.svg" />
          </Column>
        </CompareItem>
      </Container>
    </Section>
  );
};
