import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { Br } from '~/elements/Br';

const ProductSection = styled(Section)`
  overflow: hidden;
`;

export const SectionCustomerExperience: React.FC = () => {
  return (
    <ProductSection pt={{ lg: 32 }}>
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column
            gridColumn={{ _: 12, md: 8, lg: 6 }}
            gridColumnStart={{ _: 1, md: 3, lg: 4 }}
            position="relative"
            zIndex={2}
          >
            <Heading as="h2" size="700" weight="semibold" textAlign="center">
              Optimize your <Br display={{ _: 'none', md: 'block' }} />
              customer experience
            </Heading>
            <Text size="300" color="gray-500" mt={2} textAlign="center" px={10}>
              Through use cases like overdraft and real-time ledgering, our instant transfers ensure you provide a {''}
              <Text as="span" color="gray-900" size="inherit">
                superior customer experience while simplifying reconciliation
              </Text>
              .
            </Text>
          </Column>

          <Column gridColumn={{ _: 10, md: 6 }} gridColumnStart={{ _: 2, md: 4 }} position="relative" zIndex="2">
            <Code title="List all book transfers" mb="var(--space-n48)">
              {`curl 'https://api.column.com/transfers/book' \\
      -u :<YOUR API KEY>`}
            </Code>
          </Column>

          <Column gridColumn={{ _: 12, md: 10 }} gridColumnStart={{ _: 1, md: 2 }}>
            <ProductScreen mt={6}>
              <img src="/images/products/book-transfers/transfers.png" />
            </ProductScreen>
          </Column>
        </Grid>
      </Container>
    </ProductSection>
  );
};

export default SectionCustomerExperience;
