import React from 'react';
import { Passport } from '../parameters/Passport';
import { NationalID } from '../parameters/NationalID';
import { License } from '../parameters/DriversLicense';

export const Person = [
  {
    param: 'first_name',
    type: 'string',
    required: true,
    description: <p>First name of the legal person. Must adhere to <a href="/docs/wire#valid-characters-permitted">Fedwire character validation</a>.</p>,
  },
  {
    param: 'last_name',
    type: 'string',
    required: true,
    description: <p>Last name of the legal person. Must adhere to <a href="/docs/wire#valid-characters-permitted">Fedwire character validation</a>.</p>,
  },
  {
    param: 'middle_name',
    type: 'string',
    required: false,
    description: <p>Middle name of the legal person. Must adhere to <a href="/docs/wire#valid-characters-permitted">Fedwire character validation</a>.</p>,
  },
  {
    param: 'ssn',
    type: 'string',
    required: true,
    description: <p>Social Security Number. SSN is required for U.S. citizens. ITIN may be shared in place of SSN.</p>,
  },
  {
    param: 'passport',
    type: 'object',
    required: false,
    description: <p>Passport Details. Passport, Driver's License or National ID is required for non U.S. citizens.</p>,
    object: [...Passport],
  },
  {
    param: 'drivers_license',
    type: 'object',
    required: false,
    description: <p>Driver's License Details. Passport, Driver's License or National ID is required for non U.S. citizens.</p>,
    object: [...License],
  },
  {
    param: 'national_id',
    type: 'object',
    required: false,
    description: <p>National ID Details. Passport, Driver's License or National ID is required for non U.S. citizens.</p>,
    object: [...NationalID],
  },
  {
    param: 'date_of_birth',
    type: 'string',
    required: true,
    description: <p>Date of birth (YYYY-MM-DD)</p>,
  },
  {
    param: 'email',
    type: 'string',
    required: false,
    description: (
      <p>
        Email of the individual. Optional, but highly encouraged as it will increase likelihood of an automated
        verification.
      </p>
    ),
  },
];
