import { css } from 'styled-components';
import { md } from '~/ds/mixins/breakpoints';

export const Product = css`
  .product-hero-bg {
    background-color: var(--color-gray-50);
    border-radius: var(--radii-default);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    overflow: hidden;

    @media ${md} {
      min-width: 442px;
      width: auto;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: var(--bg-gradient-secondary);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url(/images/guilloche.svg);
      background-repeat: repeat;
      background-position: top right;
      background-size: 20px, 20px;
      z-index: 0;
      opacity: 0.075;
      background-clip: padding-box;
      mask-image: linear-gradient(160deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

      @media ${md} {
        border-radius: var(--radii-default);
      }
    }
  }

  .demo-frame {
    border-radius: var(--radii-default);
    background-color: var(--color-gray-50);
    overflow: hidden;
    position: relative;

    img {
      max-width: auto;
    }
  }

  .new-account-number {
    @media ${md} {
      min-height: calc(681px + 64px);
    }
  }

  .product-hero-wrapper {
    @media ${md} {
      margin-right: -109px;
    }
  }

  .product-hero-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    border-radius: var(--radii-default);
    overflow: hidden;

    left: -120px;
  }

  .product-hero-inner .globe {
    position: absolute;
    left: -24%;
    bottom: -48%;

    opacity: 0;
    transform: scale(0.75);
  }

  .product-hero-inner .globe.show {
    animation: hero-globe-scale 0.35s ease forwards 0.35s;
  }

  @keyframes hero-globe-scale {
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
