import { css } from 'styled-components';

export const Gray = css`
  .color-palette--gray {
    --icon-color: var(--color-gray-400);
  }

  .color-palette-inverted--gray {
    --icon-color: var(--color-gray-300);
  }
`;
