import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Link } from '~/elements/Link';
import Logo from '~/assets/svg/logo.svg';
import TwitterIcon from '~/assets/svg/blog/twitter.svg';
import LinkedinIcon from '~/assets/svg/blog/linkedin.svg';

export interface FooterProps {
  className?: string;
  style?: CSSProperties;
}

const FooterWrapper = styled.footer`
  position: relative;
  padding-top: var(--space-20);
  color: var(--color-gray-900);
`;

const FooterContainer = styled(Container)`
  display: grid;
  grid-template-columns: var(--cols-12);
  row-gap: var(--space-18);
`;

const FooterLogo = styled(Link)`
  svg {
    height: 19px;
  }
`;

const FooterItem = styled(Link)`
  font-weight: var(--font-medium);
  color: var(--color-gray-900);
  border-radius: var(--space-2);
  padding: var(--space-2) var(--space-3);
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(var(--rgb-black), 0.02);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 1px 0 rgba(var(--rgb-black), 0.25);
  }

  p {
    color: var(--color-gray-900);
  }
`;

const FooterSocialLink = styled(Link)`
  color: rgba(var(--rgb-black), 0.25);
  transition: 0.2s color;

  &:hover {
    color: rgba(var(--rgb-black), 0.5);
  }

  svg {
    height: var(--space-4);
  }
`;

const FooterEnd = styled.div`
  border-top-width: 1px;
  border-color: rgba(var(--rgb-gray-900), 0.1);
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
  margin-top: var(--space-20);
`;

export const Footer: React.FC<FooterProps> = (props) => {
  const products = [
    {
      name: 'ACH',
      href: '/ach',
    },
    {
      name: 'Wire',
      href: '/wire',
    },
    {
      name: 'Book Transfers',
      href: '/book-transfers',
    },
    {
      name: 'Ledger',
      href: '/ledger',
    },
    {
      name: 'Bank Accounts',
      href: '/bank-accounts',
    },
    {
      name: 'Card Programs',
      href: '/card-programs',
    },
    {
      name: 'Loan Origination',
      href: '/loan-origination',
    },
    {
      name: 'Debt Financing',
      href: '/debt-financing',
    },
    {
      name: 'Loan Purchase',
      href: '/loan-purchase',
    },
  ];

  const developers = [
    {
      name: 'Documentation',
      href: '/docs',
    },
    {
      name: 'Status',
      href: 'https://status.column.com',
    },
    {
      name: 'Changelog',
      href: '/changelog',
    },
  ];

  const company = [
    {
      name: 'Company',
      href: '/company',
    },
    {
      name: 'Careers',
      href: '/careers',
    },
    {
      name: 'Press',
      href: '/press',
    },
    {
      name: 'Blog',
      href: '/blog',
    },
    {
      name: 'Security',
      href: '/security',
    },
    {
      name: 'Contact',
      href: 'https://dashboard.column.com/contact',
    },
  ];

  return (
    <FooterWrapper className={props.className} style={props.style}>
      <FooterContainer>
        <Column gridColumn={12}>
          <FooterLogo to="/">
            <Logo />
          </FooterLogo>
        </Column>
        <Column gridColumn={{ _: 12, sm: 6 }}>
          <Heading as="h4" size="300" weight="medium" color="rgba(var(--rgb-black), .4)">
            Products
          </Heading>
          <Grid as="ul" flexDirection="column" gridTemplateColumns={2} pr={6} mt={8} ml={-3} gapX={6} gapY={6}>
            {products.map((item, index) => (
              <li key={index}>
                <Text size="200" weight="book">
                  <FooterItem to={item.href}>{item.name}</FooterItem>
                </Text>
              </li>
            ))}
          </Grid>
        </Column>
        <Column gridColumn={{ _: 6, sm: 3 }}>
          <Heading as="h4" size="300" weight="medium" color="rgba(var(--rgb-black), .4)">
            Developers
          </Heading>
          <Grid as="ul" gridTemplateColumns={0} flexDirection="column" pr={6} mt={8} ml={-3} gapY={6}>
            {developers.map((item, index) => (
              <li key={index}>
                <Text size="200" weight="book">
                  <FooterItem to={item.href}>{item.name}</FooterItem>
                </Text>
              </li>
            ))}
          </Grid>
        </Column>
        <Column gridColumn={{ _: 6, sm: 3 }}>
          <Heading as="h4" size="300" weight="medium" color="rgba(var(--rgb-black), .4)">
            Company
          </Heading>
          <Grid as="ul" gridTemplateColumns={0} flexDirection="column" pr={6} mt={8} ml={-3} gapY={6}>
            {company.map((item, index) => (
              <li key={index}>
                <Text size="200" weight="book">
                  <FooterItem to={item.href}>{item.name}</FooterItem>
                </Text>
              </li>
            ))}
          </Grid>
        </Column>
      </FooterContainer>
      <FooterEnd>
        <Container>
          <Flex flexDirection={{ _: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" gap={2}>
            <Flex alignItems="center" gap={2}>
              <img src={'/images/mark.svg'} alt="Column logo" />
              <Heading as="p" size="100" weight="medium">
                Column N.A. Member FDIC
              </Heading>
            </Flex>

            <Flex alignItems="center" gap={4}>
              <Heading as="p" size="100" weight="medium">
                <Link to="/legal/privacy-notice">Privacy Notice</Link>
              </Heading>

              <Heading as="p" size="100" weight="medium">
                <Link to="/legal/privacy-policy">Privacy Policy</Link>
              </Heading>

              <FooterSocialLink href="https://twitter.com/column" target="_blank">
                <TwitterIcon />
              </FooterSocialLink>
              <FooterSocialLink href="https://www.linkedin.com/company/column-bank" target="_blank">
                <LinkedinIcon />
              </FooterSocialLink>
            </Flex>
          </Flex>
        </Container>
      </FooterEnd>
    </FooterWrapper>
  );
};
