import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Icon } from '~/elements/Icon';
import { Box } from '~/elements/Box';
import { ProductScreen } from '~/elements/ProductScreen';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px;
`;

const CreateReturn = styled.img``;

export const SectionReturnsDisputes: React.FC = () => {
  return (
    <Section mt={18}>
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column gridColumn={{ _: 12, lg: 5 }}>
            <Box position="relative" zIndex="2" mb={8}>
              <Heading as="h2" weight="semibold" size="700">
                Be at the helm of <br />
                returns and disputes
              </Heading>
              <Text size="300" mt={2}>
                Programmatically control returns through either our API or dashboard. Returns need to be viewed from two
                perspectives - one as the ODFI and the other as the RDFI.
              </Text>
            </Box>

            <ProductScreen backgroundColor="#fbfcfd" maxWidth="430px">
              <CreateReturn src="/images/products/ach/create-return.png" />
            </ProductScreen>
          </Column>

          <Column gridColumn={{ _: 12, lg: 6 }} gridColumnStart={{ _: 1, lg: 7 }} position="relative" zIndex={1}>
            <Flex flexDirection="column" gap={8}>
              <Column>
                <Code title="RDFI - Create ACH return">
                  {`curl 'https://api.column.com/transfers/ach/acht_1xXyZXXpC5QcnyNDJW0bt0ki95E/return' \\
    -d return_code="R08"`}
                </Code>
                <Flex gap={1} alignItems="center">
                  <CircleCheck color="blue-500" viewportSize={20} />
                  <Text size="100" color="gray-500">
                    Initiate any NACHA return code via our API.
                  </Text>
                </Flex>
              </Column>
              <Column>
                <Code title="ODFI - Receive webhooks on ACH returns">
                  {`{
  "id": "evnt_1vEdMiZ5pmkmrKZfNZ8LeqF2KFP",
  "created_at": "2021-07-12T23:05:36Z",
  "type": "ACH_TRANSFER_REJECTED",
  "data": {
    "id": "acht_1vEdMiRjQWQYqRqaOppMfU7BWr1",
    "return_code": "R08",
    ... // details of an ACH return object
  }
}`}
                </Code>
                <Flex gap={1} alignItems="center">
                  <CircleCheck color="blue-500" viewportSize={20} />
                  <Text size="100" color="gray-500">
                    Receive webhooks on any incoming returns and dispute programatically.{' '}
                  </Text>
                </Flex>
              </Column>
              <Column>
                <Code title="Get ACH return">
                  {`curl 'https://api.column.com/transfers/ach/acht_1xXyZXXpC5QcnyNDJW0bt0ki95E/return'`}
                </Code>
              </Column>
            </Flex>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionReturnsDisputes;
