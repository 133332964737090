import { css } from 'styled-components';

export const Gray = css`
  .color-palette--gray {
    --bg-color-primary: var(--color-gray-700);
    --bg-color-secondary: var(--color-gray-800);
    --bg-gradient-primary: var(--color-gray-900);
  }

  .color-palette-inverted--gray {
    --bg-color-primary: var(--color-gray-700);
    --bg-color-secondary: var(--color-gray-800);
    --bg-gradient-primary: var(--color-0);
  }
`;
