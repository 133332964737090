import { IconProductACH } from './ACH';
import { IconProductWire } from './wire';
import { IconProductBookTransfers } from './book-transfers';
import { IconProductLedger } from './ledger';
import { IconProductBankAccounts } from './bank-accounts';
import { IconProductCardPrograms } from './card-programs';
import { IconProductChecks } from './checks';
import { IconProductLoanOrigination } from './loan-origination';
import { IconProductDebtFinancing } from './debt-financing';
import { IconProductLoanPurchase } from './loan-purchase';
import { IconProductDayOne } from './day-one';
import { IconProductWarehouseFinancing } from './warehouse-financing';
import { IconProductInternationalWires } from './international-wires';

export const IconProductInverted = {
  // Infrastructure
  ProductACH: IconProductACH,
  ProductWire: IconProductWire,
  ProductBookTransfers: IconProductBookTransfers,
  ProductLedger: IconProductLedger,
  ProductBankAccounts: IconProductBankAccounts,
  ProductCardPrograms: IconProductCardPrograms,
  ProductChecks: IconProductChecks,
  ProductInternationalWires: IconProductInternationalWires,

  // Lending
  ProductLoanOrigination: IconProductLoanOrigination,
  ProductDebtFinancing: IconProductDebtFinancing,
  ProductLoanPurchase: IconProductLoanPurchase,
  ProductDayOne: IconProductDayOne,
  ProductWarehouseFinancing: IconProductWarehouseFinancing,
};
