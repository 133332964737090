import { useCallback, useEffect, useState } from 'react';

export const useHistory = () => {
  if (typeof document === 'undefined') {
    return;
  }

  const [path, setPath] = useState<string>(() => document.location.pathname);

  const onHistoryChange = useCallback(() => {
    setPath(document.location.pathname);
  }, []);

  useEffect(() => {
    window.addEventListener('historyChanged', onHistoryChange);
    return () => {
      window.removeEventListener('historyChanged', onHistoryChange);
    };
  }, []);

  return path;
};
