import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconBlogHome: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="2" width="14" height="11">
        <path d="M4 8h8v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8Z" fill="url(#16_blog-home__a)" />
        <path
          d="M13.974 7.12 8.651 2.558a1 1 0 0 0-1.302 0L2.026 7.12a.5.5 0 0 0 .326.88h11.297a.5.5 0 0 0 .325-.88Z"
          fill="url(#16_blog-home__b)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M16 0H0v16h16V0Z" fill="url(#16_blog-home__d)" />
      </g>
      <defs>
        <linearGradient
          id="16_blog-home__a"
          x1="13.54"
          y1="-.904"
          x2="7.681"
          y2="10.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="16_blog-home__b"
          x1="14.591"
          y1="-6.417"
          x2="8.068"
          y2="7.355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="16_blog-home__d" x1="0" y1="0" x2="16" y2="16" gradientUnits="userSpaceOnUse">
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
