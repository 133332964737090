import React from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Button } from '~/elements/Button';
import { Icon } from '~/elements/Icon';
import { Br } from '~/elements/Br';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { nav } from '~/ds/mixins/breakpoints';

const ProductHero = styled.section`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -82px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, #edf2f7 0%, rgba(237, 242, 247, 0) 100%);
  }
`;

const Crop = styled.div`
  overflow: hidden;
  padding: 32px 0 0;

  @media ${nav} {
    padding: 154px 0 var(--space-18);
    margin-top: -82px;
  }
`;

const GradientText = styled.span`
  background: #1662a8;
  background: linear-gradient(92.07deg, #1662a8 0%, #65b3f1 99.67%);
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Image = styled.div`
  width: 100%;
  height: 320px;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);

  @media ${nav} {
    width: 1200px;
    height: 640px;
    border-radius: 48px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BlurredImage = styled(Image)`
  position: absolute;
  right: 100%;
  bottom: 72px;

  @media (min-width: 1250px) {
    right: calc(100% - 32px);
  }

  @media (min-width: 1350px) {
    right: calc(100% - 72px);
  }

  img {
    filter: blur(32px);
  }
`;

export const SectionHeroPoliticalTreasury: React.FC = () => {
  return (
    <ProductHero>
      <Crop>
        <Container>
          <Grid gridTemplateColumns={12} position="relative" justifyContent={{ _: 'center', nav: 'start' }}>
            <Column gridColumn={{ _: 12, nav: 6 }} position="relative" zIndex="3" height="100%">
              <Flex flexDirection="column" height="100%" justifyContent="space-between" py={9}>
                <div>
                  <Heading as="h1" size="700" weight="semibold" mt={4} textAlign={{ _: 'center', nav: 'left' }}>
                    <GradientText>
                      Banking purpose built for <Br display={{ _: 'none', md: 'block' }} />
                      Political Treasury Management
                    </GradientText>
                  </Heading>
                  <Text
                    size="400"
                    color="gray-800"
                    mt={2}
                    textAlign={{ _: 'center', nav: 'left' }}
                    maxWidth={{ nav: '470px' }}
                  >
                    Column is a nationally chartered, OCC-regulated Bank built for complex commercial and payments
                    focused customers.
                  </Text>
                </div>

                <Column maxWidth={{ nav: '430px' }}>
                  <Text textAlign={{ _: 'center', nav: 'left' }} mt={{ _: 2, nav: 0 }}>
                    We’ve seen first hand how political treasurers with complex financial and payments needs are
                    under-served with legacy technology and convoluted processes from traditional banks. We created a
                    dedicated product and team built from the ground up to limit the time and headache you spend on
                    payments and reconciliations.
                  </Text>
                  <ButtonGroup mt={6} justifyContent={{ _: 'center', nav: 'start' }}>
                    <Button href="#contact-form" type="link" variant="primary">
                      Contact us <Icon.AnimatedArrowRight />
                    </Button>
                  </ButtonGroup>
                </Column>
              </Flex>
            </Column>

            <Column gridColumn={{ _: 12, nav: 5 }} gridColumnStart={{ _: 1, nav: 8 }}>
              <Image>
                <img src="/images/specialty-deposits/political.jpg" />
              </Image>
            </Column>
          </Grid>
        </Container>
        <BlurredImage>
          <img src="/images/specialty-deposits/political.jpg" />
        </BlurredImage>
      </Crop>
    </ProductHero>
  );
};
