import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/loan-purchase/_hero';
import { SectionOverview } from './products/loan-purchase/_overview';
import { SectionCloser } from './index/_closer';
import { Layout } from '~/components/Layout';
import { AllProducts } from '~/components/AllProducts';
import { Testimonials } from '~/components/Testimonials';
import { Footer } from '~/components/Footer';

const LoanPurchasePage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Loan Purchase — Column</title>
        <meta property="og:title" content="Column - Loan Purchase" />
        <meta
          name="description"
          content="Instantly send and receive funds to any US bank account via API. Id quam purus arcu adipiscing sed malesuada. Lectus tristique diam natoque parturient id lorem mi neque, imperdiet."
        />
      </Helmet>
      <SectionHero />
      <SectionOverview />
      <Testimonials />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />
      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default LoanPurchasePage;
