import { css } from 'styled-components';

export const Primary = css`
  :root,
  .color-palette--primary {
    --bg-color-primary: var(--color-blue-50);
    --bg-color-secondary: var(--color-gray-100);
    --bg-color-muted: var(--color-gray-100);
    --bg-gradient-primary: linear-gradient(165deg, #4575cd 0%, #7ea7e9 92%);
  }

  .color-palette-inverted--primary {
    --bg-color-primary: var(--color-gray-900);
    --bg-color-secondary: var(--color-gray-50);
  }
`;
