import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from '~/elements/Link';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Layout } from '~/components/Layout';

const NotFoundPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Column — Page not found</title>
    </Helmet>
    <div className="flex flex-col items-center justify-center text-center py-18" style={{ height: '80vh' }}>
      <img src="/images/404.png" alt="Column being chiseled into form" className="h-full mb-8 max-h-60" />
      <div>
        <Heading weight="semibold" as="h1" size="400">
          Page not found
        </Heading>
        <Text size="300" color="gray-500" marginTop={2}>
          We weren't able to find what you are looking for. <br />
          <Link as="a" className="font-medium text-blue-600 hover:text-blue-700" to="/">
            Go home.
          </Link>
        </Text>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
