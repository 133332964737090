import styled from 'styled-components';
import {
  system,
  position,
  compose,
  grid,
  space,
  flexbox,
  display,
  layout,
  PositionProps,
  GridProps,
  SpaceProps,
  FlexboxProps,
  BackgroundColorProps,
  DisplayProps,
  LayoutProps,
} from 'styled-system';

interface ColumnProps
  extends GridProps,
    PositionProps,
    SpaceProps,
    FlexboxProps,
    BackgroundColorProps,
    DisplayProps,
    LayoutProps {
  gridColumnStart?: Record<string, number>;
  gridColumnEnd?: Record<string, number>;
}

export const Column = styled.div<ColumnProps>`
  ${compose(grid, position, space, flexbox, display, layout)}

  ${system({
    gridColumnStart: {
      property: 'gridColumnStart',
      scale: 'gridColumnStartEnd',
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      scale: 'gridColumnStartEnd',
    },
  })}
`;
