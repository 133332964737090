import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsACH: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M16.62 7.2L12.52 3.92C12.21 3.67 11.77 3.63 11.41 3.83L3.51 8.21C3.35 8.3 3.22 8.44 3.13 8.6L8 12.5L16.9 7.55C16.84 7.42 16.74 7.3 16.62 7.2ZM10 9.5C8.9 9.5 8 8.83 8 8C8 7.17 8.9 6.5 10 6.5C11.1 6.5 12 7.17 12 8C12 8.83 11.1 9.5 10 9.5Z"
        fill="url(#paint0_linear_3204_731)"
      />
      <path
        d="M17 7.98V11.91C17 12.27 16.8 12.61 16.49 12.79L8.59 17.17C8.4 17.27 8.2 17.31 8 17.29V12.5L16.9 7.55C16.96 7.68 17 7.83 17 7.98Z"
        fill="url(#paint1_linear_3204_731)"
      />
      <path
        d="M8 12.5V17.29C7.81 17.27 7.63 17.2 7.48 17.08L3.38 13.8C3.14 13.61 3 13.32 3 13.02V9.09001C3 8.92001 3.05 8.75001 3.13 8.60001L8 12.5Z"
        fill="url(#paint2_linear_3204_731)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_731"
          x1="17.76"
          y1="11.29"
          x2="4.17"
          y2="5.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_731"
          x1="22.38"
          y1="23.96"
          x2="11.15"
          y2="10.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_731"
          x1="9.69"
          y1="11.54"
          x2="-3.52"
          y2="16.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
