import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { mergeRefs } from '~/utils/mergeRefs';

export interface TypingProps {
  className?: string;
  text: string;
  delayMs?: number;
  speedMs?: number; // Default: 80
}

export const Typing: React.FC<TypingProps> = (props) => {
  const [idle, setIdle] = useState<boolean>(true);
  const spanRef = useRef<HTMLSpanElement>(null);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!inView || !spanRef.current || !idle || !window) {
      return;
    }

    const current = spanRef.current;
    const array = props.text.split('');

    let timer: ReturnType<typeof setTimeout> | null = null;

    current.textContent = '';

    const init = () => {
      setIdle(false);

      if (array.length > 0 && current.textContent !== null) {
        current.textContent += array.shift();
      } else {
        clearTimeout(timer as ReturnType<typeof setTimeout>);
      }
      timer = setTimeout(init, props.speedMs ?? 80);
    };

    setTimeout(init, props.delayMs ?? 0);
  }, [inView]);

  return (
    <span className="typing" style={{ opacity: idle ? 0 : 1 }} ref={mergeRefs([ref, spanRef])}>
      {props.text}
    </span>
  );
};
