import { css } from 'styled-components';

export const BorderTokens = css`
  :root {
    --radii-0: ${({ theme }) => theme.radii[0]};
    --radii-1: ${({ theme }) => theme.radii[1]};
    --radii-sm: ${({ theme }) => theme.radii[1]};
    --radii-2: ${({ theme }) => theme.radii[2]};
    --radii-4: ${({ theme }) => theme.radii[4]};
    --radii-lg: ${({ theme }) => theme.radii[4]};
    --radii-default: ${({ theme }) => theme.radii.default};
    --radii-pill: ${({ theme }) => theme.radii.pill};
    --radii-round: ${({ theme }) => theme.radii.round};
    --radii-full: ${({ theme }) => theme.radii.round};
  }
`;
