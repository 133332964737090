module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://column.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/website/website/node_modules/gatsby-remark-prismjs","id":"1200397a-68bf-52ee-87d8-2c9ab8aaaf15","name":"gatsby-remark-prismjs","version":"6.25.0","modulePath":"/home/runner/work/website/website/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"showLineNumbers":true,"noInlineHighlight":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"rehypePlugins":[null,[null,{"behavior":"wrap"}]],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/website/website","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"column","start_url":"/","icon":"static/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4f3a4afc685faf4e5b84c3f33f00bedb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7R0YMMWPJX"],"gtagConfig":{"optimize_id":"OPT-TK5J2HR","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
