import React from 'react';
import styled from 'styled-components';
import { InlineLink } from '~/elements/InlineLink';
import { Section } from '~/elements/Section';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Text } from '~/elements/NewText';

export const SectionInfo: React.FC = () => {
  return (
    <Section pt={{ _: 0, md: 0 }}>
      <Container>
        <Grid gapY={16} zIndex="10" position="relative">
          <Column gridColumn={{ _: '1 / span 12', md: '2 / span 7', lg: '2 / span 6' }}>
            <Grid gridTemplateColumns={1} gapY={8}>
              <Text size="300" color="gray-700">
                If you've made it here you probably want to know what we're hiring for and if you should apply. You've
                made it to the right place. However, a couple things to note. First, we're not your normal tech company
                and we're definitely not your normal bank. You should read through this site in detail before you apply
                (especially the <InlineLink to="/company">company</InlineLink> page) and about our{' '}
                <InlineLink to="/blog/funding-column">funding</InlineLink> and our{' '}
                <InlineLink to="/blog/hiring-at-column">hiring</InlineLink> philosophy.
              </Text>

              <Text size="300" color="gray-700">
                In short, we're a pretty small team and we plan on staying that way. We are hiring a few people so we're
                excited to talk but it may be a different process than you're used to. We heavily emphasize employee
                experience, and a big part of that means not burdening them with constant interviews. So part of our
                process is async (things like a take-home) and that can sometimes result in a less than ideal candidate
                experience. That's a tradeoff we're willing to make, and means once you work here there is more deep
                work time and fewer distractions. If this sounds like something you'd be into — let's go do this thing!
              </Text>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionInfo;
