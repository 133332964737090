import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Flex } from '~/elements/Flex';
import { Box } from '~/elements/Box';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Code } from '~/elements/Code/Code';
import { Icon } from '~/elements/Icon';
import { lg, md } from '~/ds/mixins/breakpoints';

const fadeInHalf = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: .5;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  transform: scale(0.75) translateX(65px);
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  @media ${md} {
    transform: scale(0.9) translateX(25px);
  }

  @media ${lg} {
    transform: scale(1) translateX(0);
  }

  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(246, 247, 250, 1) 0%,
      rgba(246, 247, 250, 0) 90%,
      rgba(246, 247, 250, 1) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -50%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    top: -5%;
    right: -5%;
    bottom: -5%;
    left: -20%;
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
    filter: blur(30px);
    opacity: 0;
    z-index: 0;
    animation: 2.5s ${fadeInHalf} forwards;
  }
`;

const flyIn = keyframes`
 0% {
  transform: scale(.7);
  opacity: 0;
  }
 100% {
  transform: scale(1);
  opacity: 1;
  }
`;

const TransferCard = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--shadow-product-border), var(--shadow-product);
  min-width: 320px;
  position: relative;
  z-index: 3;
  opacity: 0;
  animation: 0.5s var(--animation-curve) ${flyIn} 0.5s forwards;
`;

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
`;

const stepIn = keyframes`
 0% {
  transform: translateX(-10px);
  opacity: 0;
  }
 100% {
  transform: translateX(0);
  opacity: 1;
  }
`;

const checkIn = keyframes`
 0% {
  transform: scale(.8);
  opacity: 0;
  }
 100% {
  transform: scale(1);
  opacity: 1;
  }
`;

const SettlementWindow = styled(Flex)`
  position: relative;
  width: 100%;
  transform: translateX(-10px);
  opacity: 0;
  animation: 0.5s var(--animation-curve) ${stepIn} 0.5s forwards;

  &:after {
    content: '';
    position: absolute;
    top: 26px;
    bottom: -16px;
    left: 9px;
    width: 2px;
    border-radius: 2px;
    right: 0;
    background: var(--color-gray-100);
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  ${CircleCheck} {
    animation: 0.5s var(--animation-curve) ${checkIn} 0.5s forwards;

    &:nth-child(2) {
      animation: 0.5s var(--animation-curve) ${checkIn} 0.7s forwards;
    }
    &:nth-child(3) {
      animation: 0.5s var(--animation-curve) ${checkIn} 0.8s forwards;
    }
    &:nth-child(4) {
      animation: 0.5s var(--animation-curve) ${checkIn} 0.9s forwards;
    }
    &:nth-child(5) {
      animation: 0.5s var(--animation-curve) ${checkIn} 1s forwards;
    }
  }

  &:nth-child(2) {
    animation: 0.5s var(--animation-curve) ${stepIn} 0.6s forwards;
  }
  &:nth-child(3) {
    animation: 0.5s var(--animation-curve) ${stepIn} 0.8s forwards;
  }
  &:nth-child(4) {
    animation: 0.5s var(--animation-curve) ${stepIn} 0.9s forwards;
  }
  &:nth-child(5) {
    animation: 0.5s var(--animation-curve) ${stepIn} 1s forwards;
  }
`;

const responseIn = keyframes`
 0% {
  transform: scale(0.7) translateX(88px);
  opacity: 0;
  }
 100% {
  transform: scale(1) translateX(0);
  opacity: 1;
  }
`;

const ACHResponse = styled.div`
  position: absolute;
  top: 32px;
  left: -128px;
  bottom: 32px;
  max-width: 320px;
  z-index: 2;
  transform: scale(0.7) translateX(88px);
  opacity: 0;
  animation: 0.5s var(--animation-curve) ${responseIn} 0.75s forwards;

  div:nth-child(1),
  div:nth-child(2) {
    height: 100%;
  }
`;

export const Transfer: React.FC = () => {
  return (
    <Wrapper>
      <TransferCard>
        <Box p={6} pb={4}>
          <Heading as="span" size="200" weight="medium">
            Transfer
          </Heading>
        </Box>
        <Flex justifyContent="space-between" px={6} pb={6}>
          <Flex flexDirection="column" gap={0.5}>
            <Heading as="span" size="400" weight="semibold">
              $241.00
            </Heading>
            <Text size="200" color="gray-500">
              Personal checking - 2919
            </Text>
          </Flex>
        </Flex>

        <Flex
          px={6}
          py={5}
          backgroundColor="#FBFBFB"
          gapY={5}
          flexDirection="column"
          borderTop="1px solid var(--color-gray-200)"
          borderRadius="0 0 8px 8px"
        >
          <Flex gap={6} flexDirection="column">
            <SettlementWindow gap={{ _: 2, md: 3 }} alignItems="flex-start">
              <CircleCheck color="blue-500" viewportSize={20} />
              <Flex flexDirection="column" gap={0.5}>
                <Heading as="span" size="100" weight="medium">
                  Created
                </Heading>
                <Text size="100" color="gray-400">
                  January 26, 2023 - 10:20 AM
                </Text>
              </Flex>
            </SettlementWindow>

            <SettlementWindow gap={{ _: 2, md: 3 }} alignItems="flex-start">
              <CircleCheck color="blue-500" viewportSize={20} />
              <Flex flexDirection="column" gap={0.5}>
                <Heading as="span" size="100" weight="medium">
                  Initiated
                </Heading>
                <Text size="100" color="gray-400">
                  January 26, 2023 - 12:03 PM
                </Text>
              </Flex>
            </SettlementWindow>

            <SettlementWindow gap={{ _: 2, md: 3 }} alignItems="flex-start">
              <CircleCheck color="blue-500" viewportSize={20} />
              <Flex flexDirection="column" gap={0.5}>
                <Heading as="span" size="100" weight="medium">
                  Submitted
                </Heading>
                <Text size="100" color="gray-400">
                  January 26, 2023 - 1:14 PM
                </Text>
              </Flex>
            </SettlementWindow>

            <SettlementWindow gap={{ _: 2, md: 3 }} alignItems="flex-start">
              <CircleCheck color="blue-500" viewportSize={20} />
              <Flex flexDirection="column" gap={0.5}>
                <Heading as="span" size="100" weight="medium">
                  Settled
                </Heading>
                <Text size="100" color="gray-400">
                  January 26, 2023 - 3:46 PM
                </Text>
              </Flex>
            </SettlementWindow>

            <SettlementWindow gap={{ _: 2, md: 3 }} alignItems="flex-start">
              <CircleCheck color="blue-500" viewportSize={20} />
              <Flex flexDirection="column" gap={0.5}>
                <Heading as="span" size="100" weight="medium">
                  Completed
                </Heading>
                <Text size="100" color="gray-400">
                  July 26, 2021 - 3:46 PM
                </Text>
              </Flex>
            </SettlementWindow>
          </Flex>
        </Flex>
      </TransferCard>

      <ACHResponse>
        <Code language="json" maxHeight="auto">
          {`{
  "id": "acht_2HKbYE2th2sFioBxrRFOZL3IHR4",
  "iat": null,
  "type": "CREDIT",
  "amount": 100000,
  "status": "SUBMITTED",
  "is_on_us": false,
  "same_day": false,
  "company_id": "9959349647",
  "created_at": "2022-11-09T23:32:47Z",
  "settled_at": null,
  "updated_at": "2022-11-09T23:32:48Z",
  "description": "",
  "is_incoming": false,
  "receiver_id": "",
  "returned_at": null,
  "cancelled_at": null,
  "company_name": "COLUMN NA",
  "completed_at": null,
  "effective_on": "2022-11-10T08:00:00Z",
  "initiated_at": "2022-11-09T23:32:47Z",
  "nsf_deadline": null,
  "submitted_at": "2022-11-09T23:32:48Z",
  "trace_number": "121145300000005",
  "currency_code": "USD",
  "receiver_name": "CHASE ACCOUNT",
  "return_details": [],
  "acknowledged_at": null,
  "allow_overdraft": false,
  "bank_account_id": "bacc_2HKbY4W10hcBQBx5r42xMDEhv2K",
  "counterparty_id": "cpty_2HKbYBePtS8z3zK32QvwKlIoIeK",
  "idempotency_key": "",
  "entry_class_code": "PPD",
  "manual_review_at": null,
  "account_number_id": "acno_2HKbY3eq0gPP1WaQLZShDk87JC4",
  "funds_availability": "default",
  "odfi_routing_number": "121145307",
  "return_contested_at": null,
  "payment_related_info": "payment addenda for COR testing",
  "return_dishonored_at": null,
  "notification_of_changes": null,
  "company_entry_description": "PAYMENT",
  "reversal_pair_transfer_id": "",
  "company_discretionary_data": ""
}`}
        </Code>
      </ACHResponse>
    </Wrapper>
  );
};
