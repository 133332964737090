import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductCardPrograms: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="5" width="24" height="22">
        <path
          d="M25 18v4H4v-6c0-1.1.9-2 2-2h1v2c0 1.1.9 2 2 2h16zM4 25c0 1.1.9 2 2 2h17c1.1 0 2-.9 2-2H4z"
          fill="url(#32_bin-sponsorship-dark__paint0_linear)"
        />
        <path
          d="M13 11v1c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z"
          fill="url(#32_bin-sponsorship-dark__paint1_linear)"
        />
        <path
          d="M26 5H9c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h17c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-13 7c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v1z"
          fill="url(#32_bin-sponsorship-dark__paint2_linear)"
        />
        <path d="M25 22H4v3h21v-3z" fill="url(#32_bin-sponsorship-dark__paint3_linear)" />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#32_bin-sponsorship-dark__paint4_linear)" />
      </g>
      <defs>
        <linearGradient
          id="32_bin-sponsorship-dark__paint0_linear"
          x1="36.056"
          y1="6.313"
          x2="4.485"
          y2="27.091"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_bin-sponsorship-dark__paint1_linear"
          x1="24.597"
          y1="-4.959"
          x2="9.295"
          y2="13.564"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_bin-sponsorship-dark__paint2_linear"
          x1="11.682"
          y1="16.795"
          x2="27.789"
          y2="2.137"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_bin-sponsorship-dark__paint3_linear"
          x1="25.773"
          y1="21.52"
          x2="1.934"
          y2="25.707"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_bin-sponsorship-dark__paint4_linear"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
