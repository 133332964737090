import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsDataModels: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M10 10.5C13.3137 10.5 16 8.933 16 7C16 5.067 13.3137 3.5 10 3.5C6.68629 3.5 4 5.067 4 7C4 8.933 6.68629 10.5 10 10.5Z"
        fill="url(#paint0_linear_3204_750)"
      />
      <path
        d="M16 7V10.5C16 12.43 13.31 14 10 14C6.69 14 4 12.43 4 10.5V7C4 7.64 4.3 8.24 4.82 8.75C5.86 9.8 7.79 10.5 10 10.5C12.21 10.5 14.14 9.8 15.18 8.75C15.7 8.24 16 7.64 16 7Z"
        fill="url(#paint1_linear_3204_750)"
      />
      <path
        d="M16 10.5V14C16 15.93 13.31 17.5 10 17.5C6.69 17.5 4 15.93 4 14V10.5C4 11.14 4.3 11.74 4.82 12.25C5.86 13.3 7.79 14 10 14C12.21 14 14.14 13.3 15.18 12.25C15.7 11.74 16 11.14 16 10.5Z"
        fill="url(#paint2_linear_3204_750)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_750"
          x1="6.18"
          y1="9.16"
          x2="30.29"
          y2="-4.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_750"
          x1="-0.13"
          y1="5.6"
          x2="18.72"
          y2="13.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_750"
          x1="-5.17"
          y1="9.04"
          x2="14.4"
          y2="14.59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
