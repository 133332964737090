import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

interface FootnoteLinkProps {
  footnoteNumber: string;
  footnoteContent: any;
  children: React.ReactNode;
}

const FootnoteNumber = styled.span`
  background-color: rgba(var(--rgb-blue-200), 0.5);
  border-radius: 4px;
  padding: 3px 4px;
  margin: 0 1px;
  line-height: 1;
  position: relative;
  top: -0.5em;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.1s ease, color 0.1s ease;

  sup {
    top: 0;
    line-height: 1;
  }

  &:hover {
    background-color: rgba(var(--rgb-blue-700), 1);
    color: var(--color-white);
  }
`;

const TooltipContainer = styled.div<{ offsetLeft: number; offsetTop: number; isVisible: boolean }>`
  position: fixed;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: var(--radii-default);
  width: 300px;
  z-index: 1000;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.05),
    0 16px 32px rgba(0, 0, 0, 0.05);

  left: ${(props) => props.offsetLeft}px;
  top: ${(props) => props.offsetTop}px;
  transform: translate(-50%, ${(props) => (props.isVisible ? '-100%' : '-90%')});
  margin-top: -8px;

  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.15s ease, transform 0.15s ease, visibility 0.15s ease;
`;

export const FootnoteLink: React.FC<FootnoteLinkProps> = ({ footnoteNumber, footnoteContent, children }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ offsetLeft: 0, offsetTop: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: PointerEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        linkRef.current &&
        !linkRef.current.contains(event.target as Node)
      ) {
        setIsTooltipVisible(false);
      }
    };

    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, []);

  const handleClick = (event: React.PointerEvent<HTMLSpanElement>) => {
    event.preventDefault();
    const linkRect = linkRef.current?.getBoundingClientRect();
    if (linkRect) {
      const tooltipWidth = 300;
      const viewportWidth = window.innerWidth;
      const margin = 24;

      let offsetLeft = linkRect.left + linkRect.width / 2;
      const offsetTop = linkRect.top;

      // Adjust if tooltip would extend beyond right edge of viewport
      if (offsetLeft + tooltipWidth / 2 > viewportWidth - margin) {
        offsetLeft = viewportWidth - tooltipWidth / 2 - margin;
      }
      // Adjust if tooltip would extend beyond left edge of viewport
      else if (offsetLeft - tooltipWidth / 2 < margin) {
        offsetLeft = tooltipWidth / 2 + margin;
      }

      setTooltipPosition({ offsetLeft, offsetTop });
      setIsTooltipVisible(!isTooltipVisible);
    }
  };

  return (
    <span>
      <FootnoteNumber onPointerDown={handleClick} ref={linkRef}>
        {children}
      </FootnoteNumber>
      <TooltipContainer
        className="footnote blog-content"
        ref={tooltipRef}
        offsetLeft={tooltipPosition.offsetLeft}
        offsetTop={tooltipPosition.offsetTop}
        isVisible={isTooltipVisible}
      >
        {footnoteContent && renderRichText(footnoteContent)}
      </TooltipContainer>
    </span>
  );
};
