import { css } from 'styled-components';

export const Infrastructure = css`
  .color-palette--infrastructure {
    --icon-gradient-stop-1: var(--color-seafoam-500);
    --icon-gradient-stop-2: var(--color-cyan-700);

    --icon-color-docs: var(--color-seafoam-500);
  }

  .color-palette-inverted--infrastructure {
    --icon-gradient-stop-1: var(--color-seafoam-400);
    --icon-gradient-stop-2: var(--color-cyan-300);

    --icon-color-docs: var(--color-seafoam-400);
  }
`;
