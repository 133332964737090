import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductFedNow: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductChecks {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <path
        d="M5.9996 27.4997C5.7096 27.4997 5.4196 27.4497 5.1296 27.3397C3.8396 26.8597 3.1696 25.4197 3.6496 24.1297L8.4396 11.1897C9.0996 9.40965 10.7596 8.24965 12.6596 8.24965C14.5596 8.24965 16.2196 9.39965 16.8796 11.1897L19.3296 17.7997L23.6496 6.12965C24.1296 4.82965 25.5696 4.17965 26.8596 4.64965C28.1496 5.12965 28.8196 6.56965 28.3396 7.85965L23.5496 20.7997C22.8896 22.5797 21.2296 23.7397 19.3296 23.7397C17.4296 23.7397 15.7696 22.5897 15.1096 20.7997L12.6596 14.1897L8.3396 25.8597C7.9696 26.8697 7.0096 27.4897 5.9996 27.4897V27.4997Z"
        fill="url(#paint0_linear_135_127193)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_135_127193"
          x1="1.2696"
          y1="15.9997"
          x2="26.2696"
          y2="15.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
