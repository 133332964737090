import { css } from 'styled-components';

export const Primary = css`
  :root {
    --icon-gradient-stop-1: var(--color-blue-500);
    --icon-gradient-stop-2: var(--color-blue-600);
  }

  .color-palette--primary {
    --icon-gradient-stop-1: var(--color-blue-500);
    --icon-gradient-stop-2: var(--color-blue-600);
  }

  .color-palette-inverted--primary {
    --icon-gradient-stop-1: var(--color-blue-200);
    --icon-gradient-stop-2: var(--color-blue-400);
  }
`;
