import React from 'react';
import styled, { css } from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Icon } from '~/elements/Icon';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Box } from '~/elements/Box';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Typing } from '~/elements/Typing';
import { sm, md } from '~/ds/mixins/breakpoints';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 24px !important;
`;

const CardStyles = css`
  background: rgba(var(--rgb-white), 1);
  border-radius: var(--space-3);
  box-shadow: 0 0 0 1px rgba(var(--rgb-black), 0.05);
`;

const Card = styled.div`
  ${CardStyles}
  padding: var(--space-6);

  @media ${sm} {
    height: 100%;
  }

  @media ${md} {
    padding: var(--space-8);
  }
`;

const CardLarge = styled.div`
  ${CardStyles}
  padding: var(--space-6);
  height: 100%;
  overflow: hidden;

  @media ${md} {
    padding: var(--space-12);
  }
`;

const APIFirst = styled(Flex)`
  position: relative;

  &:after {
    border-radius: 0%;
    content: '';
    background: rgba(var(--rgb-white), 1);
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -20%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    --glow-offset: -10%;
    top: var(--glow-offset);
    right: var(--glow-offset);
    bottom: var(--glow-offset);
    left: var(--glow-offset);
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%);
    filter: blur(30px);
    opacity: 0.3;
    z-index: 0;
  }
`;

const API = styled.img`
  width: 68%;
  margin-right: -72px;
  position: relative;
  z-index: 2;
  border-radius: 8px;
  box-shadow: var(--shadow-product-border), var(--shadow-product);
`;

const Spreadsheet = styled.img`
  width: 44%;
  border-radius: 8px;
  box-shadow: var(--shadow-product-border), var(--shadow-product);
  position: relative;
  z-index: 1;
`;

const Transparent = styled.div`
  max-width: 250px;
  margin: 0 auto 16px;
  position: relative;

  img {
    width: 100%;
    max-width: 250px;
    border-radius: 8px;
    box-shadow: var(--shadow-product-border), var(--shadow-product);
    position: relative;
    z-index: 1;
  }

  &:after {
    border-radius: 0%;
    content: '';
    background: rgba(var(--rgb-white), 1);
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -20%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    --glow-offset: -20%;
    top: var(--glow-offset);
    right: var(--glow-offset);
    bottom: var(--glow-offset);
    left: var(--glow-offset);
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%);
    filter: blur(30px);
    opacity: 0.3;
    z-index: 0;
  }
`;

export const SectionOverview: React.FC = () => {
  return (
    <Section pt={{ _: 0, md: 0, lg: 0 }} overflow="hidden">
      <Container>
        <Grid gridTemplateColumns={12}>
          <Column gridColumn={{ _: 12, md: 9, lg: 7 }}>
            <Column>
              <Heading as="h2" weight="semibold" size="700">
                Build a compliant lending program powered by our developer fir
                <Typing text="st APIs" />
              </Heading>
            </Column>
          </Column>

          <Column gridColumn={12} mt={12}>
            <Grid gridTemplateColumns={{ _: 1, md: 3 }} gap={{ md: 6 }} gapY={6}>
              <Column gridColumn={2}>
                <CardLarge>
                  <Flex flexDirection="column" justifyContent={{ _: 'center', lg: 'end' }} height="100%">
                    <APIFirst maxWidth="510px" alignItems="center" margin="0 auto 16px">
                      <API src="/images/products/loan-origination/api.png" />
                      <Spreadsheet src="/images/products/loan-origination/spreadsheet.svg" />
                    </APIFirst>
                    <Column>
                      <Box maxWidth="400px" margin="0 auto" position="relative" zIndex="2">
                        <Heading as="h3" weight="semibold" size="500" textAlign="center" mt={4}>
                          API First
                        </Heading>
                        <Text size="300" mt={1} textAlign="center">
                          Originate and settle loans directly from our APIs. Never send spreadsheets again.
                        </Text>
                      </Box>
                    </Column>
                  </Flex>
                </CardLarge>
              </Column>

              <Column gridColumn={{ _: 2, md: 1 }}>
                <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1 }} gap={6} height="100%">
                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Program flexibility
                      </Heading>
                      <Text size="200" mt={1}>
                        Our experienced team can help you build a variety of customizable credit products.
                      </Text>
                    </Card>
                  </Column>

                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Modern compliance
                      </Heading>
                      <Text size="200" mt={1}>
                        Our experienced and forward-thinking compliance team has helped scale dozens of modern programs.
                      </Text>
                    </Card>
                  </Column>
                </Grid>
              </Column>

              <Column gridColumn={{ _: 2, md: 1 }}>
                <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1 }} gap={6} height="100%">
                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Credit experience
                      </Heading>
                      <Text size="200" mt={1}>
                        Our team has years of experience evaluating complex loan portfolios at leading banks, investment
                        firms, and fintechs.
                      </Text>
                    </Card>
                  </Column>

                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Speed to market
                      </Heading>
                      <Text size="200" mt={1}>
                        Implementation at your pace with no surprises.
                      </Text>
                    </Card>
                  </Column>
                </Grid>
              </Column>

              <Column gridColumn={2}>
                <CardLarge>
                  <Flex flexDirection="column" justifyContent="end" height="100%">
                    <Transparent>
                      <img src="/images/products/loan-origination/transparent.png" />
                    </Transparent>
                    <Column>
                      <Box maxWidth="480px" margin="0 auto" position="relative" zIndex="2">
                        <Heading as="h3" weight="semibold" size="500" textAlign="center" mt={4}>
                          Transparent
                        </Heading>
                        <Text size="300" mt={1} textAlign="center">
                          We focus on providing a straightforward and transparent process that enables you to focus on
                          building and scaling.
                        </Text>
                      </Box>
                    </Column>
                  </Flex>
                </CardLarge>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionOverview;
