import { css } from 'styled-components';

export const Orange = css`
  .color-palette--orange {
    --icon-color: var(--color-orange-500);
  }

  .color-palette-inverted--orange {
    --icon-color: var(--color-orange-500);

    /* Link */
    --btn-text-color-link: var(--color-0);
  }
`;
