import { businessDetailsObject } from "../../../../src/data/api/parameters/businessDetailsObject";
import { AddressObject } from "../../../../src/data/api/parameters/AddressObject";
import { Passport } from "../../../../src/data/api/parameters/Passport";
import { License } from "../../../../src/data/api/parameters/DriversLicense";
import { NationalID } from "../../../../src/data/api/parameters/NationalID";
import * as React from 'react';
export default {
  businessDetailsObject,
  AddressObject,
  Passport,
  License,
  NationalID,
  React
};