import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';

export const IconAnimatedRight: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke icon-animated-arrow-right', props.className];

  return (
    <IconBase {...props} className={classNames(staticClasses)}>
      <path
        className="icon-animated-arrow-right--chevron"
        d="M8 13L13 8L8 3"
        style={{ stroke: 'var(--icon-base-color)' }}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        className="icon-animated-arrow-right--stem"
        d="M12 8L2 8"
        style={{ stroke: 'var(--icon-base-color)' }}
        strokeWidth="1.5"
      />
    </IconBase>
  );
};
