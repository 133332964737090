import { css } from 'styled-components';

export const SpaceTokens = css`
  :root {
    --space-auto: ${({ theme }) => theme.space.auto};
    --space-px: ${({ theme }) => theme.space.px};
    --space-0: ${({ theme }) => theme.space[0]};
    --space-0-5: ${({ theme }) => theme.space[0.5]}; // 2
    --space-1: ${({ theme }) => theme.space[1]}; // 4
    --space-1-5: ${({ theme }) => theme.space[1.5]}; // 6
    --space-2: ${({ theme }) => theme.space[2]}; // 8
    --space-2-5: ${({ theme }) => theme.space[2.5]}; // 10
    --space-3: ${({ theme }) => theme.space[3]}; // 12
    --space-3-5: ${({ theme }) => theme.space[3.5]}; // 14
    --space-4: ${({ theme }) => theme.space[4]}; // 16
    --space-5: ${({ theme }) => theme.space[5]}; // 20
    --space-6: ${({ theme }) => theme.space[6]}; // 24
    --space-7: ${({ theme }) => theme.space[7]}; // 28
    --space-8: ${({ theme }) => theme.space[8]}; // 32
    --space-9: ${({ theme }) => theme.space[9]}; // 36
    --space-10: ${({ theme }) => theme.space[10]}; // 40
    --space-11: ${({ theme }) => theme.space[11]}; // 44
    --space-12: ${({ theme }) => theme.space[12]}; // 48
    --space-14: ${({ theme }) => theme.space[14]}; // 56
    --space-16: ${({ theme }) => theme.space[16]}; // 64
    --space-18: ${({ theme }) => theme.space[18]}; // 72
    --space-20: ${({ theme }) => theme.space[20]}; // 80
    --space-24: ${({ theme }) => theme.space[24]}; // 96
    --space-28: ${({ theme }) => theme.space[28]}; // 112
    --space-32: ${({ theme }) => theme.space[32]}; // 128
    --space-36: ${({ theme }) => theme.space[36]}; // 144
    --space-40: ${({ theme }) => theme.space[40]}; // 160
    --space-44: ${({ theme }) => theme.space[44]}; // 176
    --space-48: ${({ theme }) => theme.space[48]}; // 192
    --space-52: ${({ theme }) => theme.space[52]}; // 208
    --space-56: ${({ theme }) => theme.space[56]}; // 224
    --space-60: ${({ theme }) => theme.space[60]}; // 240
    --space-64: ${({ theme }) => theme.space[64]}; // 256
    --space-72: ${({ theme }) => theme.space[72]}; // 288
    --space-80: ${({ theme }) => theme.space[80]}; // 320
    --space-96: ${({ theme }) => theme.space[96]}; // 384

    // Negative
    --space-npx: -${({ theme }) => theme.space.px};
    --space-n0-5: -${({ theme }) => theme.space[0.5]}; // 2
    --space-n1: -${({ theme }) => theme.space[1]}; // 4
    --space-n1-5: -${({ theme }) => theme.space[1.5]}; // 6
    --space-n2: -${({ theme }) => theme.space[2]}; // 8
    --space-n2-5: -${({ theme }) => theme.space[2.5]}; // 10
    --space-n3: -${({ theme }) => theme.space[3]}; // 12
    --space-n3-5: -${({ theme }) => theme.space[3.5]}; // 14
    --space-n4: -${({ theme }) => theme.space[4]}; // 16
    --space-n5: -${({ theme }) => theme.space[5]}; // 20
    --space-n6: -${({ theme }) => theme.space[6]}; // 24
    --space-n7: -${({ theme }) => theme.space[7]}; // 28
    --space-n8: -${({ theme }) => theme.space[8]}; // 32
    --space-n9: -${({ theme }) => theme.space[9]}; // 36
    --space-n10: -${({ theme }) => theme.space[10]}; // 40
    --space-n11: -${({ theme }) => theme.space[11]}; // 44
    --space-n12: -${({ theme }) => theme.space[12]}; // 48
    --space-n14: -${({ theme }) => theme.space[14]}; // 56
    --space-n16: -${({ theme }) => theme.space[16]}; // 64
    --space-n18: -${({ theme }) => theme.space[18]}; // 72
    --space-n20: -${({ theme }) => theme.space[20]}; // 80
    --space-n24: -${({ theme }) => theme.space[24]}; // 96
    --space-n28: -${({ theme }) => theme.space[28]}; // 112
    --space-n32: -${({ theme }) => theme.space[32]}; // 128
    --space-n36: -${({ theme }) => theme.space[36]}; // 144
    --space-n40: -${({ theme }) => theme.space[40]}; // 160
    --space-n44: -${({ theme }) => theme.space[44]}; // 176
    --space-n48: -${({ theme }) => theme.space[48]}; // 192
    --space-n52: -${({ theme }) => theme.space[52]}; // 208
    --space-n56: -${({ theme }) => theme.space[56]}; // 224
    --space-n60: -${({ theme }) => theme.space[60]}; // 240
    --space-n64: -${({ theme }) => theme.space[64]}; // 256
    --space-n72: -${({ theme }) => theme.space[72]}; // 288
    --space-n80: -${({ theme }) => theme.space[80]}; // 320
    --space-n96: -${({ theme }) => theme.space[96]}; // 384
  }
`;
