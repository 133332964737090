import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  position,
  SpaceProps,
  ColorProps,
  LayoutProps,
  PositionProps,
} from 'styled-system';

export interface IconStyleProps extends SpaceProps, ColorProps, LayoutProps, PositionProps {}

export interface IconProps extends Partial<IconStyleProps> {
  viewportSize?: number;
  viewportSizeX?: number;
  viewportSizeY?: number;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onMouseEnter?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onMouseMove?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onMouseDown?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onMouseUp?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onFocus?: (event: React.FocusEvent<SVGSVGElement>) => void;
  onBlur?: (event: React.FocusEvent<SVGSVGElement>) => void;
  className?: string;
  inverted?: boolean;
}

const Svg = styled.svg<any>`
  ${compose(space, color, layout, position)}
`;

export const IconBase: React.FC<IconProps> = (props) => {
  const viewportSizeX = props.viewportSizeX || props.viewportSize || 16;
  const viewportSizeY = props.viewportSizeY || props.viewportSize || 16;

  const styleProps: IconStyleProps = {};
  const staticClasses: StaticClasses = [props.className];

  return (
    <Svg
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseMove={props.onMouseMove}
      onMouseLeave={props.onMouseLeave}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      viewBox={`0 0 ${viewportSizeX} ${viewportSizeY}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(staticClasses)}
      {...styleProps}
    >
      {props.children}
    </Svg>
  );
};
