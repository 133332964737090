import { css } from 'styled-components';

export const Icon = css`
  .icon {
    display: inline-block;
    height: 16px;
  }

  .icon--stroke {
    display: inline-block;
    stroke: currentColor;
    height: 16px;
  }

  .icon--product {
    display: inline-block;
    width: 32px;
    height: 32px;
  }

  .icon-duotone {
    display: inline-block;
    fill: currentColor;

    & + [class*='text-group-'],
    & + [class*='text-group-duotone-'] {
      margin-top: var(--space-6);
    }

    & + .text-group-300,
    & + .text-group-duotone-300 {
      margin-top: var(--space-4);
    }
  }

  .icon-fill-highlight {
    fill: var(--icon-fill-highlight);
  }

  .icon-fill-shadow {
    fill: var(--icon-fill-shadow);
  }

  /* Animated icons */

  .icon-animated-arrow-right {
    margin: 0 -4px 0 0;
  }

  .icon-animated-arrow-right--chevron {
    transform: translateX(-3px);
    transition: 0.15s transform;
  }

  .icon-animated-arrow-right--stem {
    stroke-dasharray: 10;
    stroke-dashoffset: 10;
    transform: translateX(-3px);
    transition: 0.15s stroke-dashoffset, 0.15s transform;
  }
`;
