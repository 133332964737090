import React from 'react';

export const IsRoot = [
  {
    param: 'is_root',
    type: 'boolean',
    required: false,
    description: <p>Indicates if the entity is the root entity of the platform.</p>,
  },
];
