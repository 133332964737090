import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import ArrowIcon from '~/assets/svg/fednow/beepboop.svg';
import ClockIcon from '~/assets/svg/fednow/clockyclock.svg';
import CostIcon from '~/assets/svg/fednow/dollas.svg';
import SpeedIcon from '~/assets/svg/fednow/speeeed.svg';
import { Typing } from '~/elements/Typing';

const StyledSectionBox = styled(SectionBox)`
  background-color: rgba(var(--rgb-white), 0.4);
  backdrop-filter: blur(32px);
  box-shadow: inset 0 1px 1px rgba(var(--rgb-white), 1);
  /* padding: 100px 0; */
  padding-top: var(--space-18);
  padding-bottom: var(--space-18);

  @media (min-width: 640px) {
    /* padding: 140px 0; */
    padding-top: var(--space-32);
  padding-bottom: var(--space-32);
  }
`;

const StyledEyebrow = styled(Heading)`
  background-color: var(--color-cyan-800);
  color: var(--color-white);
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  margin: 0 0 48px 0;
  box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.05);
  line-height: 1;

  .typing {
    &::after {
      display: none;
    }
  }
`;

export const SectionBoth: React.FC = () => {
  return (
    <Section pb={{ _: 0, md: 0, lg: 0 }}>
      <StyledSectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gapY={8}>
            <Column gridColumn={12}>
              <Flex flexDirection="column" alignItems="center" height="100%">
                <StyledEyebrow size="200" weight="medium">
                  Wh
                  <Typing text="y FedNow" />
                </StyledEyebrow>
                <Heading weight="semibold" size="1000" color="cyan-800" as="h2" textAlign="center">
                  Best of both worlds
                </Heading>
                <Text size="500" weight="semibold" mt={4} color="cyan-800" textAlign="center">
                  FedNow is faster than Wire, configurable like ACH.
                </Text>
              </Flex>
            </Column>
            <Column gridColumn={{ _: 12, md: 10 }} gridColumnStart={{ _: 1, md: 2 }}>
              <Grid gridTemplateColumns={{ _: 2, md: 4 }} gap={10} mt={16}>
                <Column>
                  <Flex gap={4} flexDirection="column" alignItems="start">
                    <CostIcon viewportSize={20} />
                    <Text size="300" color="cyan-800" textAlign="start">
                      Faster than Wire, and more affordable, too.
                    </Text>
                  </Flex>
                </Column>
                <Column>
                  <Flex gap={4} flexDirection="column" alignItems="start">
                    <ArrowIcon viewportSize={20} />
                    <Text size="300" color="cyan-800" textAlign="start">
                      Send or request funds. FedNow does both.
                    </Text>
                  </Flex>
                </Column>
                <Column>
                  <Flex gap={4} flexDirection="column" alignItems="start">
                    <SpeedIcon viewportSize={20} />
                    <Text size="300" color="cyan-800" textAlign="start">
                      API request to settlement in under a second.
                    </Text>
                  </Flex>
                </Column>
                <Column>
                  <Flex gap={4} flexDirection="column" alignItems="start">
                    <ClockIcon viewportSize={20} />
                    <Text size="300" color="cyan-800" textAlign="start">
                      Settle any time, any day. What Bank holiday?
                    </Text>
                  </Flex>
                </Column>
              </Grid>
            </Column>
          </Grid>
        </Container>
      </StyledSectionBox>
    </Section>
  );
};

export default SectionBoth;
