import React from 'react';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

interface FootnoteProps {
  referenceNumber: number;
  text: any;
}

const FootnoteItem = styled.div`
  display: flex;
`;

export const Footnote: React.FC<FootnoteProps> = ({ referenceNumber, text }) => (
  <FootnoteItem id={`footnote-${referenceNumber}`} className="footnote">
    <p>{referenceNumber}.</p>&nbsp;<div>{text && renderRichText(text)}</div>
  </FootnoteItem>
);
