import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductLoanPurchase: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="26" height="26">
        <path
          d="M11.457 27.543c1.42 1.42 3.263 1.881 4.115 1.029.852-.852.392-2.695-1.029-4.115-1.421-1.421-3.263-1.881-4.115-1.029-.852.852-.392 2.695 1.029 4.115z"
          fill="url(#32_forward-flow-dark__paint0_linear)"
        />
        <path
          d="M17.457 7.543c1.42 1.42 3.263 1.881 4.115 1.029.852-.852.392-2.695-1.029-4.115-1.421-1.421-3.263-1.881-4.115-1.029-.852.852-.392 2.695 1.029 4.115z"
          fill="url(#32_forward-flow-dark__paint1_linear)"
        />
        <path
          d="M14.543 24.457c1.42 1.42 1.881 3.263 1.029 4.115l13-13c.852-.852.392-2.695-1.029-4.115-1.421-1.421-3.263-1.881-4.115-1.029l-13 13c.852-.852 2.695-.392 4.115 1.029z"
          fill="url(#32_forward-flow-dark__paint2_linear)"
        />
        <path
          d="M4.457 20.543c1.421 1.42 3.263 1.881 4.115 1.029l13-13c-.852.852-2.695.392-4.115-1.029-1.42-1.42-1.881-3.263-1.029-4.115l-13 13c-.852.852-.392 2.695 1.029 4.115z"
          fill="url(#32_forward-flow-dark__paint3_linear)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M0 32h32V0H0v32z" fill="url(#32_forward-flow-dark__paint4_linear)" />
      </g>
      <defs>
        <linearGradient
          id="32_forward-flow-dark__paint0_linear"
          x1="19.893"
          y1="25.621"
          x2="8.9"
          y2="26.225"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_forward-flow-dark__paint1_linear"
          x1="20.648"
          y1="5.614"
          x2="9.292"
          y2="8.272"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_forward-flow-dark__paint2_linear"
          x1="17.7"
          y1="16.156"
          x2="22.927"
          y2="21.383"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset=".702" stopOpacity=".1" />
          <stop offset="1" stopOpacity=".3" />
        </linearGradient>
        <linearGradient
          id="32_forward-flow-dark__paint3_linear"
          x1="9.156"
          y1="10.7"
          x2="14.37"
          y2="15.913"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset=".702" stopOpacity=".1" />
          <stop offset="1" stopOpacity=".3" />
        </linearGradient>
        <linearGradient
          id="32_forward-flow-dark__paint4_linear"
          x1="16"
          y1="32"
          x2="16"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
