import { css } from 'styled-components';
import { md, xl } from '~/ds/mixins/breakpoints';
import { body100 } from '~/ds/mixins/typography';

export const Homepage = css`
  .pattern-accent-light,
  .pattern-accent-light-flip,
  .pattern-accent-dark,
  .pattern-accent-dark-flip {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url(/images/guilloche.svg);
      background-repeat: repeat;
      background-position: top right;
      background-size: 20px, 20px;
      z-index: 0;
      opacity: 0.075;
      background-clip: padding-box;
      mask-image: linear-gradient(160deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

      @media ${md} {
        border-radius: var(--radii-default);
      }
    }
  }

  .pattern-accent-dark {
    &::after {
      filter: invert(1);
      opacity: 0.1;
    }
  }

  .pattern-accent-light-flip,
  .pattern-accent-dark-flip {
    &::after {
      mask-image: linear-gradient(-160deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    }
  }

  .home-transfers {
    margin-bottom: var(--space-6);
    perspective: 1000px;
    svg {
      border-width: 1px;
      overflow: hidden;
      width: 100%;
      background-color: var(--color-white);
      border-radius: var(--radii-default);
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      transform: rotateX(0deg) translate3d(0, 0, 0);
      transition: transform 0.75s;
    }

    .home-transfers--shift {
      transform: rotateX(10deg) translate3d(0, -16px, -40px);
    }
  }

  .pattern-guilloche {
    background-color: var(--color-blue-50);
    position: absolute;
    width: 100%;
    display: block;
    top: var(--space-n16);
    bottom: var(--space-n6);
    border-radius: var(--radii-default);
    left: 100%;
    margin-left: -20%;
    z-index: -1;
  }

  .stack {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 10;
    /* transition: opacity 0.75s, transform 0.5s; */
  }

  .stack-label {
    grid-column: var(--span-2);
    overflow: hidden;
    margin: 20% -2rem 0 0;
  }

  .stack-label-text {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
    border-top: 1px solid rgba(var(--rgb-black), 0.2);
    display: block;
    opacity: 1;
    white-space: nowrap;
    transition: opacity 0.25s, transform 0.25s;
    ${body100}
    font-weight: var(--font-book);
  }

  .stack-layer-disk {
    grid-column: var(--span-4);
    position: relative;
    border-radius: 50%;
    overflow: visible;
    opacity: 1;
    /* transition: opacity 0.3s, box-shadow 0.3s; */

    svg {
      width: 100%;
      border-radius: 0;
    }
  }

  .stack-layer-middleware {
    .stack-label-text {
      transition-delay: 0.1s;
    }
  }
  .stack-layer-core {
    .stack-label-text {
      transition-delay: 0.15s;
    }
  }
  .stack-layer-bank {
    .stack-label-text {
      transition-delay: 0.2s;
    }
  }
  .stack-layer-fed {
    .stack-label-text {
      transition-delay: 0.25s;
    }
  }

  .stack-layer-fintech,
  .stack-layer-middleware,
  .stack-layer-core,
  .stack-layer-column,
  .stack-layer-bank,
  .stack-layer-fed {
    gap: var(--space-8);
    display: grid;
    grid-template-columns: var(--cols-6);
    align-items: center;
    width: 100%;
    position: relative;
    opacity: 1;
    transition: opacity 0.5s, transform 0.8s ease;
  }

  .stack-layer-middleware,
  .stack-layer-core,
  .stack-layer-bank {
    transition: opacity 0.4s ease, transform 0.7s ease;
  }

  .stack-layer-group {
    transition: opacity 0.4s 0.2s ease, transform 0.6s 0.1s ease;
  }

  .stack-layer-fintech {
    z-index: 6;
    transform: translate3d(0, 0, 0);
  }

  .stack-layer-group {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -55%, 0);
    z-index: 3;
    opacity: 1;
    /* transition: opacity 0.25s; */
  }

  .stack-layer-core,
  .stack-layer-bank {
    margin-top: -42%;
  }

  .stack-layer-middleware {
    z-index: 5;
  }

  .stack-layer-core {
    z-index: 4;
  }

  .stack-layer-bank {
    z-index: 3;
  }

  .stack-layer-column {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  .stack-layer-fed {
    z-index: 1;
    transform: translate3d(0, 0, 0);

    & .stack-layer-disk::after {
      background-color: rgba(16, 41, 79, 0.75);
      transform: scale(1) translate3d(0, 40%, 0);
      filter: blur(56px);
    }
  }

  .stack-layer-fintech,
  .stack-layer-column,
  .stack-layer-fed,
  .stack-layer-bank {
    .stack-layer-disk {
      &::after {
        content: '';
        position: absolute;
        top: 20%;
        z-index: -1;
        width: 100%;
        padding-top: calc(156 / 248 * 100%);
        border-radius: 50%;
        overflow: visible;
        /* transition: transform 0.5s ease, filter 0.5s ease, background-color 0.5s ease; */
      }
    }
  }

  .stack-layer-column {
    transition: opacity 0.3s 0.1s ease, transform 0.6s ease;
  }

  .stack-start {
    .stack-label-text {
      opacity: 0;
      transform: translate3d(-25%, 0, 0);
    }

    .stack-layer-fintech {
      transform: translate3d(0, -25%, 0);
    }

    .stack-layer-group {
      opacity: 1;
      transform: translate3d(0, -60%, 0);
    }

    .stack-layer-middleware,
    .stack-layer-bank,
    .stack-layer-fed {
      transform: translate3d(0, 0, 0);
    }

    .stack-layer-fed {
      transform: translate3d(0, 0, 0);
    }
  }

  .stack-traditional {
    .stack-label-text {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    .stack-layer-fintech {
      transform: translate3d(0, -15%, 0);
    }

    .stack-layer-middleware,
    .stack-layer-bank {
      transform: translate3d(0, 0, 0);
    }

    .stack-layer-group {
      opacity: 1;
      transform: translate3d(0, -55%, 0);
    }

    .stack-layer-column {
      opacity: 0;
      .stack-label-text {
        opacity: 0;
      }
    }
  }

  .stack-column {
    .stack-layer-fintech {
      transform: translate3d(0, 68%, 0);
      transition: opacity 0.6s, transform 0.75s ease;
    }

    .stack-layer-group,
    .stack-layer-middleware,
    .stack-layer-core,
    .stack-layer-bank {
      transition: opacity 0.2s 0.2s, transform 0.5s ease;
    }

    .stack-layer-group {
      opacity: 0;
    }
    .stack-layer-middleware {
      transform: translate3d(0, 34%, 0);
    }
    .stack-layer-core {
      transform: translate3d(0, 17%, 0);
    }
    .stack-layer-bank {
      transform: translate3d(0, 0, 0);
    }

    .stack-layer-column {
      .stack-label-text {
        transform: translate3d(0, 0, 0);
      }
    }
    .stack-layer-column {
      transform: translate3d(0, -15%, 0);
    }
  }

  .advantage-background {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-image: url(/images/guilloche.svg);
      background-repeat: repeat;
      background-position: top right;
      background-size: 20px, 20px;
      mix-blend-mode: overlay;
      z-index: 0;
      border-radius: 8px;
      background-clip: padding-box;
      border-radius: var(--radii-lg);
    }
  }

  .advantage-sticky-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    clip-path: inset(0 0 0 0);
    padding: 410px 0 0 0;
    z-index: 1;

    
    @media ${xl} {
    clip-path: inset(0 0 0 0 round var(--radii-lg));
    }

    @media ${md} {
      display: block;
    }
  }

  .advantage-sticky-frame {
    position: sticky;
    top: 50%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    transform: translateY(-50%);
    height: 410px;
    margin-bottom: -100px;

    &::after {
      content: '';
      position: absolute;
      top: 90%;
      left: 0;
      width: 100%;
      height: 464px;
      transform: translate3d(0%, 0%, 0) skewY(-30deg);
      /* border-radius: 8px; */
      background-image: linear-gradient(-90deg, rgba(var(--rgb-blue-300), 0.25), rgba(var(--rgb-blue-100), 0.1));
      z-index: 0;
    }
  }

  .advantage-glow {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.05);

    &::before {
      content: '';
      inset: 0;
      position: absolute;
      opacity: 0.3;
      z-index: 0;
      background-image: radial-gradient(
        1000px 1500px at top right,
        rgba(var(--rgb-blue-400), 1),
        rgba(var(--rgb-cyan-400), 0)
      );
    }

      @media ${xl} {
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
        
        &::before {
          border-radius: var(--radii-lg);
      }
    }
  }

  /* Demo */

  .demo-sticky-track {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: calc(50% + 48px);
    clip-path: inset(0 0 0 0 round var(--radii-lg) 0 0 var(--radii-lg));
    padding: 450px 0 0 0;
    z-index: 1;
    display: none;
    border-radius: var(--radii-lg) 0 0 var(--radii-lg);

    @media ${md} {
      display: block;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
    }
  }
  .demo-sticky-bg-track {
    position: absolute;
    inset: 0;
  }
  .demo-sticky-bgs {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    &::before {
      content: '';
      width: 2000px;
      height: 2000px;
      position: absolute;
      top: 75vh;
      left: 0;
      margin: -1000px -1000px;
      background-image: linear-gradient(90deg, rgba(var(--rgb-seafoam-400), 1), rgba(var(--rgb-green-400), 0.5));
      opacity: 0.5;
      z-index: 0;
      border-radius: 50%;
      mask-image: radial-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0) 50%);
    }
  }

  .demo-sticky-frame {
    position: sticky;
    top: 50%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    transform: translateY(-50%);
    height: 400px;
    margin-bottom: -56px;
  }

  .demo-sticky-frame {
    height: 100vh;
    margin-bottom: calc(-100vh + 320px);
    overflow: hidden;
  }

  .product-demo {
    position: relative;
    left: var(--space-24);
    transition: opacity 0.2s;
  }

  .product-demo-1-visual,
  .product-demo-2-visual,
  .product-demo-3-visual {
    position: relative;
    opacity: 0;
    transition: opacity 0s, transform 0s;
  }

  .product-demo-2-visual,
  .product-demo-3-visual {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .product-demo-3-visual {
    top: 60%;
  }

  .product-demo-1 {
    .product-demo-1-visual {
      opacity: 1;
      transition: opacity 0.75s, transform 0.75s;
    }
    .product-demo-2-visual,
    .product-demo-3-visual {
      opacity: 0;
    }
  }

  .product-demo-2 {
    .product-demo-2-visual {
      opacity: 1;
      transition: opacity 0.75s, transform 0.75s;
    }
    .product-demo-1-visual,
    .product-demo-3-visual {
      opacity: 0;
    }
  }

  .product-demo-3 {
    .product-demo-3-visual {
      opacity: 1;
      transition: opacity 0.75s, transform 0.75s;
    }
    .product-demo-1-visual,
    .product-demo-2-visual {
      opacity: 0;
    }
  }

  /* Lending graph */

  .lending-graph-wrap {
    width: 387px;
    height: 427px;
    transform: translateY(-10%);
  }

  .lending-graph-cylinder {
    width: 150px;
    height: 90px;
    position: absolute;
    border-radius: 100%;
    box-shadow: inset -2px 0 2px rgba(0, 0, 0, 0.1), inset -2px 0 4px rgba(0, 0, 0, 0.1), 1px 0px 2px rgba(0, 0, 0, 0.1),
      3px 0px 2px rgba(0, 0, 0, 0.1), 8px -2px 8px rgba(0, 0, 0, 0.1), 16px -2px 12px rgba(0, 0, 0, 0.05),
      24px -4px 16px rgba(0, 0, 0, 0.05), 32px -8px 24px rgba(0, 0, 0, 0.05), 48px -12px 32px rgba(0, 0, 0, 0.05),
      16px 0 32px rgba(0, 0, 0, 0.05);

    & p {
      position: absolute;
      left: 100%;
      bottom: 0;
      display: flex;
      font-size: 12px;
      line-height: 1em;
      font-family: 'SuisseIntlMono', 'SuisseIntl';
      font-weight: 600;
      text-transform: uppercase;
      background-color: rgba(var(--rgb-gray-400), 0.3);
      color: var(--color-gray-700);
      padding: 4px 6px;
      border-radius: 4px;
      white-space: nowrap;

      padding-top: var(--space-1);
      padding-bottom: var(--space-1);
    }

    &:nth-child(1) {
      bottom: 0;
      left: 0;
      z-index: 3;
    }
    &:nth-child(2) {
      bottom: 69px;
      left: calc(50% - 75px);
      z-index: 2;
    }
    &:nth-child(3) {
      bottom: 137px;
      right: 0;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 100%;
      transform: translateY(-200px);
      transition: 1.5s transform ease;
      background-image: linear-gradient(90deg, rgb(var(--rgb-white)), #d9dfd9);
    }
  }

  .lending-graph-cylinder div {
    width: 100%;
    height: 200px;
    /* background: inherit; */
    position: absolute;
    bottom: 45px;
    left: 0;
    transform-origin: center bottom;
    transition: 1.5s transform ease;
    box-shadow: inset -2px 3px 2px rgba(0, 0, 0, 0.1), inset -2px 5px 4px rgba(0, 0, 0, 0.1);
  }

  .lending-graph-cylinder:nth-child(1) {
    & div {
      transform: scale(1, 0.4);
    }
    &:after {
      transform: translateY(-80px);
    }
  }

  .lending-graph-cylinder:nth-child(2) {
    & div {
      transform: scale(1, 0.7);
    }
    &:after {
      transform: translateY(-140px);
    }
  }

  .lending-graph-cylinder:nth-child(3) {
    & div {
      transform: scale(1, 1);
    }
    &:after {
      transform: translateY(-200px);
    }
  }

  .product-demo-start,
  .product-demo-1,
  .product-demo-2 {
    & .lending-graph-wrap {
      opacity: 0;
    }
    & .lending-graph-cylinder {
      & div {
        transform: scale(1, 0.1);
        transition: 0s 0s transform;
      }
      &:after {
        transform: translateY(-25px);
        transition: 0s 0s transform;
      }
    }
  }

  .product-demo-3 {
    & .lending-graph-cylinder:nth-child(1) {
      & div {
        transform: scale(1, 0.4);
        transition: 0.4s 0s transform ease;
      }
      &:after {
        transform: translateY(-80px);
        transition: 0.4s 0s transform ease;
      }
    }

    & .lending-graph-cylinder:nth-child(2) {
      & div {
        transform: scale(1, 0.7);
        transition: 0.875s 0.4s transform ease;
      }
      &:after {
        transform: translateY(-140px);
        transition: 0.875s 0.4s transform ease;
      }
    }

    & .lending-graph-cylinder:nth-child(3) {
      & div {
        transform: scale(1, 1);
        transition: 1.5s 1.1s transform ease;
      }
      &:after {
        transform: translateY(-200px);
        transition: 1.5s 1.1s transform ease;
      }
    }
  }

  .lending-graph-cylinder,
  .lending-graph-cylinder div {
    background-image: linear-gradient(
      90deg,
      #f1f9f1,
      rgb(var(--rgb-gray-100)) 10%,
      rgb(var(--rgb-gray-500)) 92%,
      rgb(var(--rgb-gray-400))
    );
  }

  .demo-graphic-mobile {
    & .lending-graph-wrap {
      transform: scale(0.75) translateY(0%);
    }
    & .lending-graph-cylinder,
    & .lending-graph-cylinder div {
      background-image: linear-gradient(
        90deg,
        rgb(var(--rgb-gray-100)),
        rgb(var(--rgb-gray-200)) 10%,
        rgb(var(--rgb-gray-600)) 92%,
        rgb(var(--rgb-gray-400))
      );
    }
    & .lending-graph-cylinder:after {
      background-image: linear-gradient(90deg, rgb(var(--rgb-white)), rgb(var(--rgb-gray-300)));
    }
  }

  /* Card visual */

  .virtual-card {
    overflow: hidden;
    width: 216px;
    height: 216px;
    position: absolute;
    border-radius: var(--radii-lg);
    z-index: 10;
    top: 48px;
    right: 92px;
    box-shadow: 1px 1px 0 rgba(var(--rgb-gray-300)), 2px 2px 0 rgba(var(--rgb-gray-300)),
      96px 96px 48px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, 0, 0) scale(1, 0.5773) rotate(45deg);
  }

  .physical-card {
    border-radius: var(--radii-default);
    position: relative;
    top: 24px;
    left: -40px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }

  .product-demo-start,
  .product-demo-1,
  .product-demo-3 {
    & .virtual-card {
      transform: translate3d(0, 40%, 0) scale(1, 0.5773) rotate(45deg);
      filter: blur(16px);
      opacity: 0;
      box-shadow: 1px 1px 0 rgba(var(--rgb-gray-300)), 2px 2px 0 rgba(var(--rgb-gray-300)),
        8px 8px 8px rgba(0, 0, 0, 0.1);
      transition: opacity 0s 0s, transform 0s 0s ease;
    }

    & .physical-card {
      opacity: 0;
      transform: translate3d(25%, 25%, 0);
      transition: opacity 0s 0s, transform 0s 0s ease;
    }
  }

  .product-demo-2 {
    & .virtual-card {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1, 0.5773) rotate(45deg);
      filter: blur(0px);
      box-shadow: 1px 1px 0 rgba(var(--rgb-gray-300)), 2px 2px 0 rgba(var(--rgb-gray-300)),
        96px 96px 48px rgba(0, 0, 0, 0.1);
      transition: opacity 1.25s, transform 1.25s ease, filter 1.25s, box-shadow 1.25s ease;
      z-index: 1;
    }

    & .physical-card {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.25s, transform 1.25s ease;
    }
  }

  .demo-graphic-mobile {
    & .physical-card {
      top: 0;
      left: 0;
    }
    & .virtual-card {
      top: 0;
      right: 15%;
      width: 32vw;
      height: 32vw;
    }
  }

  .products-list {
    position: relative;

    & a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border-radius: var(--radii-default);
      background-color: var(--color-gray-50);
      padding: 32px 40px;
      margin: 0 0 32px 0;
      transition: 0.1s background-color ease-out;

      & p {
        transition: 0.1s color ease-out;
      }
    }
    & a:hover {
      background-color: var(--color-gray-100);

      & p {
        color: var(--color-gray-800);
      }
    }
  }

  .scale-graphic-svg {
    position: absolute;
    padding: 2px;

    @media ${md} {
      width: 750px;
      height: 500px;
    }
  }

  .inview--partners #scale-graphic-svg-one {
    animation-name: scale-pattern-animation-one;
    animation-duration: 4s;
    animation-iteration-count: 40;
  }

  @keyframes scale-pattern-animation-one {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .inview--partners #scale-graphic-svg-two {
    animation-name: scale-pattern-animation-two;
    animation-duration: 4s;
    animation-iteration-count: 40;
  }

  @keyframes scale-pattern-animation-two {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .background-gradient {
    position: absolute;
    top: 50%;
    left: 80%;
    width: 150vw;
    height: 1000px;
    transform: translate3d(-50%, -50%, 0);
    mask-image: radial-gradient(farthest-side at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    background-image: linear-gradient(90deg, rgba(var(--rgb-blue-400), 1), rgba(var(--rgb-seafoam-400), 1));
    /* opacity: 0.4; */
    filter: hue-rotate(0deg);

    .inview--gradient & {
      animation-name: background-gradient-animation;
      animation-duration: 8s;
      animation-iteration-count: 4;
      animation-direction: forward;
    }
  }

  .background-gradient-closer {
    top: 125%;
    left: 90%;
    width: 170vw;
    height: 1000px;
  }

  .background-gradient-pattern {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(/images/guilloche.svg);
    background-repeat: repeat;
    background-position: top right;
    background-size: 20px, 20px;
    mix-blend-mode: overlay;
  }

  @keyframes background-gradient-animation {
    0% {
      filter: hue-rotate(15deg);
    }
    50% {
      filter: hue-rotate(-80deg);
    }
    100% {
      filter: hue-rotate(15deg);
    }
  }

  .typing {
    &::after {
      content: '_';
      opacity: 0.3;
      position: absolute;
      animation: blink 1.5s ease infinite;
    }
  }

  .underscore-blink {
    opacity: 0.3;
    animation: blink 1.5s ease infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;
