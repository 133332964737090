import { css } from 'styled-components';

export const Purple = css`
  .color-palette--purple {
    --bg-color-primary: var(--color-purple-700);
    --bg-color-secondary: var(--color-purple-800);

    --bg-gradient-primary: linear-gradient(
      180deg,
      rgba(var(--rgb-purple-600), 0.7) 0%,
      rgba(var(--rgb-purple-400), 0.7) 50%,
      rgba(var(--rgb-orange-600), 0.7) 100%
    );
  }

  .color-palette-inverted--purple {
    --bg-color-primary: var(--color-purple-700);
    --bg-color-secondary: var(--color-purple-800);

    --bg-gradient-primary: linear-gradient(
      180deg,
      rgba(var(--rgb-purple-600), 0.7) 0%,
      rgba(var(--rgb-purple-400), 0.7) 50%,
      rgba(var(--rgb-orange-600), 0.7) 100%
    );
  }
`;
