import { css } from 'styled-components';

export const Products = css`
  .color-palette--products {
    --icon-gradient-stop-1: var(--color-seafoam-500);
    --icon-gradient-stop-2: var(--color-cyan-500);
    --icon-gradient-stop-3: var(--color-purple-500);
    --icon-gradient-stop-4: var(--color-red-500);
    --icon-gradient-stop-5: var(--color-yellow-500);
  }
`;
