import React from 'react';

export const balanceFields = [
  {
    param: 'available_amount',
    type: 'int64',
    required: false,
    description: <p>This balance is the amount of money that is available to spend. If the account is not enabled for overdrafts, any requests to move money above this number will fail.</p>,
  },
  {
    param: 'holding_amount',
    type: 'int64',
    required: false,
    description: <p>The balance of money which is currently in a <code>HOLD</code> state specified in the Create Book Transfer API. </p>,
  },
  {
    param: 'locked_amount',
    type: 'int64',
    required: false,
    description: <p>Only applicable for root accounts. The locked balance is posted on the account but cannot be withdrawn.</p>,
  },
  {
    param: 'pending_amount',
    type: 'int64',
    required: false,
    description: <p>The total amount of transfers that are in a pending state. These transfers will affect the available_balance unless they are canceled prior to completion.</p>,
  },
];

export const balanceObject = [
  {
    param: 'balances',
    type: 'object',
    required: false,
    description: <p>Lists all possible balance amounts for an account represented in the smallest unit of the currency. </p>,
    object: [...balanceFields],
  },
];
