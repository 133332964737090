import React from 'react';
import { Helmet } from 'react-helmet';
import { Testimonials } from '~/components/Testimonials';
import { SectionHero } from './products/checks/_hero';
import { SectionOwn } from './products/checks/_own';
import { SectionDeposits } from './products/checks/_deposits';
import { SectionCloser } from './index/_closer';
import { Layout } from '~/components/Layout';
import { AllProducts } from '~/components/AllProducts';
import { Footer } from '~/components/Footer';

const ChecksPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Checks — Column</title>
        <meta property="og:title" content="Column - Checks" />
        <meta
          name="description"
          content="Take full control of the end to end check flows. From RDC to checkbooks to positive-pay we allow you to build your optimal check flow and own the entire process."
        />
      </Helmet>
      <SectionHero />
      <SectionOwn />
      <SectionDeposits />
      <Testimonials />

      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />

      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default ChecksPage;
