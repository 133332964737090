import React from 'react';

export const micrFields = [
  {
    param: 'amount_field',
    type: 'string',
    required: false,
    description: <p>Amount of the issued check.</p>,
  },
  {
    param: 'auxiliary_on_us',
    type: 'string',
    required: false,
    description: <p>Auxiliary on us. This field is provided by the issuing bank and most commonly used for a check number. In many cases, auxiliary_on_us will be the string before a routing number in the MICR line.</p>,
  },
  {
    param: 'external_processing_code',
    type: 'string',
    required: false,
    description: <p>External processing code.</p>,
  },
  {
    param: 'payor_bank_routing_number',
    type: 'string',
    required: true,
    description: <p>Payor bank routing number.</p>,
  },
  {
    param: 'on_us',
    type: 'string',
    required: true,
    description: <p>On us. This field is provided by the issuing bank and most commonly contains both check number and account number. In many cases, on-us will be the string after a routing number in the MICR line.</p>,
  },
];

export const micrObject = [
  {
    param: 'micr_line',
    type: 'object',
    required: true,
    description: <p>The MICR line is the sequence of numbers and characters at the bottom of a check.</p>,
    object: [...micrFields],
  },
];
