import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductACH: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductACH {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="50.43" x2="12.03" y1="47.93" y2="9.53">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity="0" />
        <stop offset="1" stopColor="var(--color-blue-600)" />
      </linearGradient>
      <linearGradient id="b" x1="61.99" x2="21.62" href="#a" y1="56.23" y2="15.86" />
      <linearGradient id="c" x1="58.67" x2="18.3" href="#a" y1="59.55" y2="19.18" />
      <linearGradient id="d">
        <stop offset="0" stopColor="var(--color-blue-600)" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="e" gradientUnits="userSpaceOnUse" x1="78.44" x2=".53" href="#d" y1="10.5" y2="10.5" />
      <linearGradient id="f" gradientUnits="userSpaceOnUse" x1="-12.12" x2="11.18" href="#d" y1="40.12" y2="16.82" />
      <linearGradient id="g" x1="-11.4" x2="11.9" href="#f" y1="36.14" y2="12.84" />
      <linearGradient id="h" x1="-8.08" x2="15.22" href="#f" y1="39.46" y2="16.16" />
      <linearGradient id="i" x1="65.8" x2="-12.11" href="#f" y1="10.62" y2="10.62" />
      <path d="m29 13v3l-16 8v-3z" fill="url(#a)" />
      <path d="m29 10.24v2.76l-16 8v-4l15.68-7.84c.21.31.32.69.32 1.08z" fill="url(#b)" />
      <path d="m29 16v2.76c0 .76-.43 1.45-1.11 1.79l-13.99 7c-.28.14-.59.21-.9.21v-3.76z" fill="url(#c)" />
      <path
        d="m18.83 11.91c-1.56.79-4.1.79-5.66 0-1.56-.78-1.56-2.04 0-2.82 1.56-.79 4.1-.79 5.66 0 1.56.78 1.56 2.04 0 2.82z"
        fill="url(#e)"
      />
      <path d="m13 21v3l-10-5v-3z" fill="url(#f)" />
      <path d="m13 17v4l-10-5v-2.76c0-.39.11-.77.32-1.08z" fill="url(#g)" />
      <path d="m13 24v3.76c-.31 0-.61-.07-.89-.21l-8-4c-.68-.34-1.11-1.03-1.11-1.79v-2.76z" fill="url(#h)" />
      <path
        d="m27.89 8.45-8-4c-.56-.28-1.22-.28-1.78 0l-14 7c-.33.16-.6.41-.79.71l9.68 4.84 15.68-7.84c-.19-.3-.46-.55-.79-.71zm-9.06 3.46c-1.56.79-4.1.79-5.66 0-1.56-.78-1.56-2.04 0-2.82 1.56-.79 4.1-.79 5.66 0 1.56.78 1.56 2.04 0 2.82z"
        fill="url(#i)"
      />
    </IconBase>
  );
};
