import { css } from 'styled-components';

export const Orange = css`
  .color-palette--orange {
    --list-item-bullet: var(--color-orange-500);
    --list-item-number: var(--color-orange-500);
  }

  .color-palette-inverted--orange {
    --list-item-bullet: var(--color-orange-400);
    --list-item-number: var(--color-orange-400);
  }
`;
