import { css } from 'styled-components';

export const Color = css`
  :root {
    --rgb-0: ${({ theme }) => theme.colors.rgb[0]};
    --rgb-1000: ${({ theme }) => theme.colors.rgb[1000]};
    --rgb-white: var(--rgb-0);
    --rgb-black: var(--rgb-1000);

    --rgb-purple-50: ${({ theme }) => theme.colors.rgb['purple-50']};
    --rgb-purple-100: ${({ theme }) => theme.colors.rgb['purple-100']};
    --rgb-purple-200: ${({ theme }) => theme.colors.rgb['purple-200']};
    --rgb-purple-300: ${({ theme }) => theme.colors.rgb['purple-300']};
    --rgb-purple-400: ${({ theme }) => theme.colors.rgb['purple-400']};
    --rgb-purple-500: ${({ theme }) => theme.colors.rgb['purple-500']};
    --rgb-purple-600: ${({ theme }) => theme.colors.rgb['purple-600']};
    --rgb-purple-700: ${({ theme }) => theme.colors.rgb['purple-700']};
    --rgb-purple-800: ${({ theme }) => theme.colors.rgb['purple-800']};
    --rgb-purple-900: ${({ theme }) => theme.colors.rgb['purple-900']};

    --rgb-blue-50: ${({ theme }) => theme.colors.rgb['blue-50']};
    --rgb-blue-100: ${({ theme }) => theme.colors.rgb['blue-100']};
    --rgb-blue-200: ${({ theme }) => theme.colors.rgb['blue-200']};
    --rgb-blue-300: ${({ theme }) => theme.colors.rgb['blue-300']};
    --rgb-blue-400: ${({ theme }) => theme.colors.rgb['blue-400']};
    --rgb-blue-500: ${({ theme }) => theme.colors.rgb['blue-500']};
    --rgb-blue-600: ${({ theme }) => theme.colors.rgb['blue-600']};
    --rgb-blue-700: ${({ theme }) => theme.colors.rgb['blue-700']};
    --rgb-blue-800: ${({ theme }) => theme.colors.rgb['blue-800']};
    --rgb-blue-900: ${({ theme }) => theme.colors.rgb['blue-900']};

    --rgb-cyan-50: ${({ theme }) => theme.colors.rgb['cyan-50']};
    --rgb-cyan-100: ${({ theme }) => theme.colors.rgb['cyan-100']};
    --rgb-cyan-200: ${({ theme }) => theme.colors.rgb['cyan-200']};
    --rgb-cyan-300: ${({ theme }) => theme.colors.rgb['cyan-300']};
    --rgb-cyan-400: ${({ theme }) => theme.colors.rgb['cyan-400']};
    --rgb-cyan-500: ${({ theme }) => theme.colors.rgb['cyan-500']};
    --rgb-cyan-600: ${({ theme }) => theme.colors.rgb['cyan-600']};
    --rgb-cyan-700: ${({ theme }) => theme.colors.rgb['cyan-700']};
    --rgb-cyan-800: ${({ theme }) => theme.colors.rgb['cyan-800']};
    --rgb-cyan-900: ${({ theme }) => theme.colors.rgb['cyan-900']};

    --rgb-seafoam-50: ${({ theme }) => theme.colors.rgb['seafoam-50']};
    --rgb-seafoam-100: ${({ theme }) => theme.colors.rgb['seafoam-100']};
    --rgb-seafoam-200: ${({ theme }) => theme.colors.rgb['seafoam-200']};
    --rgb-seafoam-300: ${({ theme }) => theme.colors.rgb['seafoam-300']};
    --rgb-seafoam-400: ${({ theme }) => theme.colors.rgb['seafoam-400']};
    --rgb-seafoam-500: ${({ theme }) => theme.colors.rgb['seafoam-500']};
    --rgb-seafoam-600: ${({ theme }) => theme.colors.rgb['seafoam-600']};
    --rgb-seafoam-700: ${({ theme }) => theme.colors.rgb['seafoam-700']};
    --rgb-seafoam-800: ${({ theme }) => theme.colors.rgb['seafoam-800']};
    --rgb-seafoam-900: ${({ theme }) => theme.colors.rgb['seafoam-900']};

    --rgb-green-50: ${({ theme }) => theme.colors.rgb['green-50']};
    --rgb-green-100: ${({ theme }) => theme.colors.rgb['green-100']};
    --rgb-green-200: ${({ theme }) => theme.colors.rgb['green-200']};
    --rgb-green-300: ${({ theme }) => theme.colors.rgb['green-300']};
    --rgb-green-400: ${({ theme }) => theme.colors.rgb['green-400']};
    --rgb-green-500: ${({ theme }) => theme.colors.rgb['green-500']};
    --rgb-green-600: ${({ theme }) => theme.colors.rgb['green-600']};
    --rgb-green-700: ${({ theme }) => theme.colors.rgb['green-700']};
    --rgb-green-800: ${({ theme }) => theme.colors.rgb['green-800']};
    --rgb-green-900: ${({ theme }) => theme.colors.rgb['green-900']};
    --rgb-yellow-50: ${({ theme }) => theme.colors.rgb['yellow-50']};

    --rgb-yellow-100: ${({ theme }) => theme.colors.rgb['yellow-100']};
    --rgb-yellow-200: ${({ theme }) => theme.colors.rgb['yellow-200']};
    --rgb-yellow-300: ${({ theme }) => theme.colors.rgb['yellow-300']};
    --rgb-yellow-400: ${({ theme }) => theme.colors.rgb['yellow-400']};
    --rgb-yellow-500: ${({ theme }) => theme.colors.rgb['yellow-500']};
    --rgb-yellow-600: ${({ theme }) => theme.colors.rgb['yellow-600']};
    --rgb-yellow-700: ${({ theme }) => theme.colors.rgb['yellow-700']};
    --rgb-yellow-800: ${({ theme }) => theme.colors.rgb['yellow-800']};
    --rgb-yellow-900: ${({ theme }) => theme.colors.rgb['yellow-900']};

    --rgb-orange-50: ${({ theme }) => theme.colors.rgb['orange-50']};
    --rgb-orange-100: ${({ theme }) => theme.colors.rgb['orange-100']};
    --rgb-orange-200: ${({ theme }) => theme.colors.rgb['orange-200']};
    --rgb-orange-300: ${({ theme }) => theme.colors.rgb['orange-300']};
    --rgb-orange-400: ${({ theme }) => theme.colors.rgb['orange-400']};
    --rgb-orange-500: ${({ theme }) => theme.colors.rgb['orange-500']};
    --rgb-orange-600: ${({ theme }) => theme.colors.rgb['orange-600']};
    --rgb-orange-700: ${({ theme }) => theme.colors.rgb['orange-700']};
    --rgb-orange-800: ${({ theme }) => theme.colors.rgb['orange-800']};
    --rgb-orange-900: ${({ theme }) => theme.colors.rgb['orange-900']};

    --rgb-red-50: ${({ theme }) => theme.colors.rgb['red-50']};
    --rgb-red-100: ${({ theme }) => theme.colors.rgb['red-100']};
    --rgb-red-200: ${({ theme }) => theme.colors.rgb['red-200']};
    --rgb-red-300: ${({ theme }) => theme.colors.rgb['red-300']};
    --rgb-red-400: ${({ theme }) => theme.colors.rgb['red-400']};
    --rgb-red-500: ${({ theme }) => theme.colors.rgb['red-500']};
    --rgb-red-600: ${({ theme }) => theme.colors.rgb['red-600']};
    --rgb-red-700: ${({ theme }) => theme.colors.rgb['red-700']};
    --rgb-red-800: ${({ theme }) => theme.colors.rgb['red-800']};
    --rgb-red-900: ${({ theme }) => theme.colors.rgb['red-900']};
    --rgb-pink-50: ${({ theme }) => theme.colors.rgb['pink-50']};

    --rgb-pink-100: ${({ theme }) => theme.colors.rgb['pink-100']};
    --rgb-pink-200: ${({ theme }) => theme.colors.rgb['pink-200']};
    --rgb-pink-300: ${({ theme }) => theme.colors.rgb['pink-300']};
    --rgb-pink-400: ${({ theme }) => theme.colors.rgb['pink-400']};
    --rgb-pink-500: ${({ theme }) => theme.colors.rgb['pink-500']};
    --rgb-pink-600: ${({ theme }) => theme.colors.rgb['pink-600']};
    --rgb-pink-700: ${({ theme }) => theme.colors.rgb['pink-700']};
    --rgb-pink-800: ${({ theme }) => theme.colors.rgb['pink-800']};
    --rgb-pink-900: ${({ theme }) => theme.colors.rgb['pink-100']};

    --rgb-gray-50: ${({ theme }) => theme.colors.rgb['gray-50']};
    --rgb-gray-100: ${({ theme }) => theme.colors.rgb['gray-100']};
    --rgb-gray-200: ${({ theme }) => theme.colors.rgb['gray-200']};
    --rgb-gray-300: ${({ theme }) => theme.colors.rgb['gray-300']};
    --rgb-gray-400: ${({ theme }) => theme.colors.rgb['gray-400']};
    --rgb-gray-500: ${({ theme }) => theme.colors.rgb['gray-500']};
    --rgb-gray-600: ${({ theme }) => theme.colors.rgb['gray-600']};
    --rgb-gray-700: ${({ theme }) => theme.colors.rgb['gray-700']};
    --rgb-gray-800: ${({ theme }) => theme.colors.rgb['gray-800']};
    --rgb-gray-900: ${({ theme }) => theme.colors.rgb['gray-900']};

    // RGBA
    --color-1000: rgba(var(--rgb-1000), 1);
    --color-0: rgba(var(--rgb-0), 1);
    --color-white: var(--color-0);
    --color-black: var(--color-1000);

    --color-purple-50: rgba(var(--rgb-purple-50), 1);
    --color-purple-100: rgba(var(--rgb-purple-100), 1);
    --color-purple-200: rgba(var(--rgb-purple-200), 1);
    --color-purple-300: rgba(var(--rgb-purple-300), 1);
    --color-purple-400: rgba(var(--rgb-purple-400), 1);
    --color-purple-500: rgba(var(--rgb-purple-500), 1);
    --color-purple-600: rgba(var(--rgb-purple-600), 1);
    --color-purple-700: rgba(var(--rgb-purple-700), 1);
    --color-purple-800: rgba(var(--rgb-purple-800), 1);
    --color-purple-900: rgba(var(--rgb-purple-900), 1);

    --color-blue-50: rgba(var(--rgb-blue-50), 1);
    --color-blue-100: rgba(var(--rgb-blue-100), 1);
    --color-blue-200: rgba(var(--rgb-blue-200), 1);
    --color-blue-300: rgba(var(--rgb-blue-300), 1);
    --color-blue-400: rgba(var(--rgb-blue-400), 1);
    --color-blue-500: rgba(var(--rgb-blue-500), 1);
    --color-blue-600: rgba(var(--rgb-blue-600), 1);
    --color-blue-700: rgba(var(--rgb-blue-700), 1);
    --color-blue-800: rgba(var(--rgb-blue-800), 1);
    --color-blue-900: rgba(var(--rgb-blue-900), 1);

    --color-cyan-50: rgba(var(--rgb-cyan-50), 1);
    --color-cyan-100: rgba(var(--rgb-cyan-100), 1);
    --color-cyan-200: rgba(var(--rgb-cyan-200), 1);
    --color-cyan-300: rgba(var(--rgb-cyan-300), 1);
    --color-cyan-400: rgba(var(--rgb-cyan-400), 1);
    --color-cyan-500: rgba(var(--rgb-cyan-500), 1);
    --color-cyan-600: rgba(var(--rgb-cyan-600), 1);
    --color-cyan-700: rgba(var(--rgb-cyan-700), 1);
    --color-cyan-800: rgba(var(--rgb-cyan-800), 1);
    --color-cyan-900: rgba(var(--rgb-cyan-900), 1);

    --color-seafoam-50: rgba(var(--rgb-seafoam-50), 1);
    --color-seafoam-100: rgba(var(--rgb-seafoam-100), 1);
    --color-seafoam-200: rgba(var(--rgb-seafoam-200), 1);
    --color-seafoam-300: rgba(var(--rgb-seafoam-300), 1);
    --color-seafoam-400: rgba(var(--rgb-seafoam-400), 1);
    --color-seafoam-500: rgba(var(--rgb-seafoam-500), 1);
    --color-seafoam-600: rgba(var(--rgb-seafoam-600), 1);
    --color-seafoam-700: rgba(var(--rgb-seafoam-700), 1);
    --color-seafoam-800: rgba(var(--rgb-seafoam-800), 1);
    --color-seafoam-900: rgba(var(--rgb-seafoam-900), 1);

    --color-green-50: rgba(var(--rgb-green-50), 1);
    --color-green-100: rgba(var(--rgb-green-100), 1);
    --color-green-200: rgba(var(--rgb-green-200), 1);
    --color-green-300: rgba(var(--rgb-green-300), 1);
    --color-green-400: rgba(var(--rgb-green-400), 1);
    --color-green-500: rgba(var(--rgb-green-500), 1);
    --color-green-600: rgba(var(--rgb-green-600), 1);
    --color-green-700: rgba(var(--rgb-green-700), 1);
    --color-green-800: rgba(var(--rgb-green-800), 1);
    --color-green-900: rgba(var(--rgb-green-900), 1);

    --color-yellow-50: rgba(var(--rgb-yellow-50), 1);
    --color-yellow-100: rgba(var(--rgb-yellow-100), 1);
    --color-yellow-200: rgba(var(--rgb-yellow-200), 1);
    --color-yellow-300: rgba(var(--rgb-yellow-300), 1);
    --color-yellow-400: rgba(var(--rgb-yellow-400), 1);
    --color-yellow-500: rgba(var(--rgb-yellow-500), 1);
    --color-yellow-600: rgba(var(--rgb-yellow-600), 1);
    --color-yellow-700: rgba(var(--rgb-yellow-700), 1);
    --color-yellow-800: rgba(var(--rgb-yellow-800), 1);
    --color-yellow-900: rgba(var(--rgb-yellow-900), 1);

    --color-orange-50: rgba(var(--rgb-orange-50), 1);
    --color-orange-100: rgba(var(--rgb-orange-100), 1);
    --color-orange-200: rgba(var(--rgb-orange-200), 1);
    --color-orange-300: rgba(var(--rgb-orange-300), 1);
    --color-orange-400: rgba(var(--rgb-orange-400), 1);
    --color-orange-500: rgba(var(--rgb-orange-500), 1);
    --color-orange-600: rgba(var(--rgb-orange-600), 1);
    --color-orange-700: rgba(var(--rgb-orange-700), 1);
    --color-orange-800: rgba(var(--rgb-orange-800), 1);
    --color-orange-900: rgba(var(--rgb-orange-900), 1);

    --color-red-50: rgba(var(--rgb-red-50), 1);
    --color-red-100: rgba(var(--rgb-red-100), 1);
    --color-red-200: rgba(var(--rgb-red-200), 1);
    --color-red-300: rgba(var(--rgb-red-300), 1);
    --color-red-400: rgba(var(--rgb-red-400), 1);
    --color-red-500: rgba(var(--rgb-red-500), 1);
    --color-red-600: rgba(var(--rgb-red-600), 1);
    --color-red-700: rgba(var(--rgb-red-700), 1);
    --color-red-800: rgba(var(--rgb-red-800), 1);
    --color-red-900: rgba(var(--rgb-red-900), 1);

    --color-pink-50: rgba(var(--rgb-pink-50), 1);
    --color-pink-100: rgba(var(--rgb-pink-100), 1);
    --color-pink-200: rgba(var(--rgb-pink-200), 1);
    --color-pink-300: rgba(var(--rgb-pink-300), 1);
    --color-pink-400: rgba(var(--rgb-pink-400), 1);
    --color-pink-500: rgba(var(--rgb-pink-500), 1);
    --color-pink-600: rgba(var(--rgb-pink-600), 1);
    --color-pink-700: rgba(var(--rgb-pink-700), 1);
    --color-pink-800: rgba(var(--rgb-pink-800), 1);
    --color-pink-900: rgba(var(--rgb-pink-100), 1);

    --color-gray-50: rgba(var(--rgb-gray-50), 1);
    --color-gray-100: rgba(var(--rgb-gray-100), 1);
    --color-gray-200: rgba(var(--rgb-gray-200), 1);
    --color-gray-300: rgba(var(--rgb-gray-300), 1);
    --color-gray-400: rgba(var(--rgb-gray-400), 1);
    --color-gray-500: rgba(var(--rgb-gray-500), 1);
    --color-gray-600: rgba(var(--rgb-gray-600), 1);
    --color-gray-700: rgba(var(--rgb-gray-700), 1);
    --color-gray-800: rgba(var(--rgb-gray-800), 1);
    --color-gray-900: rgba(var(--rgb-gray-900), 1);
  }
`;
