import { css } from 'styled-components';

export const Pink = css`
  .color-palette--pink {
    /* Primary */
    --btn-text-color-primary: var(--color-0);

    --btn-bg-primary: var(--color-pink-600);
    --btn-bg-primary-hover: var(--color-pink-700);
    --btn-bg-primary-active: var(--color-pink-800);

    --btn-border-primary: var(--color-pink-700);
    --btn-border-primary-hover: var(--color-pink-800);
    --btn-border-primary-active: var(--color-pink-900);

    --btn-focus-ring-primary: var(--color-pink-200);

    /* Secondary */
    --btn-text-color-secondary: var(--color-pink-700);

    --btn-bg-secondary: var(--color-pink-100);
    --btn-bg-secondary-hover: var(--color-pink-100);
    --btn-bg-secondary-active: var(--color-pink-100);

    --btn-border-secondary: var(--color-pink-200);
    --btn-border-secondary-hover: var(--color-pink-300);
    --btn-border-secondary-active: var(--color-pink-300);

    --btn-focus-ring-secondary: var(--color-pink-200);
  }

  .color-palette-inverted--pink {
    /* Primary */
    --btn-text-color-primary: var(--color-pink-900);

    --btn-bg-primary: var(--color-0);
    --btn-bg-primary-hover: var(--color-0);
    --btn-bg-primary-active: var(--color-0);

    --btn-border-primary: var(--color-pink-800);
    --btn-border-primary-hover: rgba(var(--rgb-pink-900), 1);
    --btn-border-primary-active: rgba(var(--rgb-pink-900), 1);

    --btn-focus-ring-primary: var(--color-pink-500);

    /* Secondary */
    --btn-text-color-secondary: var(--color-0);

    --btn-bg-secondary: rgba(var(--rgb-pink-600), 0.75);
    --btn-bg-secondary-hover: var(--color-pink-600);
    --btn-bg-secondary-active: var(--color-pink-700);

    --btn-border-secondary: rgba(var(--rgb-pink-800));
    --btn-border-secondary-hover: var(--color-pink-900);
    --btn-border-secondary-active: var(--color-pink-900);

    --btn-focus-ring-secondary: var(--color-pink-500);
  }
`;
