import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { MarkdownInfo } from './Markdown/Info';
import { MarkdownPrivacyNotice } from './Markdown/PrivacyNotice';
import { Layout } from '~/components/Layout';
import { Container } from '~/elements/Container';
import { Text } from '~/elements/Text';
import { Box } from '~/elements/Box';
import { MarkdownStyles } from '~/elements/Markdown';

const components = {
  Info: MarkdownInfo,
  PrivacyNotice: MarkdownPrivacyNotice,
};

const Page: React.FC = (props: any) => {
  return (
    <Layout>
      <Helmet>
        <title>{props.data.mdx.frontmatter.title} - Column Documentation</title>
      </Helmet>

      <Container>
        <Box pt={12} mb={12}>
          <Text size="800" variant="heading" as="h1">
            {props.data.mdx.frontmatter.title}
          </Text>
        </Box>
        <MarkdownStyles>
          <MDXProvider components={components}>
            <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </MarkdownStyles>
      </Container>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
