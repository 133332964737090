import { css } from 'styled-components';

export const Lending = css`
  .color-palette--lending {
    --icon-gradient-stop-1: var(--color-orange-500);
    --icon-gradient-stop-2: var(--color-red-400);

    --icon-color-docs: var(--color-orange-500);
  }

  .color-palette-inverted--lending {
    --icon-gradient-stop-1: var(--color-orange-400);
    --icon-gradient-stop-2: var(--color-red-300);

    --icon-color-docs: var(--color-orange-400);
  }
`;
