import React from 'react';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Icon } from '~/elements/Icon';
import { Container } from '~/elements/Container';
import { Button } from '~/elements/Button';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Box } from '~/elements/Box';

export const SectionWarehouseFinancing: React.FC = () => {
  return (
    <Section>
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column gridColumn={{ _: 12, md: 8, nav: 6 }}>
            <Flex flexDirection="column">
              <Heading as="h2" weight="semibold" size="700">
                Warehouse Financing
              </Heading>
              <Text size="300" color="rgba(var(--rgb-black), 0.6)" mt={2}>
                Set up a scalable lending facility with competitive and flexible terms to free up capital and accelerate
                your originations growth.
              </Text>

              <div>
                <Button to="https://dashboard.column.com/contact" fontSize="16px" variant="link" mt={2}>
                  Contact us
                  <Icon.AnimatedArrowRight />
                </Button>
              </div>
            </Flex>
          </Column>

          <Column gridColumn={{ _: 12, nav: 10, lg: 7 }} mt={4}>
            <Grid gridTemplateColumns={{ _: 1, md: 2 }} gap={6}>
              <Column>
                <Heading as="h3" size="300" weight="medium">
                  What is Warehouse Financing?
                </Heading>

                <Flex as="ul" gapY={4} flexDirection="column" mt={4}>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)" mt={1}>
                        Committed funding
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)" mt={1}>
                        Typically non-recourse financing
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)" mt={1}>
                        Competitive interest rates and fees
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)" mt={1}>
                        Draw only what you need
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)" mt={1}>
                        Custom terms based on deep understanding of your portfolio, business, and financing goals
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Column>

              <Column>
                <Heading as="h3" size="300" weight="medium">
                  Who is a Warehouse Financing for?
                </Heading>
                <Flex as="ul" gapY={4} flexDirection="column" mt={4}>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)" mt={1}>
                        Have an established track record and a meaningful outstanding portfolio balance
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)" mt={1}>
                        Stable historical performance
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)" mt={1}>
                        Focused on optimizing your capital structure
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionWarehouseFinancing;
