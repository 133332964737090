import styled from 'styled-components';
import {
  compose,
  system,
  flexbox,
  space,
  layout,
  border,
  color,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  BorderProps,
  ColorProps,
} from 'styled-system';

interface FlexComponentProps extends FlexboxProps, SpaceProps, LayoutProps, BorderProps, ColorProps {
  gap?: any;
  gapX?: any;
  gapY?: any;
  gridTemplateColumns?: any;
}

export const Flex = styled.div<FlexComponentProps>`
  display: flex;

  ${compose(flexbox, space, layout, border, color)};

  ${system({
    gap: {
      property: 'gridGap',
      scale: 'space',
    },
    gapX: {
      property: 'gridColumnGap',
      scale: 'space',
    },
    gapY: {
      property: 'gridRowGap',
      scale: 'space',
    },
  })}
`;
