import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './security/_hero';
import { SectionPoints } from './security/_points';
import { SectionBounty } from './security/_bounty';
import { Layout } from '~/components/Layout';

const SecurityPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Security — Column</title>
        <meta property="og:title" content="Column - Security" />
        <meta
          name="description"
          content="We're committed to providing innovative and modern financial services without sacrificing the safety of your
            personal information"
        />
        <link rel="canonical" href="https://column.com/security" />
      </Helmet>
      <SectionHero />
      <SectionPoints />
      <SectionBounty />
    </Layout>
  );
};

export default SecurityPage;
