import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconBrandMark3d: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-brand icon-mark3d', props.className];

  return (
    <IconBase viewportSize={24} {...props} className={classNames(staticClasses)}>
      <rect width="24" height="24" rx="6" style={{ fill: 'var(--icon-color-brand, , #1E4199)' }} />
      <path d="M24 15V24H18V21C18 17.7 15.3 15 12 15H24Z" fill="url(#paint0_radial_349_12365)" />
      <path d="M24 9V15H9.15C9 15 9 15 9 14.85V9.15C9 9 9 9 9.15 9H24Z" fill="url(#paint1_radial_349_12365)" />
      <defs>
        <radialGradient
          id="paint0_radial_349_12365"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.4435 26.316) scale(23.3055)"
        >
          <stop offset="0.15" stopColor="white" />
          <stop offset="0.85" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_349_12365"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.011 5.1495) scale(37.5465)"
        >
          <stop offset="0.15" stopColor="white" />
          <stop offset="0.85" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </IconBase>
  );
};
