import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsAPI: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M15.53 10.97L14.35 12.15C14.15 12.35 13.84 12.35 13.64 12.15L8.35001 6.85999C8.15001 6.65999 8.15001 6.34999 8.35001 6.14999L9.53001 4.96999C10.73 3.76999 12.6 3.62999 13.95 4.54999C14.14 4.66999 14.31 4.80999 14.47 4.96999L15.53 6.02999C15.69 6.18999 15.83 6.35999 15.95 6.54999C16.87 7.89999 16.73 9.76999 15.53 10.97Z"
        fill="url(#paint0_linear_3204_735)"
      />
      <path
        d="M18 4.5L15.95 6.55C15.83 6.36 15.69 6.19 15.53 6.03L14.47 4.97C14.31 4.81 14.14 4.67 13.95 4.55L16 2.5L18 4.5Z"
        fill="url(#paint1_linear_3204_735)"
      />
      <path
        d="M11.65 14.15C11.85 14.35 11.85 14.66 11.65 14.86L10.47 16.04C9.26998 17.24 7.39998 17.38 6.04998 16.46C5.85998 16.34 5.68998 16.2 5.52998 16.04L4.46998 14.98C4.30998 14.82 4.16998 14.65 4.04998 14.46C3.12998 13.11 3.26998 11.24 4.46998 10.04L5.64998 8.86001C5.84998 8.66001 6.15998 8.66001 6.35998 8.86001L11.65 14.15Z"
        fill="url(#paint2_linear_3204_735)"
      />
      <path
        d="M6.05 16.45L4 18.5L2 16.5L4.05 14.45C4.17 14.64 4.31 14.81 4.47 14.97L5.53 16.03C5.69 16.19 5.86 16.33 6.05 16.45Z"
        fill="url(#paint3_linear_3204_735)"
      />
      <path
        d="M10.65 10.85L9.5 12L10.5 13L11.65 11.85C11.85 11.65 11.85 11.34 11.65 11.14L11.36 10.85C11.16 10.65 10.85 10.65 10.65 10.85Z"
        fill="url(#paint4_linear_3204_735)"
      />
      <path
        d="M8.65 8.85L7.5 10L8.5 11L9.65 9.85C9.85 9.65 9.85 9.34 9.65 9.14L9.36 8.85C9.16 8.65 8.85 8.65 8.65 8.85Z"
        fill="url(#paint5_linear_3204_735)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_735"
          x1="19.8"
          y1="14.3"
          x2="9.25001"
          y2="3.74999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" />
          <stop offset="1" stop-color="var(--icon-color-docs)" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_735"
          x1="17.88"
          y1="6.43"
          x2="13.97"
          y2="2.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" />
          <stop offset="1" stop-color="var(--icon-color-docs)" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_735"
          x1="10.54"
          y1="17.04"
          x2="-1.95002"
          y2="4.55001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" />
          <stop offset="1" stop-color="var(--icon-color-docs)" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3204_735"
          x1="5.45"
          y1="17.9"
          x2="-2.95"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" />
          <stop offset="1" stop-color="var(--icon-color-docs)" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3204_735"
          x1="12.03"
          y1="13.03"
          x2="9.26"
          y2="10.26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3204_735"
          x1="10.03"
          y1="11.03"
          x2="7.26"
          y2="8.26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
