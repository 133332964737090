import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductLedger: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="6" y="5" width="20" height="22">
        <path d="M26 5v4H9c-1.66 0-3-.893-3-2s1.34-2 3-2h17z" fill="url(#32_ledger-dark__paint0_linear)" />
        <path
          d="M21.5 22h-8a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v1a.5.5 0 01-.5.5z"
          fill="url(#32_ledger-dark__paint1_linear)"
        />
        <path
          d="M21 18h-7a1 1 0 01-1-1v-3a1 1 0 011-1h7a1 1 0 011 1v3a1 1 0 01-1 1z"
          fill="url(#32_ledger-dark__paint2_linear)"
        />
        <path d="M9 9v18c-1.66 0-3-.89-3-2V7c0 1.11 1.34 2 3 2z" fill="url(#32_ledger-dark__paint3_linear)" />
        <path
          d="M9 9v18h17V9H9zm13 12.5c0 .28-.22.5-.5.5h-8c-.28 0-.5-.22-.5-.5v-1c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5v1zm0-4.5c0 .55-.45 1-1 1h-7c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h7c.55 0 1 .45 1 1v3z"
          fill="url(#32_ledger-dark__paint4_linear)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#32_ledger-dark__paint5_linear)" />
      </g>
      <defs>
        <linearGradient
          id="32_ledger-dark__paint0_linear"
          x1="16"
          y1="-2.968"
          x2="16"
          y2="8.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_ledger-dark__paint1_linear"
          x1="12.621"
          y1="16.121"
          x2="20.041"
          y2="23.541"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset=".5" stopOpacity=".7" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_ledger-dark__paint2_linear"
          x1="15.371"
          y1="13.371"
          x2="22.791"
          y2="20.791"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset=".5" stopOpacity=".7" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_ledger-dark__paint3_linear"
          x1="5.372"
          y1="17"
          x2="9.589"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_ledger-dark__paint4_linear"
          x1="9.084"
          y1="9.584"
          x2="26.345"
          y2="26.845"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_ledger-dark__paint5_linear"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
