import { css } from 'styled-components';

export const TypographyTokens = css`
  :root {
    --font-sans: ${({ theme }) => theme.fontFamily.sans};
    --font-mono: ${({ theme }) => theme.fontFamily.mono};

    --text-100: ${({ theme }) => theme.fontSizes[100]}; // 12
    --text-200: ${({ theme }) => theme.fontSizes[200]}; // 14
    --text-300: ${({ theme }) => theme.fontSizes[300]}; // 16
    --text-350: ${({ theme }) => theme.fontSizes[350]}; // 18
    --text-400: ${({ theme }) => theme.fontSizes[400]}; // 20
    --text-500: ${({ theme }) => theme.fontSizes[500]}; // 24
    --text-550: ${({ theme }) => theme.fontSizes[550]}; // 28
    --text-600: ${({ theme }) => theme.fontSizes[600]}; // 32
    --text-700: ${({ theme }) => theme.fontSizes[700]}; // 36
    --text-750: ${({ theme }) => theme.fontSizes[750]}; // 40
    --text-800: ${({ theme }) => theme.fontSizes[800]}; // 44
    --text-900: ${({ theme }) => theme.fontSizes[900]}; // 56
    --text-1000: ${({ theme }) => theme.fontSizes[1000]}; // 72

    --lh-b-100: ${({ theme }) => theme.lineHeights['b-100']};
    --lh-b-200: ${({ theme }) => theme.lineHeights['b-200']};
    --lh-b-300: ${({ theme }) => theme.lineHeights['b-300']};
    --lh-b-350: ${({ theme }) => theme.lineHeights['b-350']};
    --lh-b-400: ${({ theme }) => theme.lineHeights['b-400']};
    --lh-b-500: ${({ theme }) => theme.lineHeights['b-500']};
    --lh-b-600: ${({ theme }) => theme.lineHeights['b-600']};

    --lh-h-100: ${({ theme }) => theme.lineHeights['h-100']};
    --lh-h-200: ${({ theme }) => theme.lineHeights['h-200']};
    --lh-h-300: ${({ theme }) => theme.lineHeights['h-300']};
    --lh-h-350: ${({ theme }) => theme.lineHeights['h-350']};
    --lh-h-400: ${({ theme }) => theme.lineHeights['h-400']};
    --lh-h-500: ${({ theme }) => theme.lineHeights['h-500']};
    --lh-h-550: ${({ theme }) => theme.lineHeights['h-550']};
    --lh-h-600: ${({ theme }) => theme.lineHeights['h-600']};
    --lh-h-700: ${({ theme }) => theme.lineHeights['h-700']};
    --lh-h-750: ${({ theme }) => theme.lineHeights['h-750']};
    --lh-h-800: ${({ theme }) => theme.lineHeights['h-800']};
    --lh-h-900: ${({ theme }) => theme.lineHeights['h-900']};
    --lh-h-1000: ${({ theme }) => theme.lineHeights['h-1000']};

    --font-regular: ${({ theme }) => theme.fontWeights.regular};
    --font-book: ${({ theme }) => theme.fontWeights.book};
    --font-medium: ${({ theme }) => theme.fontWeights.medium};
    --font-semibold: ${({ theme }) => theme.fontWeights.semibold};
    --font-bold: ${({ theme }) => theme.fontWeights.bold};

    --letter-spacing-1: ${({ theme }) => theme.letterSpacings['-1']};
    --letter-spacing-2: ${({ theme }) => theme.letterSpacings['-2']};
    --letter-spacing-3: ${({ theme }) => theme.letterSpacings['-3']};
  }
`;
