import React from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import styled, { css, keyframes } from 'styled-components';
import { WarpSpeed } from './_warp-speed';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Icon } from '~/elements/Icon';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Box } from '~/elements/Box';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import Speed from '~/assets/svg/debt-financing/speed.svg';
import { Typing } from '~/elements/Typing';
import Tetris from '~/assets/svg/debt-financing/tetris.svg';
import { sm, md } from '~/ds/mixins/breakpoints';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 24px !important;
`;

const CardStyles = css`
  background: rgba(var(--rgb-white), 1);
  border-radius: var(--space-3);
  box-shadow: 0 0 0 1px rgba(var(--rgb-seafoam-700), 0.1);
`;

const Card = styled.div`
  ${CardStyles}
  padding: var(--space-6);

  @media ${sm} {
    height: 100%;
  }

  @media ${md} {
    height: auto;
    padding: var(--space-8);
  }
`;

const CardLarge = styled.div`
  ${CardStyles}
  padding: var(--space-6);
  height: 100%;
  overflow: hidden;
  position: relative;

  @media ${md} {
    padding: var(--space-12);
  }
`;

const Tetris1 = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(65px, 32px, 0) rotate(25deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0) rotate(0);
  }
`;

const Tetris2 = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(48px, 72px, 0) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0) rotate(0);
  }
`;

const Tetris3 = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0px, 72px, 0) rotate(-15deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0) rotate(0);
  }
`;

const Tetris4 = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0px, 32px,0) rotate(-15deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0) rotate(0);
  }
`;

const Flexible = styled.div`
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  &:after {
    border-radius: 0%;
    content: '';
    background: rgba(var(--rgb-white), 1);
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -20%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    --glow-offset: 25%;
    top: 10%;
    right: var(--glow-offset);
    bottom: 30%;
    left: var(--glow-offset);
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%);
    filter: blur(30px);
    opacity: 0.3;
    z-index: 0;
  }

  svg {
    position: relative;
    max-width: 380px;
    z-index: 2;
    margin: 0 auto;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
  }

  .tetris-1 {
    opacity: 0;
    transform: translate3d(65px, 32px, 0) rotate(25deg);
    transform-origin: left;
  }

  .tetris-2 {
    opacity: 0;
    transform: translate3d(48px, 72px, 0) rotate(45deg);
    transform-origin: center;
  }

  .tetris-3 {
    opacity: 0;
    transform: translate3d(0px, 72px, 0) rotate(-15deg);
    transform-origin: right;
  }

  .tetris-4 {
    opacity: 0;
    transform: translate3d(0px, 32px, 0) rotate(-15deg);
    transform-origin: right;
  }

  .inview--animate & {
    .tetris-1 {
      animation: 0.5s var(--animation-curve) ${Tetris1} 0.5s forwards;
    }
    .tetris-2 {
      animation: 0.5s var(--animation-curve) ${Tetris2} 0.65s forwards;
    }
    .tetris-3 {
      animation: 0.5s var(--animation-curve) ${Tetris3} 0.6s forwards;
    }
    .tetris-4 {
      animation: 0.5s var(--animation-curve) ${Tetris4} 0.55s forwards;
    }
  }
`;

const SpeedIcon = styled(Speed)`
  display: inline-block;
  width: auto;
  color: var(--color-blue-500);
`;

export const SectionOverview: React.FC = () => {
  const [animateRef, animateInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const animateClasses: ConditionalClasses = {
    'inview--animate': animateInView,
  };

  return (
    <Section pt={{ _: 0, md: 0, lg: 0 }} overflow="hidden">
      <Container>
        <Grid gridTemplateColumns={12}>
          <Column gridColumn={{ _: 12, md: 6 }}>
            <Column>
              <Heading as="h2" weight="semibold" size="700">
                Utilize bank credit to free up your equity ca
                <Typing text="pital" />
              </Heading>
            </Column>
          </Column>

          <Column gridColumn={12} mt={12}>
            <Grid gridTemplateColumns={{ _: 1, md: 3 }} gap={{ md: 6 }} gapY={6}>
              <Column gridColumn={1}>
                <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1 }} gap={6}>
                  <Column gridColumn={1}>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Experience
                      </Heading>
                      <Text size="200" mt={1}>
                        The Column team has worked at the world's largest banks and investment firms, underwriting and
                        funding billions of dollars of loans to fintech lenders.
                      </Text>
                    </Card>
                  </Column>
                  <Column gridColumn={1}>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Technology
                      </Heading>
                      <Text size="200" mt={1}>
                        No more trading excel spreadsheets over email. We'll work with you to build out integrated
                        reporting, allowing you to save time and money managing your finances.
                      </Text>
                    </Card>
                  </Column>
                </Grid>
              </Column>

              <Column gridColumn={2}>
                <CardLarge ref={animateRef} className={classNames(animateClasses)}>
                  <Flex flexDirection="column" height="100%">
                    <Flexible>
                      <Tetris />
                    </Flexible>

                    <Column>
                      <Box maxWidth="500px" margin="0 auto" position="relative" zIndex="2">
                        <Heading as="h3" weight="semibold" size="500" textAlign="center" mt={4}>
                          Flexible
                        </Heading>
                        <Text size="300" mt={1} textAlign="center">
                          As your business grows, your needs may change. Financing with Column means your loan can
                          evolve with your business.
                        </Text>
                      </Box>
                    </Column>
                  </Flex>
                </CardLarge>
              </Column>

              <Column gridColumn={2}>
                <CardLarge ref={animateRef} className={classNames(animateClasses)}>
                  <Flex flexDirection="column" justifyContent="center" height="100%">
                    <WarpSpeed />
                    <Column>
                      <Box maxWidth="480px" margin="0 auto" position="relative" zIndex="2" textAlign="center">
                        <SpeedIcon src="/images/products/debt-financing/speed.svg" />
                        <Heading as="h3" weight="semibold" size="500" textAlign="center">
                          Speed to market
                        </Heading>
                        <Text size="300" mt={1} textAlign="center">
                          We will not waste your time with a lengthy and opaque process. We understand the requirements
                          and upfront needs necessary to close quickly.
                        </Text>
                      </Box>
                    </Column>
                  </Flex>
                </CardLarge>
              </Column>

              <Column gridColumn={1}>
                <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1 }} gap={6}>
                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Fund same-day
                      </Heading>
                      <Text size="200" mt={1}>
                        Reduce working capital needs with integrations that can enable you to fund as frequently as same
                        day.
                      </Text>
                    </Card>
                  </Column>

                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Transparent
                      </Heading>
                      <Text size="200" mt={1}>
                        With straightforward fees, simplified structures, and shorter credit agreements to avoid
                        confusion, debt from Column is a help, not a hindrance.
                      </Text>
                    </Card>
                  </Column>
                </Grid>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionOverview;
