import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductWire: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductWire {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a10" gradientUnits="userSpaceOnUse" x1="10.77" x2="1.34" y1="11.77" y2="2.34">
        <stop offset="0" stopColor="#1e4199" />
        <stop offset="1" stopColor="#1e4199" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="b10" x1="26.77" x2="17.34" href="#a10" y1="25.77" y2="16.34" />
      <linearGradient id="c10" x1="41.03" x2="4" href="#a10" y1="-9.03" y2="28" />
      <circle cx="8" cy="9" fill="url(#a10)" r="4" />
      <circle cx="24" cy="23" fill="url(#b10)" r="4" />
      <path
        d="m26 13v6.54c-.59-.34-1.27-.54-2-.54s-1.41.2-2 .54v-6.54c0-1.1-.9-2-2-2s-2 .9-2 2v6c0 3.31-2.69 6-6 6s-6-2.69-6-6v-6.54c.59.34 1.27.54 2 .54s1.41-.2 2-.54v6.54c0 1.1.9 2 2 2s2-.9 2-2v-6c0-3.31 2.69-6 6-6s6 2.69 6 6z"
        fill="url(#c10)"
      />
    </IconBase>
  );
};
