import React from 'react';

export const Query = [
  {
    param: 'limit',
    type: 'int32',
    required: false,
    description: (
      <p>
        A limit of the number of objects to be returned, between <code>1</code> and <code>100</code>. The default is{' '}
        <code>10</code>.
      </p>
    ),
  },
  {
    param: 'starting_after',
    type: 'string',
    required: false,
    description: (
      <p>
        A cursor for use in pagination. <code>starting_after</code> is an ID that defines your place in the list. For
        instance, if you make a list request and receive 20 objects, ending with <code>foo_ZXhhbXBsZQo</code>, your
        subsequent call can include <code>starting_after=foo_ZXhhbXBsZQo</code> in order to fetch the next page of the
        list.
      </p>
    ),
  },
  {
    param: 'ending_before',
    type: 'string',
    required: false,
    description: (
      <p>
        A cursor for use in pagination. <code>ending_before</code> is an ID that defines your place in the list. For
        instance, if you make a list request and receive 20 objects, starting with <code>foo_ZXhhbXBsZQo=</code>, your
        subsequent call can include <code>ending_before=foo_ZXhhbXBsZQo=</code> in order to fetch the previous page of
        the list.
      </p>
    ),
  },
];
