import React from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { TextGroup } from '~/elements/TextGroup';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Code } from '~/elements/Code/Code';
import { Button } from '~/elements/Button';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Icon } from '~/elements/Icon';
import { Typing } from '~/elements/Typing';
import VirtualCard from '~/assets/svg/index/virtual-card.svg';

export const SectionDemos: React.FC = () => {
  const [productDemo1Ref, productDemo1InView] = useInView({
    threshold: 0,
    rootMargin: '-40% 0% 0% 0%',
  });
  const [productDemo2Ref, productDemo2InView] = useInView({
    threshold: 0,
    rootMargin: '-30% 0% -30% 0%',
  });
  const [productDemo3Ref, productDemo3InView] = useInView({
    threshold: 0,
    rootMargin: '0% 0% -40% 0%',
  });

  const productDemoClasses: ConditionalClasses = {
    'product-demo-start': !productDemo1InView && !productDemo2InView && !productDemo3InView,
    'product-demo-1': productDemo1InView && !productDemo2InView && !productDemo3InView,
    'product-demo-2': productDemo2InView && !productDemo3InView,
    'product-demo-3': productDemo3InView,
  };

  return (
    <Section border palette="seafoam" className="relative py-24">
      <div className="relative">
        <Container>
          <div className="grid grid-cols-12 md:gap-8">
            <div className="col-span-12 md:col-span-6">
              <Heading as="h4" size="300" eyebrow mb={6}>
                In action
              </Heading>
              <Heading as="h2" size="700" weight="semibold" color="gray-900">
                The power of a nationally chartered bank{' '}
                <Heading as="span" size="700" color="gray-500">
                  in the lightest format po
                  <Typing text="ssible" />
                </Heading>
              </Heading>

              <div className="pt-16 pb-8 md:pb-16">
                <div className="grid grid-cols-6" ref={productDemo1Ref}>
                  <div className="col-span-6 lg:col-span-5">
                    <div className="relative flex items-center justify-center pb-8 demo-graphic-mobile md:hidden">
                      <div className="block overflow-hidden rounded shadow-product-border">
                        <img
                          src="/images/index/data-dashboard.png"
                          className="w-full"
                          alt="Screenshot of the Column Dashboard"
                        />
                      </div>
                    </div>
                    <div>
                      <Heading as="h3" size="500" weight="semibold">
                        Move and hold money
                      </Heading>
                      <Text size="300" mt={1}>
                        Leverage Column's direct connection to the Federal Reserve. Pull and push money to any bank
                        account and create flexible FDIC insured accounts.
                      </Text>
                    </div>
                  </div>

                  <div className="col-span-6">
                    <ButtonGroup mt={6} flexDirection={{ _: 'column', md: 'row' }} flexWrap="wrap">
                      <Button size="small" variant="secondary" to="/ach">
                        <Icon.ProductACH />
                        ACH
                      </Button>
                      <Button size="small" variant="secondary" to="/wire">
                        <Icon.ProductWire />
                        Wire
                      </Button>
                      <Button size="small" variant="secondary" to="/bank-accounts">
                        <Icon.ProductBankAccounts />
                        Bank Accounts
                      </Button>
                      <Button size="small" variant="secondary" to="/book-transfers">
                        <Icon.ProductBookTransfers />
                        Book Transfers
                      </Button>
                    </ButtonGroup>

                    <Code mt={8} mr={{ md: 'n8', lg: 0 }} title="Create an ACH transfer" copy={false}>
                      {`curl 'https://api.column.com/transfers/ach' \\
  -u :prod_1vWeqakwT2N7NYRtZkUPSBt4scn \\
  -d counterparty_id=cpty_1vWeqakwT2N7NYRtZkUPSBt4scn \\
  -d bank_account_id=bacc_1vWeqakwT2N7NYRtZkUPSBt4scn \\
  -d amount=241 \\
  -d type=DEBIT \\
  -d currency_code=USD \\
  -d description="Recurring bill"`}
                    </Code>
                  </div>
                </div>
              </div>

              <div className="pt-16 pb-8 md:pb-16">
                <div className="grid grid-cols-6" ref={productDemo2Ref}>
                  <div className="col-span-6 lg:col-span-5">
                    <div className="relative flex items-center justify-center pb-8 demo-graphic-mobile md:hidden">
                      <div className="virtual-card">
                        <VirtualCard />
                      </div>
                      <div className="overflow-hidden rounded physical-card">
                        <img src="/images/index/physical-card.png" width="561" alt="Image of a credit card" />
                      </div>
                    </div>
                    <div>
                      <Heading as="h3" size="500" weight="semibold">
                        Build a modern card program
                      </Heading>
                      <Text size="300" mt={1}>
                        Manage your own card program and optimize interchange revenue. Launch a debit or credit program
                        with any issuer processor.
                      </Text>
                    </div>
                  </div>

                  <div className="col-span-6">
                    <ButtonGroup mt={6} flexDirection={{ _: 'column', md: 'row' }} flexWrap="wrap">
                      <Button size="small" variant="secondary" to="/card-programs">
                        <Icon.ProductCardPrograms />
                        Card Programs
                      </Button>
                    </ButtonGroup>

                    <Code mt={6} title="Create a new bank account" copy={false}>
                      {`curl 'https://api.column.com/bank-accounts' \\
    -u :prod_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d entity_id=ent_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d description="NeoBankCo Visa Debit Settlement Account"`}
                    </Code>
                  </div>
                </div>
              </div>

              <div className="pt-16 pb-8 md:pb-32">
                <div className="grid grid-cols-6" ref={productDemo3Ref}>
                  <div className="col-span-6 lg:col-span-5">
                    <div className="relative flex items-center justify-center pb-8 demo-graphic-mobile md:hidden">
                      <div className="lending-graph-wrap">
                        <div className="lending-graph-cylinder">
                          <div />
                          <Text>Q2</Text>
                        </div>
                        <div className="lending-graph-cylinder">
                          <div />
                          <Text>Q3</Text>
                        </div>
                        <div className="lending-graph-cylinder">
                          <div />
                          <Text>Q4</Text>
                        </div>
                      </div>
                    </div>
                    <TextGroup size="500">
                      <div>
                        <Heading as="h3" size="500" weight="semibold">
                          Lend money
                        </Heading>
                        <Text size="300" mt={1}>
                          Grow originations faster with bank funding from day one. Become an originating partner and
                          expand your program nationally.
                        </Text>
                      </div>
                    </TextGroup>
                  </div>

                  <div className="col-span-6">
                    <ButtonGroup mt={6} flexDirection={{ _: 'column', md: 'row' }} flexWrap="wrap">
                      <Button size="small" variant="secondary" to="/loan-origination">
                        <Icon.ProductLoanOrigination />
                        Loan Origination
                      </Button>
                      <Button size="small" variant="secondary" to="/debt-financing">
                        <Icon.ProductDebtFinancing />
                        Debt Financing
                      </Button>
                      <Button size="small" variant="secondary" to="/loan-purchase">
                        <Icon.ProductLoanPurchase />
                        Loan Purchase
                      </Button>
                    </ButtonGroup>

                    <Code copy={false} title="Create new book transfer" mt={6}>
                      {`curl 'https://api.column.com/transfers/book' \\
  -u :prod_1vWeqakwT2N7NYRtZkUPSBt4scn \\
  -d counterparty_id=cpty_1vWeqakwT2N7NYRtZkUPSBt4scn \\
  -d bank_account_id=bacc_1vWeqakwT2N7NYRtZkUPSBt4scn \\
  -d amount=2450000 \\
  -d description="Warehouse drawdown"`}
                    </Code>
                  </div>
                </div>
              </div>
            </div>
            <div className="demo-sticky-track bg-gray-100">
              <div className="demo-sticky-frame">
                <div className={classNames(productDemoClasses)}>
                  <div className="product-demo">
                    <div className="product-demo-1-visual">
                      <div className="overflow-hidden rounded shadow-product-border" style={{ width: 800 }}>
                        <img
                          src="/images/index/data-dashboard.png"
                          className="w-full"
                          alt="Screenshot of the Column Dashboard"
                        />
                      </div>
                    </div>

                    <div className="product-demo-2-visual">
                      <div className="virtual-card">
                        <VirtualCard />
                      </div>
                      <div className="overflow-hidden rounded physical-card">
                        <img src="/images/index/physical-card.png" width="561" alt="Image of a credit card" />
                      </div>
                    </div>

                    <div className="product-demo-3-visual">
                      <div className="lending-graph-wrap">
                        <div className="lending-graph-cylinder">
                          <div />
                          <Text>Q2</Text>
                        </div>
                        <div className="lending-graph-cylinder">
                          <div />
                          <Text>Q3</Text>
                        </div>
                        <div className="lending-graph-cylinder">
                          <div />
                          <Text>Q4</Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="demo-sticky-bg-track">
                <div className="demo-sticky-bgs pattern-accent-light" />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Section>
  );
};

export default SectionDemos;
