import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconCode: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="1" width="15" height="13">
        <path
          d="M1.999 13a.999.999 0 01-.707-1.707L5.085 7.5 1.292 3.707a.999.999 0 111.414-1.414l4.5 4.5a.999.999 0 010 1.414l-4.5 4.5a.997.997 0 01-.707.293zm13 0a1 1 0 00-1-1h-6a1 1 0 100 2h6a1 1 0 001-1z"
          fill="url(#Property_1=Code,_Color=Solid__paint0_linear)"
          stroke="none"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path fill="url(#Property_1=Code,_Color=Solid__paint1_linear)" d="M15.999 0h-16v16h16V0z" />
      </g>
      <defs>
        <linearGradient
          id="Property_1=Code,_Color=Solid__paint0_linear"
          x1="1.075"
          y1="-3.145"
          x2="9.773"
          y2="16.506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="Property_1=Code,_Color=Solid__paint1_linear"
          x1="32"
          x2="0"
          y1="0"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #12161E)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
