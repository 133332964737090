import { css } from 'styled-components';

export const Green = css`
  .color-palette--green {
    --bg-color-primary: var(--color-green-700);
    --bg-color-secondary: var(--color-green-800);

    --bg-gradient-primary: linear-gradient(180deg, var(--color-green-600) 0%, var(--color-yellow-400) 100%);
  }

  .color-palette-inverted--green {
    --bg-color-primary: var(--color-green-700);
    --bg-color-secondary: var(--color-green-800);

    --bg-gradient-primary: linear-gradient(180deg, var(--color-green-600) 0%, var(--color-yellow-400) 100%);
  }
`;
