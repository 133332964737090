import React from 'react';
import { BLOCKS, MARKS, INLINES, Block, Inline, Text } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { AssetBlock } from './AssetBlock';
import { AuthorBlock } from './AuthorBlock';
import { BlogPostBlock } from './BlogPostBlock';
import { BlogPostInline } from './BlogPostInline';
import { Quote } from './Quote';
import { FootnoteLink } from './FootnoteLink';

interface Footnote {
  referenceNumber: number;
  text: any;
}

type RenderMark = NonNullable<Options['renderMark']>;
type RenderNode = NonNullable<Options['renderNode']>;

export const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text: React.ReactNode) => <em>{text}</em>,
    [MARKS.CODE]: (text: React.ReactNode) => <code>{text}</code>,
    [MARKS.UNDERLINE]: (text: React.ReactNode) => <u>{text}</u>,
    [MARKS.SUBSCRIPT]: (text: React.ReactNode) => <sub>{text}</sub>,
    [MARKS.SUPERSCRIPT]: (text: any) => <sup>{text}</sup>,
  } as RenderMark,
  renderNode: {
    [BLOCKS.QUOTE]: (node: Block | Inline, children: React.ReactNode) => <Quote>{children}</Quote>,
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      return <AssetBlock data={node.data.target} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
      const data = node.data.target;

      if (data.__typename === 'ContentfulBlogPost') {
        return <BlogPostBlock data={data} />;
      }

      if (data.__typename === 'ContentfulAuthor') {
        return <AuthorBlock data={data} />;
      }

      return null;
    },
    [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) => {
      const data = node.data.target;

      if (data.__typename === 'ContentfulBlogPost') {
        return <BlogPostInline data={data} />;
      }

      return null;
    },
    [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => {
      const { uri } = node.data;
      if (uri.startsWith('#footnote-')) {
        const footnoteNumber = uri.split('-')[1];
        return (
          <FootnoteLink footnoteNumber={footnoteNumber} footnoteContent={null}>
            {children}
          </FootnoteLink>
        );
      }
      return <a href={uri}>{children}</a>;
    },
  } as RenderNode,
};

export const createOptionsWithFootnotes = (footnotes: Footnote[]): Options => ({
  ...options,
  renderNode: {
    ...options.renderNode,
    [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => {
      const { uri } = node.data;
      if (uri.startsWith('#footnote-')) {
        const footnoteNumber = uri.split('-')[1];
        const footnote = footnotes.find((f) => f.referenceNumber === parseInt(footnoteNumber, 10));
        if (!footnote) {
            return null;
        }
        return (
          <FootnoteLink footnoteNumber={footnoteNumber} footnoteContent={footnote?.text}>
            {children}
          </FootnoteLink>
        );
      }
      return <a href={uri}>{children}</a>;
    },
  } as RenderNode,
});
