import { css } from 'styled-components';

export const Infrastructure = css`
  .color-palette--infrastructure {
    --bg-gradient-primary: linear-gradient(165deg, var(--color-cyan-400) 0%, var(--color-seafoam-600) 92%);
    --bg-gradient-secondary: linear-gradient(
      215deg,
      rgba(var(--rgb-cyan-400), 0.4),
      rgba(var(--rgb-seafoam-300), 0.1),
      rgba(var(--rgb-seafoam-300), 0)
    );
  }
`;
