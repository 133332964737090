import React from 'react';
import styled from 'styled-components';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Br } from '~/elements/Br';
import { Heading } from '~/elements/Heading';
import { Container } from '~/elements/Container';
import { Text } from '~/elements/NewText';
import { sm, md } from '~/ds/mixins/breakpoints';

const FednowHero = styled.section`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -82px;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: linear-gradient(180deg, #edf2f7 0%, rgba(237, 242, 247, 0) 100%); */
  }
`;

const StyledHeading = styled(Heading)`
  font-size: 48px;
  line-height: 0.9;
  letter-spacing: -0.04em;
  color: var(--color-cyan-800);

  span {
    color: var(--color-cyan-500);
    color: rgba(var(--rgb-cyan-800), 0.5);
  }

  @media ${sm} {
    font-size: 80px;
  }

  @media ${md} {
    font-size: 120px;
  }
`;

const StyledEyebrow = styled(Heading)`
  background-color: var(--color-orange-500);
  color: var(--color-white);
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.05);

  .typing {
    &::after {
      display: none;
    }
  }
`;

export const SectionHero: React.FC = () => {
  return (
    <FednowHero>
      <Container>
        <Grid
          gridTemplateColumns={12}
          alignItems="center"
          minHeight="406px"
          position="relative"
          zIndex="1"
          justifyContent={{ _: 'center', lg: 'start' }}
        >
          <Column gridColumn="12" position="relative" zIndex="2" pt={{ _: 8, sm: 16}}>
            <Flex gap={2} flexDirection="column" alignItems="start" justifyContent="left" py="48px">
              <StyledHeading size="1000" weight="semibold" mt={8} textAlign="left">
                FedNow <Br />
                <span>EarlyAccess</span>
              </StyledHeading>
              <Text
                size="400"
                maxWidth={{ _: '280px',  sm: '360px', md: '640px' }}
                color="rgba(var(--rgb-cyan-800), 0.75)"
                mt={{ _: 2, sm: 8}}
                textAlign="left"
              >
                The Federal Reserve’s new real-time payment rail. Instant, transparent, and available 24/7/365.
              </Text>
            </Flex>
          </Column>
        </Grid>
      </Container>
    </FednowHero>
  );
};
