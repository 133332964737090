import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Container } from '~/elements/Container';
import { Box } from '~/elements/Box';
import { Br } from '~/elements/Br';
import { List } from '~/elements/List';
import { Icon } from '~/elements/Icon';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Typing } from '~/elements/Typing';

const RoleGroupTrigger = styled.summary`
  cursor: pointer;
  background-image: none;
  -webkit-appearance: none;
  outline: none;

  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: '';
  }

  h4 {
    display: flex;
    gap: var(--space-2);
    align-items: center;
    padding-right: 32px;
  }
`;

const RoleGroup = styled.details`
  scroll-margin-top: 80px;

  .icon--chevron-down {
    transition: 0.1s transform;
  }

  &[open] {
    .icon--chevron-down {
      transform: rotate(180deg);
    }
    ${RoleGroupTrigger} {
      ${Box} {
        display: none;
      }
    }
  }
`;

const RoleTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-4) 0;
  border-top: 1px solid var(--color-purple-800);
`;

export const SectionFaq: React.FC = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      document.getElementById(window.location.hash.replace('#', ''))?.setAttribute('open', 'true');
    }
  }, []);

  return (
    <Section>
      <Container>
        <Grid gridTemplateColumns={12}>
          <Column gridColumn={{ _: 12, md: 6 }}>
            <Heading as="h2" size="900" weight="semibold" color="purple-800">
              Frequently asked ques
              <Typing text="tions" />
            </Heading>
          </Column>

          <Column gridColumn={{ _: 12, md: 6 }} mt={{ _: 8, md: 4 }}>
            <List>
              <RoleGroup id="faq-1">
                <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#faq-1')}>
                  <RoleTitle>
                    <Heading as="h3" size="400" weight="medium" color="purple-800">
                      What are Protected Deposits?
                    </Heading>
                    <Icon.ChevronDown />
                  </RoleTitle>
                </RoleGroupTrigger>
                <Text pb={8} pr={8} color="rgba(var(--rgb-purple-800), 0.7)">
                  Column provides its customers with the ability to increase their FDIC insurance through a trusted
                  network of FDIC insured financial institutions.
                </Text>
              </RoleGroup>

              <RoleGroup id="faq-2">
                <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#faq-2')}>
                  <RoleTitle>
                    <Heading as="h3" size="400" weight="medium" color="purple-800">
                      How does it work?
                    </Heading>
                    <Icon.ChevronDown />
                  </RoleTitle>
                </RoleGroupTrigger>
                <Text pb={8} pr={8} color="rgba(var(--rgb-purple-800), 0.7)">
                  The standard maximum FDIC (Federal Deposit Insurance Corporation) insurance coverage amount for
                  deposits is $250,000, per depositor, per insured financial institution, for each ownership category.
                  <Br />
                  <Br />
                  By working directly with Column, Column can provide you with access to more than $250,000 in FDIC
                  insurance coverage by placing deposits at a large network of FDIC-insured financial institutions. For
                  depositors with large sums of money, this is an avenue to maximize your FDIC insurance coverage.
                  Deposits are placed in amounts at or below $250,000, expanding your FDIC insurance coverage across
                  financial institutions. Through this service, Column can provide you with up to $150 million in FDIC
                  insurance coverage.
                </Text>
              </RoleGroup>

              <RoleGroup id="faq-3">
                <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#faq-3')}>
                  <RoleTitle>
                    <Heading as="h3" size="400" weight="medium" color="purple-800">
                      What happens to your deposits?
                    </Heading>
                    <Icon.ChevronDown />
                  </RoleTitle>
                </RoleGroupTrigger>
                <Text pb={8} pr={8} color="rgba(var(--rgb-purple-800), 0.7)">
                  When you submit funds for placement through Column, that deposit is divided into amounts under the
                  standard FDIC insurance maximum of $250,000 and placed in deposit accounts at other network banks—each
                  an FDIC-insured institution. This makes your deposit eligible for FDIC insurance at each institution,
                  provided you don’t already hold deposits at that institution (more on that below).
                </Text>
              </RoleGroup>

              <RoleGroup id="faq-5">
                <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#faq-5')}>
                  <RoleTitle>
                    <Heading as="h3" size="400" weight="medium" color="purple-800">
                      Who has custody of my funds?
                    </Heading>
                    <Icon.ChevronDown />
                  </RoleTitle>
                </RoleGroupTrigger>
                <Text pb={8} pr={8} color="rgba(var(--rgb-purple-800), 0.7)">
                  Funds placed through this banking network are deposited only in FDIC-insured institutions. Column N.A.
                  acts as custodian for your deposits placed, and the subcustodian for the deposits is the Bank of New
                  York Mellon (BNY Mellon), the largest custodian in the world with $43 trillion in assets under custody
                  and/or administration and $1.92 trillion in assets under management (as of June 30, 2022).
                  <Br />
                  <Br />
                  Even though Column has custody, this is still your money and you retain access and visibility into
                  your money.
                </Text>
              </RoleGroup>

              <RoleGroup id="faq-6">
                <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#faq-6')}>
                  <RoleTitle>
                    <Heading as="h3" size="400" weight="medium" color="purple-800">
                      Who provides the additional FDIC insurance when my funds are placed using the banking network?
                    </Heading>
                    <Icon.ChevronDown />
                  </RoleTitle>
                </RoleGroupTrigger>
                <Text pb={8} pr={8} color="rgba(var(--rgb-purple-800), 0.7)">
                  Funds are placed in deposit accounts in eligible financial institutions that are network members. Each
                  member provides $250,000 of FDIC insurance, provided you don't already hold deposits at that
                  institution.
                </Text>
              </RoleGroup>

              <RoleGroup id="faq-7">
                <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#faq-7')}>
                  <RoleTitle>
                    <Heading as="h3" size="400" weight="medium" color="purple-800">
                      What to avoid?
                    </Heading>
                    <Icon.ChevronDown />
                  </RoleTitle>
                </RoleGroupTrigger>
                <Text pb={8} pr={8} color="rgba(var(--rgb-purple-800), 0.7)">
                  When setting up your access, make sure that you identify any financial institutions where you already
                  hold deposits so additional deposits are not placed at those institutions. Each institution can only
                  provide $250,000 of FDIC insurance per depositor per ownership category. By providing this information
                  to us, we will make sure we do not place deposits at institutions where you already have coverage.
                  This will maximize your ability to access FDIC insurance coverage.
                </Text>
              </RoleGroup>
            </List>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};
