import React, { CSSProperties } from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames';

export interface TextStyleProps {
  as?:
    | 'p'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'span'
    | 'em'
    | 'strong'
    | 'pre'
    | 'li'
    | 'a'
    | 'address'
    | 'blockquote'
    | 'figcaption'
    | 'div';
  variant?: 'heading' | 'subheading' | 'body' | 'mono';
  weight?: 'inherit' | 'regular' | 'book' | 'medium' | 'semibold';
  size?: 'inherit' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | '1000';
  align?: 'inherit' | 'left' | 'right' | 'center';
  eyebrow?: boolean;
  title?: boolean;
  maxWidth?: string;
}

export interface TextProps extends TextStyleProps {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  to?: string;
  href?: string;
  className?: string;
  style?: CSSProperties;
}

export const Text: React.FC<TextProps> = (props) => {
  const styleProps: TextStyleProps = {
    as: props.as ?? 'p',
    variant: props.variant ?? 'body',
    weight: props.weight ?? 'inherit',
    size: props.size ?? 'inherit',
    align: props.align ?? 'inherit',
    eyebrow: props.eyebrow ?? false,
    title: props.title ?? false,
  };

  const staticClasses: StaticClasses = [props.className];

  let conditionalClasses: ConditionalClasses = {
    'text-mono': styleProps.variant === 'mono',

    'text-eyebrow': styleProps.eyebrow === true,
    'text-title': styleProps.title === true,

    'text-left': styleProps.align === 'left',
    'text-center': styleProps.align === 'center',
    'text-right': styleProps.align === 'right',

    'font-regular': styleProps.weight === 'regular',
    'font-book': styleProps.weight === 'book',
    'font-medium': styleProps.weight === 'medium',
    'font-semibold': styleProps.weight === 'semibold',

    'list-item': styleProps.as === 'li',
  };

  if (styleProps.variant === 'body') {
    conditionalClasses = {
      ...conditionalClasses,
      'text-body': styleProps.size === 'inherit',
      'text-b-100': styleProps.size === '100',
      'text-b-200': styleProps.size === '200',
      'text-b-300': styleProps.size === '300',
      'text-b-400': styleProps.size === '400',
      'text-b-500': styleProps.size === '500',
      'text-b-600': styleProps.size === '600',
    };
  }

  if (styleProps.variant === 'subheading' && styleProps.eyebrow === false && styleProps.title === false) {
    conditionalClasses = {
      ...conditionalClasses,
      'text-subheading': styleProps.size === 'inherit',
      'text-b-100 font-semibold': styleProps.size === '100',
      'text-b-200 font-semibold': styleProps.size === '200',
      'text-b-300 font-semibold': styleProps.size === '300',
      'text-b-400 font-semibold': styleProps.size === '400',
      'text-b-500 font-semibold': styleProps.size === '500',
      'text-b-600 font-semibold': styleProps.size === '600',
    };
  }

  if (styleProps.variant === 'heading') {
    conditionalClasses = {
      ...conditionalClasses,
      'text-heading': styleProps.size === 'inherit',
      'text-h-100': styleProps.size === '100',
      'text-h-200': styleProps.size === '200',
      'text-h-300': styleProps.size === '300',
      'text-h-400': styleProps.size === '400',
      'text-h-500': styleProps.size === '500',
      'text-h-600': styleProps.size === '600',
      'text-h-700': styleProps.size === '700',
      'text-h-800': styleProps.size === '800',
      'text-h-900': styleProps.size === '900',
      'text-h-1000': styleProps.size === '1000',
    };
  }

  const Element = styleProps.as as React.ElementType;

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(event);
    }

    if (props.to) {
      event.preventDefault();

      navigate(props.to);
    }
  };

  return (
    <Element
      className={classNames(staticClasses, conditionalClasses)}
      onClick={handleClick}
      href={props.href}
      style={props.style}
    >
      {props.children}
    </Element>
  );
};
