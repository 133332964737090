import { css } from 'styled-components';

export const Products = css`
  .color-palette--products {
    --bg-gradient-primary: linear-gradient(
      165deg,
      var(--color-seafoam-500) 0%,
      var(--color-cyan-500) 25%,
      var(--color-purple-400) 50%,
      var(--color-red-300) 75%,
      var(--color-yellow-500) 100%
    );
  }

  .color-palette--products-alt {
    --bg-gradient-primary: linear-gradient(165deg, var(--color-seafoam-500) 0%, var(--color-cyan-600) 75%);
  }
`;
