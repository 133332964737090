import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsIdempotency: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M10 16.5C8.4 16.5 6.89 15.88 5.76 14.74C4.63 13.61 4 12.1 4 10.5C4 8.9 4.62 7.39 5.76 6.26C6.89 5.13 8.4 4.5 10 4.5V7.5C9.2 7.5 8.45 7.81 7.88 8.38C7.31 8.95 7 9.7 7 10.5C7 11.51 7.5 12.45 8.39 13.03C9.35 13.66 10.64 13.66 11.6 13.03C12.49 12.45 13 11.51 13 10.49H16C16 12.09 15.38 13.6 14.24 14.73C13.11 15.86 11.6 16.49 10 16.49V16.5Z"
        fill="url(#paint0_linear_3204_768)"
      />
      <path
        d="M15.4 5.59L10.78 2.42C10.45 2.19 10 2.43 10 2.83V9.16C10 9.56 10.45 9.8 10.78 9.57L15.4 6.41C15.69 6.21 15.69 5.78 15.4 5.59Z"
        fill="url(#paint1_linear_3204_768)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_768"
          x1="17.18"
          y1="19.59"
          x2="6.39"
          y2="7.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_768"
          x1="8.58"
          y1="16.16"
          x2="12.93"
          y2="4.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
