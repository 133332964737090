import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '~/elements/Button';
import { Flex } from '~/elements/Flex';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Box } from '~/elements/Box';
import { Link } from '~/elements/Link';
import { Icon } from '~/elements/Icon';
import { Tooltip } from '~/elements/Tooltip';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

interface ProductPriceProps {
  child?: boolean;
}

interface TagProps {
  primary?: boolean;
}

const PricingCard = styled.div`
  background: var(--color-gray-50);
  padding: var(--space-5);
  border-radius: var(--radii-default);

  @media ${({ theme }) => theme.screens['bp-md']} {
    padding: var(--space-8);
  }
`;

const ProductGroupTrigger = styled.summary`
  cursor: pointer;
  background-image: none;
  -webkit-appearance: none;
  outline: none;

  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: '';
  }
`;

const ProductGroup = styled.details`
  .icon--chevron-down {
    transition: 0.1s transform;
  }

  &[open] {
    .icon--chevron-down {
      transform: rotate(180deg);
    }
    ${ProductGroupTrigger} {
      ${Box} {
        display: none;
      }
    }
  }
`;

const ProductPrice = styled.div<ProductPriceProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-3) 0;
  border-top: 1px solid var(--color-gray-200);
  min-height: 61px;

  ${({ child }) =>
    child === true &&
    css<ProductPriceProps>`
      padding: var(--space-3) 0 var(--space-3) var(--space-4);
    `};

  > ${Text} {
    flex: auto;
  }
`;

const Tag = styled.span<TagProps>`
  font-size: var(--text-100);
  font-weight: var(--font-book);
  color: var(--color-gray-500);
  border: 1px solid var(--color-gray-200);
  border-radius: var(--radii-pill);
  padding: var(--space-1) var(--space-2);
  line-height: 1;
  white-space: nowrap;

  ${({ primary }) =>
    primary === true &&
    css<TagProps>`
      border-color: var(--color-blue-200);
      color: var(--color-blue-500);
      font-weight: var(--font-medium);
    `};
`;

const PricingInfo = styled(Flex)`
  border-top: 1px solid var(--color-gray-200);
`;

export const SectionPaymentOrigination: React.FC = () => {
  return (
    <PricingCard id="payment-origination" className="color-palette--infrastructure">
      <Grid alignItems="flex-start" justifyContent="space-between" gap={{ _: 4, md: 8 }}>
        <Column gridColumn={{ _: 12, md: 8 }}>
          <Flex gap={4} flexDirection={{ _: 'column', md: 'row' }} justifyContent="flex-start">
            <Icon.ProductBookTransfers />
            <Column>
              <Heading as="h2" size="500" weight="semibold">
                Payment Origination
              </Heading>
              <Text color="gray-500" mt={2}>
                Build out complex and customized payment origination with Column.
              </Text>
            </Column>
          </Flex>
        </Column>
        <Column gridColumn={{ _: 12, md: 4 }}>
          <Flex
            flexDirection={{ _: 'column-reverse', sm: 'row-reverse', md: 'column' }}
            alignItems={{ _: 'flex-start', sm: 'center', md: 'flex-end' }}
            justifyContent={{ _: 'flex-start', sm: 'space-between', md: 'flex-end' }}
            gap={4}
          >
            <Box textAlign={{ md: 'right' }}>
              <Tag primary>Start testing today</Tag>
            </Box>

            <Box textAlign={{ md: 'right' }}>
              <Button size="large" variant="primary" type="link" href="https://dashboard.column.com/register">
                Start building
                <Icon.AnimatedArrowRight />
              </Button>
            </Box>
          </Flex>
        </Column>
      </Grid>
      <Grid mt={6}>
        <Column gridColumn={12} pl={{ _: 0, md: 11 }}>
          <ul>
            <li>
              <ProductGroup>
                <ProductGroupTrigger>
                  <ProductPrice>
                    <Flex gap={1} alignItems="center">
                      <Heading as="h4" weight="semibold">
                        ACH Transfers
                      </Heading>
                      <Icon.ChevronDown />
                    </Flex>

                    <Box textAlign="right">
                      <Heading as="p" weight="semibold" size="300">
                        $0.50
                      </Heading>
                      <Text size="100" color="gray-500">
                        per transaction
                      </Text>
                    </Box>
                  </ProductPrice>
                </ProductGroupTrigger>
                <ul>
                  <li>
                    <ProductPrice child>
                      <Text size="200" weight="medium" color="gray-800">
                        Standard ACH transaction
                      </Text>
                      <Box textAlign="right">
                        <Heading as="p" weight="semibold" size="200">
                          $0.50
                        </Heading>
                        <Text size="100" color="gray-500">
                          per transaction
                        </Text>
                      </Box>
                    </ProductPrice>
                  </li>
                  <li>
                    <ProductPrice child>
                      <Text size="200" weight="medium" color="gray-800">
                        Same-day surcharge
                      </Text>
                      <Box textAlign="right">
                        <Heading as="p" weight="semibold" size="200">
                          $0.50
                        </Heading>
                        <Text size="100" color="gray-500">
                          per transaction
                        </Text>
                      </Box>
                    </ProductPrice>
                  </li>
                  <li>
                    <ProductPrice child>
                      <Text size="200" weight="medium" color="gray-800">
                        Administrative returns
                        <Tooltip content="Administrative returns are defined as any return (outgoing or incoming) with codes R02, R03 and R04.">
                          <sup>1</sup>
                        </Tooltip>
                      </Text>
                      <Box textAlign="right">
                        <Heading as="p" weight="semibold" size="200">
                          $2.00
                        </Heading>
                        <Text size="100" color="gray-500">
                          per return
                        </Text>
                      </Box>
                    </ProductPrice>
                  </li>
                  <li>
                    <ProductPrice child>
                      <Text size="200" weight="medium" color="gray-800">
                        Unauthorized returns
                        <Tooltip content="Unauthorized returns are defined as any return (outgoing or incoming) with codes R05, R07, R10, R11, R29 and R51.">
                          <sup>2</sup>
                        </Tooltip>
                      </Text>
                      <Box textAlign="right">
                        <Heading as="p" weight="semibold" size="300">
                          $10.00
                        </Heading>
                        <Text size="100" color="gray-500">
                          per return
                          <Tooltip
                            position="left"
                            content="If your return rates exceed the limits defined by NACHA (3% administrative returns, .5% unauthorized return rate, or 15% overall return rate), Column has the right to increase the price per return."
                          >
                            <sup>3</sup>
                          </Tooltip>
                        </Text>
                      </Box>
                    </ProductPrice>
                  </li>
                </ul>
              </ProductGroup>
            </li>
            <li>
              <ProductPrice>
                <div>
                  <Heading as="h4" weight="semibold">
                    Wire Transfers
                  </Heading>
                  <Text size="100" color="gray-500" mt={1}>
                    First 10 incoming wires free
                  </Text>
                </div>
                <Box textAlign="right">
                  <Heading as="p" weight="semibold" size="300">
                    $5.00
                  </Heading>
                  <Text size="100" color="gray-500">
                    per transaction
                  </Text>
                </Box>
              </ProductPrice>
            </li>
            <li>
              <ProductPrice>
                <Heading as="h3" weight="semibold">
                  Book Transfers
                </Heading>
                <Box textAlign="right">
                  <Heading as="p" weight="semibold" size="300">
                    Free
                  </Heading>
                </Box>
              </ProductPrice>
            </li>
            <li>
              <ProductPrice>
                <Heading as="h4" weight="semibold">
                  Checks
                </Heading>
                <Box textAlign="right">
                  <Heading as="p" weight="semibold">
                    $1.00
                  </Heading>
                  <Text size="100" color="gray-500">
                    per issued check
                  </Text>
                </Box>
              </ProductPrice>
            </li>
            <li>
              <ProductPrice>
                <Heading as="h3" weight="semibold">
                  International Payments
                </Heading>
              </ProductPrice>
            </li>
            <li>
              <ProductPrice>
                <div>
                  <Heading as="h3" weight="semibold">
                    Monthly minimum
                  </Heading>
                  <Box maxWidth="64ch">
                    <Text color="gray-500" mt={1} size="200">
                      Column has a monthly minimum fee that is incurred if transaction and usage based fees amount to
                      lower than the monthly minimum threshold, which varies based on your use case.
                    </Text>
                  </Box>
                </div>
              </ProductPrice>
            </li>
          </ul>
        </Column>

        <Column gridColumn={12} pl={{ _: 0, md: 11 }}>
          <PricingInfo flexDirection={{ _: 'column', md: 'row' }} mt={6} pt={8} pb={2} gap={{ _: 8, lg: 16 }}>
            <Column>
              <Flex
                gap={4}
                flexDirection={{ _: 'column', sm: 'row', md: 'column', lg: 'row' }}
                justifyContent={{ _: 'flex-start', sm: 'space-between', md: 'flex-start', lg: 'space-between' }}
              >
                <Column>
                  <Flex gap={2}>
                    <Icon.Testing />
                    <div>
                      <Heading as="h4" weight="semibold">
                        Test payment origination in sandbox
                      </Heading>
                      <Text size="200" mt={1} color="gray-500">
                        Learn more about Payment Origination with Column's APIs
                      </Text>
                    </div>
                  </Flex>
                </Column>
                <Column pl={{ _: 6, lg: 0 }}>
                  <Button variant="secondary" size="small" to="/docs/guides/payment-origination">
                    Documentation
                  </Button>
                </Column>
              </Flex>
            </Column>

            <Column>
              <Flex
                gap={4}
                flexDirection={{ _: 'column', sm: 'row', md: 'column', lg: 'row' }}
                justifyContent={{ _: 'flex-start', sm: 'space-between', md: 'flex-start', lg: 'space-between' }}
              >
                <Column>
                  <Flex gap={2}>
                    <Icon.Chat />
                    <div>
                      <Heading as="h4" weight="semibold">
                        Ready to talk?
                      </Heading>
                      <Text size="200" mt={1} color="gray-500">
                        Contact us to learn more about partnering with Column.
                      </Text>
                    </div>
                  </Flex>
                </Column>
                <Column pl={{ _: 6, lg: 0 }}>
                  <Button variant="secondary" size="small" type="link" href="https://dashboard.column.com/contact">
                    Contact us
                    <Icon.AnimatedArrowRight />
                  </Button>
                </Column>
              </Flex>
            </Column>
          </PricingInfo>
        </Column>
      </Grid>
    </PricingCard>
  );
};

export default SectionPaymentOrigination;
