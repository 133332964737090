import { css } from 'styled-components';
import { Primary } from './primary/background';
import { Blue } from './blue/background';
import { Cyan } from './cyan/background';
import { Green } from './green/background';
import { Orange } from './orange/background';
import { Pink } from './pink/background';
import { Purple } from './purple/background';
import { Red } from './red/background';
import { Seafoam } from './seafoam/background';
import { Yellow } from './yellow/background';
import { Gray } from './gray/background';
import { Products } from './products/background';
import { Lending } from './lending/background';
import { Infrastructure } from './infrastructure/background';

export const Background = css`
  ${Primary}
  ${Blue}
  ${Cyan}
  ${Green}
  ${Orange}
  ${Pink}
  ${Purple}
  ${Red}
  ${Seafoam}
  ${Yellow}
  ${Gray}
  ${Products}
  ${Lending}
  ${Infrastructure}
`;
