import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Sidebar } from './Sidebar';
import { Header } from '~/components/Header';
import { Footer } from '~/components/Footer';
import DesignSystem from '~/ds';
import columnDS from '~/ds/ds';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Box } from '~/elements/Box';
import { Section } from '~/elements/Section';
import { Container } from '~/elements/Container';

const LegalBody = styled(Section)`
  position: relative;
  padding: 0;
  z-index: 0;
`;

export const Layout = (props: any) => {
  return (
    <ThemeProvider theme={columnDS}>
      <DesignSystem />
      <Helmet />
      <Header />
      <LegalBody className="docs-body">
        <Container>
          <Grid gridTemplateColumns={12} gap={8}>
            <Column gridColumn={{ _: 12, sm: 3 }}>
              <Sidebar />
            </Column>
            <Column gridColumn={{ _: 12, sm: 9 }}>
              <Box as="main" position="relative">
                {props.children}
              </Box>
            </Column>
          </Grid>
        </Container>
      </LegalBody>

      <Footer />
    </ThemeProvider>
  );
};
