import React from 'react';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { SectionBox } from '~/elements/SectionBox';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

export const SectionEntities: React.FC = () => {
  return (
    <SectionBox theme="light" my={18}>
      <Container>
        <Grid gridTemplateColumns={12} gap={{ _: 0, lg: 6 }}>
          <Column gridColumn={{ _: 12, lg: 6 }} position="relative" zIndex={2}>
            <Heading as="h4" size="300" weight="medium" color="blue-500" mb={4}>
              Entities
            </Heading>
            <Heading as="h2" size="700" weight="semibold" color="blue-800">
              Manage multiple, complex entities with ease
            </Heading>
          </Column>

          <Column gridColumn={{ _: 12, lg: 5 }} position="relative" zIndex={2}>
            <Text color="gray-600" size="300" mt={{ _: 2, lg: 8 }}>
              Seamlessly manage banking needs for hundreds of properties, sub-accounts, and payments all in one
              interface purpose built for complex entity management. Quickly invite all of your employees with
              customized permissions.
            </Text>
          </Column>

          <Column gridColumn={12} mb="-23%">
            <ProductScreen mt={6} backgroundColor="#fbfcfd">
              <img src="/images/specialty-deposits/screen-entities.png" />
            </ProductScreen>
          </Column>
        </Grid>
      </Container>
    </SectionBox>
  );
};
