import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsWire: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M5.5 9.5C6.88071 9.5 8 8.38071 8 7C8 5.61929 6.88071 4.5 5.5 4.5C4.11929 4.5 3 5.61929 3 7C3 8.38071 4.11929 9.5 5.5 9.5Z"
        fill="url(#paint0_linear_3204_793)"
      />
      <path
        d="M14.5 16.5C15.8807 16.5 17 15.3807 17 14C17 12.6193 15.8807 11.5 14.5 11.5C13.1193 11.5 12 12.6193 12 14C12 15.3807 13.1193 16.5 14.5 16.5Z"
        fill="url(#paint1_linear_3204_793)"
      />
      <path
        d="M16 8.95V12C15.59 11.69 15.06 11.5 14.5 11.5C13.94 11.5 13.41 11.69 13 12V8.95C13 8.86 13 8.59 12.64 8.53C12.3 8.47 12.2 8.73 12.17 8.81L10.66 13.17C10.17 14.59 8.9 15.48 7.46 15.48C7.26 15.48 7.06 15.46 6.86 15.43C5.18 15.14 4 13.75 4 12.05V9C4.41 9.31 4.94 9.5 5.5 9.5C6.06 9.5 6.59 9.31 7 9V12.05C7 12.14 7 12.41 7.36 12.47C7.71 12.53 7.8 12.27 7.83 12.19L9.34 7.83C9.9 6.22 11.47 5.29 13.14 5.57C14.82 5.86 16 7.25 16 8.95Z"
        fill="url(#paint2_linear_3204_793)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_793"
          x1="0.99"
          y1="16.39"
          x2="6.07"
          y2="5.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_793"
          x1="9.99"
          y1="23.39"
          x2="15.07"
          y2="12.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_793"
          x1="4.14"
          y1="4.1"
          x2="15.5"
          y2="16.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
