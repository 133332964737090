import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconStatus: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <g clipPath="url(#status_Status__clip0)">
        <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="-1" y="1" width="17" height="14">
          <path
            d="M11.52 8.75h4.479V13a2 2 0 01-2 2h-12a2 2 0 01-2-2V8.75H5a.75.75 0 00.702-.486L6.5 6.136l2.298 6.128a.75.75 0 001.404 0L11.52 8.75zM13.999 1h-12a2 2 0 00-2 2v4.25H4.48l1.317-3.514a.752.752 0 011.405 0L9.5 9.864l.798-2.128A.75.75 0 0111 7.25h4.999V3a2 2 0 00-2-2z"
            fill="url(#status_Status__paint0_linear)"
            stroke="none"
          />
        </mask>
        <g mask={`url(#${id})`}>
          <path fill="url(#status_Status__paint1_linear)" d="M15.999 0h-16v16h16V0z" />
        </g>
      </g>
      <defs>
        <linearGradient
          id="status_Status__paint0_linear"
          x1="15.221"
          y1="16.242"
          x2="4.107"
          y2="3.557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="status_Status__paint1_linear" x1="32" x2="0" y1="0" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-gradient-stop-1, #12161E)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #12161E)" />
        </linearGradient>
        <clipPath id="status_Status__clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </IconBase>
  );
};
