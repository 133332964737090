import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductInternationalWires: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
        <path
          d="M18.09 18.09c-2.88 2.87-5.58 4.47-7.41 5.16A8.961 8.961 0 0 1 7 16c0-2.98 1.45-5.62 3.68-7.25 1.83.68 4.53 2.28 7.41 5.16.71.71 1.34 1.41 1.9 2.09-.56.68-1.19 1.38-1.9 2.09Zm2.82 2.82c-1.55 1.56-3.27 2.97-4.99 4.09H16c2.98 0 5.62-1.45 7.25-3.68-.21-.56-.52-1.22-.92-1.93-.46.52-.93 1.03-1.42 1.52ZM16 7h-.08c1.72 1.12 3.44 2.53 4.99 4.09.49.49.96 1 1.42 1.52.4-.71.71-1.37.92-1.93A8.961 8.961 0 0 0 16 7Z"
          fill="url(#32_international-payments-dark__a)"
        />
        <path
          d="M25 15.92v.16c-.02-.03-.04-.05-.06-.08.02-.03.04-.05.06-.08Z"
          fill="url(#32_international-payments-dark__b)"
        />
        <path
          d="M26.41 5.59C24.16 3.33 20.09 4.39 16.08 7c1.96.02 3.78.66 5.24 1.75.77-.31 1.39-.44 1.8-.44.21 0 .37.04.47.1.2.28.12 1.09-.34 2.27-.69 1.83-2.29 4.53-5.16 7.41-2.88 2.87-5.58 4.47-7.41 5.16-1.17.46-1.99.54-2.26.34-.2-.27-.13-1.1.32-2.28A8.941 8.941 0 0 1 7 16.08c-2.61 4.01-3.67 8.08-1.41 10.33.85.86 1.96 1.24 3.23 1.24 2.09 0 4.61-1.02 7.1-2.65 1.72-1.12 3.44-2.53 4.99-4.09 1.56-1.55 2.97-3.27 4.09-4.99 2.61-4.01 3.67-8.08 1.41-10.33Z"
          fill="url(#32_international-payments-dark__c)"
        />
        <path
          d="M25 16.08c-.02-.03-.04-.05-.05-.08-.76 1.15-1.64 2.3-2.62 3.39.4.71.7 1.36.92 1.93.46 1.17.54 1.99.34 2.26-.27.2-1.1.13-2.28-.32A8.941 8.941 0 0 1 16.08 25c2.49 1.63 5.01 2.65 7.1 2.65 1.27 0 2.38-.38 3.23-1.24 2.26-2.25 1.2-6.32-1.41-10.33ZM10.68 8.75c1.83.68 4.53 2.28 7.41 5.16.71.71 1.34 1.41 1.9 2.09 1.01-1.22 1.78-2.37 2.34-3.39-.45-.52-.93-1.03-1.42-1.52-1.55-1.56-3.27-2.97-4.99-4.09-4.01-2.61-8.08-3.67-10.33-1.41C3.33 7.84 4.39 11.91 7 15.92c.02-1.96.66-3.78 1.75-5.24-.46-1.18-.54-1.99-.34-2.27.28-.19 1.09-.12 2.27.34Z"
          fill="url(#32_international-payments-dark__d)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0Z" fill="url(#32_international-payments-dark__f)" />
      </g>
      <defs>
        <linearGradient
          id="32_international-payments-dark__a"
          x1="15.125"
          y1="10.392"
          x2="15.125"
          y2="41.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".009" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_international-payments-dark__b"
          x1="24.97"
          y1="15.939"
          x2="24.97"
          y2="16.208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset=".991" />
        </linearGradient>
        <linearGradient
          id="32_international-payments-dark__c"
          x1="12.016"
          y1="-6.604"
          x2="17.935"
          y2="26.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".009" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_international-payments-dark__d"
          x1="16.983"
          y1="2.678"
          x2="14.567"
          y2="35.417"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".009" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_international-payments-dark__f"
          x1="32"
          y1="0"
          x2="0"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
