import React from 'react';

export const License = [
  {
    param: 'number',
    type: 'string',
    required: true,
    description: <p>Driver's license number</p>,
  },
  {
    param: 'country_code',
    type: 'string',
    required: true,
    description: <p>Country code where the driver's license was issued. ISO 3166-1 Alpha-2 Country Code (e.g., <code>US, FR, UK, DE, ...</code>). US Drivers License will be rejected as we require US citizens to submit SSN.</p>,
  },
];
