import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductWarehouseFinancing: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductWarehouseFinancing {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
        <path d="M18.4 4.5L4 9.3l9.6 3.196 14.4-4.8L18.4 4.5z" fill="url(#warehouse-facility__paint0_linear)" />
        <path d="M13.6 12.5v15L4 24.3v-15l9.6 3.2z" fill="url(#warehouse-facility__paint1_linear)" />
        <path d="M28 7.7v15l-14.4 4.8v-15L28 7.7z" fill="url(#warehouse-facility__paint2_linear)" />
        <path
          d="M19.802 8.504c0 .707-1.705 1.276-3.789 1.276s-3.789-.568-3.789-1.276c0-.707 1.705-1.288 3.789-1.288s3.789.58 3.789 1.288z"
          fill="url(#warehouse-facility__paint3_linear)"
        />
        <path d="M13.6 15.5v3L4 15.3v-3l9.6 3.2z" fill="url(#warehouse-facility__paint4_linear)" />
        <path d="M13.6 21.5v3L4 21.3v-3l9.6 3.2z" fill="url(#warehouse-facility__paint5_linear)" />
        <path d="M28 10.7v3l-14.4 4.8v-3L28 10.7z" fill="url(#warehouse-facility__paint6_linear)" />
        <path d="M28 16.7v3l-14.4 4.8v-3L28 16.7z" fill="url(#warehouse-facility__paint7_linear)" />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#warehouse-facility__paint8_linear)" />
      </g>
      <defs>
        <linearGradient
          id="warehouse-facility__paint0_linear"
          x1="3.593"
          y1="3.653"
          x2="24.956"
          y2="11.995"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="warehouse-facility__paint1_linear"
          x1="-19.943"
          y1="18.4"
          x2="13.576"
          y2="18.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="warehouse-facility__paint2_linear"
          x1="68.93"
          y1="17.6"
          x2="2.487"
          y2="17.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="warehouse-facility__paint3_linear"
          x1="8.88"
          y1="1.752"
          x2="20.195"
          y2="12.463"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="warehouse-facility__paint4_linear"
          x1="-52.641"
          y1="15.4"
          x2="15.034"
          y2="15.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="warehouse-facility__paint5_linear"
          x1="-52.641"
          y1="21.4"
          x2="15.034"
          y2="21.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="warehouse-facility__paint6_linear"
          x1="44.769"
          y1="14.6"
          x2="-16.787"
          y2="14.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="warehouse-facility__paint7_linear"
          x1="44.769"
          y1="20.6"
          x2="-16.787"
          y2="20.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="warehouse-facility__paint8_linear"
          x1="32"
          y1="0"
          x2="0"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #12161E)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
