import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './press/_hero';
import { Layout } from '~/components/Layout';

const CompanyPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Press — Column</title>
        <meta
          name="description"
          content="We'd love to answer questions or try to work with you on whatever you have in mind."
        />
      </Helmet>
      <SectionHero />
    </Layout>
  );
};

export default CompanyPage;
