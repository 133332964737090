import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsChangelog: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M15 4.5H5C4.45 4.5 4 4.95 4 5.5V8.5C4 9.05 4.45 9.5 5 9.5H15C15.55 9.5 16 9.05 16 8.5V5.5C16 4.95 15.55 4.5 15 4.5ZM6 7.5C5.45 7.5 5 7.05 5 6.5C5 5.95 5.45 5.5 6 5.5C6.55 5.5 7 5.95 7 6.5C7 7.05 6.55 7.5 6 7.5Z"
        fill="url(#paint0_linear_3204_777)"
      />
      <path
        d="M15 11.5H5C4.45 11.5 4 11.95 4 12.5V15.5C4 16.05 4.45 16.5 5 16.5H15C15.55 16.5 16 16.05 16 15.5V12.5C16 11.95 15.55 11.5 15 11.5ZM6 14.5C5.45 14.5 5 14.05 5 13.5C5 12.95 5.45 12.5 6 12.5C6.55 12.5 7 12.95 7 13.5C7 14.05 6.55 14.5 6 14.5Z"
        fill="url(#paint1_linear_3204_777)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_777"
          x1="14.33"
          y1="14.03"
          x2="8.05"
          y2="3.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_777"
          x1="14.33"
          y1="21.03"
          x2="8.05"
          y2="10.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
