import { css } from 'styled-components';

export const BlogTypography = css`
  .blog-content {
    & h1 {
      color: var(--color-gray-900);
    }
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: var(--blog-800);
    }
  }

  .blog-content {
    color: var(--color-gray-900);
  }

  .blog-content ol > li::before {
    color: var(--blog-500);
    color: var(--color-gray-900);
  }

  .blog-content .footnotes ol > li::before {
    color: var(--color-gray-600);
  }

  .blog-content ul > li::before {
    background-color: var(--blog-500);
    background-color: var(--color-gray-400);
  }

  .blog-content hr {
    border-color: var(--color-gray-100);
  }

  .blog-content blockquote {
    color: var(--blog-700);
  }

  .blog-content code {
    background-color: var(--blog-50);
    color: var(--blog-800);
  }

  .blog-content a code {
    color: var(--blog-800);
  }

  .blog-content pre {
    background-color: var(--blog-900);
    color: var(--blog-200);
  }

  .blog-content thead {
    color: var(--color-gray-900);
    border-color: var(--color-gray-900);
  }

  .blog-content tbody tr {
    border-color: var(--color-gray-100);
  }

  .blog-content {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: var(--font-book);
  }

  .blog-content p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .blog-content img {
    width: 100%;
    border-radius: var(--radii-sm);
  }
  .blog-content .gatsby-image-wrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    background-color: var(--color-gray-50);
    display: block;
  }

  /* 40/44 */
  .blog-content h1 {
    font-weight: var(--font-medium);
    font-size: 2.5rem;
    line-height: 2.75rem;
    margin-top: 3.5rem;
    margin-bottom: 1.25rem;
    letter-spacing: -0.015em;
  }

  /* 32/36 */
  .blog-content h2 {
    font-weight: var(--font-medium);
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-top: 3.5rem;
    margin-bottom: 1.25rem;
  }

  /* 24/32 */
  .blog-content h3 {
    font-weight: var(--font-book);
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  /* 20/28 */
  .blog-content h4 {
    font-weight: var(--font-book);
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: 3rem;
    margin-bottom: 0.75rem;
  }

  /* 16/24 */
  .blog-content h5 {
    font-weight: var(--font-semibold);
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 0.75rem;
  }

  /* 16/20 */
  .blog-content h6 {
    font-weight: var(--font-book);
    font-size: 0.85rem;
    line-height: 1.25rem;
    letter-spacing: 0.025em;
    margin-top: 3rem;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    color: var(--color-gray-500);
  }

  .blog-content a {
    text-decoration: underline;
  }

  .blog-content p a {
    font-weight: var(--font-medium);
    text-decoration: none;
    color: var(--blog-700);
    background-image: linear-gradient(var(--blog-600), var(--blog-600));
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;

    &:hover {
      color: var(--blog-900);
      background-image: linear-gradient(var(--blog-900), var(--blog-900));
    }
  }

  .blog-content strong {
    font-weight: 600;
  }

  .blog-content ol > li {
    position: relative;
  }

  .blog-content ol > li > * {
    display: inline;
  }

  .blog-content ol li ol {
    display: block;
    padding-left: 2rem;
  }

  .blog-content ul > li {
    position: relative;
  }

  .blog-content ul li ul {
    padding-left: 2rem;
  }

  .blog-content ol > li {
    padding-left: 2em;

    &::before {
      margin-left: -2em;
      content: counter(list-item, var(--list-counter-style, decimal)) '.';
      position: absolute;
    }
  }

  .blog-content ul > li {
    padding-left: 1.5em;

    &::before {
      margin-left: -1.25em;
      margin-top: 0.8em;
      content: '';
      position: absolute;
      border-radius: 50%;
      width: 0.375em;
      height: 0.375em;
      top: -0.1875em;
    }
  }

  .blog-content hr {
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .blog-content blockquote {
    justify-content: center;
    text-align: center;
    padding-right: var(--space-12);
    padding-left: var(--space-12);
    font-size: 1.5rem;
    line-height: 2rem;
    quotes: '\\201C''\\201D''\\2018''\\2019';
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .blog-content blockquote p:first-of-type::before {
    content: open-quote;
  }

  .blog-content blockquote p:last-of-type::after {
    content: close-quote;
  }

  .blog-content figure figcaption {
    color: var(--color-gray-500);
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 1rem;
  }

  .blog-content code {
    padding-right: var(--space-1);
    padding-left: var(--space-1);
    border-radius: var(--radii-sm);
    position: relative;
    font-size: 0.875rem;
    line-height: 1rem;
    top: -1px;
  }

  .blog-content a code {
    text-decoration: none;
  }

  .blog-content pre {
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    padding-right: var(--space-8);
    padding-left: var(--space-8);
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 2px rgba(255, 255, 255, 0.1),
      inset 0 0 8px 4px rgba(255, 255, 255, 0.1), inset 0 0 16px 8px rgba(255, 255, 255, 0.1),
      0 1px 2px rgba(0, 0, 0, 0.4), 0 2px 4px rgba(0, 0, 0, 0.3), 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  .blog-content pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  .blog-content pre code::before {
    content: none;
  }

  .blog-content pre code::after {
    content: none;
  }

  .blog-content table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .blog-content thead {
    border-bottom-width: 1px;
  }

  .blog-content thead th {
    font-weight: var(--font-semibold);
    vertical-align: bottom;
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
  }

  .blog-content tbody tr {
    border-bottom-width: 1px;
  }

  .blog-content tbody tr:last-child {
    border-bottom-width: 0;
  }

  .blog-content tbody td {
    vertical-align: top;
    padding-top: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
  }

  .blog-content video {
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    width: 100%;
    display: block;
  }

  .blog-content figure {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .blog-content figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  .blog-content h2 code {
    font-size: 0.875rem;
  }

  .blog-content h3 code {
    font-size: 0.9rem;
  }

  .blog-content ol {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .blog-content ul {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .blog-content li {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .blog-content ul p,
  .blog-content ol p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .blog-content ul ul,
  .blog-content ul ol,
  .blog-content ol ul,
  .blog-content ol ol {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .blog-content hr + * {
    margin-top: 0;
  }

  .blog-content h1 + * {
    margin-top: 0;
  }

  .blog-content h2 + * {
    margin-top: 0;
  }

  .blog-content h3 + * {
    margin-top: 0;
  }

  .blog-content h4 + * {
    margin-top: 0;
  }

  .blog-content h5 + * {
    margin-top: 0;
  }

  .blog-content h6 + * {
    margin-top: 0;
  }

  .blog-content thead th:first-child {
    padding-left: 0;
  }

  .blog-content thead th:last-child {
    padding-right: 0;
  }

  .blog-content tbody td:first-child {
    padding-left: 0;
  }

  .blog-content tbody td:last-child {
    padding-right: 0;
  }

  .blog-content > :first-child {
    margin-top: 0;
  }

  .blog-content > :last-child {
    margin-bottom: 0;
  }

  .blog-content em {
    font-style: italic;
  }

  .blog-content .footnotes {
    color: var(--color-gray-600);
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 1rem;
  }

  .blog-content .footnote p {
    color: var(--color-gray-600);
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0.25rem 0 0.25rem 0;
  }

  .blog-content [class~='lead'] {
    color: red;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
`;
