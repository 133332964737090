import { IconCheck } from './Check';
import { IconCircleCheck } from './CircleCheck';
import { IconDiamond } from './Diamond';
import { IconCode } from './Code';
import { IconCopy } from './Copy';
import { IconArrow } from './Arrow';
import { IconTesting } from './Testing';
import { IconChat } from './Chat';
import { IconX } from './X';
import { IconHamburger } from './Hamburger';

export const IconMisc = {
  Check: IconCheck,
  CircleCheck: IconCircleCheck,
  Diamond: IconDiamond,
  Code: IconCode,
  Copy: IconCopy,
  Arrow: IconArrow,
  Testing: IconTesting,
  Chat: IconChat,
  X: IconX,
  Hamburger: IconHamburger,
};
