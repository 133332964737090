export interface PaletteProps {
  palette?:
    | 'inherit'
    | 'primary'
    | 'purple'
    | 'cyan'
    | 'seafoam'
    | 'green'
    | 'yellow'
    | 'orange'
    | 'red'
    | 'pink'
    | 'gray'
    | 'infrastructure'
    | 'lending'
    | 'products';
}

export const getPaletteClassnames = (palette: string | undefined, classes?: ConditionalClasses, inverted?: boolean) => {
  if (inverted) {
    return {
      ...classes,
      'color-palette-inverted--primary': palette === 'primary',
      'color-palette-inverted--purple': palette === 'purple',
      'color-palette-inverted--cyan': palette === 'cyan',
      'color-palette-inverted--seafoam': palette === 'seafoam',
      'color-palette-inverted--green': palette === 'green',
      'color-palette-inverted--yellow': palette === 'yellow',
      'color-palette-inverted--orange': palette === 'orange',
      'color-palette-inverted--red': palette === 'red',
      'color-palette-inverted--pink': palette === 'pink',
      'color-palette-inverted--gray': palette === 'gray',
      'color-palette-inverted--infrastructure': palette === 'infrastructure',
      'color-palette-inverted--lending': palette === 'lending',
      'color-palette-inverted--products': palette === 'products',
    };
  }
  return {
    ...classes,
    'color-palette--primary': palette === 'primary',
    'color-palette--purple': palette === 'purple',
    'color-palette--cyan': palette === 'cyan',
    'color-palette--seafoam': palette === 'seafoam',
    'color-palette--green': palette === 'green',
    'color-palette--yellow': palette === 'yellow',
    'color-palette--orange': palette === 'orange',
    'color-palette--red': palette === 'red',
    'color-palette--pink': palette === 'pink',
    'color-palette--gray': palette === 'gray',
    'color-palette--infrastructure': palette === 'infrastructure',
    'color-palette--lending': palette === 'lending',
    'color-palette--products': palette === 'products',
  };
};
