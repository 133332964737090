import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './protected-deposits/_hero';
import { SectionCompare } from './protected-deposits/_compare';
import { SectionSafety } from './protected-deposits/_safety';
import { SectionGraph } from './protected-deposits/_graph';
import { SectionFaq } from './protected-deposits/_faq';
import { Layout } from '~/components/Layout';

const BankDepositsPage: React.FC = () => {
  return (
    <Layout bg="var(--color-gray-50)">
      <Helmet>
        <title>Protected Deposits — Column</title>
        <meta property="og:title" content="Column - Bank Deposits" />
        <meta
          name="description"
          content="Nine digits of protection from banks like us, and other top FDIC insured institutions. Eliminate your exposure to a single bank and offer your customers unparalleled peace of mind."
        />
        <link rel="canonical" href="https://column.com/protected-deposits" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <SectionHero />
      <SectionCompare />
      <SectionSafety />
      <SectionGraph />
      <SectionFaq />
    </Layout>
  );
};

export default BankDepositsPage;
