import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { compose, color, space, layout, LayoutProps, ColorProps, SpaceProps } from 'styled-system';
import { md, xl } from '~/ds/mixins/breakpoints';

export interface SectionBoxProps extends Omit<ColorProps, 'color'>, SpaceProps, LayoutProps {
  theme?: 'dark' | 'light';
  pattern?: boolean;
  patternShade?: 'light' | 'dark';
  patternFlip?: boolean;
  className?: string;
  noOverflow?: boolean;
  style?: CSSProperties;
}

const Wrapper = styled.section<SectionBoxProps>`
  max-width: var(--screen-xl);
  margin-right: auto;
  margin-left: auto;
  position: relative;
  /* padding: var(--space-18) auto; */
  padding-top: var(--space-18);
  padding-bottom: var(--space-18);
  overflow: hidden;
  z-index: 6;

  ${({ noOverflow }) =>
    noOverflow === true &&
    css<SectionBoxProps>`
      overflow: visible;
    `};

  ${({ theme }) =>
    theme === 'dark' &&
    css<SectionBoxProps>`
      background-color: var(--color-blue-800);
      box-shadow: var(--shadow-product);
    `};

  ${({ theme }) =>
    theme === 'light' &&
    css<SectionBoxProps>`
      background-color: rgba(var(--rgb-white), 0.6);
      box-shadow: 0 0 0 1px rgba(var(--rgb-black), 0.04);
      backdrop-filter: blur(4px);
    `};

  @media ${xl} {
    border-radius: var(--radii-lg);
  }

  ${({ pattern }) =>
    pattern === true &&
    css<SectionBoxProps>`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(/images/guilloche.svg);
        background-repeat: repeat;
        background-position: top right;
        background-size: 20px, 20px;
        z-index: 0;
        opacity: 0.075;
        background-clip: padding-box;
        mask-image: linear-gradient(160deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

        @media ${xl} {
          border-radius: var(--radii-lg);
        }
      }

      ${({ patternShade }) =>
        patternShade === 'dark' &&
        css<SectionBoxProps>`
          &::after {
            filter: invert(1);
            opacity: 0.1;
          }
        `}

      ${({ patternFlip }) =>
        patternFlip === true &&
        css<SectionBoxProps>`
          &::after {
            mask-image: linear-gradient(-160deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
          }
        `}
    `}

  ${compose(space, color, layout)};
`;

export const SectionBox: React.FC<SectionBoxProps> = (props) => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
};

SectionBox.defaultProps = {
  pattern: false,
  patternFlip: false,
  patternShade: 'dark',
};
