import { IconDocumentation } from './Documentation';
import { IconGuides } from './Guides';
import { IconStatus } from './Status';
import { IconChangelog } from './Changelog';

export const IconDevelopers = {
  Documentation: IconDocumentation,
  Guides: IconGuides,
  Status: IconStatus,
  Changelog: IconChangelog,
};
