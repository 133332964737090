import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '~/components/Layout';
import { Container } from '~/elements/Container';
import { MarkdownStyles } from '~/elements/Markdown';
import { MarkdownPrivacyNotice } from '~/components/Markdown/PrivacyNotice';

const PrivacyNotice: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Privacy Notice — Column</title>
      <meta property="og:title" content="Column - Privacy Notice" />
      <meta name="description" content="Learn about what Column, N.A. does with your personal information" />
    </Helmet>

    <Container>
      <MarkdownStyles>
        <MarkdownPrivacyNotice />
      </MarkdownStyles>
    </Container>
  </Layout>
);

export default PrivacyNotice;
