import { ReactNode } from 'react';
import { Infrastructure } from './infrastructure';
import { Lending } from './lending';

export interface ProductType {
  icon: ReactNode;
  iconInverted: ReactNode;
  name: string;
  description: string;
  href: string;
}

export default {
  infrastructure: Infrastructure,
  lending: Lending,
};
