import React, { CSSProperties, ElementType } from 'react';
import { navigate, Link as InternalLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { compose, color, margin, fontSize, ColorProps, MarginProps, FontSizeProps } from 'styled-system';
import styled, { css } from 'styled-components';

export interface ButtonStyleProps extends ColorProps, MarginProps, FontSizeProps {
  variant: 'primary' | 'secondary' | 'link';
  size?: 'inherit' | 'default' | 'small' | 'large';
  iconLeft?: boolean;
  inverted: boolean;
}

export interface ButtonLinkProps {
  href?: string;
}

export interface ButtonElementProps {
  type?: 'submit' | 'button' | 'link';
  disabled?: boolean;
  file?: boolean;
  tabIndex?: number;
}

const ButtonText = styled.span`
  --btn-font-size: var(--text-200);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: var(--space-1);
  font-size: var(--btn-font-size);

  .btn-icon--left & {
    flex-direction: row-reverse;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ButtonGradient = css`
  &::after {
    content: '';
    background-image: var(--btn-gradient, linear-gradient(180deg, var(--color-white), rgba(var(--rgb-0), 0) 100%));
    display: block;
    border-radius: var(--space-2);
    opacity: 0.1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.5s;
  }

  &:hover::after {
    opacity: 0.15;
  }

  &:active::after {
    opacity: 0.05;
  }
`;

const ButtonStyles = css<ButtonStyleProps>`
  /* Default button palette */
  --btn-font-size: var(--font-size-200);

  /* Primary */
  --btn-text-color-primary: var(--color-0);

  --btn-bg-primary: var(--color-blue-800);
  --btn-bg-primary-hover: var(--color-blue-800);
  --btn-bg-primary-active: var(--color-blue-800);

  --btn-border-primary: var(--color-blue-800);
  --btn-border-primary-hover: var(--color-blue-800);
  --btn-border-primary-active: var(--color-blue-800);

  --btn-focus-ring-primary: var(--color-blue-100);

  /* Secondary */
  --btn-text-color-secondary: var(--color-gray-800);

  --btn-bg-secondary: rgba(var(--rgb-0), 0.5);
  --btn-bg-secondary-hover: var(--color-0);
  --btn-bg-secondary-active: var(--color-0);

  --btn-border-secondary: rgba(var(--rgb-gray-600), 0.12);
  --btn-border-secondary-hover: rgba(var(--rgb-gray-600), 0.2);
  --btn-border-secondary-active: rgba(var(--rgb-gray-600), 0.2);

  --btn-focus-ring-secondary: var(--color-gray-50);

  /* Button Link - To be removed */
  --btn-text-color-link: var(--color-gray-900);
  --btn-icon-fill: var(--color-gray-500);

  /* Link */
  --link-color: var(--color-blue-600);
  --link-color-hover: var(--color-blue-700);
  --link-icon-fill: var(--color-blue-600);

  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  font-weight: var(--font-medium);
  --icon-size: 16px;
  margin: 1px;
  height: 36px;

  &::disabled {
    opacity: 0.5;
  }

  ${({ variant }) =>
    variant === 'primary' &&
    css<ButtonStyleProps>`
      ${ButtonGradient}
      padding: 0 16px;
      border-radius: 8px;
      color: var(--btn-text-color-primary);
      background-color: var(--btn-bg-primary);
      box-shadow: 0 0 0 1px var(--btn-border-primary), 0 2px 4px rgba(var(--rgb-1000), 0.25),
        0 1px 2px rgba(var(--rgb-1000), 0.25);
      transition: box-shadow 0.2s, background-color 0.2s, border-color 0.2s;
      position: relative;
      min-height: 36px;

      &:hover {
        background-color: var(--btn-bg-primary-hover);
        box-shadow: 0 0 0 1px var(--btn-border-primary-hover), 0 2px 4px rgba(var(--rgb-1000), 0.25);
      }

      &:focus {
        background-color: var(--btn-bg-primary-hover);
        box-shadow: 0 0 0 1px var(--btn-border-primary-hover), 0 1px 3px rgba(var(--rgb-1000), 0.15),
          0 0 0 4px var(--btn-focus-ring-primary);
      }

      &:active {
        background-color: var(--btn-bg-primary-active);
        box-shadow: 0 0 0 1px var(--btn-border-primary-active), 0 1px 3px rgba(var(--rgb-1000), 0.15),
          0 0 0 0 var(--btn-focus-ring-primary);
      }
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css<ButtonStyleProps>`
      ${({ inverted }) =>
        inverted === false &&
        css<ButtonStyleProps>`
          ${ButtonGradient}
        `};
      padding: 0 16px;
      border-radius: 8px;
      color: var(--btn-text-color-secondary);
      background-color: var(--btn-bg-secondary);
      backdrop-filter: blur(2px);
      box-shadow: 0 1px 1px rgba(var(--rgb-white), 0), 0 0 0 1px var(--btn-border-secondary);
      transition: box-shadow 0.2s, background-color 0.2s, border-color 0.2s;

      &::after {
        opacity: 1;
      }

      &:hover {
        background-color: var(--btn-bg-secondary-hover);
        box-shadow: 0 1px 1px rgba(var(--rgb-1000), 0.15), 0 0 0 1px var(--btn-border-secondary-hover);
      }

      &:focus {
        background-color: var(--btn-bg-secondary-hover);
        box-shadow: 0 1px 1px rgba(var(--rgb-1000), 0.2), 0 0 0 1px var(--btn-border-secondary-hover),
          0 0 0 4px var(--btn-focus-ring-secondary);
      }

      &:active {
        background-color: var(--btn-bg-secondary-active);
        box-shadow: 0 0 0 rgba(var(--rgb-1000), 0.2), 0 0 0 1px var(--btn-border-secondary-active);
      }
    `}

    ${({ variant }) =>
    variant === 'link' &&
    css<ButtonStyleProps>`
      background: transparent;
      transition: 0.2s color;
      ${compose(color)};

      &::after {
        display: none;
      }

      ${({ inverted }) =>
        inverted === true &&
        css<ButtonStyleProps>`
          color: var(--color-white);
        `};

      ${ButtonText} {
        ${compose(fontSize)};
      }
    `};

  ${({ size }) =>
    size === 'small' &&
    css<ButtonStyleProps>`
      ${ButtonGradient}
      padding: 0 12px;
      height: 32px;

      &:after {
        background-image: var(--btn-gradient, linear-gradient(180deg, var(--color-white), #fdfefe 100%));
      }

      .icon--product {
        width: 16px;
        height: 16px;
      }
    `}

  ${({ inverted }) =>
    inverted === true &&
    css<ButtonStyleProps>`
      /* Primary */
      --btn-text-color-primary: var(--color-blue-800);

      --btn-bg-primary: rgba(var(--rgb-white), 0.9);
      --btn-bg-primary-hover: rgba(var(--rgb-white), 0.85);
      --btn-bg-primary-active: rgba(var(--rgb-white), 0.9);

      --btn-border-primary: var(--color-blue-800);
      --btn-border-primary-hover: var(--color-blue-800);
      --btn-border-primary-active: var(--color-blue-700);

      --btn-focus-ring-primary: var(--color-blue-500);

      /* Secondary */
      --btn-text-color-secondary: var(--color-white);

      --btn-bg-secondary: rgba(var(--rgb-white), 0.05);
      --btn-bg-secondary-hover: rgba(var(--rgb-white), 0.07);
      --btn-bg-secondary-active: rgba(var(--rgb-white), 0.1);

      --btn-border-secondary: rgba(var(--rgb-white), 0.2);
      --btn-border-secondary-hover: rgba(var(--rgb-white), 0.25);
      --btn-border-secondary-active: rgba(var(--rgb-white), 0.3);

      --btn-focus-ring-secondary: var(--color-blue-500);

      /* Link */
      --btn-text-color-link: var(--color-0);
    `};

  &:hover {
    .icon-animated-arrow-right--chevron,
    .icon-animated-arrow-right--stem {
      transform: translateX(0);
    }
    .icon-animated-arrow-right--stem {
      stroke-dashoffset: 0;
    }
  }

  &:active {
    .icon-animated-arrow-right--chevron,
    .icon-animated-arrow-right--stem {
      transform: translateX(2px);
    }
  }

  .icon {
    height: 16px;
    --icon-base-color: currentColor !important;
  }

  ${compose(margin)};
`;

const StyledButton = styled.button`
  ${ButtonStyles}
`;

const StyledOutboundLink = styled(OutboundLink)`
  ${ButtonStyles}
`;

const StyledInternalLink = styled(InternalLink)`
  ${ButtonStyles}
`;

export interface ButtonProps extends Partial<ButtonStyleProps>, ButtonLinkProps, Omit<ButtonElementProps, 'type'> {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  type?: 'submit' | 'button' | 'link';
  to?: string;
  className?: string;
  style?: CSSProperties;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const external = props?.href?.includes('https://');
  const Element =
    props.type === 'link' ? ((external ? StyledOutboundLink : StyledInternalLink) as ElementType) : StyledButton;

  const buttonLinkProps: ButtonLinkProps = {
    href: props.href,
  };

  const buttonElementProps: ButtonElementProps = {
    type: props.type !== 'link' && props.type ? props.type : 'submit',
    disabled: props.disabled,
  };

  const additionalProps = props.type === 'link' ? buttonLinkProps : buttonElementProps;

  if (props.file === true) {
    buttonElementProps.tabIndex = -1;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(event);
    }

    if (props.to) {
      event.preventDefault();

      navigate(props.to);
    }
  };

  return (
    <Element
      onClick={handleClick}
      className={'btn' && props.className}
      style={props.style}
      {...props}
      {...additionalProps}
    >
      <ButtonText>{props.children}</ButtonText>
    </Element>
  );
};
