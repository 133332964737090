import React from 'react';
import { Footnote } from './Footnote';

interface FootnoteContainerProps {
  footnotes: {
    id: string;
    referenceNumber: number;
    text: any;
  }[];
}

export const FootnoteContainer: React.FC<FootnoteContainerProps> = ({ footnotes }) => (
  <div className="footnotes-container">
    {footnotes.map(({ id, ...footnote }) => (
      <Footnote key={id} {...footnote} />
    ))}
  </div>
);
