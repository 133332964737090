import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconDiamond: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon icon--diamond', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path
          d="M8 14.672a2.978 2.978 0 01-2.122-.879l-3.671-3.672a3.002 3.002 0 010-4.242l3.672-3.672c1.131-1.133 3.109-1.133 4.242 0l3.672 3.672a3.007 3.007 0 01.001 4.242l-3.673 3.672A2.982 2.982 0 018 14.672zM8 3.328a.986.986 0 00-.706.293L3.621 7.293a1 1 0 000 1.414l3.672 3.672a1.022 1.022 0 001.414 0l3.671-3.672a1 1 0 000-1.414L8.707 3.621A.997.997 0 008 3.328z"
          fill="url(#16_Bullet__paint0_linear)"
          stroke="none"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path fill="url(#16_Bullet__paint1_linear)" d="M15.999 0h-16v16h16V0z" />
      </g>
      <defs>
        <linearGradient
          id="16_Bullet__paint0_linear"
          x1="10.67"
          y1="19.644"
          x2="6.643"
          y2="2.087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="16_Bullet__paint1_linear" x1="32" x2="0" y1="0" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-gradient-stop-1, #12161E)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
