import { css } from 'styled-components';

export const LayoutTokens = css`
  :root {
    /* Grid template columns */
    --cols-1: ${({ theme }) => theme.gridTemplateColumns[1]};
    --cols-2: ${({ theme }) => theme.gridTemplateColumns[2]};
    --cols-3: ${({ theme }) => theme.gridTemplateColumns[3]};
    --cols-4: ${({ theme }) => theme.gridTemplateColumns[4]};
    --cols-5: ${({ theme }) => theme.gridTemplateColumns[5]};
    --cols-6: ${({ theme }) => theme.gridTemplateColumns[6]};
    --cols-7: ${({ theme }) => theme.gridTemplateColumns[7]};
    --cols-8: ${({ theme }) => theme.gridTemplateColumns[8]};
    --cols-9: ${({ theme }) => theme.gridTemplateColumns[9]};
    --cols-10: ${({ theme }) => theme.gridTemplateColumns[10]};
    --cols-11: ${({ theme }) => theme.gridTemplateColumns[11]};
    --cols-12: ${({ theme }) => theme.gridTemplateColumns[12]};

    /* Column spans */
    --span-1: ${({ theme }) => theme.gridColumn[1]};
    --span-2: ${({ theme }) => theme.gridColumn[2]};
    --span-3: ${({ theme }) => theme.gridColumn[3]};
    --span-4: ${({ theme }) => theme.gridColumn[4]};
    --span-5: ${({ theme }) => theme.gridColumn[5]};
    --span-6: ${({ theme }) => theme.gridColumn[6]};
    --span-7: ${({ theme }) => theme.gridColumn[7]};
    --span-8: ${({ theme }) => theme.gridColumn[8]};
    --span-9: ${({ theme }) => theme.gridColumn[9]};
    --span-10: ${({ theme }) => theme.gridColumn[10]};
    --span-11: ${({ theme }) => theme.gridColumn[11]};
    --span-12: ${({ theme }) => theme.gridColumn[12]};
  }
`;
