import styled from 'styled-components';
import {
  compose,
  system,
  grid,
  flexbox,
  space,
  layout,
  borders,
  position,
  GridProps,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  BorderProps,
  PositionProps,
} from 'styled-system';

interface GridComponentProps extends GridProps, FlexboxProps, SpaceProps, LayoutProps, BorderProps, PositionProps {
  gap?: any;
  gapX?: any;
  gapY?: any;
  gridTemplateColumns?: any;
}

export const Grid = styled.div<GridComponentProps>`
  display: grid;
  list-style: none;

  ${system({
    gap: {
      property: 'gridGap',
      scale: 'space',
    },
    gapX: {
      property: 'gridColumnGap',
      scale: 'space',
    },
    gapY: {
      property: 'gridRowGap',
      scale: 'space',
    },
    gridTemplateColumns: {
      property: 'gridTemplateColumns',
      scale: 'gridTemplateColumns',
    },
  })}
  ${compose(space, grid, layout, borders, position, flexbox)}
`;

Grid.defaultProps = {
  gridTemplateColumns: 12,
};
