import { css } from 'styled-components';
import { Homepage } from './homepage';
import { Product } from './product';
import { Company } from './company';
import { Blog } from './blog';
import { BlogThemes } from './blog/themes';
import { BlogTypography } from './blog/typography';

export const Pages = css`
    ${Homepage}
    ${Product}
    ${Company}
    ${BlogTypography}
    ${BlogThemes}
    ${Blog}
  }
`;
