import { css } from 'styled-components';

export const Orange = css`
  .color-palette--orange {
    --icon-gradient-stop-1: var(--color-red-500);
    --icon-gradient-stop-2: var(--color-orange-500);
  }

  .color-palette-inverted--orange {
    --icon-gradient-stop-1: var(--color-red-400);
    --icon-gradient-stop-2: var(--color-orange-500);
  }
`;
