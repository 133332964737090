import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductLoanOrigination: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductLoanOrigination {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a8" gradientUnits="userSpaceOnUse" x1="51.07" x2="15.25" y1="-2.7" y2="33.12">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity="0" />
        <stop offset="1" stopColor="var(--color-blue-600)" />
      </linearGradient>
      <linearGradient id="b8" x1="55.47" x2="19.91" href="#a8" y1="-18.76" y2="16.81" />
      <linearGradient id="c8" x1="43.84" x2="7.95" href="#a8" y1="-15.67" y2="20.22" />
      <linearGradient id="d8" gradientUnits="userSpaceOnUse" x1="6.09" x2="10.09" y1="16.22" y2="16.22">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity=".1" />
        <stop offset=".21" stopColor="var(--color-blue-600)" stopOpacity=".4" />
        <stop offset=".4" stopColor="var(--color-blue-600)" stopOpacity=".62" />
        <stop offset=".56" stopColor="var(--color-blue-600)" stopOpacity=".75" />
        <stop offset=".66" stopColor="var(--color-blue-600)" stopOpacity=".8" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity=".7" />
      </linearGradient>
      <linearGradient id="e8">
        <stop offset="0" stopColor="var(--color-blue-600)" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="f8" gradientUnits="userSpaceOnUse" x1="-102.08" x2="21.28" href="#e8" y1="23.81" y2="23.81" />
      <linearGradient id="g8" x2="21.28" href="#f8" y1="11.88" y2="11.88" />
      <linearGradient id="h8" gradientUnits="userSpaceOnUse" x1="11.09" x2="15.09" y1="18.21" y2="18.21">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity=".1" />
        <stop offset=".06" stopColor="var(--color-blue-600)" stopOpacity=".2" />
        <stop offset=".25" stopColor="var(--color-blue-600)" stopOpacity=".5" />
        <stop offset=".42" stopColor="var(--color-blue-600)" stopOpacity=".72" />
        <stop offset=".56" stopColor="var(--color-blue-600)" stopOpacity=".85" />
        <stop offset=".66" stopColor="var(--color-blue-600)" stopOpacity=".9" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity=".7" />
      </linearGradient>
      <linearGradient id="i8" gradientUnits="userSpaceOnUse" x1="15.59" x2="15.59" href="#e8" y1="29.94" y2="15.3" />
      <linearGradient id="j8" gradientUnits="userSpaceOnUse" x1="16.09" x2="20.09" y1="20.13" y2="20.13">
        <stop offset="0" stopColor="var(--color-blue-600)" stopOpacity=".1" />
        <stop offset=".11" stopColor="var(--color-blue-600)" stopOpacity=".3" />
        <stop offset=".28" stopColor="var(--color-blue-600)" stopOpacity=".6" />
        <stop offset=".44" stopColor="var(--color-blue-600)" stopOpacity=".82" />
        <stop offset=".57" stopColor="var(--color-blue-600)" stopOpacity=".95" />
        <stop offset=".66" stopColor="var(--color-blue-600)" />
        <stop offset="1" stopColor="var(--color-blue-600)" stopOpacity=".7" />
      </linearGradient>
      <linearGradient id="k8" x1="21.09" x2="25.09" href="#h8" y1="18.14" y2="18.14" />
      <path
        d="m29.23 24.33-10.4 4.16c-.23.09-.49.14-.74.14v-3.64l10-4 1.49 2.23c.26.4.09.94-.35 1.11z"
        fill="url(#a8)"
      />
      <path
        d="m27.09 11.84v1.13c0 .31-.19.59-.47.7l-7.99 3.19c-.23.1-.49.14-.74.14v-1.84l9.14-3.66c.04.11.06.22.06.34z"
        fill="url(#b8)"
      />
      <path
        d="m27.03 11.5-9.14 3.66-6.83-8.4 8.44-3.49c.41-.16.88-.05 1.16.3l6.21 7.63s.02.03.03.04c.06.08.1.17.13.26z"
        fill="url(#c8)"
      />
      <path d="m10.09 14.04v4.95c0 .55-.9 1-2 1s-2-.45-2-1v-6.55z" fill="url(#d8)" />
      <path
        d="m18.09 24.99v3.64c-.25 0-.51-.05-.74-.14l-15.4-6.16c-.44-.17-.61-.71-.35-1.11l1.49-2.23z"
        fill="url(#f8)"
      />
      <path
        d="m17.89 15.16v1.84c-.25 0-.51-.04-.74-.14l-12.59-5.03c-.28-.11-.47-.39-.47-.7v-.99c0-.3.18-.58.46-.69l6.51-2.69z"
        fill="url(#g8)"
      />
      <path d="m15.09 16.04v4.95c0 .55-.9 1-2 1s-2-.45-2-1v-6.55l1.69.68.31.12z" fill="url(#h8)" />
      <path
        d="m28.09 20.99-10 4-15-6 3-1.2v1.2c0 .55.9 1 2 1s2-.45 2-1v-2.8l1-.4v5.2c0 .55.9 1 2 1s2-.45 2-1v-4.95l1 .4v6.55c0 .55.9 1 2 1s2-.45 2-1v-5.2l1 .4v2.8c0 .55.9 1 2 1s2-.45 2-1v-1.2z"
        fill="url(#i8)"
      />
      <path
        d="m20.09 16.28v6.71c0 .55-.9 1-2 1s-2-.45-2-1v-6.55l1.06.42c.23.1.49.14.74.14s.51-.04.74-.14z"
        fill="url(#j8)"
      />
      <path d="m25.09 14.28v6.71c0 .55-.9 1-2 1s-2-.45-2-1v-5.11l2-.8z" fill="url(#k8)" />
    </IconBase>
  );
};
