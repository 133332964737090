import React from 'react';
import styled from 'styled-components';
import { layout, color, space } from 'styled-system';
import { MarkdownStyles } from '~/elements/Markdown';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

interface ParamType {
  param?: string;
  type?: string;
  required?: boolean;
  description?: string;
  object?: any[];
  blankRequired?: boolean;
}

interface MarkdownParametersProps {
  type: 'body' | 'path' | 'query' | 'object';
  data: ParamType[];
}

const ParameterWrapper = styled.div`
  margin: 36px 0;
`;

const Type = styled.span`
  text-transform: capitalize;
`;

const Item = styled.div<any>`
  border-top: 1px solid var(--color-gray-200);
  border-color: ${({ theme, type }) => (type === 'object' || type === 'array of objects') && theme.colors['blue-800']};
  padding-top: var(--space-3);
  margin-top: var(--space-3-5)};
  gap: var(--space-8);

  ${layout}
  ${color}
  ${space}
`;

const RequiredToken = styled(Text)<any>`
  padding: var(--space-1) var(--space-2);
  border-radius: var(--radii-pill);

  ${color}
`;

const EntryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    margin: var(--space-1) 0 0;
  }
`;

const EntryInfo = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

const ChildParams: React.FC<ParamType> = (props) =>
  props.object ? (
    <>
      {props.object.map((objectEntry: ParamType, index: number) => (
        <Item pl={4} key={index}>
          <Entry key={index} {...objectEntry} blankRequired={props.blankRequired} />
        </Item>
      ))}
    </>
  ) : null;

const Entry: React.FC<ParamType> = (props) => (
  <>
    <EntryWrapper>
      <EntryInfo>
        <Heading weight="medium" size="300">
          {props.param}
        </Heading>
        <Text size="100" color="gray-500">
          {props.type}
        </Text>
      </EntryInfo>
      {!props.blankRequired &&
        (props.required ? (
          <RequiredToken size="100" mt="-2px" mb="0" bg="blue-50" color="blue-500">
            Required
          </RequiredToken>
        ) : (
          <RequiredToken size="100" color="gray-400" mt="-2px" mb="0">
            Optional
          </RequiredToken>
        ))}
    </EntryWrapper>
    <MarkdownStyles baseSize="200">{props.description}</MarkdownStyles>
    <ChildParams {...props} />
  </>
);

export const MarkdownParameters: React.FC<MarkdownParametersProps> = (props) => {
  return (
    <ParameterWrapper>
      <Heading size="400" mt={12} color="blue-900">
        <Type>{props.type} parameters</Type>
      </Heading>
      {props.data.map((param: ParamType, index: number) => {
        return (
          <Item key={index}>
            <Entry {...param} blankRequired={props.type === 'object'} />
          </Item>
        );
      })}
    </ParameterWrapper>
  );
};
