import { css } from 'styled-components';

export const Cyan = css`
  .color-palette--cyan {
    --list-item-bullet: var(--color-cyan-400);
    --list-item-number: var(--color-cyan-500);
  }

  .color-palette-inverted--cyan {
    --list-item-bullet: var(--color-cyan-400);
    --list-item-number: var(--color-cyan-300);
  }
`;
