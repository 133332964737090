import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Icon } from '~/elements/Icon';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
`;

export const SectionBusinessHours: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }}>
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 6, lg: 5 }}>
              <Flex flexDirection="column" justifyContent="space-between" height="100%">
                <Column>
                  <Heading as="h2" weight="semibold" size="700">
                    Say goodbye to <br />
                    business hours
                  </Heading>
                  <Text size="300" mt={2} color="gray-500">
                    Initiate and settle wire transfers 22 hours a day, 5 days a week. We process{' '}
                    <Text as="em" color="gray-900" size="inherit">
                      every second the Federal Reserve is open
                    </Text>
                    , unlike legacy banks.
                  </Text>
                </Column>
                <Column mt={{ _: 4, md: 0 }}>
                  <Flex gap={8}>
                    <Flex alignItems="center" gap={2}>
                      <Heading as="p" size="800" weight="semibold">
                        22
                      </Heading>
                      <div>
                        <Heading as="p" size="200" weight="semibold">
                          Hours
                        </Heading>
                        <Text size="100" color="gray-500">
                          Per day
                        </Text>
                      </div>
                    </Flex>

                    <Flex alignItems="center" gap={2}>
                      <Heading as="p" size="800" weight="semibold">
                        5
                      </Heading>
                      <div>
                        <Heading as="p" size="200" weight="semibold">
                          Days
                        </Heading>
                        <Text size="100" color="gray-500">
                          Per week
                        </Text>
                      </div>
                    </Flex>
                  </Flex>
                </Column>
              </Flex>
            </Column>

            <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 0, md: 7, lg: 7 }}>
              <Code title="Initiate the wire transfer">
                {`curl 'https://api.column.com/transfers/wire' \\
     -XPOST \\
     -u :<YOUR API KEY> \\
     -d description="Example wire transfer" \\
     -d amount=10000 \\
     -d currency_code=USD \\
     -d bank_account_id="<bank_account_id>" \\
     -d counterparty_id="<counterparty_id>"`}
              </Code>

              <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1, lg: 2 }} gap={6} mt={5}>
                <Column>
                  <Flex gap={2}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <div>
                      <Heading as="h3" size="200" weight="medium">
                        Simplicity
                      </Heading>
                      <Text size="100" color="gray-500" mt={1}>
                        Programmatically send wire transfers with one API call.
                      </Text>
                    </div>
                  </Flex>
                </Column>
                <Column>
                  <Flex gap={2}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <div>
                      <Heading as="h3" size="200" weight="medium">
                        Speed
                      </Heading>
                      <Text size="100" color="gray-500" mt={1}>
                        Funds are transferred in real-time to any US bank account.
                      </Text>
                    </div>
                  </Flex>
                </Column>
              </Grid>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionBusinessHours;
