import React, { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button } from '~/elements/Button';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Icon } from '~/elements/Icon';
import { Flex } from '~/elements/Flex';
import { Status } from '~/elements/Status';
import { Heading } from '~/elements/Heading';

export interface HeaderProps {
  className?: string;
  style?: CSSProperties;
}

const DocsHeader = styled.header`
  background: var(--color-0);
  padding: var(--space-3) var(--space-4);
  display: block;
  position: fixed;
  box-shadow: 0 1px 0 rgba(var(--rgb-black), 0.075);
  transition: transform 0.2s;
  top: 0;
  z-index: 1;
  width: 100%;

  &.scroll-down {
    transform: translate3d(0, calc(-100% - 1px), 0);
  }

  &.scroll-up {
    transform: translate3d(0, 0, 0);
  }
`;

const DocsHome = styled(Flex)`
  width: 280px;
  & .icon-brand {
    height: 18px;
    margin-top: -3px;
    --icon-color-brand: var(--color-gray-900);
  }
`;

const DocsLink = styled.a`
  padding-left: var(--space-4);
  border-left: 1px solid var(--color-gray-200);
`;

const StatusComponent = styled(Status)`
  padding: var(--space-2) var(--space-4);
  border-radius: var(--radii-pill);
  border: 1px solid var(--color-gray-200);
`;

export const Header: React.FC<HeaderProps> = (props) => {
  const navbar = useRef<HTMLElement>(null);
  const lastScroll = useRef<number>(0);

  const handleScroll = () => {
    if (!navbar.current) {
      return;
    }

    const body = document.querySelector('.docs-body');
    const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';

    const currentScroll = window.scrollY;

    if (
      body &&
      (window.innerHeight >= body.clientHeight || window.innerHeight + window.scrollY >= document.body.offsetHeight)
    ) {
      return;
    }

    if (currentScroll <= 0) {
      navbar.current.classList.remove(scrollUp);

      if (body) {
        body.classList.remove(scrollUp);
      }

      return;
    }

    if (currentScroll > lastScroll.current && !navbar.current.classList.contains(scrollDown)) {
      // down
      navbar.current.classList.remove(scrollUp);
      if (body) {
        body.classList.remove(scrollUp);
      }
      navbar.current.classList.add(scrollDown);
      if (body) {
        body.classList.add(scrollDown);
      }
    } else if (currentScroll < lastScroll.current && navbar.current.classList.contains(scrollDown)) {
      // up
      navbar.current.classList.remove(scrollDown);
      if (body) {
        body.classList.remove(scrollDown);
      }
      navbar.current.classList.add(scrollUp);
      if (body) {
        body.classList.add(scrollUp);
      }
    }
    lastScroll.current = currentScroll;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <DocsHeader ref={navbar} style={props.style}>
      <Flex gap={8} alignItems="center" justifyContent="space-between">
        <DocsHome gap={4} alignItems="center">
          <a href="/">
            <Icon.BrandWordmark />
          </a>
          <DocsLink href="/docs">
            <Heading as="h1" color="gray-500" weight="medium" size="200">
              Documentation
            </Heading>
          </DocsLink>
        </DocsHome>
        <Flex alignItems="center" gap={6}>
          <StatusComponent />
          <ButtonGroup as="nav" gap={5} display={{ _: 'none', md: 'flex' }}>
            <Button href="https://dashboard.column.com/login" variant="link" type="link" color="gray-500">
              Log in
            </Button>
            <Button href="https://dashboard.column.com/register" variant="secondary" type="link">
              Sign up <Icon.AnimatedArrowRight />
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </DocsHeader>
  );
};
