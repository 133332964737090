import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Flex } from '~/elements/Flex';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Code } from '~/elements/Code/Code';
import { sm, md, lg } from '~/ds/mixins/breakpoints';
import FDIC from '~/assets/svg/logos/fdic.svg';

const fadeInHalf = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: .5;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: var(--space-12) 0 0;
  transform: scale(1) translateX(0);
  width: 100%;
  max-width: 360px;
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  @media ${sm} {
    transform: scale(0.9) translateX(25px);
  }

  @media ${lg} {
    margin: 0;
    transform: scale(1) translateX(0);
  }

  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(246, 247, 250, 1) 0%,
      rgba(246, 247, 250, 0) 90%,
      rgba(246, 247, 250, 1) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -50%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    top: -15%;
    right: -5%;
    bottom: -15%;
    left: -30%;
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
    filter: blur(30px);
    opacity: 0.5;
    z-index: 0;
    animation: 2.5s ${fadeInHalf} forwards;
  }
`;

const flyIn = keyframes`
 0% {
  transform: scale(.7);
  opacity: 0;
  }
 100% {
  transform: scale(1);
  opacity: 1;
  }
`;

const AccountCard = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--shadow-product-border), var(--shadow-product);
  opacity: 0;

  transform: scale(0.7);
  position: relative;
  z-index: 3;
  animation: 0.5s var(--animation-curve) ${flyIn} 0.5s forwards;

  @media ${sm} {
  }
`;

const responseIn = keyframes`
 0% {
  transform: scale(0.7) translateX(88px);
  opacity: 0;
  }
 100% {
  transform: scale(1) translateX(0);
  opacity: 1;
  }
`;

const Response = styled.div`
  display: none;
  position: absolute;
  top: 24px;
  right: 24px;
  bottom: -32px;
  left: 24px;
  max-width: 320px;
  z-index: 2;
  opacity: 1;
  transform: scale(1) translateX(0px) translateY(-72px);

  @media ${sm} {
    display: block;
    top: 32px;
    right: 0;
    bottom: 32px;
    left: -88px;
    transform: scale(0.7) translateX(88px);
    opacity: 0;
    animation: 0.5s var(--animation-curve) ${responseIn} 0.75s forwards;
  }

  div:nth-child(1),
  div:nth-child(2) {
    height: 100%;
    max-height: none;
  }
`;

export const Accounts: React.FC = () => {
  return (
    <Wrapper>
      <AccountCard>
        <Flex justifyContent="space-between" alignItems="center" p={6}>
          <Heading as="span" size="200" weight="medium">
            Bank Account
          </Heading>
          <FDIC />
        </Flex>
        <Flex justifyContent="space-between" px={6} pb={6}>
          <Flex flexDirection="column" gap={0.5}>
            <Heading as="span" size="400" weight="semibold">
              House fund
            </Heading>
            <Text size="200" color="gray-500">
              Savings - 2919
            </Text>
          </Flex>

          <Flex flexDirection="column" alignItems="flex-end" gap={0.5}>
            <Heading as="span" size="400" weight="semibold">
              $62,703.32
            </Heading>
            <Text size="200" color="gray-500">
              Available balance
            </Text>
          </Flex>
        </Flex>

        <Flex
          px={6}
          py={5}
          backgroundColor="#FBFBFB"
          gapY={5}
          flexDirection="column"
          borderTop="1px solid var(--color-gray-200)"
          borderRadius="0 0 8px 8px"
        >
          <Flex flexDirection="column">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              py={3}
              borderBottom="1px solid var(--color-gray-200)"
            >
              <Heading as="span" size="200" weight="medium">
                Available
              </Heading>
              <Heading as="span" size="200" weight="medium">
                $62,703.32
              </Heading>
            </Flex>

            <Flex
              justifyContent="space-between"
              alignItems="center"
              py={3}
              borderBottom="1px solid var(--color-gray-200)"
            >
              <Heading as="span" size="200" weight="medium">
                Pending
              </Heading>
              <Heading as="span" size="200" weight="medium">
                $2,958.92
              </Heading>
            </Flex>

            <Flex justifyContent="space-between" alignItems="center" py={3}>
              <Heading as="span" size="200" weight="medium">
                Locked
              </Heading>
              <Heading as="span" size="200" weight="medium">
                $0
              </Heading>
            </Flex>
          </Flex>
          <Text as="p" size="200" color="gray-500">
            Created January, 23 2022
          </Text>
        </Flex>
      </AccountCard>

      <Response>
        <Code language="json">
          {`{
  "id": "string",
  "description": "House fund",
  "owners": ["string"],
  "default_account_number_id": "string",
  "default_account_number": "string",
  "default_routing_number": "string",
  "available_balance": {
    "currency_code": "USD",
    "cents": "6270332"
  },\
  "pending_balance": {
    "currency_code": "string",
    "cents": "string"
  },\
  "locked_balance": {
    "currency_code": "string",
    "cents": "string"
  },\
  "holding_balance": {
    "currency_code": "string",
    "cents": "string"
  },\
  "created_at": "2021-11-29T16:32:11.806Z",
  "type": "UNKNOWN",
  "is_overdraftable": false,
  "overdraft_reserve_account_id": "string"
}`}
        </Code>
      </Response>
    </Wrapper>
  );
};
