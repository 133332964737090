import { css } from 'styled-components';
import { Color } from './base';
import { Background } from './background';
import { Button } from './button';
import { Icon } from './icon';
import { List } from './list';
import { Typography } from './typography';

export const ColorTokens = css`
    ${Color}
    ${Background}
    ${Button}
    ${Typography}
    ${List}
    ${Icon}
  }
`;
