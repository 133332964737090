import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { SectionPaymentOrigination } from './pricing/_payment-origination';
import { SectionBankAccounts } from './pricing/_bank-accounts';
import { SectionCardPrograms } from './pricing/_card-programs';
import { SectionLoanOrigination } from './pricing/_loan-origination';
import { SectionDebtFinancing } from './pricing/_debt-financing';
import { Heading } from '~/elements/Heading';
import { Link } from '~/elements/Link';
import { Container } from '~/elements/Container';
import { Box } from '~/elements/Box';
import { Flex } from '~/elements/Flex';
import { Layout } from '~/components/Layout';

interface TabProps {
  active?: boolean;
}

const TabsList = styled(Flex)`
  margin: 0 0 var(--space-8) 0;
  border-bottom: 1px solid var(--color-gray-200);
  display: none;

  @media ${({ theme }) => theme.screens['bp-md']} {
    display: flex;
  }
`;

const Tab = styled(Link)`
  padding: var(--space-3) 0;
  display: inline-block;
  color: var(--color-gray-500);
  font-weight: var(--font-medium);
  font-size: var(--text-300);
  white-space: nowrap;
  box-shadow: 0 1px 0 transparent;
  transition: color 0.2s, box-shadow 0.2s;

  &:hover {
    color: var(--color-gray-700);
    box-shadow: 0 1px 0 var(--color-gray-500);
  }

  ${({ active }) =>
    active === true &&
    css<TabProps>`
      color: var(--color-blue-700);
      box-shadow: 0 1px 0 var(--color-blue-700);
    `};
`;

const PricingPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Pricing — Column</title>
        <meta property="og:title" content="Column - Pricing" />
        <meta name="description" content="How are services using the Column API priced?" />
      </Helmet>
      <Container>
        <Box pt={18}>
          <Heading as="h1" weight="semibold" size="800" mb={8}>
            Pricing
          </Heading>

          <TabsList as="ul" gap={11}>
            <li>
              <Tab href="#payment-origination" active>
                Payment Origination
              </Tab>
            </li>
            <li>
              <Tab href="#bank-accounts">Bank Accounts</Tab>
            </li>
            <li>
              <Tab href="#card-programs">Card Programs</Tab>
            </li>
            <li>
              <Tab href="#loan-origination">Loan Origination</Tab>
            </li>
            <li>
              <Tab href="#debt-financing">Debt Financing</Tab>
            </li>
          </TabsList>
        </Box>

        <Flex flexDirection="column" gap={8}>
          <SectionPaymentOrigination />
          <SectionBankAccounts />
          <SectionCardPrograms />
          <SectionLoanOrigination />
          <SectionDebtFinancing />
        </Flex>
      </Container>
    </Layout>
  );
};

export default PricingPage;
