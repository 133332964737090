import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { Flex } from '~/elements/Flex';
import FDIC from '~/assets/svg/logos/fdic.svg';

export const SectionScale: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }}>
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 6 }} position="relative" zIndex={2}>
              <Grid gridTemplateColumns={6}>
                <Column gridColumn={{ _: 6, md: 5 }}>
                  <Heading as="h2" weight="semibold" size="700">
                    Built for scale
                  </Heading>
                  <Text size="300" mt={2}>
                    From one to billions of dollars in deposits, nothing is too small or large for us. We support FBO,
                    sweep, clearing and custom account types — all FDIC insured.
                  </Text>
                </Column>
              </Grid>

              <Code title="Create a bank account" mt={6}>
                {`curl 'https://api.column.com/bank-accounts' \\
     -XPOST \\
     -u :<YOUR API KEY> \\
     -d description="Travel Checking" \\
     -d entity_id="<entity_id>"`}
              </Code>

              <Flex gap={2} mt={8}>
                <FDIC width="64" height="32" fill="var(--color-gray-500)" />
                <Text size="100" color="gray-400">
                  All accounts are <br />
                  insured by the FDIC
                </Text>
              </Flex>
            </Column>

            <Column gridColumn={{ _: 12, md: 5 }} gridColumnStart={{ _: 0, md: 8 }}>
              <ProductScreen width="720px" mb={'-50%'} backgroundColor="#fbfcfd">
                <img src="/images/products/bank-accounts/new-bank-account.jpg" />
              </ProductScreen>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionScale;
