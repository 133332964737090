import gsap from 'gsap';
import styled, { keyframes } from 'styled-components';
import React, { createRef, ReactNode, useEffect, useRef } from 'react';
import { md, nav, lg } from '~/ds/mixins/breakpoints';

const fadeInHalf = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: .5;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 324px;
  height: 100%;
  position: relative;
  transform: scale(0.9);

  @media ${md} {
    transform: scale(0.9) translateY(32px);
  }

  @media ${nav} {
    max-width: 100%;
    transform: scale(0.9) translateX(32px) translateY(-16px);
  }

  @media ${lg} {
    margin: 0;
    transform: scale(1) translateX(32px) translateX(-32px);
  }
`;

const CardsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateX(111px);

  @media ${nav} {
    left: 32px;
    transform: translateX(0);
  }

  &:after {
    content: '';
    background: linear-gradient(180deg, #edf2f7 0%, rgba(237, 242, 247, 0) 70%),
      linear-gradient(
        0deg,
        rgba(246, 247, 250, 1) 0%,
        rgba(246, 247, 250, 1) 5%,
        rgba(246, 247, 250, 0) 45%,
        rgba(246, 247, 250, 0) 65%,
        rgba(246, 247, 250, 1) 95%,
        rgba(246, 247, 250, 1) 100%
      );
    position: absolute;
    top: -164px;
    right: -164px;
    bottom: -164px;
    left: -164px;
    z-index: 2;
  }

  .product-hero-ledger-card {
    z-index: 2;
  }
`;

const Glow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;

  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(246, 247, 250, 1) 0%,
      rgba(246, 247, 250, 0) 90%,
      rgba(246, 247, 250, 1) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -50%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: -80%;
    left: var(--pattern-offset);
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
    filter: blur(30px);
    z-index: 0;
    opacity: 0;
    animation: 2.5s ${fadeInHalf} forwards;

    @media ${nav} {
      top: -60%;
      left: -10%;
    }
  }
`;

const random = (min: number, max: number) => {
  return Math.round(Math.random() * (max - min) + min);
};

const thousand = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const randomText = (arr: string[] | ReactNode[]) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const Incoming: React.FC = () => (
  <div className="direction">
    Incoming
    <svg viewBox="0 0 9 9">
      <path d="M2 8h6V2M1 1l6.5 6.5" />
    </svg>
  </div>
);

const Outgoing: React.FC = () => (
  <div className="direction">
    Outgoing
    <svg viewBox="0 0 9 9">
      <path d="M2 1h6v6M1 8l6.5-6.5" />
    </svg>
  </div>
);

const Card: React.FC = () => {
  return (
    <div className="product-hero-ledger-card">
      <div className="amount">${thousand(random(100, 5000))}</div>
      <div className="type">{randomText(['ACH Debit', 'ACH Credit', 'Wire'])}</div>
      {randomText([<Incoming key={0} />, <Outgoing key={1} />])}
    </div>
  );
};

export const Cards: React.FC = () => {
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);
  const wrapperRef = createRef<HTMLDivElement>();

  const animate = () => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    gsap.to(wrapper.querySelectorAll('.product-hero-ledger-card'), {
      '--y': '+=78px',
      '--x': (index: number) => {
        if (index < 4) {
          return '-=16px';
        }
        return '+=16px';
      },
      '--b': (index: number) => {
        const amount = (index > 3 ? 3 - (index - 3) : index) + 1;
        return amount * 0.1 + 0.6;
      },
      duration: 1.4,
      ease: 'elastic.out(.98, .96)',
      onComplete: () => {
        const lastElem = wrapper.querySelector('.product-hero-ledger-card:last-child');

        if (!lastElem) {
          return;
        }
        const newElem = lastElem.cloneNode(true);

        lastElem.remove();

        gsap.set(newElem, {
          clearProps: true,
        });

        (newElem as HTMLDivElement).style.opacity = '1';

        wrapper.insertBefore(newElem, wrapper.firstChild);
      },
    });
  };

  const handleDocumentHidden = () => {
    if (document.hidden) {
      clearTimeout(intervalRef.current as NodeJS.Timeout);
    } else {
      intervalRef.current = setInterval(() => {
        animate();
      }, 4500);
    }
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    gsap.set(wrapper.querySelectorAll('.product-hero-ledger-card'), {
      '--y': (index: number) => {
        return `+=${78 * index}`;
      },
      '--x': (index: number) => {
        const amount = index > 4 ? 4 - (index - 4) : index;
        return `-=${16 * amount + 1}`;
      },
      '--b': (index: number) => {
        const amount = index > 4 ? 4 - (index - 4) : index;
        return amount * 0.1 + 0.6;
      },
    });

    gsap.to(wrapper.querySelectorAll('.product-hero-ledger-card'), {
      opacity: 1,
      duration: 0.3,
      stagger: 0.05,
      onComplete: () => {
        setTimeout(() => {
          animate();
          intervalRef.current = setInterval(() => {
            animate();
          }, 4500);
        }, 1500);
      },
    });

    document.addEventListener('visibilitychange', handleDocumentHidden);

    return () => {
      gsap.set(wrapper.querySelectorAll('.product-hero-ledger-card'), {
        clearProps: true,
      });
      clearTimeout(intervalRef.current as NodeJS.Timeout);
      document.removeEventListener('visibilitychange', handleDocumentHidden);
    };
  }, []);

  return (
    <Wrapper>
      <CardsWrapper ref={wrapperRef}>
        {[...Array(8)].map((n: number, i: number) => (
          <Card key={`index-${i}`} />
        ))}
      </CardsWrapper>
      <Glow />
    </Wrapper>
  );
};
