import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductLedger: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductLedger {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a7" gradientUnits="userSpaceOnUse" x1="47.78" x2="6.73" y1="47.78" y2="6.73">
        <stop offset="0" stopColor="#1e4199" />
        <stop offset="1" stopColor="#1e4199" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="b7" x1="16" x2="16" href="#a7" y1="38.81" y2="21.43" />
      <linearGradient id="c7" x1="9.2" x2="-2.06" href="#a7" y1="24" y2="24" />
      <linearGradient id="d7" x1="21.86" x2="6.75" href="#a7" y1="5.14" y2="20.25" />
      <linearGradient id="e7" x1="24.36" x2="9.25" href="#a7" y1="7.64" y2="22.75" />
      <linearGradient id="f7" x1="26.86" x2="11.75" href="#a7" y1="10.14" y2="25.25" />
      <path d="m26 5v16c0 3.31-1.35 6-3 6h-17c1.65 0 3-2.69 3-6v-16z" fill="url(#a7)" />
      <path d="m26 5v16c0 3.31-1.35 6-3 6h-17c1.65 0 3-2.69 3-6v-16z" fill="url(#b7)" />
      <path d="m9 21c0 3.31-1.35 6-3 6s-3-2.69-3-6z" fill="url(#c7)" />
      <rect fill="url(#d7)" height="3" rx=".5" width="9" x="13" y="8" />
      <rect fill="url(#e7)" height="3" rx=".5" width="9" x="13" y="13" />
      <rect fill="url(#f7)" height="3" rx=".5" width="9" x="13" y="18" />
    </IconBase>
  );
};
