import styled, { css } from 'styled-components';
import {
  compose,
  textAlign,
  system,
  space,
  color,
  maxWidth,
  MaxWidthProps,
  TextAlignProps,
  SpaceProps,
  ColorProps,
} from 'styled-system';
import { md, lg } from '~/ds/mixins/breakpoints';
import {
  heading100,
  heading200,
  heading300,
  heading400,
  heading500,
  heading600,
  heading700,
  heading800,
  heading900,
  heading1000,
} from '~/ds/mixins/typography';

export interface HeadingStyleProps {
  weight?: 'regular' | 'book' | 'medium' | 'semibold' | 'bold';
  size?: 'inherit' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | '1000';
  truncate?: boolean;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
  whiteSpace?: string;
  eyebrow?: boolean;
}

export interface HeadingProps extends HeadingStyleProps, SpaceProps, ColorProps, TextAlignProps {
  className?: string;
}

export const Heading = styled.h3<HeadingProps>`
  font-size: inherit;
  font-weight: ${(props) => props.weight || 'inherit'};
  color: var(--text-color-heading);
  white-space: ${(props) => props.whiteSpace};

  ${({ size }) =>
    size === '100' &&
    css<HeadingStyleProps>`
      ${heading100}
    `}

  ${({ size }) =>
    size === '200' &&
    css<HeadingStyleProps>`
      ${heading200}
    `}

    ${({ size }) =>
    size === '300' &&
    css<HeadingStyleProps>`
      ${heading300}
    `}

    ${({ size }) =>
    size === '400' &&
    css<HeadingStyleProps>`
      ${heading400}
    `}

    ${({ size }) =>
    size === '500' &&
    css<HeadingStyleProps>`
      ${heading500}
    `}

    ${({ size }) =>
    size === '600' &&
    css<HeadingStyleProps>`
      ${heading600}
    `}

    ${({ size }) =>
    size === '700' &&
    css<HeadingStyleProps>`
      ${heading700}
    `}

    ${({ size }) =>
    size === '800' &&
    css<HeadingStyleProps>`
      ${heading800}
    `}

    ${({ size }) =>
    size === '900' &&
    css<HeadingStyleProps>`
      ${heading900}
    `}

    ${({ size }) =>
    size === '1000' &&
    css<HeadingStyleProps>`
      ${heading1000}
    `}

    // No wrapping and truncate with ellipsis
    ${({ truncate }) =>
    truncate === true &&
    css<HeadingStyleProps>`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    // Weight
    ${({ weight }) =>
    weight === 'regular' &&
    css<HeadingStyleProps>`
      font-weight: var(--font-regular);
    `}

    ${({ weight }) =>
    weight === 'book' &&
    css<HeadingStyleProps>`
      font-weight: var(--font-book);
    `}

    ${({ weight }) =>
    weight === 'medium' &&
    css<HeadingStyleProps>`
      font-weight: var(--font-medium);
    `}

    ${({ weight }) =>
    weight === 'semibold' &&
    css<HeadingStyleProps>`
      font-weight: var(--font-semibold);
    `}

    ${({ weight }) =>
    weight === 'bold' &&
    css<HeadingStyleProps>`
      font-weight: var(--font-bold);
    `}

    ${({ eyebrow }) =>
    eyebrow === true &&
    css<HeadingStyleProps>`
      font-weight: var(--font-semibold);

      &::before {
        width: 8px;
        height: 16px;
        margin-right: 8px;
        background: var(--bg-gradient-primary, var(--text-color-heading));
        display: inline-block;
        position: relative;
        top: 2px;
        border-radius: 1px;
        content: '';
      }
    `}

    ${system({
    textTransform: {
      property: 'textTransform',
      scale: 'textTransforms',
    },
  })}

  ${compose(space, color, textAlign, maxWidth)};
`;

Heading.defaultProps = {
  size: '300',
};
