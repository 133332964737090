import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductInternationalWires: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductChecks {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a6" gradientUnits="userSpaceOnUse" x1="4.94" x2="25.24" y1="14.22" y2="-6.08">
        <stop offset="0" stopColor="#1e4199" stopOpacity="0" />
        <stop offset=".99" stopColor="#1e4199" />
      </linearGradient>
      <linearGradient id="b6" x1="7.31" x2="27.61" href="#a6" y1="22.35" y2="2.05" />
      <linearGradient id="c6" x1="10.35" x2="10.35" href="#a6" y1="45.45" y2="20.52" />
      <linearGradient id="d6" x1="21.65" x2="21.65" href="#a6" y1="45.45" y2="20.52" />
      <linearGradient id="e6" x1="4.86" x2="25.16" href="#a6" y1="20.99" y2=".69" />
      <linearGradient id="f6" x1="16" x2="16" href="#a6" y1="45.45" y2="20.52" />
      <linearGradient id="g6" x1="8.08" x2="27.5" href="#a6" y1="31.93" y2="12.51" />
      <linearGradient id="h6" x1="1.62" x2="21.04" href="#a6" y1="30.38" y2="10.96" />
      <linearGradient id="i6" x1="-1.84" x2="17.58" href="#a6" y1="25.83" y2="6.41" />
      <path d="m16 4c-2.18 0-4.03 3.34-4.71 8h-6.6c1.64-4.66 6.08-8 11.31-8z" fill="url(#a6)" opacity=".3" />
      <path d="m27.31 12h-6.6c-.68-4.66-2.53-8-4.71-8 5.23 0 9.67 3.34 11.31 8z" fill="url(#b6)" opacity=".9" />
      <path d="m16 28c-5.23 0-9.67-3.34-11.31-8h6.6c.68 4.66 2.53 8 4.71 8z" fill="url(#c6)" opacity=".6" />
      <path d="m27.31 20c-1.64 4.66-6.08 8-11.31 8 2.18 0 4.03-3.34 4.71-8z" fill="url(#d6)" />
      <path d="m20.71 12h-9.42c.68-4.66 2.53-8 4.71-8s4.03 3.34 4.71 8z" fill="url(#e6)" opacity=".5" />
      <path d="m20.71 20c-.68 4.66-2.53 8-4.71 8s-4.03-3.34-4.71-8z" fill="url(#f6)" opacity=".8" />
      <path
        d="m28 16c0 1.4-.24 2.75-.69 4h-6.6c.19-1.25.29-2.6.29-4s-.1-2.75-.29-4h6.6c.45 1.25.69 2.6.69 4z"
        fill="url(#g6)"
      />
      <path
        d="m21 16c0 1.4-.1 2.75-.29 4h-9.42c-.19-1.25-.29-2.6-.29-4s.1-2.75.29-4h9.42c.19 1.25.29 2.6.29 4z"
        fill="url(#h6)"
        opacity=".8"
      />
      <path
        d="m11 16c0 1.4.1 2.75.29 4h-6.6c-.45-1.25-.69-2.6-.69-4s.24-2.75.69-4h6.6c-.19 1.25-.29 2.6-.29 4z"
        fill="url(#i6)"
        opacity=".5"
      />
    </IconBase>
  );
};
