import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/card-programs/_hero';
import { SectionCustomize } from './products/card-programs/_customize';
import { SectionDeveloperFirst } from './products/card-programs/_developer-first';
import { SectionCloser } from './index/_closer';
import { Layout } from '~/components/Layout';
import { AllProducts } from '~/components/AllProducts';
import { Testimonials } from '~/components/Testimonials';
import { Footer } from '~/components/Footer';

const CardProgramsPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Card Programs — Column</title>
        <meta property="og:title" content="Column - Card Programs" />
        <meta
          name="description"
          content="Build a card program to offer your customers any type of card. Column works with all major networks and issuer processors."
        />
      </Helmet>

      <SectionHero />
      <SectionCustomize />
      <SectionDeveloperFirst />
      <Testimonials />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />

      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default CardProgramsPage;
