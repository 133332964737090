import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsHolds: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M14.27 5.29001L9.27 3.86001C8.64 3.68001 8 4.16001 8 4.83001V16.17C8 16.84 8.64 17.32 9.27 17.14L14.27 15.71C14.7 15.58 15 15.19 15 14.75V6.25001C15 5.81001 14.7 5.42001 14.27 5.29001ZM9.75 12C9.34 12 9 11.66 9 11.25C9 10.84 9.34 10.5 9.75 10.5C10.16 10.5 10.5 10.84 10.5 11.25C10.5 11.66 10.16 12 9.75 12Z"
        fill="url(#paint0_linear_3204_761)"
      />
      <path
        d="M6 5.5H8V15.5H6C5.45 15.5 5 15.05 5 14.5V6.5C5 5.95 5.45 5.5 6 5.5Z"
        fill="url(#paint1_linear_3204_761)"
      />
      <path
        d="M9.75 12C10.1642 12 10.5 11.6642 10.5 11.25C10.5 10.8358 10.1642 10.5 9.75 10.5C9.33579 10.5 9 10.8358 9 11.25C9 11.6642 9.33579 12 9.75 12Z"
        fill="url(#paint2_linear_3204_761)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_761"
          x1="5.18"
          y1="10.5"
          x2="19.42"
          y2="10.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_761"
          x1="-3.75"
          y1="1.62"
          x2="5.84"
          y2="9.68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_761"
          x1="11.29"
          y1="8.59"
          x2="9.62"
          y2="11.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
