import React, { CSSProperties } from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import { PaletteProps, getPaletteClassnames } from '~/utils/getPaletteClassnames';

export interface TextGroupStyleProps extends PaletteProps {
  as?: 'div' | 'a' | 'li';
  size?: 'inherit' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | '1000';
  align?: 'inherit' | 'left' | 'right' | 'center';
  duotone?: boolean;
  inverted?: boolean;
}

export interface TextGroupProps extends TextGroupStyleProps {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  to?: string;
  href?: string;
  className?: string;
  style?: CSSProperties;
}

export const TextGroup: React.FC<TextGroupProps> = (props) => {
  const styleProps: TextGroupStyleProps = {
    as: props.as ?? 'div',
    size: props.size ?? 'inherit',
    align: props.align ?? 'inherit',
    palette: props.palette ?? 'inherit',
    duotone: props.duotone ?? false,
    inverted: props.inverted ?? false,
  };

  let conditionalClasses: ConditionalClasses = {
    'text-left': styleProps.align === 'left',
    'text-center': styleProps.align === 'center',
    'text-right': styleProps.align === 'right',

    'list-item': styleProps.as === 'li',

    ...getPaletteClassnames(styleProps.palette),
  };

  if (styleProps.inverted === true) {
    conditionalClasses = getPaletteClassnames(styleProps.palette, conditionalClasses, true);
  }

  if (styleProps.duotone === false) {
    conditionalClasses = {
      ...conditionalClasses,
      'text-group-100': styleProps.size === '100',
      'text-group-200': styleProps.size === '200',
      'text-group-300': styleProps.size === '300',
      'text-group-400': styleProps.size === '400',
      'text-group-500': styleProps.size === '500',
      'text-group-600': styleProps.size === '600',
      'text-group-700': styleProps.size === '700',
      'text-group-800': styleProps.size === '800',
      'text-group-900': styleProps.size === '900',
      'text-group-1000': styleProps.size === '1000',
    };
  }

  if (styleProps.duotone === true) {
    conditionalClasses = {
      ...conditionalClasses,
      'text-group-duotone-300': styleProps.size === '300',
      'text-group-duotone-400': styleProps.size === '400',
      'text-group-duotone-500': styleProps.size === '500',
      'text-group-duotone-600': styleProps.size === '600',
      'text-group-duotone-700': styleProps.size === '700',
    };
  }

  const Element = styleProps.as as React.ElementType;

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(event);
    }

    if (props.to) {
      event.preventDefault();

      navigate(props.to);
    }
  };

  return (
    <Element
      className={classNames([props.className], conditionalClasses)}
      onClick={handleClick}
      href={props.href}
      style={props.style}
    >
      {props.children}
    </Element>
  );
};
