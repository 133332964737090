export const blogCategories: Record<string, string> = {
  Changelog: 'blog-theme--blue',
  News: 'blog-theme--blue',
  Engineering: 'blog-theme--blue',
  Product: 'blog-theme--blue',
  Lending: 'blog-theme--blue',
};

export const getBlogCategoryColor = (name: string): string => {
  if (typeof blogCategories[name] !== 'undefined') {
    return blogCategories[name];
  }
  return 'blog-theme--blue';
};
