import { css } from 'styled-components';

export const Yellow = css`
  .color-palette--yellow {
    /* Yellow button palette */
    /* Primary */
    --btn-text-color-primary: var(--color-yellow-800);

    --btn-bg-primary: var(--color-yellow-500);
    --btn-bg-primary-hover: var(--color-yellow-600);
    --btn-bg-primary-active: var(--color-yellow-700);

    --btn-border-primary: var(--color-yellow-600);
    --btn-border-primary-hover: var(--color-yellow-700);
    --btn-border-primary-active: var(--color-yellow-800);

    --btn-focus-ring-primary: var(--color-yellow-300);

    /* Secondary */
    --btn-text-color-secondary: var(--color-yellow-800);

    --btn-bg-secondary: var(--color-yellow-100);
    --btn-bg-secondary-hover: var(--color-yellow-100);
    --btn-bg-secondary-active: var(--color-yellow-100);

    --btn-border-secondary: var(--color-yellow-500);
    --btn-border-secondary-hover: var(--color-yellow-600);
    --btn-border-secondary-active: var(--color-yellow-700);

    --btn-focus-ring-secondary: var(--color-yellow-300);
  }

  .color-palette-inverted--yellow {
    /* Primary */
    --btn-text-color-primary: var(--color-yellow-900);

    --btn-bg-primary: var(--color-0);
    --btn-bg-primary-hover: var(--color-0);
    --btn-bg-primary-active: var(--color-0);

    --btn-border-primary: var(--color-yellow-800);
    --btn-border-primary-hover: rgba(var(--rgb-yellow-900), 1);
    --btn-border-primary-active: rgba(var(--rgb-yellow-900), 1);

    --btn-focus-ring-primary: var(--color-yellow-500);

    /* Secondary */
    --btn-text-color-secondary: var(--color-0);

    --btn-bg-secondary: rgba(var(--rgb-yellow-600), 0.75);
    --btn-bg-secondary-hover: var(--color-yellow-600);
    --btn-bg-secondary-active: var(--color-yellow-700);

    --btn-border-secondary: rgba(var(--rgb-yellow-800));
    --btn-border-secondary-hover: var(--color-yellow-900);
    --btn-border-secondary-active: var(--color-yellow-900);

    --btn-focus-ring-secondary: var(--color-yellow-500);
  }
`;
