import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductLoanOrigination: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="26" height="26">
        <path
          d="M3 13C3 7.48 7.48 3 13 3c4.33 0 8.02 2.75 9.4 6.6.39 1.06.6 2.21.6 3.4 0 .9-.12 1.76-.34 2.59-.92 3.44-3.63 6.15-7.07 7.07a5.005 5.005 0 01-1.46-4.79 5.02 5.02 0 003.74-3.74 5.005 5.005 0 00-1.46-4.79 5.002 5.002 0 00-7.07 7.07C9.12 17.24 9 18.1 9 19c0 1.19.21 2.34.6 3.4A9.981 9.981 0 013 13z"
          fill="url(#32_origination-partnerships-dark__paint0_linear)"
        />
        <path
          d="M9 19c0-.9.12-1.76.34-2.59.92-3.44 3.63-6.15 7.07-7.07a5.005 5.005 0 011.46 4.79 5.02 5.02 0 00-3.74 3.74 5.005 5.005 0 001.46 4.79 5.002 5.002 0 007.07-7.07c.22-.83.34-1.69.34-2.59 0-1.19-.21-2.34-.6-3.4A9.981 9.981 0 0129 19c0 5.52-4.48 10-10 10-4.33 0-8.02-2.75-9.4-6.6-.39-1.06-.6-2.21-.6-3.4z"
          fill="url(#32_origination-partnerships-dark__paint1_linear)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#32_origination-partnerships-dark__paint2_linear)" />
      </g>
      <defs>
        <linearGradient
          id="32_origination-partnerships-dark__paint0_linear"
          x1="-6.596"
          y1="32.596"
          x2="19.18"
          y2="6.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_origination-partnerships-dark__paint1_linear"
          x1="1.82"
          y1="36.18"
          x2="36.621"
          y2="1.378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_origination-partnerships-dark__paint2_linear"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
