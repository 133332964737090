import { css } from 'styled-components';

export const BoxShadow = css`
  :root {
    --shadow-product: 0px 40px 32px rgba(0, 0, 0, 0.02), 0px 22px 18px rgba(0, 0, 0, 0.03),
      0px 12px 10px rgba(0, 0, 0, 0.03), 0px 7px 5px rgba(0, 0, 0, 0.04), 0px 3px 2px rgba(0, 0, 0, 0.07);
    --shadow-product-border: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .shadow-product {
    box-shadow: var(--shadow-product);
  }

  .shadow-border {
    box-shadow: var(--shadow-product-border);
  }

  .shadow-product-border {
    box-shadow: var(--shadow-product-border), var(--shadow-product);
  }
`;
