import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
  margin-bottom: var(--space-2);
`;

export const SectionCustomize: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }} overflow="hidden">
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 6 }} position="relative" zIndex={2}>
              <Grid gridTemplateColumns={6} gapX={7} gapY={8} mb={8}>
                <Column gridColumn={{ _: 6, md: 5 }}>
                  <Heading as="h2" weight="semibold" size="700">
                    Your card program, designed to your needs
                  </Heading>
                  <Text size="300" mt={2}>
                    Successful card programs require close coordination across a program manager, processor, card
                    network and issuing bank.
                  </Text>
                </Column>

                <Column gridColumn={{ _: 6, md: 3 }}>
                  <CircleCheck />
                  <Heading as="h3" size="300" weight="medium">
                    Own your program
                  </Heading>
                  <Text size="200" color="gray-500" mt={1}>
                    From card design to customized benefits, you build for your customer profile.
                  </Text>
                </Column>

                <Column gridColumn={{ _: 6, md: 3 }}>
                  <CircleCheck />
                  <Heading as="h3" size="300" weight="medium">
                    Built for scale
                  </Heading>
                  <Text size="200" color="gray-500" mt={1}>
                    Unlike managed programs, we are built for scale and flexibility.
                  </Text>
                </Column>
              </Grid>

              <Code title="Create a book transfer" mt={6}>
                {`curl 'https://api.column.com/transfers/book' \\
     -XPOST \\
     -u :<YOUR API KEY> \\
      -d amount="10000" \\
      -d currency_code=USD \\
      -d sender_bank_account_id="<bank_account_id>" \\
      -d receiver_bank_account_id="<bank_account_id>"`}
              </Code>

              <Grid gridTemplateColumns={6} gap={7} mt={8}>
                <Column gridColumn={{ _: 6, md: 3 }}>
                  <CircleCheck />
                  <Heading as="h3" size="300" weight="medium">
                    Compliance at your pace
                  </Heading>
                  <Text size="200" color="gray-500" mt={1}>
                    Our compliance team is optimized for teams who ship. We will meet your pace and requirements, not
                    the other way around.
                  </Text>
                </Column>

                <Column gridColumn={{ _: 6, md: 3 }}>
                  <CircleCheck />
                  <Heading as="h3" size="300" weight="medium">
                    Own your economics
                  </Heading>
                  <Text size="200" color="gray-500" mt={1}>
                    You own 100% of interchange. Control your unit economics, and build your business at scale.
                  </Text>
                </Column>
              </Grid>
            </Column>

            <Column gridColumn={{ _: 12, md: 5 }} gridColumnStart={{ _: 0, md: 8 }}>
              <ProductScreen width="760px" backgroundColor="#fbfcfd">
                <img src="/images/products/card-programs/new-book-transfer.png" />
              </ProductScreen>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionCustomize;
