import React from 'react';
import { Helmet } from 'react-helmet';
import { Card } from '~/components/docs/Card';
import { Layout } from '~/components/docs/Layout';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

const DocsIndexPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Column Documentation — Guides</title>
      </Helmet>

      <Container pt={12} mt="62px">
        <Grid>
          <Column gridColumn={{ _: 12, lg: 8 }}>
            <Heading as="h1" size="500" color="blue-900" weight="semibold">
              Guides
            </Heading>
          </Column>
        </Grid>
      </Container>

      <Container pb={12} mt={6}>
        <Heading as="h4" size="300" id="common-workflows" weight="medium">
          Common workflows
        </Heading>
        <Grid gap={5} py={6} mb={10}>
          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="book-transfers-and-holds"
              title="Book Transfers and Holds"
              description="A book transfer, by default, will move money from one of your Column accounts to another instantaneously."
              cta="Read more"
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="overdrafts"
              title="Overdrafts"
              description="Learn how to handle situations where the amount of an outgoing transfer can exceed the balance of the underlying bank account."
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="two-legged-transactions"
              title="Two Legged Transactions"
              description="Learn more about money movement operations that require two steps to get funds where they need to go."
              cta="Read more"
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="going-live"
              title="Going Live"
              description="Get the details about steps you must take to get a live Column environment enabled (and start moving real money)."
              cta="Read more"
            />
          </Column>
        </Grid>

        <Heading as="h4" size="300" id="technical-guides" weight="medium">
          Technical guides
        </Heading>
        <Grid gap={5} py={6} mb={10}>
          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="getting-started"
              title="Getting Started"
              description="Build a basic payment workflow consisting of a wire, a book transfer, and an ACH credit with
                  Column's APIs."
              cta="Read more"
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="sandbox-and-testing"
              title="Sandbox and Testing"
              description="Learn how to use our sandbox environment to test our APIs and learn how Column works."
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="reporting"
              title="Reporting"
              description="Use our settlement reports to reconcile your internal data with Column ledger."
              cta="Read more"
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="kyc-kyb"
              title="KYC/KYB"
              description="Bring your own KYC/KYB process, or use our KYC/KYB verifications with support for manual reviewing."
              cta="Read more"
            />
          </Column>
        </Grid>

        <Heading as="h4" size="300" id="use-cases">
          Use cases
        </Heading>
        <Grid gap={5} py={6} mb={10}>
          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="payment-origination"
              title="Payment Origination"
              description="Originate wires, ACH and books transfers, and store money in an FBO account for your customers."
              cta="Read more"
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="bank-accounts"
              title="Bank Accounts"
              description="Programmatically open and manage FDIC insured bank accounts at Column on behalf of your customers. We support FBO, sweep, clearing and custom account types."
              cta="Read more"
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="card-programs"
              title="Card Programs"
              description="Build a card program with Column to offer your customers any type of card. We work with all major networks and issuer processors."
              cta="Read more"
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 6, lg: 3 }}>
            <Card
              to="loan-origination"
              title="Loan Origination"
              description="Partner with Column on lending to provide a variety of credit products to your customers."
              cta="Read more"
            />
          </Column>
        </Grid>

        <Heading as="h4" size="300" weight="medium" id="informational-guides">
          Informational Guides
        </Heading>
        <Grid gap={5} py={6}>
          <Column gridColumn={{ _: 12, md: 3 }}>
            <Card
              to="intro-to-card-issuing"
              title="Intro to Card Issuing"
              description="Whether you're interested in launching a consumer debit card, a commercial credit card, or
                        something in between."
              cta="Read more"
            />
          </Column>

          <Column gridColumn={{ _: 12, md: 3 }}>
            <Card
              to="roles-and-permissions"
              title="Roles & Permissions"
              description="Set up roles and permissions to control access to your Column environment."
              cta="Read more"
            />
          </Column>
        </Grid>
      </Container>
    </Layout>
  );
};

export default DocsIndexPage;
