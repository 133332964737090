import { css } from 'styled-components';
import { md, lg } from '~/ds/mixins/breakpoints';

// Type sizes

export const b100 = css`
  font-size: var(--text-100);
  line-height: var(--lh-b-100);
`;

export const b200 = css`
  font-size: var(--text-200);
  line-height: var(--lh-b-200);
`;

export const b300 = css`
  font-size: var(--text-300);
  line-height: var(--lh-b-300);
`;

export const b350 = css`
  font-size: var(--text-350);
  line-height: var(--lh-b-350);
`;

export const b400 = css`
  font-size: var(--text-400);
  line-height: var(--lh-b-400);
`;

export const b500 = css`
  font-size: var(--text-500);
  line-height: var(--lh-b-500);
  letter-spacing: var(--letter-spacing-1);
`;

export const b550 = css`
  font-size: var(--text-550);
  line-height: var(--lh-b-550);
  letter-spacing: var(--letter-spacing-1);
`;

export const b600 = css`
  font-size: var(--text-600);
  line-height: var(--lh-b-600);
  letter-spacing: var(--letter-spacing-1);
`;

// Headings

export const h100 = css`
  font-size: var(--text-100);
  line-height: var(--lh-h-100);
`;

export const h200 = css`
  font-size: var(--text-200);
  line-height: var(--lh-h-200);
`;

export const h300 = css`
  font-size: var(--text-300);
  line-height: var(--lh-h-300);
  letter-spacing: var(--letter-spacing-1);
`;

export const h350 = css`
  font-size: var(--text-350);
  line-height: var(--lh-h-350);
  letter-spacing: var(--letter-spacing-1);
`;

export const h400 = css`
  font-size: var(--text-400);
  line-height: var(--lh-h-400);
  letter-spacing: var(--letter-spacing-1);
`;

export const h500 = css`
  font-size: var(--text-500);
  line-height: var(--lh-h-500);
  letter-spacing: var(--letter-spacing-1);
`;

export const h550 = css`
  font-size: var(--text-550);
  line-height: var(--lh-h-550);
  letter-spacing: var(--letter-spacing-1);
`;

export const h600 = css`
  font-size: var(--text-600);
  line-height: var(--lh-h-600);
  letter-spacing: var(--letter-spacing-1);
`;

export const h700 = css`
  font-size: var(--text-700);
  line-height: var(--lh-h-700);
  letter-spacing: var(--letter-spacing-1);
`;

export const h750 = css`
  font-size: var(--text-750);
  line-height: var(--lh-h-750);
  letter-spacing: var(--letter-spacing-2);
`;

export const h800 = css`
  font-size: var(--text-800);
  line-height: var(--lh-h-800);
  letter-spacing: var(--letter-spacing-2);
`;

export const h900 = css`
  font-size: var(--text-900);
  line-height: var(--lh-h-900);
  letter-spacing: var(--letter-spacing-2);
`;

export const h1000 = css`
  font-size: var(--text-1000);
  line-height: var(--lh-h-1000);
  letter-spacing: var(--letter-spacing-3);
`;

// Responsive Type

export const body100 = css`
  ${b100}
`;

export const body200 = css`
  ${b200}
`;

export const body300 = css`
  ${b300}
`;

export const body400 = css`
  ${b350}

  @media ${md} {
    ${b400}
    letter-spacing: var(--letter-spacing-1);
  }
`;

export const body500 = css`
  ${b400}

  @media ${lg} {
    ${b500}
  }
`;

export const body600 = css`
  ${b500}

  @media ${lg} {
    ${b600}
  }
`;

// Headings

export const heading100 = css`
  ${h100}
`;

export const heading200 = css`
  ${h200}
`;

export const heading300 = css`
  ${h300}
`;

export const heading400 = css`
  ${h350}

  @media ${lg} {
    ${h400}
    letter-spacing: var(--letter-spacing-1);
  }
`;

export const heading500 = css`
  ${h400}
  font-weight: var(--font-semibold);

  @media ${lg} {
    ${h500}
  }
`;

export const heading600 = css`
  ${h500}
  font-weight: var(--font-semibold);

  @media ${md} {
    ${h550}
  }

  @media ${lg} {
    ${h600}
  }
`;

export const heading700 = css`
  ${h550}
  font-weight: var(--font-semibold);

  @media ${md} {
    ${h600}
  }

  @media ${lg} {
    ${h700}
  }
`;

export const heading800 = css`
  ${h600}
  font-weight: var(--font-medium);

  @media ${md} {
    ${h750}
  }

  @media ${lg} {
    ${h800}
  }
`;

export const heading900 = css`
  ${h700}
  font-weight: var(--font-medium);

  @media ${md} {
    ${h800}
  }

  @media ${lg} {
    ${h900}
  }
`;

export const heading1000 = css`
  ${h800}
  font-weight: var(--font-medium);

  @media ${md} {
    ${h900}
  }

  @media ${lg} {
    ${h1000}
  }
`;
