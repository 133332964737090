import { css } from 'styled-components';
import { md, lg } from '~/ds/mixins/breakpoints';

export const Company = css`
  .two-column-text {
    @media ${md} {
      column-count: 2;
      column-gap: 60px;
    }
  }

  .pillar-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 460px;
    transform: scale(0.75);
    margin: -32px 0 32px 0;

    @media ${lg} {
      margin: 0 0 0 64px;
      transform: scale(1);
    }
  }

  .company-pillar {
    width: 164px;
    height: 328px;
    position: absolute;
    transition: transform 10s ease;

    &:nth-child(1) {
      transform: translate(0, calc(-84px + 100px));
    }
    &:nth-child(2) {
      transform: translate(-140px, 100px);
    }
    &:nth-child(3) {
      transform: translate(140px, 100px);
    }
    &:nth-child(4) {
      transform: translate(0, calc(84px + 100px));
    }
  }

  .inview--pillars {
    & .company-pillar:nth-child(1) {
      transform: translate(0, calc(-84px - 0px));
    }
    & .company-pillar:nth-child(2) {
      transform: translate(-140px, 80px);
    }
    & .company-pillar:nth-child(3) {
      transform: translate(140px, 40px);
    }
    & .company-pillar:nth-child(4) {
      transform: translate(0, calc(84px + 120px));
    }
  }

  .company-layer-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 460px;
    margin: 0;
    transform: scale(0.75);
    order: 9999;

    @media ${md} {
      order: -9999;
    }
    @media ${lg} {
      margin: 0 64px 0 0;
      transform: scale(1);
    }
  }

  .company-layer {
    width: 300px;
    height: 300px;
    position: absolute;
    transform: translate(-50%, -50%) rotateX(45deg) rotateZ(45deg);
    transform-style: preserve-3d;
    top: 50%;
    left: 50%;
    transition: transform 20s ease;

    &:nth-child(1) {
      transform: translate(-50%, -180%) rotateX(45deg) rotateZ(45deg);
      z-index: 2;
    }
    &:nth-child(2) {
      transform: translate(-50%, -20%) rotateX(45deg) rotateZ(45deg);
      z-index: 1;
    }
  }

  .company-side {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 20s ease;
    background: linear-gradient(160deg, rgb(var(--rgb-gray-50)), rgb(var(--rgb-gray-200)));

    &-top {
      background: linear-gradient(160deg, rgb(var(--rgb-gray-50)), rgb(var(--rgb-gray-200)));
    }
    &-left {
      top: 100%;
      transform: rotateX(-90deg) scale(1, 1);
      transform-origin: 150px 0;
      background: linear-gradient(90deg, rgb(var(--rgb-gray-50)), rgb(var(--rgb-gray-100)));
    }
    &-right {
      left: 100%;
      transform: rotateY(90deg) scale(1, 1);
      transform-origin: 0 150px;
      background: linear-gradient(0deg, rgb(var(--rgb-gray-300)), rgb(var(--rgb-gray-200)));
    }
  }

  .inview--layers {
    & .company-layer:nth-child(1) {
      transform: translate(-50%, -75%) rotateX(45deg) rotateZ(45deg);
    }
    & .company-layer:nth-child(2) {
      transform: translate(-50%, -45%) rotateX(45deg) rotateZ(45deg);
    }
    & .company-side-left {
      transform: rotateX(-90deg) scale(1, 0.25);
    }
    & .company-side-right {
      transform: rotateY(90deg) scale(0.25, 1);
    }
  }

  .company-intro-bg {
    &::before {
      content: '';
      inset: 0;
      position: absolute;
      opacity: 0.3;
      z-index: 0;
      background-image: radial-gradient(
        1000px 600px at top left,
        rgba(var(--rgb-blue-400), 1),
        rgba(var(--rgb-cyan-400), 0)
      );

      @media ${md} {
        border-radius: var(--radii-default);
      }
    }
  }

  .company-summary-bg {
    &::before {
      content: '';
      inset: 0;
      position: absolute;
      opacity: 0.3;
      z-index: 0;
      background-image: radial-gradient(
        1000px 600px at top right,
        rgba(var(--rgb-seafoam-400), 1),
        rgba(var(--rgb-green-400), 0)
      );

      @media ${md} {
        border-radius: var(--radii-default);
      }
    }
  }
`;
