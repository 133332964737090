import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconChangelog: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="0" width="14" height="16">
        <path
          d="M14 5H2a2 2 0 012-2h8a2 2 0 012 2zm-3-5H5a2 2 0 00-2 2h10a2 2 0 00-2-2zm2 6H3a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2V8a2 2 0 00-2-2z"
          fill="url(#16_Changelog__paint0_linear)"
          stroke="none"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path fill="url(#16_Changelog__paint1_linear)" d="M16 0H0v16h16V0z" />
      </g>
      <defs>
        <linearGradient
          id="16_Changelog__paint0_linear"
          x1="9.716"
          y1="12.712"
          x2="1.824"
          y2="-.979"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="16_Changelog__paint1_linear" x1="32" x2="0" y1="0" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-gradient-stop-1, #12161E)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
