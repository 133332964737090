import React from 'react';

const gb_postcode = '^[A-Z]{1,2}\d[A-Z0-9]? ?\d[A-Z]{2}$'

export const AddressFields = [
  {
    param: 'line_1',
    type: 'string',
    required: true,
    description: <p>Address line 1</p>,
  },
  {
    param: 'line_2',
    type: 'string',
    required: false,
    description: <p>Address line 2</p>,
  },
  {
    param: 'city',
    type: 'string',
    required: true,
    description: <p>City</p>,
  },
  {
    param: 'state',
    type: 'string',
    required: false,
    description: (
      <p>
        State name. For US addresses, this field is mandatory and only postal abbreviations (e.g. <code>AL, CA, DE, ...</code>) are allowed.
      </p>
    ),
  },
  {
    param: 'postal_code',
    type: 'string',
    required: false,
    description: <p>Postal code. The following postal code validations apply. CN: <code>^\d{6}$</code>, DE: <code>^\d{5}$</code>, FR: <code>^\d{5}$</code>, GB: <code>{gb_postcode}</code>, JP: <code>^\d{3}-\d{4}$</code>, US: <code>^\d{5}(?:-\d{4})?$</code></p>,
  },
  {
    param: 'country_code',
    type: 'string',
    required: true,
    description: (
      <p>
        ISO 3166-1 Alpha-2 Country Code (e.g., <code>US, FR, UK, DE, ...</code>).
      </p>
    ),
  },
];

export const AddressObject = [
  {
    param: 'address',
    type: 'object',
    required: true,
    description: (<p>
      Addresses need to adhere to character validation, as addresses are used across multiple payment rails. Characters are validated according to the <a href="/docs/wire#valid-characters-permitted">Fedwire character validation</a>.
    </p>),
    object: [...AddressFields],
  },
];
