import styled from 'styled-components';

export const MarkdownHeadline = styled.h2`
  font-size: var(--text-600) !important;
  line-height: var(--lh-h-600);
  letter-spacing: var(--letter-spacing-2);
  font-weight: var(--font-semibold);
  color: rgba(var(--rgb-blue-900), 1);
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: var(--space-2) !important;
`;
