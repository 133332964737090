import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/debt-financing/_hero';
import { SectionOverview } from './products/debt-financing/_overview';
import { SectionDay1 } from './products/debt-financing/_day-1';
import { SectionWarehouseFinancing } from './products/debt-financing/_warehouse-financing';
import { SectionCloser } from './index/_closer';
import { Layout } from '~/components/Layout';
import { AllProducts } from '~/components/AllProducts';
import { Testimonials } from '~/components/Testimonials';
import { Footer } from '~/components/Footer';

const DebtFinancingPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Debt Financing — Column</title>
        <meta property="og:title" content="Column - Debt Financing" />
        <meta
          name="description"
          content="Grow your lending program more efficiently with Column capital, whether you are at scale or on your way."
        />
      </Helmet>
      <SectionHero />
      <SectionOverview />
      <SectionDay1 />
      <SectionWarehouseFinancing />
      <Testimonials />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />
      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default DebtFinancingPage;
