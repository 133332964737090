import { css } from 'styled-components';

export const ScreenTokens = css`
  :root {
    --screen-nav: ${({ theme }) => theme.screens.nav};
    --screen-sm: ${({ theme }) => theme.screens.sm};
    --screen-md: ${({ theme }) => theme.screens.md};
    --screen-lg: ${({ theme }) => theme.screens.lg};
    --screen-xl: ${({ theme }) => theme.screens.xl};
    --screen-xxl: ${({ theme }) => theme.screens.xxl};
  }
`;
