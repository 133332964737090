import React, { CSSProperties, Children, ElementType } from 'react';
import classNames from 'classnames';
import { Icon } from './Icon';
import { PaletteProps, getPaletteClassnames } from '~/utils/getPaletteClassnames';

export interface ListStyleProps extends PaletteProps {
  as?: 'ul' | 'ol';
  bullet?: 'none' | 'diamond' | 'numbered';
  size?: 'inherit' | '100' | '200' | '300' | '400' | '500' | '600';
  align?: 'inherit' | 'left' | 'right' | 'center';
  inverted?: boolean;
}

export interface ListProps extends ListStyleProps {
  className?: string;
  style?: CSSProperties;
}

export const List: React.FC<ListProps> = (props) => {
  const styleProps: ListStyleProps = {
    as: props.as ?? 'ul',
    bullet: props.bullet ?? 'none',
    size: props.size ?? '300',
    align: props.align ?? 'inherit',
    palette: props.palette ?? 'inherit',
    inverted: props.inverted ?? false,
  };

  let conditionalClasses: ConditionalClasses = {
    'list-none': styleProps.bullet === 'none',
    'list-diamond': styleProps.bullet === 'diamond',

    'text-left': styleProps.align === 'left',
    'text-center': styleProps.align === 'center',
    'text-right': styleProps.align === 'right',

    'list-100': styleProps.size === '100',
    'list-200': styleProps.size === '200',
    'list-300': styleProps.size === '300',
    'list-400': styleProps.size === '400',
    'list-500': styleProps.size === '500',
    'list-600': styleProps.size === '600',

    ...getPaletteClassnames(styleProps.palette),
  };

  if (styleProps.inverted === true) {
    conditionalClasses = getPaletteClassnames(styleProps.palette, conditionalClasses, true);
  }

  const Element = styleProps.as as ElementType;

  return (
    <Element className={classNames([props.className], conditionalClasses)} style={props.style}>
      {Children.map(props.children, (child: any) => {
        if (!child) {
          return null;
        }

        const Component = child.type;

        return (
          <Component {...child.props}>
            {props.bullet === 'diamond' && <Icon.Diamond />}
            {child.props.children}
          </Component>
        );
      })}
    </Element>
  );
};
