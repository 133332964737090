import { css } from 'styled-components';

export const Purple = css`
  .color-palette--purple {
    /* Primary */
    --btn-text-color-primary: var(--color-0);

    --btn-bg-primary: var(--color-purple-600);
    --btn-bg-primary-hover: var(--color-purple-700);
    --btn-bg-primary-active: var(--color-purple-800);

    --btn-border-primary: var(--color-purple-700);
    --btn-border-primary-hover: var(--color-purple-800);
    --btn-border-primary-active: var(--color-purple-900);

    --btn-focus-ring-primary: var(--color-purple-200);

    /* Secondary */
    --btn-text-color-secondary: var(--color-purple-700);

    --btn-bg-secondary: var(--color-purple-100);
    --btn-bg-secondary-hover: var(--color-purple-100);
    --btn-bg-secondary-active: var(--color-purple-100);

    --btn-border-secondary: var(--color-purple-200);
    --btn-border-secondary-hover: var(--color-purple-300);
    --btn-border-secondary-active: var(--color-purple-300);

    --btn-focus-ring-secondary: var(--color-purple-200);
  }

  .color-palette-inverted--purple {
    /* Primary */
    --btn-text-color-primary: var(--color-purple-900);

    --btn-bg-primary: var(--color-0);
    --btn-bg-primary-hover: var(--color-0);
    --btn-bg-primary-active: var(--color-0);

    --btn-border-primary: var(--color-purple-800);
    --btn-border-primary-hover: rgba(var(--rgb-purple-900), 1);
    --btn-border-primary-active: rgba(var(--rgb-purple-900), 1);

    --btn-focus-ring-primary: var(--color-purple-500);

    /* Secondary */
    --btn-text-color-secondary: var(--color-0);

    --btn-bg-secondary: rgba(var(--rgb-purple-600), 0.75);
    --btn-bg-secondary-hover: var(--color-purple-600);
    --btn-bg-secondary-active: var(--color-purple-700);

    --btn-border-secondary: rgba(var(--rgb-purple-800));
    --btn-border-secondary-hover: var(--color-purple-900);
    --btn-border-secondary-active: var(--color-purple-900);

    --btn-focus-ring-secondary: var(--color-purple-500);
  }
`;
