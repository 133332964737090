import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/fednow/_hero';
import { SectionSignup } from './products/fednow/_signup';
import { SectionSignupBottom } from './products/fednow/_signup-bottom';
import { SectionBoth } from './products/fednow/_both';
import { SectionDev } from './products/fednow/_dev';
import { SectionUseCases } from './products/fednow/_use-cases';
import { AllProducts } from './products/fednow/_all-products';
import { Layout } from '~/components/Layout';
import { Footer } from '~/components/Footer';
import HeroBackground from '~/assets/svg/fednow/woo-hero.svg';
import LowerBackground from '~/assets/svg/fednow/woo-lower.svg';
import BottomBackground from '~/assets/svg/fednow/woo-bottom.svg';
import { sm } from '~/ds/mixins/breakpoints';

const StyledHeroBackground = styled(HeroBackground)`
  position: absolute;
  width: 3943px;
  top: -280px;
  left: 50%;
  transform: translate(-46%, -14%) scale(0.5);

  @media ${sm} {
    transform: translateX(-40%);
  }
`;

const StyledLowerBackground = styled(LowerBackground)`
  position: absolute;
  width: 2678px;
  top: 0;
  left: 50%;
  transform: translate(-45%, 20%) scale(0.5);

  @media ${sm} {
    transform: translateX(-58%);
  }
`;

const StyledBottomBackground = styled(BottomBackground)`
  position: absolute;
  width: 2341px;
  top: 1500px;
  left: 50%;
  transform: translate(-50%, 40%) scale(0.5);

  @media ${sm} {
    transform: translateX(-50%);
  }
`;

const HeroCrop = styled.div`
  position: relative;
  overflow: hidden;
  margin: -80px 0 0 0;
  padding: 80px 0 0 0;
`;

const Crop = styled.div`
  position: relative;
  overflow: hidden;
`;

const FedNowPage: React.FC = () => {
  return (
    <Layout noFooter bg="#EEF7F9">
      <HeroCrop>
        <Helmet>
          <title>FedNow — Column</title>
          <meta property="og:title" content="Column - FedNow" />
          <meta property="og:image" content="https://column.com/images/social/column-og-fednow.png" />
          <meta
            name="description"
            content="FedNow, the newest payment rail from the Federal Reserve, is the best of both worlds: fast like Wire, flexible and affordable like ACH."
          />
        </Helmet>
        <SectionHero />
        <SectionSignup />
        <SectionBoth />
        <StyledHeroBackground />
      </HeroCrop>
      <Crop>
        <SectionDev />
        <SectionUseCases />
        <StyledLowerBackground />
        <SectionSignupBottom />
        <AllProducts />
        <StyledBottomBackground />
      </Crop>
      <Footer />
    </Layout>
  );
};

export default FedNowPage;
