import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Icon } from '~/elements/Icon';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
`;

export const SectionUseCases: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }}>
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 5 }}>
              <Flex flexDirection="column" justifyContent="space-between" height="100%">
                <Column>
                  <Heading as="h2" weight="semibold" size="700">
                    Facilitate payments internationally
                  </Heading>
                  <Text size="300" mt={4} color="gray-600">
                    Leverage Column's infrastructure to help your customers send funds abroad — all via API and easily
                    reconciled with Column's ledger.
                  </Text>
                  <Flex gap={2} mt={8}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <div>
                      <Heading as="h3" size="300" weight="medium">
                        International Vendor and Supplier Payments
                      </Heading>
                    </div>
                  </Flex>

                  <Flex gap={2} mt={4}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <div>
                      <Heading as="h3" size="300" weight="medium">
                        Global Payroll
                      </Heading>
                    </div>
                  </Flex>

                  <Flex gap={2} mt={4}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <div>
                      <Heading as="h3" size="300" weight="medium">
                        Cross-border Remittance
                      </Heading>
                    </div>
                  </Flex>
                </Column>
              </Flex>
            </Column>
            <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 0, md: 7, lg: 7 }}>
              <Code title="Initiate the international wire transfer" maxHeight="auto">
                {`curl 'https://api.column.com/transfers/international-wire' \\
 -XPOST \\
 -u :<YOUR API KEY> \\
 -d description="Example wire transfer" \\
 -d message_to_beneficiary_bank="please contact with the beneficiary before releasing the funds" \\
 -d currency_code=CNY \\
 -d amount=10000 \\
 -d bank_account_id="<bank_account_id>" \\
 -d counterparty_id="<counterparty_id>"`}
              </Code>
              <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1, lg: 2 }} gap={6} mt={5}>
                <Column>
                  <Flex gap={2}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <div>
                      <Heading as="h3" size="200" weight="medium">
                        Simplicty
                      </Heading>
                      <Text size="100" color="gray-500" mt={1}>
                        Programmatically send international wire transfers with one API call.
                      </Text>
                    </div>
                  </Flex>
                </Column>
                <Column>
                  <Flex gap={2}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <div>
                      <Heading as="h3" size="200" weight="medium">
                        Transparency
                      </Heading>
                      <Text size="100" color="gray-500" mt={1}>
                        See your FX rate before you send a transfer.
                      </Text>
                    </div>
                  </Flex>
                </Column>
              </Grid>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionUseCases;
