import React from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Box } from '~/elements/Box';
import { Typing } from '~/elements/Typing';

import StackFintech from '~/assets/svg/index/stack-fintech.svg';
import StackMiddleware from '~/assets/svg/index/stack-middleware.svg';
import StackCore from '~/assets/svg/index/stack-core.svg';
import StackBank from '~/assets/svg/index/stack-bank.svg';
import StackFed from '~/assets/svg/index/stack-fed.svg';
import StackColumn from '~/assets/svg/index/stack-column.svg';
import StillStackBefore from '~/assets/svg/index/stack-before-mobile.svg';
import StillStackColumn from '~/assets/svg/index/stack-column-mobile.svg';

export const SectionAdvantage: React.FC = () => {
  const [stackRef, stackInView] = useInView({
    threshold: 1,
  });
  const [columnRef, columnInView] = useInView({
    threshold: 1,
    rootMargin: '100% 0% -10%',
  });

  const stackClasses: ConditionalClasses = {
    'stack-start': !stackInView && !columnInView,
    'stack-traditional': stackInView && !columnInView,
    'stack-column': columnInView,
  };

  return (
    <Section palette="primary">
      <SectionBox bg="blue-50" pb="0" pattern patternShade="light" className="advantage-glow" noOverflow>
        <Container className="relative z-10">
          <div className="grid grid-cols-12">
            <div className="col-span-8 md:col-span-6">
              <Heading as="h4" size="300" eyebrow>
                Our advantage
              </Heading>

              <Heading as="h2" size="900" mt={5} weight="semibold">
                Financial services a
                <Typing text="s code" />
              </Heading>
            </div>
          </div>

          <div className="grid grid-cols-12 md:gap-8">
            <div className="col-span-12 md:col-span-5 lg:col-span-5">
              <div className="pt-24 pb-24 md:pb-64">
                <Box pr={10}>
                  <div ref={stackRef}>
                    <Text weight="medium" size="400" color="gray-900">
                      Before Column
                      <Text as="span" color="gray-500">
                        , new financial companies had to work through expensive middleware providers that wrapped legacy
                        sponsor banks. These relied upon outdated and limiting core systems.
                      </Text>
                    </Text>
                  </div>
                </Box>

                <div className="flex items-center justify-center w-full mt-8 md:hidden">
                  <StillStackBefore className="w-full max-w-lg" />
                </div>
              </div>

              <div className="pb-24 md:pb-32">
                <Box pr={10}>
                  <div ref={columnRef}>
                    <Text weight="medium" size="400" color="gray-900">
                      Column{' '}
                      <Text as="span" color="gray-500">
                        allows you to get to the bare metal. We are a bank made for developers and we have built our own
                        core, ledger, and data model for the purpose of powering your unique use case, at any scale.
                      </Text>
                    </Text>
                  </div>
                </Box>

                <div className="flex items-center justify-center w-full mt-8 md:hidden">
                  <StillStackColumn className="w-full max-w-lg" />
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="advantage-sticky-wrap">
          <div className="advantage-sticky-frame">
            <div className="relative flex items-center justify-center w-1/2 pr-8">
              <div className={classNames(stackClasses)}>
                <div className="stack">
                  <div className="stack-layer-fintech">
                    <div className="stack-label">
                      <Heading as="h4" size="200" weight="book" className="stack-label-text">
                        Company
                      </Heading>
                    </div>
                    <div className="stack-layer-disk">
                      <StackFintech className="stack-layer-disk" />
                    </div>
                  </div>
                  <div className="stack-layer-group">
                    <div className="stack-layer-middleware">
                      <div className="stack-label">
                        <Heading as="h4" size="200" weight="book" className="stack-label-text">
                          Middleware Providers
                        </Heading>
                      </div>
                      <div className="stack-layer-disk">
                        <StackMiddleware />
                      </div>
                    </div>

                    <div className="stack-layer-core">
                      <div className="stack-label">
                        <Heading as="h4" size="200" weight="book" className="stack-label-text">
                          Core Systems
                        </Heading>
                      </div>
                      <div className="stack-layer-disk">
                        <StackCore />
                      </div>
                    </div>

                    <div className="stack-layer-bank">
                      <div className="stack-label">
                        <Heading as="h4" size="200" weight="book" className="stack-label-text">
                          Sponsor Bank
                        </Heading>
                      </div>
                      <div className="stack-layer-disk">
                        <StackBank />
                      </div>
                    </div>
                  </div>

                  <div className="stack-layer-column">
                    <div className="stack-label">
                      <Heading as="h4" size="200" weight="book" className="stack-label-text">
                        Column
                      </Heading>
                    </div>
                    <div className="stack-layer-disk">
                      <StackColumn />
                    </div>
                  </div>

                  <div className="stack-layer-fed">
                    <div className="stack-label">
                      <Heading as="h4" size="200" weight="book" className="stack-label-text">
                        Federal Reserve
                      </Heading>
                    </div>
                    <div className="stack-layer-disk">
                      <StackFed />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionBox>
    </Section>
  );
};

export default SectionAdvantage;
