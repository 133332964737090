import { css } from 'styled-components';

export const Blue = css`
  .color-palette--blue {
    --list-item-bullet: var(--color-blue-900);
    --list-item-number: var(--color-blue-900);
  }

  .color-palette-inverted--blue {
    --list-item-bullet: var(--color-blue-600);
    --list-item-number: var(--color-blue-600);
  }
`;
