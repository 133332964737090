import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Icon } from '~/elements/Icon';
import { Container } from '~/elements/Container';
import { Button } from '~/elements/Button';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Box } from '~/elements/Box';

const Point = styled.li`
  display: flex;
  gap: var(--space-2);
  flex-direction: column;
  color: var(--color-seafoam-600);
  align-items: center;
  justify-content: center;
  padding: var(--space-4) var(--space-6);
  background: rgba(var(--rgb-white), 0.3);
  border-radius: 8px;
  box-shadow: var(--shadow-product-border);
  height: 120px;
  text-align: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SectionDay1: React.FC = () => {
  return (
    <Section>
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column gridColumn={{ _: 12, md: 8, nav: 6 }}>
            <Flex flexDirection="column">
              <Heading as="h2" weight="semibold" size="700">
                Day 1 Line
              </Heading>
              <Text size="300" color="rgba(var(--rgb-black), 0.6)" mt={2}>
                We can support your very first loan. Get a commitment within days to launch your lending program. No
                exorbitant interest rates, hidden fees, or complex legal jargon.
              </Text>
              <div>
                <Button to="https://dashboard.column.com/contact" fontSize="16px" variant="link" mt={4}>
                  Contact us
                  <Icon.AnimatedArrowRight />
                </Button>
              </div>
            </Flex>
          </Column>

          <Column gridColumn={{ _: 12, nav: 10, lg: 7 }} mt={4}>
            <Grid gridTemplateColumns={{ _: 1, md: 2 }} gap={6}>
              <Column>
                <Heading as="h3" size="300" weight="medium">
                  What is Day 1 line?
                </Heading>

                <Flex as="ul" gapY={4} flexDirection="column" mt={4}>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Committed funding in days
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Lower upfront fees
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Clear terms
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Draw only what you need
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Minimal financial covenants for maximum freedom
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Column>

              <Column>
                <Heading as="h3" size="300" weight="medium">
                  Who is a Day 1 line for?
                </Heading>
                <Flex as="ul" gapY={4} flexDirection="column" mt={4}>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Companies that have just started originating and are building a credit program
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Companies that don't have a long track record originating
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} alignItems="baseline" color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Want to close financing on a compressed timeline
                      </Text>
                    </Box>
                  </Flex>
                  <Flex as="li" gap={2} color="blue-500">
                    <Icon.CircleCheck />
                    <Box position="relative" top="-3px">
                      <Text size="200" color="rgba(var(--rgb-black), 0.6)">
                        Small teams looking for minimal operational overhead
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionDay1;
