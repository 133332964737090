import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductBankAccounts: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="3" width="30" height="26">
        <path
          d="M28.09 21l-10 4-15-6 3-1.2V19c0 .55.9 1 2 1s2-.45 2-1v-2.8l1-.4V21c0 .55.9 1 2 1s2-.45 2-1v-4.95l1 .4V23c0 .55.9 1 2 1s2-.45 2-1v-5.2l1 .4V21c0 .55.9 1 2 1s2-.45 2-1v-1.2l3 1.2z"
          fill="url(#32_deposits-and-bank-accounts-dark__paint0_linear)"
        />
        <path d="M30.09 24l-12 4.8V25l10-4 2 3z" fill="url(#32_deposits-and-bank-accounts-dark__paint1_linear)" />
        <path
          d="M27.09 11.49v2l-9.2 3.68v-2l9.2-3.68z"
          fill="url(#32_deposits-and-bank-accounts-dark__paint2_linear)"
        />
        <path
          d="M27.09 11.49l-9.2 3.68-6.9-8.49L20.19 3l6.9 8.49z"
          fill="url(#32_deposits-and-bank-accounts-dark__paint3_linear)"
        />
        <path
          d="M20.09 16.29V23c0 .55-.9 1-2 1s-2-.45-2-1v-6.55l1.8.72.2-.08 2-.8z"
          fill="url(#32_deposits-and-bank-accounts-dark__paint4_linear)"
        />
        <path
          d="M25.09 14.29V21c0 .55-.9 1-2 1s-2-.45-2-1v-5.11l2-.8 2-.8z"
          fill="url(#32_deposits-and-bank-accounts-dark__paint5_linear)"
        />
        <path
          d="M10.09 14.05V19c0 .55-.9 1-2 1s-2-.45-2-1v-6.55l4 1.6z"
          fill="url(#32_deposits-and-bank-accounts-dark__paint6_linear)"
        />
        <path d="M18.09 25v3.8l-17-6.8 2-3 15 6z" fill="url(#32_deposits-and-bank-accounts-dark__paint7_linear)" />
        <path
          d="M15.09 16.05V21c0 .55-.9 1-2 1s-2-.45-2-1v-6.55l1.69.68.31.12 2 .8z"
          fill="url(#32_deposits-and-bank-accounts-dark__paint8_linear)"
        />
        <path
          d="M17.89 15.17v2l-13.8-5.52v-2l6.9-2.97 6.9 8.49z"
          fill="url(#32_deposits-and-bank-accounts-dark__paint9_linear)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#32_deposits-and-bank-accounts-dark__paint10_linear)" />
      </g>
      <defs>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint0_linear"
          x1="16.073"
          y1="16.893"
          x2="13.537"
          y2="33.201"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint1_linear"
          x1="17.148"
          y1="26.276"
          x2="41.671"
          y2="20.598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint2_linear"
          x1="16.871"
          y1="15.489"
          x2="40.307"
          y2="10.656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint3_linear"
          x1="13.62"
          y1="10.638"
          x2="32.587"
          y2="5.202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint4_linear"
          x1="16.09"
          y1="20.145"
          x2="20.09"
          y2="20.145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity=".2" />
          <stop offset=".251" />
          <stop offset=".606" stopOpacity=".4" />
          <stop offset="1" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint5_linear"
          x1="21.09"
          y1="18.145"
          x2="25.09"
          y2="18.145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity=".2" />
          <stop offset=".251" />
          <stop offset=".606" stopOpacity=".4" />
          <stop offset="1" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint6_linear"
          x1="6.09"
          y1="16.225"
          x2="10.09"
          y2="16.225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity=".2" />
          <stop offset=".251" />
          <stop offset=".606" stopOpacity=".4" />
          <stop offset="1" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint7_linear"
          x1="15.677"
          y1="27.608"
          x2="-4.014"
          y2="14.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint8_linear"
          x1="11.09"
          y1="18.225"
          x2="15.09"
          y2="18.225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity=".2" />
          <stop offset=".251" />
          <stop offset=".606" stopOpacity=".4" />
          <stop offset="1" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint9_linear"
          x1="15.319"
          y1="12.815"
          x2="-.386"
          y2="5.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_deposits-and-bank-accounts-dark__paint10_linear"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
