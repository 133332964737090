import React from 'react';

export const returnDetails = [
  {
    param: 'addenda',
    type: 'string',
    description: <p>Optional information provided about the ACH return.</p>,
  },
  {
    param: 'created_at',
    type: 'string',
    description: <p>The timestamp at which the ACH return is created.</p>,
  },
  {
    param: 'description',
    type: 'string',
    description: <p>Description of the ACH return.</p>,
  },
  {
    param: 'return_code',
    type: 'string',
    description: <p>Reason for the ACH return.</p>,
  },
  {
    param: 'status',
    type: 'string',
    description: <p>Status of the ACH return.</p>,
  },
  {
    param: 'updated_at',
    type: 'string',
    description: <p>The timestamp at which the ACH return is updated.</p>,
  },
];

export const returnDetailsObject = [
  {
    param: 'details',
    type: 'array of objects',
    required: true,
    description: <p>Includes an object containing detailed information about the return.</p>,
    object: [...returnDetails],
  },
];