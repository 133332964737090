import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconHamburger: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <line x1="1.75" y1="2.75" x2="14.25" y2="2.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="1.75" y1="7.75" x2="14.25" y2="7.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="1.75" y1="12.75" x2="14.25" y2="12.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </IconBase>
  );
};
