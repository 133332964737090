import { css } from 'styled-components';

export const Cyan = css`
  .color-palette--cyan {
    --text-color-subheading: var(--color-gray-900);
    --text-color-heading: var(--color-gray-900);
    --text-color-body: var(--color-gray-600);

    --text-color-duotone-primary: var(--color-gray-900);
    --text-color-duotone-secondary: var(--color-gray-500);
  }

  .color-palette-inverted--cyan {
    --text-color-subheading: var(--color-0);
    --text-color-heading: var(--color-0);
    --text-color-body: rgba(var(--rgb-0), 0.6);

    --text-color-duotone-primary: var(--color-0);
    --text-color-duotone-secondary: rgba(var(--rgb-0), 0.5);
  }
`;
