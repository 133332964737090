import React from 'react';
import { AddressObject } from './AddressObject';
import { BeneficialOwner } from './BeneficialOwner';
import { RegistrationID } from './RegistrationID';

export const businessDetails = [
  ...AddressObject,
  ...BeneficialOwner,
  {
    param: 'business_name',
    type: 'string',
    required: true,
    description: <p>Legal Business Name</p>,
  },
  {
    param: 'ein',
    type: 'string',
    required: true,
    description: <p>Employer Identification Number (Tax ID). This may be SSN for a sole proprietorship.</p>,
  },
  {
    param: 'registration_id',
    type: 'object',
    required: false,
    description: <p>Registration ID. EIN or Registration ID is required.</p>,
    object: [...RegistrationID],
  },
  {
    param: 'industry',
    type: 'string',
    required: false,
    description: <p>Industry in which the business entity operates.</p>,
  },
  {
    param: 'website',
    type: 'string',
    required: false,
    description: (
      <p>
        Website of the business. Optional, but highly encouraged as it will increase likelihood of an automated
        verification.
      </p>
    ),
  },
  {
    param: 'legal_type',
    type: 'string',
    required: false,
    description: (
      <p>
        Type of business. Permitted values are <code>limited-partnership</code>, <code>trust</code>,{' '}
        <code>sole-proprietorship</code>, <code>corporation</code>,<code>llc</code>, <code>general-partnership</code>,{' '}
        <code>professional-association</code>,<code>government</code>,<code>non-profit</code>, <code>other</code>.
      </p>
    ),
  },
  {
    param: 'state_of_incorporation',
    type: 'string',
    required: false,
    description: (
      <p>
        State in which the business is incorporated. Only postal abbreviations (e.g. AL, CA, DE, ...) are allowed. Only
        required for a root entity.{' '}
      </p>
    ),
  },
  {
    param: 'year_of_incorporation',
    type: 'string',
    required: false,
    description: <p>Year in which the business entity was incorporated. Only required for a root entity. </p>,
  },
  {
    param: 'account_usage',
    type: 'array of strings',
    required: false,
    description: (
      <p>Indicates possible uses of the accounts an entity may use at Column. Only required for a root entity.</p>
    ),
  },
  {
    param: 'description',
    type: 'string',
    required: false,
    description: <p>Description of the business entity. Only required for a root entity.</p>,
  },
  {
    param: 'payment_volumes',
    type: 'string',
    required: false,
    description: <p>Expected payment volumes. Only required for a root entity.</p>,
  },
  {
    param: 'countries_of_operation',
    type: 'array of strings',
    required: false,
    description: (
      <p>
        Countries in which the business currently operates or expects to operate. Only ISO 3166-1 Alpha-2 Country Codes
        (e.g., <code>US, FR, UK, DE, ...</code>) are allowed. Only required for a root entity.
      </p>
    ),
  },
];

export const businessDetailsObject = [
  {
    param: 'business_details',
    type: 'object',
    required: true,
    description: (
      <p>
        Contains all business details of the legal business entity. Only returned when entity type is{' '}
        <code>BUSINESS</code>.{' '}
      </p>
    ),
    object: [...businessDetails],
  },
];
