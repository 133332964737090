import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  width,
  height,
  MinWidthProps,
  MinHeightProps,
  MaxWidthProps,
  MaxHeightProps,
  WidthProps,
  HeightProps,
  SpaceProps,
} from 'styled-system';

export interface ProductScreenProps
  extends SpaceProps,
    WidthProps,
    HeightProps,
    MinWidthProps,
    MinHeightProps,
    MaxWidthProps,
    MaxHeightProps {
  backgroundColor?: string;
  className?: string;
}

const Wrapper = styled.div<ProductScreenProps>`
  border-radius: 8px;
  position: relative;

  &:before {
    content: '';
    border-radius: 0%;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
    filter: blur(30px);
    opacity: 0.5;
    position: absolute;
    --pattern-offset: -1%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
  }

  &:after {
    border-radius: 0%;
    content: '';
    background-color: ${(props) => props.backgroundColor || '#F6F7FA'};
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -30%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
  }

  ${compose(space, minWidth, width, maxWidth, minHeight, height, maxHeight)};
`;

const ScreenWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-shadow: var(--shadow-product-border), var(--shadow-product);
`;

export const ProductScreen: React.FC<PropsWithChildren<ProductScreenProps>> = (props) => {
  return (
    <Wrapper className={props.className} {...props}>
      <ScreenWrapper {...props}>{props.children}</ScreenWrapper>
    </Wrapper>
  );
};
