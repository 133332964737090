import React from 'react';

export const LoanBalanceObject = [
  {
    param: 'balances',
    type: 'object',
    required: true,
    object: [
      {
        param: 'principal_charged_off',
        type: 'string',
        required: true,
        description: <p>The balance of the loan which has been charged off.</p>,
      },
      {
        param: 'principal_holding',
        type: 'string',
        required: false,
        description: (
          <p>
            The balance of the loan which is in a <code>holding</code> state.
          </p>
        ),
      },
      {
        param: 'principal_outstanding',
        type: 'string',
        required: true,
        description: <p>The balance of the loan which is outstanding.</p>,
      },
      {
        param: 'principal_paid',
        type: 'string',
        required: true,
        description: <p>The balance of the loan which has been paid.</p>,
      },
    ],
  },
];
