import { css } from 'styled-components';

export const Syntax = css`
  code[class*='language-'],
  pre[class*='language-'] {
    color: #f8f8f2;
    background: none;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  /* Code blocks */
  pre[class*='language-'] {
    padding: var(--space-4);
    overflow: auto;
    border-radius: 0.3em;
    margin: 24px 0 !important;
  }

  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    border-radius: var(--space-3);
    background: rgba(var(--rgb-blue-800), 1);
    box-shadow: var(--shadow-product);
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: rgba(var(--rgb-blue-200), 1);
  }

  .token.punctuation {
    color: rgba(var(--rgb-text-code), 1);
  }

  .namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: rgba(var(--rgb-blue-200), 1);
  }

  .token.boolean,
  .token.number {
    color: rgba(var(--rgb-seafoam-300), 1);
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: rgba(var(--rgb-orange-300), 1);
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string,
  .token.variable {
    color: rgba(var(--rgb-cyan-300), 1);
  }

  .token.atrule,
  .token.attr-value,
  .token.function,
  .token.class-name {
    color: rgba(var(--rgb-purple-300), 1);
  }

  .token.keyword {
    color: rgba(var(--rgb-red-300), 1);
  }

  .token.regex,
  .token.important {
    color: rgba(var(--rgb-orange-400), 1);
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  pre[class*='language-'].line-numbers {
    position: relative;
    padding-left: 3.6em;
    counter-reset: linenumber;
  }

  pre[class*='language-'].line-numbers > code {
    position: relative;
    white-space: inherit;
  }

  .line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    padding: 1rem 1rem 1.25rem;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .line-numbers-rows > span {
    display: block;
    counter-increment: linenumber;
  }

  .line-numbers-rows > span:before {
    content: counter(linenumber);
    color: rgba(var(--rgb-white), 0.4);
    display: block;
    padding-right: 0.8em;
    text-align: right;
  }
`;
