import React from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

export const SectionPointsTitleEscrow: React.FC = () => {
  return (
    <Container py={18}>
      <Grid gridTemplateColumns={12} position="relative" justifyContent={{ _: 'center', nav: 'start' }} gap={8}>
        <Column gridColumn={{ _: 12, nav: 4 }}>
          <Flex mb={{ _: 2, lg: 6 }} alignItems="center" minHeight="100px">
            <Column>
              <Heading as="span" size="600" color="blue-700" weight="semibold">
                $150M
              </Heading>
              <Text size="200" color="blue-700" weight="semibold">
                FDIC Insurance
              </Text>
            </Column>
          </Flex>

          <Heading as="h3" size="400" color="blue-700" weight="semibold">
            Ultimate protection for you and your customers
          </Heading>
          <Text size="300" color="gray-600" mt={3}>
            Up to $150 million in FDIC insurance per account through our proprietary sweep network with full access to
            liquidity.
          </Text>
        </Column>

        <Column gridColumn={{ _: 12, nav: 4 }}>
          <Flex mb={{ _: 2, lg: 6 }} alignItems="center" minHeight="100px">
            <Flex flexDirection="column" gap={1}>
              <img src="/images/specialty-deposits/qualia.svg" />
            </Flex>
          </Flex>

          <Heading as="h3" size="400" color="blue-700" weight="semibold">
            Unparalleled connectivity
          </Heading>
          <Text size="300" color="gray-600" mt={3}>
            Technology-first connectivity and real-time integrations to your every-day software tools including Qualia.
            Our auto-reconciliation ensures you never have to spend time reconciling systems.
          </Text>
        </Column>

        <Column gridColumn={{ _: 12, nav: 4 }}>
          <Flex mb={{ _: 2, lg: 6 }} alignItems="center" minHeight="100px">
            <Flex gap={2}>
              <img src="/images/specialty-deposits/support.svg" />
              <img src="/images/specialty-deposits/support-chat.svg" />
            </Flex>
          </Flex>

          <Heading as="h3" size="400" color="blue-700" weight="semibold">
            Premium customer support
          </Heading>
          <Text size="300" color="gray-600" mt={3}>
            High-touch customer service with a dedicated team of customer engineers to address your needs and questions.
          </Text>
        </Column>
      </Grid>
    </Container>
  );
};
