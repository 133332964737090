import { css } from 'styled-components';

export const Blue = css`
  .color-palette--button {
    --icon-color: var(--color-blue-400);
  }

  .color-palette-inverted--button {
    --icon-color: var(--color-blue-300);
  }
`;
