import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Flex } from '~/elements/Flex';
import { Text } from '~/elements/NewText';
import { md, lg } from '~/ds/mixins/breakpoints';

const fadeInHalf = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: .5;
  }
`;

const Wrapper = styled.div`
  position: relative;
  --animation-curve: cubic-bezier(1, 0, 0.3, 1);
  margin-top: var(--space-16);
  transform: scale(0.8) translateX(10%);

  @media ${md} {
    transform: scale(0.9) translateX(32px);
  }

  @media ${lg} {
    margin: 0;
    transform: scale(1);
  }

  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(246, 247, 250, 1) 0%,
      rgba(246, 247, 250, 0) 90%,
      rgba(246, 247, 250, 1) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -80%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: -80%;
    left: var(--pattern-offset);
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    right: var(--glow-offset);
    --glow-offset: 0;
    top: var(--glow-offset);
    right: var(--glow-offset);
    bottom: var(--glow-offset);
    left: var(--glow-offset);
    position: absolute;
    background: linear-gradient(151.84deg, #f7d8da 9%, #7ea7e9 111%);
    filter: blur(30px);
    opacity: 0;
    z-index: 0;
    animation: 2.5s ${fadeInHalf} forwards;
  }

  img {
    max-width: 393px;
  }
`;

const readerFlyin = keyframes`
 0% {
  opacity: 0;
  transform: translate(180px, -90px);
  }
  50% {
  opacity: 1;
  }
 100% {
  opacity: 1;
  transform: translate(0)
  }
`;

const NFCReader = styled.div`
  position: relative;
  z-index: 2;
  opacity: 0;
  transform: translate(100px, -10px);
  animation: 0.75s ${readerFlyin} 0.5s forwards;
`;

const statusChange = keyframes`
 0% {
  opacity: 0;
  }

 100% {
  opacity: 1;
  }
`;

const NFCStatus = styled(Flex)`
  position: absolute;
  top: 51px;
  right: 90px;
  font-size: 8px;
  transform: rotate(30deg);

  div {
    opacity: 0;
  }

  div:nth-child(1) {
    animation: 0.2s ${statusChange} 1.5s forwards;
  }

  div:nth-child(2) {
    animation: 0.2s ${statusChange} 1.6s forwards;
  }

  div:nth-child(3) {
    animation: 0.2s ${statusChange} 1.7s forwards;
  }

  div:nth-child(4) {
    animation: 0.2s ${statusChange} 1.8s forwards;
  }
`;

const BlurWrapper = styled.div`
  filter: blur(4px);
  position: absolute;
  top: 62px;
  left: -95px;
  width: 393px;
  height: 238px;
  transform: rotate(-60deg);
`;

const cardShadow = keyframes`
 0% {
  background-position: 140px 0;
  opacity: 0;
  }

 100% {
  background-position: 380px 0;
  opacity: 1;
  }
`;

const CardShadow = styled.div`
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 7%,
    rgba(0, 0, 0, 0.3) 15%,
    rgba(0, 0, 0, 0.3) 25%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 50%
  );
  background-size: 200%;
  background-position: 140px 0;
  width: 393px;
  height: 238px;
  mask-image: url(/images/products/card-programs/card-shadow.svg);
  mask-size: 393px 238px;
  transform: scale(1.25);
  opacity: 0;
  animation: 0.75s ${cardShadow} 1s forwards;
`;

const cardFlyin = keyframes`
 0% {
  opacity: 0;
  transform: translate(-180px, 120px);
  }
  50% {
  opacity: 1;
  }
 100% {
  opacity: 1;
  transform: translate(0, 30px);
  }
`;

const Card = styled.img`
  position: absolute;
  top: 20px;
  left: -95px;
  opacity: 0;
  transform: translate(-180px, 120px);
  animation: 0.75s ${cardFlyin} 1s forwards;
`;

const StatusMessages = styled.div`
  position: absolute;
  bottom: 200px;
  z-index: 3;
`;

const messageEnter = keyframes`
 from {
  opacity: 0;
  transform: scale(.8) translateY(8px);
  }
 to {
  opacity: 1;
  transform: scale(1) translateY(0);
  }
`;

const messageMove1 = keyframes`
 from {
  opacity: 1;
  transform: scale(1) translateY(0);
  }
 to {
  opacity: 1;
  transform: scale(1) translateY(-44px);
  }
`;

const messageMove2 = keyframes`
 from {
  opacity: 1;
  transform: scale(1) translateY(-44px);
  }
 to {
  opacity: 1;
  transform: scale(1) translateY(-88px);
  }
`;

const Message = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  display: flex;
  white-space: nowrap;
  transform: translateY(8px);
  padding: 8px 12px;
  background: #fff;
  border-radius: 24px;
  gap: var(--space-2);
  box-shadow: 0 0 0 1px rgba(var(--rgb-black), 0.1);
  animation: var(--animation-sequence);
  --delayEnter: 2s;
  --delay1: 3s;
  --delay2: 4s;

  &:nth-child(1) {
    --animation-sequence: ${messageEnter} 0.5s var(--delayEnter) var(--animation-curve) forwards,
      ${messageMove1} 0.5s var(--delay1) var(--animation-curve) forwards,
      ${messageMove2} 0.5s var(--delay2) var(--animation-curve) forwards;
  }
  &:nth-child(2) {
    --animation-sequence: ${messageEnter} 0.5s var(--delay1) var(--animation-curve) forwards,
      ${messageMove1} 0.5s var(--delay2) var(--animation-curve) forwards;
  }

  &:nth-child(3) {
    --animation-sequence: ${messageEnter} 0.5s var(--delay2) var(--animation-curve) forwards;
  }
`;

export const Reader: React.FC = () => {
  return (
    <Wrapper>
      <StatusMessages>
        <Message>
          <Text size="100" color="gray-300" textTransform="uppercase" weight="medium">
            Step 1
          </Text>
          <Text size="100" color="gray-600" weight="book">
            Authorization
          </Text>
        </Message>
        <Message>
          <Text size="100" color="gray-300" textTransform="uppercase" weight="medium">
            Step 2
          </Text>
          <Text size="100" color="gray-600" weight="book">
            Funds disbursement
          </Text>
        </Message>
        <Message>
          <Text size="100" color="gray-300" textTransform="uppercase" weight="medium">
            Step 3
          </Text>
          <Text size="100" color="gray-600" weight="book">
            Settlement
          </Text>
        </Message>
      </StatusMessages>

      <NFCReader>
        <NFCStatus gap={1} color="green-400">
          <div>&bull;</div>
          <div>&bull;</div>
          <div>&bull;</div>
          <div>&bull;</div>
        </NFCStatus>
        <img src="/images/products/card-programs/nfc-reader.svg" />
        <BlurWrapper>
          <CardShadow />
        </BlurWrapper>
        <Card src="/images/products/card-programs/card.svg" />
      </NFCReader>
    </Wrapper>
  );
};
