import React from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Box } from '~/elements/Box';
import { Column } from '~/elements/Column';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Button } from '~/elements/Button';
import { Icon } from '~/elements/Icon';
import { Typing } from '~/elements/Typing';
import LogoPlaid from '~/assets/svg/logos/plaid.svg';
import LogoSquare from '~/assets/svg/logos/square.svg';
import LogoFacebook from '~/assets/svg/logos/facebook.svg';
import ScaleGraphicOne from '~/assets/svg/index/scale-combined-1.svg';
import ScaleGraphicTwo from '~/assets/svg/index/scale-combined-2.svg';

export const SectionPartners: React.FC = () => {
  const [partnersRef, partnersInView] = useInView({
    threshold: 0,
  });

  const partnersClasses: ConditionalClasses = {
    'inview--partners': partnersInView,
  };

  return (
    <Section palette="cyan" className={classNames(partnersClasses)}>
      <SectionBox overflow="hidden" p={0}>
        <Container className="relative z-10">
          <Grid gridTemplateColumns={12} ref={partnersRef}>
            <Column gridColumn={{ _: 12, md: 8 }}>
              <Heading as="h4" size="300" weight="semibold" eyebrow>
                Partners
              </Heading>

              <Heading as="h2" weight="semibold" size="900" mt={6}>
                Your partner at any s
                <Typing text="cale" />
              </Heading>

              <Box maxWidth="617px" mt={4}>
                <Text size="400">
                  As the backbone for the largest and most sophisticated fintechs and enterprises, we are built to scale
                  with anyone who needs speed, control, and to be as close to the Federal Reserve as possible.
                </Text>
              </Box>

              <Grid gridTemplateColumns={{ _: 1, md: 2 }} gap={8} mt={12}>
                <Column>
                  <Heading as="h3" size="400" weight="semibold">
                    Not our first rodeo
                  </Heading>
                  <Text size="300" mt={2}>
                    We previously founded and built companies like Plaid, Square and Affirm where we had to rely on
                    archaic banks and middleware providers. We built the bank we wish we had.
                  </Text>

                  <Flex alignItems="center" gap={6} mt={6}>
                    <LogoSquare className="h-6" />
                    <LogoPlaid className="h-6" />
                    <LogoFacebook className="h-6" />
                  </Flex>
                </Column>

                <Column>
                  <Heading as="h3" size="400" weight="semibold">
                    Our promise
                  </Heading>
                  <Text size="300" mt={2}>
                    Column is funded and owned by our founders and employees. A clear ownership structure and a long
                    term view enables us to be solely focused on building technology the right way and being an
                    incredible partner.
                  </Text>
                  <Button variant="link" to="/company" mt={6} fontSize="16px" color="">
                    About our company <Icon.AnimatedArrowRight />
                  </Button>
                </Column>
              </Grid>
            </Column>

            <Column
              gridColumn={{ _: 12, md: 6 }}
              gridColumnStart={{ md: 10 }}
              height={{ _: '192px', md: '384px' }}
              position="relative"
            >
              <ScaleGraphicOne />
              <ScaleGraphicTwo />
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionPartners;
