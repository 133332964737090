import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Code } from '~/elements/Code/Code';
import { sm, lg } from '~/ds/mixins/breakpoints';

const fadeInHalf = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: .5;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  max-width: 445px;
  transform: scale(0.7);
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  @media ${sm} {
    margin: 24px 0 0;
    transform: scale(0.9);
  }

  @media ${lg} {
    transform: scale(1);
  }

  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(246, 247, 250, 1) 0%,
      rgba(246, 247, 250, 0) 90%,
      rgba(246, 247, 250, 1) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -90%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: -80%;
    left: var(--pattern-offset);
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    top: -10%;
    right: -0%;
    bottom: 0%;
    left: -10%;
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
    filter: blur(30px);
    opacity: 0;
    z-index: 0;
    animation: 2.5s ${fadeInHalf} forwards;
  }
`;

const flyInCheck = keyframes`
 0% {
  transform: scale(.7) rotate(0deg);
  opacity: 0;
  }
 100% {
  transform: scale(1) rotate(-6deg);
  opacity: 1;
  }
`;

const flyInCode = keyframes`
 0% {
  transform: scale(.7) translate3d(0, 0, 0);
  opacity: 0;
  }
 100% {
  transform: scale(1) translate3d(0, -24px, 0);
  opacity: 1;
  }
`;

const Check = styled.img`
  position: relative;
  z-index: 3;
  border-radius: 6px;
  box-shadow: var(--shadow-product-border), var(--shadow-product);
  transform: rotate(-6deg);
  margin: 0;
  opacity: 0;
  animation: 0.5s var(--animation-curve) ${flyInCheck} 0.5s forwards;

  @media ${lg} {
    margin: 0 0 -24px -32px;
  }
`;

const CheckOutputWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: none;
  opacity: 0;
  transform: scale(0.7) translate3d(0, 0, 0);
  animation: 0.5s var(--animation-curve) ${flyInCode} 0.6s forwards;

  @media ${lg} {
    max-width: 430px;
  }
`;

export const CheckOutput: React.FC = () => {
  return (
    <Wrapper>
      <Check src="/images/products/checks/check.svg" />
      <CheckOutputWrapper>
        <Code copy={false}>
          {`{
  "account_number_id": "acno_25nacNsLD8qLI1Vc6x67sxFU27c",
  "allow_overdraft": false,
  "amount": "150",
  "bank_account_id": "bacc_25nVQr05nZybpyEzw8j0wV6VRUh",
  "beneficiary_account_number": "256783259046169",
  "beneficiary_name": "Cash",
}`}
        </Code>
      </CheckOutputWrapper>
    </Wrapper>
  );
};
