import React, { ElementType } from 'react';
import { Link as InternalLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

export const Link: React.FC<any> = (props) => {
  const to = props.href ?? props.to ?? '';
  const external = to.includes('https://');
  const Element = (external ? OutboundLink : InternalLink) as ElementType;

  const newProps = { ...props };
  delete newProps.href;
  delete newProps.to;

  return (
    <Element {...newProps} to={!external ? to : undefined} href={external ? to : undefined}>
      {props.children}
    </Element>
  );
};
