import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';

export const SectionAccuracy: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }}>
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 6 }} position="relative" zIndex={2}>
              <Grid gridTemplateColumns={6} gapX={7} gapY={8}>
                <Column gridColumn={{ _: 6, md: 5 }}>
                  <Heading as="h2" weight="semibold" size="700">
                    Real-time accuracy
                  </Heading>
                  <Text size="300" color="gray-500" mt={2}>
                    Ledger transactions instantly to ensure you have the most up-to-date picture of your book of
                    business.
                  </Text>
                </Column>
              </Grid>

              <Code title="Retrieve bank accounts with balances" mt={6}>
                {`curl 'https://api.column.com/bank-accounts/<bank_account_id>' \\
    -u :<YOUR API KEY> \\`}
              </Code>
            </Column>

            <Column gridColumn={{ _: 12, md: 5 }} gridColumnStart={{ _: 0, md: 8 }}>
              <ProductScreen width="900px" mb={{ _: '-75%', md: '-100%' }} backgroundColor="#fbfcfd">
                <img src="/images/products/ledger/accounts.jpg" />
              </ProductScreen>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionAccuracy;
