import styled, { css } from 'styled-components';
import {
  compose,
  system,
  space,
  color,
  maxWidth,
  textAlign,
  MaxWidthProps,
  SpaceProps,
  ColorProps,
  TextAlignProps,
} from 'styled-system';
import { body100, body200, body300, body400, body500, body600 } from '~/ds/mixins/typography';

export interface TextStyleProps {
  weight?: 'inherit' | 'regular' | 'book' | 'medium' | 'semibold' | 'bold';
  size?: 'inherit' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | '1000';
  truncate?: boolean;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
  whiteSpace?: string;
}

export interface TextProps extends TextStyleProps, SpaceProps, ColorProps, TextAlignProps, MaxWidthProps {
  className?: string;
}

export const Text = styled.p<TextProps>`
  font-size: inherit;
  font-weight: ${(props) => props.weight || 'inherit'};
  color: var(--text-color-body);
  white-space: ${(props) => props.whiteSpace};

  ${({ size }) =>
    size === 'inherit' &&
    css<TextStyleProps>`
      font-size: inherit;
      line-height: inherit;
    `}

  ${({ size }) =>
    size === '100' &&
    css<TextStyleProps>`
      ${body100}
    `}

  ${({ size }) =>
    size === '200' &&
    css<TextStyleProps>`
      ${body200}
    `}

    ${({ size }) =>
    size === '300' &&
    css<TextStyleProps>`
      ${body300}
    `}

    ${({ size }) =>
    size === '400' &&
    css<TextStyleProps>`
      ${body400}
    `}

    ${({ size }) =>
    size === '500' &&
    css<TextStyleProps>`
      ${body500}
    `}

    ${({ size }) =>
    size === '600' &&
    css<TextStyleProps>`
      ${body600}
    `}

    // No wrapping and truncate with ellipsis
    ${({ truncate }) =>
    truncate === true &&
    css<TextStyleProps>`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    // Weight
    ${({ weight }) =>
    weight === 'inherit' &&
    css<TextStyleProps>`
      font-weight: inherit;
    `}

    ${({ weight }) =>
    weight === 'regular' &&
    css<TextStyleProps>`
      font-weight: var(--font-regular);
    `}

    ${({ weight }) =>
    weight === 'book' &&
    css<TextStyleProps>`
      font-weight: var(--font-book);
    `}

    ${({ weight }) =>
    weight === 'medium' &&
    css<TextStyleProps>`
      font-weight: var(--font-medium);
    `}

    ${({ weight }) =>
    weight === 'semibold' &&
    css<TextStyleProps>`
      font-weight: var(--font-semibold);
    `}

    ${({ weight }) =>
    weight === 'bold' &&
    css<TextStyleProps>`
      font-weight: var(--font-bold);
    `}

    ${system({
    textTransform: {
      property: 'textTransform',
      scale: 'textTransforms',
    },
  })}

  ${compose(space, color, textAlign, maxWidth)};
`;

Text.defaultProps = {
  size: 'inherit',
};
