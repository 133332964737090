import React, { CSSProperties, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '~/elements/NewText';

export interface StatusProps {
  className?: string;
  style?: CSSProperties;
}

const StatusWrapper = styled(Text)<{ online: boolean; show: boolean }>`
  padding: var(--space-2) var(--space-4);
  border-radius: var(--radii-pill);
  border: 1px solid var(--color-gray-200);
  display: flex;
  align-items: center;
  transition: opacity 0.2s linear, transform 0.3s ease;

  ${({ show }) =>
    !show &&
    css`
      opacity: 0;
      pointer-events: none;
      transform: translateX(-4px);
    `}

  &:before {
    content: '';
    margin-right: var(--space-2);
    width: var(--space-2);
    height: var(--space-2);
    display: inline-block;
    border-radius: var(--radii-round);
    background-color: var(--color-seafoam-500);

    ${({ online }) =>
      !online &&
      css`
        background-color: var(--color-red-500);
      `}
  }
`;

interface StatusObject {
  url: string;
  online: boolean;
  description: string;
}
export const Status: React.FC<StatusProps> = (props) => {
  const [status, setStatus] = useState<StatusObject | false>(false);

  useEffect(() => {
    fetch('https://status.column.com/api/v2/status.json')
      .then((response) => response.json())
      .then((response: any) => {
        if (
          typeof response.page?.url === 'undefined' ||
          typeof response.status?.description === 'undefined' ||
          typeof response.status?.indicator === 'undefined'
        ) {
          return;
        }

        setStatus({
          url: response.page.url,
          description: response.status.description,
          online: response.status.indicator === 'none',
        });
      });
  }, []);

  return (
    <StatusWrapper
      as="a"
      href={status ? status.url : ''}
      size="200"
      show={!!status}
      online={status && status.online}
      className={props.className}
    >
      {status && status.description}
    </StatusWrapper>
  );
};
