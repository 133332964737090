import React, { ReactNode, useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Popover } from '@headlessui/react';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { NavDropdownContainer, NavDropdown } from '~/components/Header';
import { Icon } from '~/elements/Icon';

export interface PopoverWrapperProps {
  className?: string;
  label: ReactNode;
  variants: Variants;
  small?: boolean;
}

export const NavItemStyles = css`
  display: flex;
  align-items: center;
  gap: var(--space-1);
  padding: var(--space-2) var(--space-4);
  border-radius: var(--radii-default);
  white-space: nowrap;
  font-size: var(--text-200);
  font-weight: var(--font-medium);
  color: var(--header-text, --color-gray-900);
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--header-text-bg, rgba(var(--rgb-black), 0.05));
    backdrop-filter: blur(8px);
    box-shadow: 0 0 1px 0 var(--header-text-border, transparent);
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px var(--color-blue-300);
  }

  .icon {
    height: 16px;
  }

  &.active {
    .icon {
      transform: rotate(180deg);
    }
  }
`;

const NavItem = styled(Popover.Button)`
  ${NavItemStyles}
`;

export const PopoverWrapper: React.FC<PopoverWrapperProps> = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openState, setOpenState] = useState<boolean>(false);

  const toggleMenu = (open: boolean) => {
    setOpenState(!open);
    buttonRef?.current?.click();
    buttonRef?.current?.blur();
  };

  const onHover = (open: boolean, action: string) => {
    if ((!open && !openState && action === 'enter') || (open && openState && action === 'leave')) {
      toggleMenu(open);
    }
  };

  const handleClick = (open: boolean) => {
    setOpenState(!open);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      buttonRef.current !== (event.target as Node) &&
      openState
    ) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Popover as="li" className={props.className}>
      {({ open }) => (
        <div
          className={`${props.small ? 'max-w-screen-sm' : ''}`}
          onMouseEnter={() => onHover(open, 'enter')}
          onMouseLeave={() => onHover(open, 'leave')}
        >
          <NavItem ref={buttonRef} onPointerDown={() => handleClick(open)} className={`${open && 'active'}`}>
            {props.label}
            <Icon.ChevronDown />
          </NavItem>

          <AnimatePresence>
            {open && (
              <NavDropdownContainer>
                <NavDropdown
                  as={motion.div}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={props.variants}
                  static
                  className={props.small && 'max-w-screen-sm'}
                  tabIndex={-1}
                >
                  {props.children}
                </NavDropdown>
              </NavDropdownContainer>
            )}
          </AnimatePresence>
        </div>
      )}
    </Popover>
  );
};
