import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsOverdrafts: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M5 4H7C7.55 4 8 4.45 8 5V14H5C4.45 14 4 13.55 4 13V5C4 4.45 4.45 4 5 4Z"
        fill="url(#paint0_linear_3204_773)"
      />
      <path d="M8 8H11C11.55 8 12 8.45 12 9V14H8V8Z" fill="url(#paint1_linear_3204_773)" />
      <path
        d="M12 12H15C15.55 12 16 12.45 16 13V16C16 16.55 15.55 17 15 17H13C12.45 17 12 16.55 12 16V12Z"
        fill="url(#paint2_linear_3204_773)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_773"
          x1="11.36"
          y1="7.13"
          x2="-4.22"
          y2="13.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_773"
          x1="18.15"
          y1="7.81"
          x2="5.1"
          y2="12.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_773"
          x1="22.12"
          y1="10.18"
          x2="12.94"
          y2="14.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
