import React from 'react';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { SectionBox } from '~/elements/SectionBox';
import { Br } from '~/elements/Br';
import { Column } from '~/elements/Column';
import { ProductScreen } from '~/elements/ProductScreen';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

export const SectionPayments: React.FC = () => {
  return (
    <SectionBox theme="light">
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column
            gridColumn={{ _: 12, md: 8, lg: 8 }}
            gridColumnStart={{ _: 1, md: 3, lg: 3 }}
            position="relative"
            zIndex={2}
          >
            <Heading as="h4" size="300" weight="medium" color="blue-500" textAlign="center">
              Payments
            </Heading>
            <Heading as="h2" size="700" color="blue-800" weight="semibold" textAlign="center" mt={4}>
              Complex payments <Br />
              made simple and secure
            </Heading>
            <Text size="300" color="gray-600" textAlign="center" maxWidth="530px" m="8px auto 0">
              Easily send and receive secure ACH, Wires and Checks 24/7 through our online dashboard with automatic,
              built-in positive pay. Utilize check-scanners, RDC or lockboxes to automate your check collections.
            </Text>
          </Column>

          <Column
            gridColumn={{ _: 10, md: 6 }}
            gridColumnStart={{ _: 2, md: 4 }}
            position="relative"
            zIndex="2"
          ></Column>

          <Column gridColumn={12} mb="-23%">
            <ProductScreen mt={6} backgroundColor="#fbfcfd">
              <img src="/images/specialty-deposits/screen-transfers.png" />
            </ProductScreen>
          </Column>
        </Grid>
      </Container>
    </SectionBox>
  );
};
