import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconChangelogImproved: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="1" width="14" height="18">
        <path
          d="M6.499 10h-3c0-1.737.677-3.369 1.904-4.597A6.46 6.46 0 0 1 9.999 3.5V1.934a.5.5 0 0 1 .777-.416l4.599 3.066a.5.5 0 0 1 0 .832l-4.599 3.066a.5.5 0 0 1-.777-.416V6.5c-.936 0-1.813.364-2.474 1.025A3.474 3.474 0 0 0 6.499 10Zm7 0c0 .936-.364 1.813-1.026 2.475A3.471 3.471 0 0 1 9.999 13.5v-1.566a.5.5 0 0 0-.777-.416l-4.599 3.066a.5.5 0 0 0 0 .832l4.599 3.066a.5.5 0 0 0 .777-.416V16.5a6.46 6.46 0 0 0 4.596-1.903A6.456 6.456 0 0 0 16.499 10h-3Z"
          fill="url(#20_improve__a)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M19.999 0h-20v20h20V0Z" fill="url(#20_improve__c)" />
      </g>
      <defs>
        <linearGradient
          id="20_improve__a"
          x1="-8.471"
          y1="15.089"
          x2="15.207"
          y2="8.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="20_improve__c" x1="-.001" y1="0" x2="19.999" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--blog-700, #12161E)" />
          <stop offset="1" stopColor="var(--blog-800, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
