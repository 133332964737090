import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Hero } from '~/components/Hero';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Code } from '~/elements/Code/Code';
import { Button } from '~/elements/Button';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Icon } from '~/elements/Icon';
import { Typing } from '~/elements/Typing';
import { sm, md, lg } from '~/ds/mixins/breakpoints';

const HeroVisual = styled.div`
  position: relative;
  z-index: 10;
  margin-top: var(--space-20);
`;

const HeroUI = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: var(--shadow-product-border), var(--shadow-product);
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(16px);
`;

const CodePosition = styled.div`
  position: relative;
  margin: 0 80px -64px 80px;
  z-index: 1;

  @media ${md} {
    position: absolute;
    top: -64px;
    right: 32px;
    margin: 0;
  }
`;

export const SectionHero: React.FC = () => {
  const [gradientRef, heroInView] = useInView({
    threshold: 0,
  });

  const heroClasses: ConditionalClasses = {
    'inview--gradient': heroInView,
  };

  return (
    <Hero palette="primary" className={classNames(heroClasses)}>
      <div className="relative z-10 max-w-screen-sm">
        <Heading as="h4" size="300" weight="medium" mb={3} color="gray-800" eyebrow>
          Column N.A. Member FDIC
        </Heading>
        <Heading as="h1" size="1000" weight="semibold" mt={8}>
          The developer
          <br />
          infrastructure bank
        </Heading>
        <Text mt={2} size="500">
          The only nationally chartered bank built to enable developers and builders to cre
          <Typing text="ate new financial products" />
        </Text>
        <ButtonGroup mt={4} flexDirection={{ _: 'column', sm: 'row' }}>
          <Button href="https://dashboard.column.com/register" type="link" variant="primary">
            Start building <Icon.AnimatedArrowRight />
          </Button>
          <Button to="/docs" variant="secondary">
            Read documentation
          </Button>
        </ButtonGroup>
      </div>

      <HeroVisual>
        <CodePosition>
          <Code copy={false}>
            {`curl 'https://api.column.com/transfers/wire' \\
    -u :prod_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d counterparty_id=cpty_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d bank_account_id=bacc_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d amount=40000 \\
    -d description="Visa daily settlement"`}
          </Code>
        </CodePosition>
        <HeroUI>
          <picture>
            <source srcSet="images/index/hero.webp, images/index/hero@2x.webp 2x" type="image/webp" />
            <source srcSet="images/index/hero.png, images/index/hero@2x.png 2x" type="image/png" />
            <img src="/images/index/hero@2x.png" width="1080px" alt="Dashboard Transfer Overview" />
          </picture>
        </HeroUI>
      </HeroVisual>

      <div className="absolute inset-0 z-0">
        <div className="background-gradient" ref={gradientRef}>
          <div className="background-gradient-pattern" />
        </div>
      </div>
    </Hero>
  );
};

export default SectionHero;
