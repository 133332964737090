import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './careers/_hero';
import { SectionInfo } from './careers/_info';
import { SectionRoles } from './careers/_roles';
import { Layout } from '~/components/Layout';

const CareersPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Careers — Column</title>
        <meta name="description" content="We're on a mission to change the financial landscape." />
      </Helmet>
      <SectionHero />
      <SectionInfo />
      <SectionRoles />
    </Layout>
  );
};

export default CareersPage;
