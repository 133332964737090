import { css } from 'styled-components';

export const sm = css`
  (min-width: 640px)
`;

export const md = css`
  (min-width: 768px)
`;

export const nav = css`
  (min-width: 870px)
`;

export const lg = css`
  (min-width: 1112px)
`;

export const xl = css`
  (min-width: 1280px)
`;
