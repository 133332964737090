import { css } from 'styled-components';

export const Green = css`
  .color-palette--green {
    --list-item-bullet: var(--color-green-300);
    --list-item-number: var(--color-gray-900);
  }

  .color-palette-inverted--green {
    --list-item-bullet: var(--color-green-400);
    --list-item-number: linear-gradient(180deg, #9cd95d 0%, #eaff6b 100%);
  }
`;
