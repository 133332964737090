import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsEventsWebhooks: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M6.5 7.5C7.88071 7.5 9 6.38071 9 5C9 3.61929 7.88071 2.5 6.5 2.5C5.11929 2.5 4 3.61929 4 5C4 6.38071 5.11929 7.5 6.5 7.5Z"
        fill="url(#paint0_linear_3204_789)"
      />
      <path d="M15 6.5V12.5H11.07C10.64 12.5 10.41 12.01 10.68 11.68L15 6.5Z" fill="url(#paint1_linear_3204_789)" />
      <path
        d="M15 12.5V13C15 15.76 12.76 18 10 18C7.24 18 5 15.76 5 13V7C5.41 7.31 5.94 7.5 6.5 7.5C7.06 7.5 7.59 7.31 8 7V13C8 14.1 8.9 15 10 15C11.1 15 12 14.1 12 13V12.5H15Z"
        fill="url(#paint2_linear_3204_789)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_789"
          x1="-0.12"
          y1="-5.61"
          x2="7.77"
          y2="7.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_789"
          x1="17.25"
          y1="5.87"
          x2="12.94"
          y2="11.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_789"
          x1="2.98"
          y1="4.84"
          x2="14.5"
          y2="19.26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
