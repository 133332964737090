import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHeroTitleEscrow } from './specialty-deposits/_hero-title-escrow';
import { SectionPointsTitleEscrow } from './specialty-deposits/_points-title-escrow';
import { SectionEntities } from './specialty-deposits/_entities';
import { SectionAccounts } from './specialty-deposits/_accounts';
import { SectionPayments } from './specialty-deposits/_payments';
import { SectionContact } from './specialty-deposits/_contact';
import { Layout } from '~/components/Layout';
import { Footer } from '~/components/Footer';

const TitleEscrowPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Banking purpose built for Title & Escrow — Column</title>
        <meta property="og:title" content="Banking purpose built for Title & Escrow — Column" />
        <meta
          name="description"
          content="We’ve seen first hand how title & escrow companies with complex financial and payments needs are
                    under-served with legacy technology and convoluted processes from traditional banks. We created a
                    dedicated product and team built from the ground up to limit the time and headache you spend on
                    payments and reconciliations."
        />
      </Helmet>
      <SectionHeroTitleEscrow />
      <SectionPointsTitleEscrow />
      <SectionEntities />
      <SectionAccounts />
      <SectionPayments />
      <SectionContact />

      <div className="relative overflow-hidden">
        <Footer />
      </div>
    </Layout>
  );
};

export default TitleEscrowPage;
