import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsPagination: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M4 16.5V10.5C4 9.95 4.45 9.5 5 9.5H7V13.5C7 14.05 7.45 14.5 8 14.5H10V16.5C10 17.05 9.55 17.5 9 17.5H5C4.45 17.5 4 17.05 4 16.5Z"
        fill="url(#paint0_linear_3204_780)"
      />
      <path
        d="M7 13.5V7.5C7 6.95 7.45 6.5 8 6.5H10V10.5C10 11.05 10.45 11.5 11 11.5H13V13.5C13 14.05 12.55 14.5 12 14.5H8C7.45 14.5 7 14.05 7 13.5Z"
        fill="url(#paint1_linear_3204_780)"
      />
      <path
        d="M15 3.5H11C10.4477 3.5 10 3.94772 10 4.5V10.5C10 11.0523 10.4477 11.5 11 11.5H15C15.5523 11.5 16 11.0523 16 10.5V4.5C16 3.94772 15.5523 3.5 15 3.5Z"
        fill="url(#paint2_linear_3204_780)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_780"
          x1="8.83"
          y1="11.89"
          x2="-5.42"
          y2="24.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_780"
          x1="15"
          y1="5.73"
          x2="4.41"
          y2="15.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_780"
          x1="24.2"
          y1="-11.91"
          x2="12.45"
          y2="8.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
