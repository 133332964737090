import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconArrow: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="2" width="14" height="16">
        <path
          d="m17.47 10.53-6.884 6.884A2 2 0 0 1 9.172 18H5.569a.65.65 0 0 1-.459-1.11l6.36-6.36a.75.75 0 0 0 0-1.061L5.11 3.11A.65.65 0 0 1 5.569 2h3.602a2 2 0 0 1 1.414.586L17.47 9.47a.749.749 0 0 1 0 1.06Z"
          fill="url(#arrow__a)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M20 0H0v20h20V0Z" fill="url(#arrow__c)" />
      </g>
      <defs>
        <linearGradient id="arrow__a" x1=".24" y1="13.802" x2="14.529" y2="6.353" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="arrow__c" x1="-5.879" y1="18.277" x2="20.03" y2="4.772" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-gradient-stop-1, #12161E)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
