import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Header } from './Header';
import { Cookie } from './Cookie';
import { Footer } from './Footer';
import DesignSystem from '~/ds';
import columnDS from '~/ds/ds';

export interface LayoutStyleProps {
  noFooter?: boolean;
  headerDark?: boolean;
  inverted?: boolean;
  bg?: string;
  scrollBg?: string;
}

export interface LayoutProps extends LayoutStyleProps {
  className?: string;
}

const Body = styled.div<LayoutStyleProps>`
  background: ${(props) => props.bg || 'white'};
`;

const url = typeof window !== 'undefined' ? window.location.href : 'https://column.com';

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <ThemeProvider theme={columnDS}>
      <DesignSystem />

      <Helmet htmlAttributes={{ lang: 'en-us' }}>
        <link
          rel="preload"
          as="font"
          href="/fonts/SuisseIntl-Regular.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link rel="preload" as="font" href="/fonts/SuisseIntl-Book.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link
          rel="preload"
          as="font"
          href="/fonts/SuisseIntl-Medium.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
          className="h-10"
        />
        <link
          rel="preload"
          as="font"
          href="/fonts/SuisseIntl-SemiBold.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta property="og:site_name" content="Column" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content="Column - The developer infrastructure bank" />
        <meta
          property="og:description"
          content="The only nationally chartered bank built to enable developers and builders to create new financial products"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://column.com/images/social/column-og.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter share */}
        <meta property="twitter:site_name" content="Column - The developer infrastructure bank" />
        <meta property="twitter:site" content="@columnbank" />
        <meta property="twitter:url" content={url} />
        <meta
          property="twitter:description"
          content="The only nationally chartered bank built to enable developers and builders to create new financial products"
        />
        <meta name="twitter:image:src" content="https://column.com/images/social/column-og.png" />
      </Helmet>

      <Body {...props}>
        <Header {...props} dark={!!props.headerDark} scrollBg={props.scrollBg} />
        <Cookie />
        <main>{props.children}</main>

        {props.noFooter ? null : <Footer />}
      </Body>
    </ThemeProvider>
  );
};
