import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: rgba(var(--rgb-blue-800), 1);
  padding: 66px var(--space-5) var(--space-5);
  position: relative;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s;
  width: 100%;
  

  &:before {
    content: '';
    background-color: inherit;
    position: absolute;
    top: var(--space-n8);
    bottom: 0;
    left: 0;
    width: 100vw;
    box-shadow: inset 0 -1px 0 0 rgba(var(--rgb-white), 0.2);
  }

  .scroll-down & {
    transform: translate3d(0, 0, 0);
  }

  .scroll-up & {
    transform: translate3d(0, 62px, 0);
  }
`;

const Inner = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: var(--space-6);

  &::after {
    content: '';
    background-color: rgba(var(--rgb-blue-800), 1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10px;
    box-shadow: 0px 0 20px 15px rgba(var(--rgb-blue-800), 1);
  }
`;

export const MarkdownSyntax: React.FC = (props) => {
  return (
    <Wrapper>
      <Inner>{props.children}</Inner>
    </Wrapper>
  );
};
