import React from 'react';
import styled from 'styled-components';
import { Section } from '~/elements/Section';
import { TextGroup } from '~/elements/TextGroup';
import { Grid } from '~/elements/Grid';
import { Container } from '~/elements/Container';
import { Column } from '~/elements/Column';
import { Text } from '~/elements/Text';

const Logo = styled.img`
  height: 28px;
`;

export const SectionPoints: React.FC = () => {
  return (
    <Section className="py-10">
      <Container>
        <Grid gap={8} position="relative" zIndex="1">
          <Column gridColumn={{ _: '1 / span 12', md: '2 / span 5' }} pr={2}>
            <img src="/images/security/security.svg" className="mb-4" alt="Image of security shield" />

            <TextGroup size="500">
              <Text variant="heading">We're serious about security</Text>
              <Text>
                Column maintains a comprehensive security program, supported by policies and procedures based on
                industry standard guidance from NIST, FFIEC, and others.
              </Text>
            </TextGroup>
          </Column>
          <Column gridColumn={{ _: '1 / span 12', md: '7 / span 5' }} pr={2}>
            <img src="/images/security/accountability.svg" alt="Image of overlooking eyeball" className="mb-4" />
            <TextGroup size="500">
              <Text variant="heading">Accountability</Text>
              <Text>
                As a federally regulated bank, we are subject to governmental audits on a periodic basis. We also work
                with third parties to perform independent assessments and testing of the security program.
              </Text>
            </TextGroup>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionPoints;
