import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './company/_hero';
import { SectionStory } from './company/_story';
import { SectionFounders } from './company/_founders';
import { Layout } from '~/components/Layout';

const CompanyPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Company — Column</title>
        <meta
          name="description"
          content="We're not your normal tech company and we're definitely not your normal bank."
        />
      </Helmet>
      <SectionHero />
      <SectionStory />
      <SectionFounders />
    </Layout>
  );
};

export default CompanyPage;
