import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Hero } from '~/components/Hero';
import { Typing } from '~/elements/Typing';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

export const SectionHero: React.FC = () => {
  const [gradientRef, heroInView] = useInView({
    threshold: 0,
  });

  const heroClasses: ConditionalClasses = {
    'inview--gradient': heroInView,
  };

  const PhotoCaption = styled.div`
    position: absolute;
    bottom: 12px;
    left: 12px;
    margin-right: 12px;
    border-radius: 4px;
    z-index: 3;
    padding: 8px 12px 8px 12px;
    background-color: rgba(0, 0, 0, 0.65);
    max-width: 400px;
    opacity: 0;
    backdrop-filter: blur(8px);
    transition: opacity 0.3s;

    p {
      color: #fff;
    }
  `;

  const PhotoWrap = styled.div`
    z-index: 2;
    position: relative;

    &:hover ${PhotoCaption} {
      display: block;
      opacity: 1;
    }
  `;

  const Photo = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: var(--color-gray-100);
    z-index: 1;
    position: relative;
    height: ${(props) => props.height || '520px'}; //520px is just the default height in case a height prop isn't set
  `;

  return (
    <Hero className={classNames(heroClasses)}>
      <Grid alignItems="center" gap={8} zIndex="10" position="relative">
        <Column gridColumn={{ _: '1 / span 12', md: '2 / span 7' }}>
          <Heading as="h4" weight="semibold" size="400" mb={8}>
            Company
          </Heading>

          <Heading as="h1" weight="semibold" size="600">
            Hi. We're Column. We're a different type of bank that enables builders and developers to create incredible
            financial products and services for their
            <Typing text=" users" />
          </Heading>
        </Column>
      </Grid>

      <Grid alignItems="center" gap={4} mt={16}>
        <Column gridColumn={{ _: '1 / span 12', sm: '1 / span 8' }} mt={0}>
          <PhotoWrap>
            <PhotoCaption>
              <Text size="200">Our inbound phone traffic is bananas.</Text>
            </PhotoCaption>
            <Photo
              src="/images/company/room.jpg"
              height="420px"
              alt="Column teammates pretend to be on important calls but are holding bananas."
            />
          </PhotoWrap>
        </Column>
        <Column gridColumn={{ _: '1 / span 12', sm: '9 / span 4' }} mt={{ _: '0', sm: -'32' }}>
          <PhotoWrap>
            <PhotoCaption>
              <Text size="200">At Column we use computers, so we use sticky notes for other things.</Text>
            </PhotoCaption>
            <Photo
              src="/images/company/smile.jpg"
              height="420px"
              alt="Teammate at Column applies sticky notes to glass in shape of a smiley face."
            />
          </PhotoWrap>
        </Column>
      </Grid>

      <div className="absolute bottom-0 left-0 right-0 overflow-hidden -top-96" style={{ height: 1200 }}>
        <div className="background-gradient" ref={gradientRef}>
          <div className="background-gradient-pattern" />
        </div>
      </div>
    </Hero>
  );
};

export default SectionHero;
