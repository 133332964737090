import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/book-transfers/_hero';
import { SectionWorkflows } from './products/book-transfers/_workflows';
import { SectionCustomerExperience } from './products/book-transfers/_customer-experience';
import { SectionCloser } from './index/_closer';
import { Layout } from '~/components/Layout';
import { AllProducts } from '~/components/AllProducts';
import { Testimonials } from '~/components/Testimonials';
import { Footer } from '~/components/Footer';

const BookTransfersPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>Book Transfers — Column</title>
        <meta property="og:title" content="Column - Book Transfers" />
        <meta
          name="description"
          content="When you own both accounts at Column, transfers are real-time every second of the day."
        />
      </Helmet>
      <SectionHero />
      <SectionWorkflows />
      <SectionCustomerExperience />
      <Testimonials />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />

      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default BookTransfersPage;
