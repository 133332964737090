import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { md } from '~/ds/mixins/breakpoints';
import { Box } from '~/elements/Box';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
  margin-bottom: var(--space-2);
`;

const Chip = styled(Text)`
  background-color: rgba(var(--rgb-white), 0.6);
  padding: 0 var(--space-2);
  border-radius: 36px;
  box-shadow: 0 0 0 1px rgba(var(--rgb-black), 0.1);
  font-size: var(--text-100);
  color: var(--color-gray-400);
  display: inline-block;
`;

const Check = styled(ProductScreen)`
  max-width: 275px;
`;

const Checks = styled.div`
  position: relative;
  transform: scale(0.7);

  @media ${md} {
    transform: scale(0.9);
  }

  > ${Check} {
    &:nth-child(2),
    &:nth-child(3) {
      position: absolute;
      top: 0;

      &:before,
      &:after {
        display: none;
      }
    }

    &:nth-child(1) {
      transform: rotate(-6deg);
      img {
        opacity: 0.35;
      }
    }

    &:nth-child(2) {
      transform: rotate(6deg);
      img {
        opacity: 0.5;
      }
    }

    &:nth-child(3) {
      transform: rotate(18deg);
    }
  }
`;

export const SectionDeposits: React.FC = () => {
  return (
    <Section>
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Flex justifyContent="center">
              <Checks>
                <Check>
                  <img src="/images/products/checks/check-ocr.svg" />
                </Check>
                <Check>
                  <img src="/images/products/checks/check-ocr.svg" />
                </Check>
                <Check>
                  <img src="/images/products/checks/check-ocr.svg" />
                </Check>
              </Checks>
            </Flex>
          </Column>

          <Column
            gridColumn={{ _: 12, md: 6 }}
            gridColumnStart={{ _: 0, md: 7 }}
            position="relative"
            zIndex={2}
            justifyContent="center"
            flexDirection="column"
            display={{ _: 'block', md: 'flex' }}
          >
            <Grid gridTemplateColumns={6} gapX={7} gapY={{ _: 6, lg: 8 }} mb={8}>
              <Column gridColumn={{ _: 6, lg: 5 }}>
                <Heading as="h2" weight="semibold" size="700">
                  Digital check deposits
                </Heading>
                <Text size="300" color="gray-500" mt={2}>
                  Deposit checks without visiting a physical branch
                </Text>
              </Column>

              <Column gridColumn={{ _: 6, lg: 3 }}>
                <CircleCheck />
                <Heading as="h3" size="300" weight="medium">
                  OCR at your fingertips
                </Heading>
                <Text size="200" color="gray-500" mt={1}>
                  One API with OCR flexibility. Leverage our capabilities or bring your own RDC
                </Text>
              </Column>

              <Column gridColumn={{ _: 6, lg: 3 }}>
                <CircleCheck />
                <Heading as="h3" size="300" weight="medium">
                  Check clearing made easy
                </Heading>
                <Text size="200" color="gray-500" mt={1}>
                  Control the check clearing process via API and automated check re-clearing
                </Text>
              </Column>
            </Grid>

            <Code title="Get a check transfer" mt={6}>
              {`curl 'https://api.column.com/transfers/checks/<check_transfer_id>' \\
      -u :<YOUR API KEY>`}
            </Code>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionDeposits;
