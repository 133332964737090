import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
`;

export const SectionSpeed: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }}>
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 6, lg: 5 }}>
              <Flex flexDirection="column" justifyContent="space-between" height="100%">
                <Column>
                  <Heading as="h2" weight="semibold" size="700">
                    Unparalleled ACH flexibility
                  </Heading>
                  <Text size="300" mt={4} color="gray-600">
                    ACH has a reputation for taking too long. At Column, we optimize for the fastest ACH transfers
                    possible —{' '}
                    <Text as="em" color="gray-900" size="inherit">
                      often in a matter of hours
                    </Text>
                    .
                  </Text>
                  <Text size="300" mt={4}>
                    Unlike legacy banks and middleware providers, we have a direct connection to the Federal Reserve to
                    facilitate the quickest and most transparent transfers.
                  </Text>
                </Column>
                <Column mt={{ _: 4, md: 8 }}></Column>
              </Flex>
            </Column>

            <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 0, md: 7, lg: 7 }}>
              <Code title="Create an ACH transfer">
                {`curl 'https://api.column.com/transfers/ach' \\
    -u :prod_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d counterparty_id=cpty_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d bank_account_id=bacc_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d amount=241 \\
    -d type=DEBIT \\
    -d currency_code=USD \\
    -d description="Recurring bill"`}
              </Code>

              <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1, lg: 2 }} gap={6} mt={5}>
                <Column>
                  <Flex gap={2}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <Text size="100" color="gray-500">
                      Control every field in the NACHA spec and send payments under any SEC code.
                    </Text>
                  </Flex>
                </Column>
                <Column>
                  <Flex gap={2}>
                    <CircleCheck color="blue-500" viewportSize={20} />
                    <Text size="100" color="gray-500">
                      Receive webhooks throughout ACH settlement to keep systems up to date at every stage of the
                      process.
                    </Text>
                  </Flex>
                </Column>
              </Grid>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionSpeed;
