import { css } from 'styled-components';
import {
  body100,
  body200,
  body300,
  body400,
  body500,
  heading200,
  heading300,
  heading400,
  heading500,
  heading600,
  heading700,
  heading800,
  heading900,
  heading1000,
} from '~/ds/mixins/typography';

export const TextGroup = css`
  .text-eyebrow {
    &::before {
      width: 8px;
      height: 16px;
      margin-right: 8px;
      background: var(--bg-gradient-primary, var(--text-color-heading));
      display: inline-block;
      position: relative;
      top: 2px;
      border-radius: 1px;
      content: '';
    }
  }

  .text-title {
    display: flex;
    align-items: center;
    gap: var(--space-2);
    margin-bottom: var(--space-8);
    ${heading400}
  }

  .text-subheading,
  .text-eyebrow {
    font-weight: var(--font-semibold);
  }

  .text-group-200 {
    .text-heading {
      ${heading200}
    }

    .text-body,
    .list-diamond {
      ${body100}
      margin-top: var(--space-1);
    }
  }

  .text-group-300 {
    .text-heading {
      ${heading300}
    }

    .text-body,
    .list-diamond {
      ${body200}
      margin-top: var(--space-1);
    }
  }

  .text-group-400 {
    .text-heading {
      ${heading400}
    }

    .text-body,
    .list-diamond {
      ${body300}
      margin-top: var(--space-3);
    }
  }

  .text-group-500 {
    .text-heading {
      ${heading500}
    }

    .text-body,
    .list-diamond {
      ${body300}
      margin-top: var(--space-2);
    }
  }

  .text-group-600 {
    .text-subheading,
    .text-eyebrow {
      margin-bottom: var(--space-6);
    }

    .text-subheading,
    .text-eyebrow {
      ${heading300}
    }

    .text-heading {
      ${heading600}
    }

    .text-body,
    .list-diamond {
      ${body300}
      margin-top: var(--space-3);
    }
  }

  .text-group-700 {
    .text-subheading,
    .text-eyebrow {
      margin-bottom: var(--space-6);
    }

    .text-subheading,
    .text-eyebrow {
      ${heading300}
    }

    .text-heading {
      ${heading700}
    }

    .text-body,
    .list-diamond {
      ${body400}
      margin-top: var(--space-4);
    }
  }

  .text-group-800 {
    .text-subheading,
    .text-eyebrow {
      margin-bottom: var(--space-6);
    }

    .text-subheading,
    .text-eyebrow {
      ${heading300}
    }

    .text-heading {
      ${heading800}
    }

    .text-body,
    .list-diamond {
      ${body400}
      margin-top: var(--space-4);
    }
  }

  .text-group-900 {
    .text-subheading,
    .text-eyebrow {
      margin-bottom: var(--space-6);
    }

    .text-subheading,
    .text-eyebrow {
      ${heading300}
    }

    .text-heading {
      ${heading900}
    }

    .text-body,
    .list-diamond {
      ${body400}
      margin-top: var(--space-2);
    }
  }

  .text-group-1000 {
    .text-subheading,
    .text-eyebrow {
      margin-bottom: var(--space-8);
    }

    .text-subheading {
      ${heading400}
    }

    .text-eyebrow {
      ${heading300}
    }

    .text-heading {
      ${heading1000}
    }

    .text-body,
    .list-diamond {
      ${body400}
      margin-top: var(--space-4);
      font-weight: var(--font-book);
    }
  }

  /* Duotone */
  [class*='text-group-duotone-'] {
    .text-heading,
    .text-body {
      display: inline;
      font-weight: var(--font-semibold);
    }

    .text-subheading,
    .text-eyebrow {
      ${heading300}
      margin-bottom: var(--space-6);
      color: var(--text-color-duotone-primary);
      font-weight: var(--font-semibold);
    }

    .text-heading {
      color: var(--text-color-duotone-primary);
    }

    .text-body {
      color: var(--text-color-duotone-secondary);
      &::before {
        content: ' ';
      }
    }
  }

  .text-group-duotone-300 {
    ${body300}

    .text-subheading,
    .text-eyebrow {
      ${body200}
    }
  }

  .text-group-duotone-400 {
    ${body400}

    .text-subheading,
    .text-eyebrow {
      ${body200}
    }
  }

  .text-group-duotone-500 {
    ${body500}

    .text-subheading,
    .text-eyebrow {
      ${body300}
    }

    .text-heading,
    .text-body {
      letter-spacing: var(--letter-spacing-1);
    }
  }

  .text-group-duotone-600 {
    ${heading600}

    .text-subheading,
    .text-eyebrow {
      ${body300}
    }

    .text-heading,
    .text-body {
      letter-spacing: var(--letter-spacing-1);
    }
  }

  .text-group-duotone-700 {
    ${heading700}

    .text-heading,
    .text-body {
      letter-spacing: var(--letter-spacing-1);
    }

    .text-subheading,
    .text-eyebrow {
      ${body300}
      text-transform: none;
    }
  }
`;
