import { css } from 'styled-components';

export const Primary = css`
  :root,
  .color-palette--primary {
    /* Default button palette */
    --btn-font-size: var(--font-size-200);

    /* Primary */
    --btn-text-color-primary: var(--color-0);

    --btn-bg-primary: var(--color-blue-800);
    --btn-bg-primary-hover: var(--color-blue-800);
    --btn-bg-primary-active: var(--color-blue-800);

    --btn-border-primary: var(--color-blue-800);
    --btn-border-primary-hover: var(--color-blue-800);
    --btn-border-primary-active: var(--color-blue-800);

    --btn-focus-ring-primary: var(--color-blue-100);

    /* Secondary */
    --btn-text-color-secondary: var(--color-gray-800);

    --btn-bg-secondary: rgba(var(--rgb-0), 0.5);
    --btn-bg-secondary-hover: var(--color-0);
    --btn-bg-secondary-active: var(--color-0);

    --btn-border-secondary: rgba(var(--rgb-blue-600), 0.12);
    --btn-border-secondary-hover: rgba(var(--rgb-blue-600), 0.2);
    --btn-border-secondary-active: rgba(var(--rgb-blue-600), 0.2);

    --btn-focus-ring-secondary: var(--color-gray-50);

    /* Button Link - To be removed */
    --btn-text-color-link: var(--color-gray-900);
    --btn-icon-fill: var(--color-gray-500);

    /* Link */
    --link-color: var(--color-blue-600);
    --link-color-hover: var(--color-blue-700);
    --link-icon-fill: var(--color-gray-500);
  }

  .color-palette-inverted--primary {
    /* Primary */
    --btn-font-size: var(--font-size-200);

    /* Primary */
    --btn-text-color-primary: var(--color-0);

    --btn-bg-primary: var(--color-blue-600);
    --btn-bg-primary-hover: var(--color-blue-600);
    --btn-bg-primary-active: var(--color-blue-600);

    --btn-border-primary: var(--color-blue-800);
    --btn-border-primary-hover: var(--color-blue-800);
    --btn-border-primary-active: var(--color-blue-700);

    --btn-focus-ring-primary: var(--color-blue-500);

    /* Secondary */
    --btn-text-color-secondary: var(--color-blue-700);

    --btn-bg-secondary: var(--color-blue-50);
    --btn-bg-secondary-hover: var(--color-blue-50);
    --btn-bg-secondary-active: var(--color-blue-50);

    --btn-border-secondary: rgba(var(--rgb-1000), 0.1);
    --btn-border-secondary-hover: rgba(var(--rgb-1000), 0.2);
    --btn-border-secondary-active: rgba(var(--rgb-1000), 0.15);

    --btn-focus-ring-secondary: var(--color-blue-500);

    /* Link */
    --btn-text-color-link: var(--color-0);
  }
`;
