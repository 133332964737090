import { css } from 'styled-components';

import {
  body400,
  body500,
  body600,
  heading400,
  heading500,
  heading600,
  heading700,
  heading800,
  heading900,
  heading1000,
} from '~/ds/mixins/typography';

export const Typography = css`
  .text-inherit {
    font-size: inherit;
  }

  /* Body type utilities */

  /* Sets the default color with opacity support */
  [class*='text-b-'],
  [class*='text-p-'],
  .text-body {
    font-weight: var(--font-book);
    color: var(--text-color-body);
  }

  /* 12 */
  /* Generated by tailwind as is */

  /* 14 */
  /* Generated by tailwind as is */

  /* 16 / 16 / 16 */
  /* Generated by tailwind as is */

  /* 20 / 18 / 18 */
  .text-b-400 {
    ${body400}
  }

  /* 24 / 20 / 20 */
  .text-b-500 {
    ${body500}
  }

  /* 32 / 24 / 24 */
  .text-b-600 {
    ${body600}
  }

  /* Heading type classes */

  /* Sets the default color with opacity support */
  [class*='text-h-'],
  .text-heading,
  .text-subheading,
  .text-eyebrow,
  .text-title {
    font-weight: var(--font-semibold);
    color: var(--text-color-heading);
  }

  .text-subheading,
  .text-eyebrow {
    color: var(--text-color-subheading);
  }

  /* 12 */
  .text-h-100 {
    font-weight: var(--font-semibold);
  }

  /* 14 */
  .text-h-200 {
    font-weight: var(--font-semibold);
  }

  /* 16 */
  .text-h-300 {
    font-weight: var(--font-semibold);
  }

  /* 20 / 18 / 18 */
  .text-h-400 {
    ${heading400}
  }

  /* 24 / 20 / 20 */
  .text-h-500 {
    ${heading500}
  }

  /* 32 / 28 / 24 */
  .text-h-600 {
    ${heading600}
  }

  /* 36 / 32 / 28 */
  .text-h-700 {
    ${heading700}
  }

  /* 44 / 40 / 32 */
  .text-h-800 {
    font-weight: var(--font-medium);
    ${heading800}
  }

  /* 56 / 44 / 36 */
  .text-h-900 {
    font-weight: var(--font-medium);
    ${heading900}
  }

  /* 80 / 48 / 44 */
  .text-h-1000 {
    font-weight: var(--font-medium);
    ${heading1000}
  }
`;
