import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductWire: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="5" width="24" height="22">
        <path d="M8 13a4 4 0 100-8 4 4 0 000 8z" fill="url(#32_wire-dark__paint0_linear)" />
        <path d="M24 27a4 4 0 100-8 4 4 0 000 8z" fill="url(#32_wire-dark__paint1_linear)" />
        <path
          d="M26 11v8.54a3.99 3.99 0 00-2-.54c-.73 0-1.41.2-2 .54V11c0-1.1-.9-2-2-2s-2 .9-2 2v10c0 3.31-2.69 6-6 6s-6-2.69-6-6v-8.54c.59.34 1.27.54 2 .54s1.41-.2 2-.54V21c0 1.1.9 2 2 2s2-.9 2-2V11c0-3.31 2.69-6 6-6s6 2.69 6 6z"
          fill="url(#32_wire-dark__paint2_linear)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#32_wire-dark__paint3_linear)" />
      </g>
      <defs>
        <linearGradient
          id="32_wire-dark__paint0_linear"
          x1="4.886"
          y1="5.886"
          x2="11.6"
          y2="12.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset=".5" stopOpacity=".2" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_wire-dark__paint1_linear"
          x1="20.886"
          y1="19.886"
          x2="27.6"
          y2="26.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset=".5" stopOpacity=".2" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_wire-dark__paint2_linear"
          x1="11.374"
          y1="11.374"
          x2="24.099"
          y2="24.099"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="32_wire-dark__paint3_linear" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
