import React from 'react';
import styled, { css } from 'styled-components';

interface TooltipProps {
  content?: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
}

const Wrapper = styled.span<TooltipProps>`
  position: relative;
  display: inline-block;
  padding: 0 2px;
  color: var(--color-gray-500);
  font-weight: normal;

  &::after {
    background: var(--color-0);
    border-radius: var(--radii-default);
    padding: var(--space-3);
    box-shadow: var(--shadow-product-border), var(--shadow-product);
    position: absolute;
    z-index: 100;
    width: 100%;
    content: attr(aria-label);
    font-size: var(--text-100);
    line-height: var(--lh-b-100);
    color: var(--color-gray-600);
    text-align: left;

    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    width: 1px;
    white-space: nowrap;
    opacity: 0;

    [aria-position='top']& {
      margin-left: 50%;
      bottom: calc(100% + var(--space-2));
      transform: translate3d(-50%, var(--space-3), 0);
    }

    [aria-position='right']& {
      margin-top: 50%;
      left: calc(100% + var(--space-2));
      transform: translate3d(0, -50%, 0);
    }

    [aria-position='bottom']& {
      margin-right: 50%;
      top: calc(100% + var(--space-2));
      transform: translate3d(-50%, var(--space-3), 0);
    }

    [aria-position='left']& {
      margin-top: 50%;
      right: calc(100% + var(--space-2));
      transform: translate3d(0, -50%, 0);
    }
  }

  &:hover {
    &::after {
      clip-path: none;
      clip: unset;
      min-width: 250px;
      width: auto;
      height: auto;
      overflow: visible;
      white-space: normal;
      opacity: 1;
      transition: opacity 0.2s, tranform 0.2s;

      [aria-position='top']& {
        transform: translate3d(-50%, 0, 0);
      }

      [aria-position='right']& {
        transform: translate3d(0, -50%, 0);
      }

      [aria-position='bottom']& {
        transform: translate3d(-50%, 0, 0);
      }

      [aria-position='left']& {
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  @media (pointer: coarse), (hover: none) {
    &:focus {
      &::after {
        clip-path: none;
        clip: unset;
        width: auto;
        height: auto;
        overflow: visible;
        white-space: normal;
        transform: translate3d(-50%, 0, 0);
        opacity: 1;
        transition: opacity 0.2s, tranform 0.2s;
      }
    }
  }
`;

export const Tooltip: React.FC<TooltipProps> = (props) => {
  return (
    <Wrapper role="tooltip" aria-label={props.content} aria-position={props.position}>
      {props.children}
    </Wrapper>
  );
};

Tooltip.defaultProps = {
  position: 'top',
};
