import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsHome: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M17.0001 9.62L10.6601 4.08C10.2801 3.75 9.72005 3.75 9.34005 4.08L3.00005 9.62C2.65005 9.92 2.87005 10.5 3.33005 10.5H16.6701C17.1301 10.5 17.3501 9.93 17.0001 9.62Z"
        fill="url(#paint0_linear_3204_765)"
      />
      <path
        d="M15 10.5V15.5C15 16.05 14.55 16.5 14 16.5H11V13C11 12.72 10.78 12.5 10.5 12.5H9.5C9.22 12.5 9 12.72 9 13V16.5H6C5.45 16.5 5 16.05 5 15.5V10.5H15Z"
        fill="url(#paint1_linear_3204_765)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_765"
          x1="24.7601"
          y1="-6.2"
          x2="3.18005"
          y2="17.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_765"
          x1="58.27"
          y1="-23.24"
          x2="6.41"
          y2="15.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
