import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconBrandMark: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-brand icon-mark', props.className];

  return (
    <IconBase viewportSize={24} {...props} className={classNames(staticClasses)}>
      <path
        d="M9 9v6h3a6 6 0 0 1 6 6v3H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6v3H9Z"
        style={{ fill: 'var(--icon-color-brand, #1E4199)' }}
      />
    </IconBase>
  );
};
