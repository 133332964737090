import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconCopy: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon-copy', props.className];

  return (
    <IconBase viewportSize={24} {...props} className={classNames(staticClasses)}>
      <path
        d="M12.3333 15H5.66667C4.74667 15 4 14.3797 4 13.6154V6.38462C4 5.62031 4.74667 5 5.66667 5H12.3333C13.2533 5 14 5.62031 14 6.38462V13.6154C14 14.3797 13.2533 15 12.3333 15Z"
        fill="url(#paint0_linear_0_1)"
        className="icon-copy-back"
      />
      <path
        d="M18.3333 19H11.6667C10.7467 19 10 18.3797 10 17.6154V10.3846C10 9.62031 10.7467 9 11.6667 9H18.3333C19.2533 9 20 9.62031 20 10.3846V17.6154C20 18.3797 19.2533 19 18.3333 19Z"
        fill="url(#paint1_linear_0_1)"
        className="icon-copy-front"
      />
      <path d="M9 12L11 14L15 10" stroke="var(--icon-mask-color)" className="icon-copy-check" />
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="13.6381"
          y1="15.7076"
          x2="3.33767"
          y2="0.559534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-base-color)" stopOpacity="0" />
          <stop offset="1" stopColor="var(--icon-base-color)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="34.8461"
          y1="43.5709"
          x2="19.2654"
          y2="11.0714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-base-color)" stopOpacity="0" />
          <stop offset="1" stopColor="var(--icon-base-color)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
