import React from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Typing } from '~/elements/Typing';
import { Button } from '~/elements/Button';
import { Icon } from '~/elements/Icon';
import { Br } from '~/elements/Br';
import { ButtonGroup } from '~/elements/ButtonGroup';

const EmailLink = styled.a`
  color: var(--color-purple-800);
  border-bottom: 1px solid rgba(var(--rgb-purple-300), 1);
  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 1px solid rgba(var(--rgb-purple-800), 1);
  }
`;

const GradientText = styled.span`
  background: #d65620;
  background: linear-gradient(to right, #d65620 0%, #541065 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SectionHero: React.FC = () => {
  return (
    <Section>
      <Container>
        <Grid gridTemplateColumns={12} position="relative">
          <Column gridColumn={{ _: 12, sm: 10, md: 9 }}>
            <Flex gap={2} alignItems="center" mb={8} color="purple-800">
              <Icon.ProductProtectedDeposits />
              <Heading as="h4" size="300" weight="medium" color="purple-800">
                <Typing text="Protected Deposits" />
              </Heading>
            </Flex>

            <Heading as="h1" size="900" weight="semibold" mt={4} color="purple-800">
              <GradientText>$150M of FDIC insurance</GradientText> protection for your cus
              <Typing text="tomers" />
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, sm: 10, md: 8 }}>
            <Text size="400" color="rgba(var(--rgb-purple-800),0.7)" mt={2} pr={{ _: 0, nav: 12 }}>
              <Text as="span" color="purple-800">
                Nine digits of protection from banks like us, and other top FDIC insured institutions.
              </Text>{' '}
              Eliminate your exposure to a single bank and offer your customers unparalleled peace of mind.
            </Text>
            <Flex
              mt={6}
              gap={4}
              alignItems={{ _: 'flex-start', md: 'center' }}
              flexDirection={{ _: 'column', md: 'row' }}
              justifyContent="start"
            >
              <div>
                <Button href="https://dashboard.column.com/register" type="link" variant="primary">
                  Start building <Icon.AnimatedArrowRight />
                </Button>
              </div>
              <Text>
                And email us
                <Br display={{ _: 'block', sm: 'none' }} />{' '}
                <EmailLink href="mailto:protected-deposits@column.com">protected-deposits@column.com</EmailLink>.
              </Text>
            </Flex>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};
