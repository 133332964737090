import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductBookTransfers: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="26" height="26">
        <path
          d="M11 18.5V29l18-9V9.5l-18 9zm9 3.58c-1.56.78-2.83.15-2.83-1.42 0-1.56 1.27-3.46 2.83-4.24 1.56-.78 2.83-.15 2.83 1.42 0 1.56-1.27 3.46-2.83 4.24z"
          fill="url(#32_instant-transfer-dark__paint0_linear)"
        />
        <path
          d="M14.83 11.34c0 1.56-1.27 3.46-2.83 4.24-1.56.78-2.83.15-2.83-1.42 0-1.56 1.27-3.46 2.83-4.24 1.56-.78 2.83-.15 2.83 1.42z"
          fill="url(#32_instant-transfer-dark__paint1_linear)"
        />
        <path
          d="M22.83 17.84c0 1.56-1.27 3.46-2.83 4.24-1.56.78-2.83.15-2.83-1.42 0-1.56 1.27-3.46 2.83-4.24 1.56-.78 2.83-.15 2.83 1.42z"
          fill="url(#32_instant-transfer-dark__paint2_linear)"
        />
        <path
          d="M3 12v10.5l18-9V3L3 12zm9 3.58c-1.56.78-2.83.15-2.83-1.42 0-1.56 1.27-3.46 2.83-4.24 1.56-.78 2.83-.15 2.83 1.42 0 1.56-1.27 3.46-2.83 4.24z"
          fill="url(#32_instant-transfer-dark__paint3_linear)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#32_instant-transfer-dark__paint4_linear)" />
      </g>
      <defs>
        <linearGradient
          id="32_instant-transfer-dark__paint0_linear"
          x1="18.561"
          y1="17.826"
          x2="34.024"
          y2="33.127"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_instant-transfer-dark__paint1_linear"
          x1="1.323"
          y1="2.666"
          x2="14.37"
          y2="14.988"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_instant-transfer-dark__paint2_linear"
          x1="16.614"
          y1="16.557"
          x2="24.285"
          y2="22.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_instant-transfer-dark__paint3_linear"
          x1="20.084"
          y1="20.288"
          x2="2.205"
          y2="3.617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_instant-transfer-dark__paint4_linear"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
