import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { InView } from 'react-intersection-observer';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { MarkdownStyles } from '~/elements/Markdown';
import { MarkdownInfo } from '~/components/Markdown/Info';
import { MarkdownContent } from '~/components/Markdown/Content';
import { MarkdownSyntax } from '~/components/Markdown/Syntax';
import { MarkdownEndpoint } from '~/components/Markdown/Endpoint';
import { MarkdownParameters } from '~/components/Markdown/Parameters';
import { MarkdownRequest } from '~/components/Markdown/Request';
import { MarkdownResponse } from '~/components/Markdown/Response';
import { MarkdownHeadline } from '~/components/Markdown/Headline';
import { Layout } from '~/components/docs/Layout';

const components = {
  Info: MarkdownInfo,
  Content: MarkdownContent,
  Syntax: MarkdownSyntax,
  Endpoint: MarkdownEndpoint,
  Parameters: MarkdownParameters,
  Request: MarkdownRequest,
  Response: MarkdownResponse,
  Headline: MarkdownHeadline,
};

const APIDocsContainer = styled(MarkdownStyles)`
  display: grid;
  gap: var(--space-8);
`;

const EntryContainer = styled.div`
  /* max-width: 1400px; */
  margin: 0 auto;
  display: grid;
  justify-items: end;
  grid-template-columns: 3fr minmax(380px, 2fr);
`;

const APIIndexPage: React.FC = (props: any) => {
  return (
    <Layout>
      <Helmet>
        <title>Column — Documentation</title>
        <meta
          name="description"
          content="Learn how to utilize Column's robust banking APIs to build new financial services."
        />
      </Helmet>

      <APIDocsContainer>
        <MDXProvider components={components}>
          {props.data.allFile.edges.map((edge: any, index: number) => {
            const data = edge.node.childMdx;
            const paths = data.slug.split('/');
            const pathFirst = paths[0].split('-');
            const pathSecond = paths[1].split('-');
            delete pathFirst[0];
            delete pathSecond[0];
            const newSlug = `${pathFirst.filter((a: any) => a).join('-')}/${pathSecond
              .filter((a: any) => a)
              .join('-')}`;

            return (
              <InView
                key={`${newSlug}-${index}`}
                id={newSlug}
                rootMargin="0px 0px -100% 0px"
                onChange={(inView) => {
                  if (!inView || typeof window === 'undefined' || !history.pushState) {
                    return;
                  }

                  window.dispatchEvent(
                    new CustomEvent('historyChanged', {
                      detail: {
                        url: `#${newSlug}`,
                      },
                    })
                  );

                  history.pushState(null, '', `#${newSlug}`);
                }}
              >
                <EntryContainer key={index}>
                  <MDXRenderer>{data.body}</MDXRenderer>
                </EntryContainer>
              </InView>
            );
          })}
        </MDXProvider>
      </APIDocsContainer>
    </Layout>
  );
};

export default APIIndexPage;

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "dataApi" } }, sort: { fields: childrenMdx___slug }) {
      edges {
        node {
          childMdx {
            body
            slug
          }
        }
      }
    }
  }
`;
