import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { MarkdownInfo } from '../Markdown/Info';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { MarkdownStyles } from '~/elements/Markdown';
import { Icon } from '~/elements/Icon';
import { Flex } from '~/elements/Flex';
import { Heading } from '~/elements/Heading';
import { Layout } from '~/components/docs/Layout';
import { Link } from '~/elements/Link';

const components = { Info: MarkdownInfo };

const GuideContainer = styled(Container)`
  transition: transform 0.2s;

  .scroll-up & {
    transform: translateY(62px);
  }

  .scroll-down & {
    transform: translateY(0);
  }
`;

const StyledViewport = styled(ScrollArea.Viewport)`
  height: 100vh;
`;

const StyledScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 4px;
  width: 12px;
  z-index: 10;
  &[data-orientation='vertical'] {
    width: SCROLLBAR_SIZE;
  }
  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: SCROLLBAR_SIZE;
  }
`;

const StyledThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background: rgba(var(--rgb-blue-200), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--rgb-blue-300), 0.65);
  border-radius: var(--radii-pill);
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    z-index: 10;
  }
`;

const IconGuide = styled(Icon.Guides)`
  width: 32px;
  height: 32px !important;
`;

const Guide: React.FC = (props: any) => {
  return (
    <Layout>
      <Helmet>
        <title>{props.data.mdx.frontmatter.title} - Column Documentation</title>
      </Helmet>

      <GuideContainer width="xlarge" className="text">
        <Grid gap={8} gridTemplateColumns={12} pt={8}>
          <Column
            gridColumn={{ _: 'span-12', md: 'span-10', lg: 'span-7' }}
            gridColumnStart={{ _: 1, md: 2, lg: 3 }}
            pt={12}
          >
            <Flex gap={2} mt={8} mb={4} alignItems="center">
              <Link to="/docs/guides">
                <Heading size="300" weight="book" color="gray-500">
                  Guide
                </Heading>
              </Link>
            </Flex>

            <Heading as="h1" size="600" weight="semibold">
              {props.data.mdx.frontmatter.title}
            </Heading>
            <MarkdownStyles mt={3} mb={18}>
              <MDXProvider components={components}>
                <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
              </MDXProvider>
            </MarkdownStyles>
          </Column>

          {/* <Column gridColumn={{ _: 'span-12', lg: '9 / span 4' }} pt={20}>
            <Box position="sticky" top={6} height="100vh" overflow="hidden">
              <ScrollArea.Root>
                <StyledViewport>
                  <Box mb={8}>
                    <Heading size="200" color="gray-400" textTransform="uppercase" mb={4}>
                      Contents
                    </Heading>
                    <TableOfContents items={props.data.mdx.tableOfContents.items} />
                  </Box>
                </StyledViewport>
                <StyledScrollbar orientation="vertical">
                  <StyledThumb />
                </StyledScrollbar>
              </ScrollArea.Root>
            </Box>
          </Column> */}
        </Grid>
      </GuideContainer>
    </Layout>
  );
};

export default Guide;

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
