import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductDebtFinancing: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="6" width="26" height="21">
        <path
          d="M15.51 9.17c0 .56-1.35 1.01-3 1.01s-3-.45-3-1.01 1.35-1.02 3-1.02 3 .46 3 1.02z"
          fill="url(#32_debt-financing-dark__paint0_linear)"
        />
        <path
          d="M22 8.53v4.5c-2.84.16-5 .98-5 1.97v4.52c-1.06-.33-2.46-.52-4-.52-.85 0-1.67.06-2.4.17v-6.84L22 8.53z"
          fill="url(#32_debt-financing-dark__paint1_linear)"
        />
        <path
          d="M19 21v4c0 1.1-2.69 2-6 2s-6-.9-6-2v-4c0 1.1 2.69 2 6 2s6-.9 6-2z"
          fill="url(#32_debt-financing-dark__paint2_linear)"
        />
        <path
          d="M14.4 6L3 9.8l7.6 2.53L22 8.53 14.4 6zm-1.89 4.18c-1.65 0-3-.45-3-1.01s1.35-1.02 3-1.02 3 .46 3 1.02-1.35 1.01-3 1.01z"
          fill="url(#32_debt-financing-dark__paint3_linear)"
        />
        <path
          d="M10.6 12.33v6.84C8.48 19.48 7 20.18 7 21v.06l-4-1.33V9.8l7.6 2.53z"
          fill="url(#32_debt-financing-dark__paint4_linear)"
        />
        <path
          d="M23 17c3.314 0 6-.895 6-2s-2.686-2-6-2-6 .895-6 2 2.686 2 6 2z"
          fill="url(#32_debt-financing-dark__paint5_linear)"
        />
        <path
          d="M13 23c3.314 0 6-.895 6-2s-2.686-2-6-2-6 .895-6 2 2.686 2 6 2z"
          fill="url(#32_debt-financing-dark__paint6_linear)"
        />
        <path
          d="M29 15v7c0 1.1-2.69 2-6 2-1.54 0-2.94-.19-4-.52V21c0-.59-.77-1.12-2-1.48V15c0 1.1 2.69 2 6 2s6-.9 6-2z"
          fill="url(#32_debt-financing-dark__paint7_linear)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0z" fill="url(#32_debt-financing-dark__paint8_linear)" />
      </g>
      <defs>
        <linearGradient
          id="32_debt-financing-dark__paint0_linear"
          x1="15.934"
          y1="10.896"
          x2="9.108"
          y2="7.453"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_debt-financing-dark__paint1_linear"
          x1="11.199"
          y1="9.886"
          x2="42.286"
          y2="34.047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="32_debt-financing-dark__paint2_linear"
          x1="7"
          y1="24"
          x2="19"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset=".34" stopOpacity="0" />
          <stop offset=".67" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_debt-financing-dark__paint3_linear"
          x1="17.435"
          y1="11.861"
          x2="3.059"
          y2="4.008"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_debt-financing-dark__paint4_linear"
          x1="10.507"
          y1="11.616"
          x2=".36"
          y2="19.468"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_debt-financing-dark__paint5_linear"
          x1="32.265"
          y1="15"
          x2="11.822"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset=".5" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_debt-financing-dark__paint6_linear"
          x1="22.265"
          y1="21"
          x2="1.822"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset=".5" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_debt-financing-dark__paint7_linear"
          x1="17"
          y1="19.5"
          x2="29"
          y2="19.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset=".34" stopOpacity="0" />
          <stop offset=".67" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="32_debt-financing-dark__paint8_linear"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
