import { css } from 'styled-components';
import { body100, body200, body300, body400, body500, body600 } from '~/ds/mixins/typography';

export const List = css`
  .list-diamond {
    margin-top: var(--space-2);

    .list-item {
      position: relative;
      padding-left: var(--space-8);
      padding-top: var(--space-2);
      padding-bottom: var(--space-2);
      margin-top: 0;
      position: relative;

      .icon--diamond {
        height: var(--space-4);
        position: absolute;
        left: 0;
        top: 0.65em;
      }
    }
  }

  ol,
  .list-numbered {
    list-style: none;
    counter-reset: numbered-list;

    .list-item {
      counter-increment: numbered-list;

      &::before {
        content: counter(numbered-list) '. ';
        color: inherit;
      }
    }
  }

  .stylized-list-numbered {
    list-style: none;
    counter-reset: numbered-list;

    .list-item {
      counter-increment: numbered-list;

      &::before {
        content: '0' counter(numbered-list);
        ${body400}
        margin-bottom: var(--space-3);
        display: block;
        color: transparent;
        font-variant-numeric: slashed-zero;
        background: var(--list-item-number);
        -webkit-background-clip: text;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .list-100 {
    .text-body {
      ${body100}
    }
  }

  .list-200 {
    .text-body {
      ${body200}
    }
  }

  .list-300 {
    .text-body {
      ${body300}
    }
  }

  .list-400 {
    .text-body {
      ${body400}
    }
  }

  .list-500 {
    .text-body {
      ${body500}
    }
  }

  .list-600 {
    .text-body {
      ${body600}
    }
  }
`;
