import { css } from 'styled-components';

export const Static = css`
  .grecaptcha-badge {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
  .static {
    position: static;
  }
  .fixed {
    position: fixed;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .bottom-0 {
    bottom: 0px;
  }
  .left-0 {
    left: 0px;
  }
  .right-0 {
    right: 0px;
  }
  .-top-96 {
    top: -24rem;
  }
  .-bottom-20 {
    bottom: -5rem;
  }
  .-top-20 {
    top: -5rem;
  }
  .left-1\\/2 {
    left: 50%;
  }
  .bottom-0\\.5 {
    bottom: 0.125rem;
  }
  .z-10 {
    z-index: 10;
  }
  .z-0 {
    z-index: 0;
  }
  .z-\\[99999\\] {
    z-index: 99999;
  }
  .col-span-8 {
    grid-column: var(--span-8);
  }
  .col-span-12 {
    grid-column: var(--span-12);
  }
  .col-span-2 {
    grid-column: var(--span-2);
  }
  .col-span-6 {
    grid-column: var(--span-6);
  }
  .col-span-10 {
    grid-column: var(--span-10);
  }
  .col-span-1 {
    grid-column: var(--span-1);
  }
  .col-start-2 {
    grid-column-start: 2;
  }
  .col-start-4 {
    grid-column-start: 4;
  }
  .float-right {
    float: right;
  }
  .float-left {
    float: left;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-\\[25\\%\\] {
    margin-top: 25%;
    margin-bottom: 25%;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-0 {
    margin-top: 0px;
  }
  .mt-2 {
    margin-top: 0.5rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }
  .mt-16 {
    margin-top: 4rem;
  }
  .mt-1 {
    margin-top: 0.25rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .-mt-1 {
    margin-top: -0.25rem;
  }
  .mt-18 {
    margin-top: 4.5rem;
  }
  .mt-14 {
    margin-top: 3.5rem;
  }
  .mt-24 {
    margin-top: 6rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mt-36 {
    margin-top: 9rem;
  }
  .-ml-\\[\\.75vw\\] {
    margin-left: -0.75vw;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mr-4 {
    margin-right: 1rem;
  }
  .-mr-12 {
    margin-right: -3rem;
  }
  .ml-auto {
    margin-left: auto;
  }
  .-ml-3 {
    margin-left: -0.75rem;
  }
  .mb-12 {
    margin-bottom: 3rem;
  }
  .mr-1 {
    margin-right: 0.25rem;
  }
  .ml-2 {
    margin-left: 0.5rem;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .inline {
    display: inline;
  }
  .flex {
    display: flex;
  }
  .inline-flex {
    display: inline-flex;
  }
  .table {
    display: table;
  }
  .grid {
    display: grid;
  }
  .contents {
    display: contents;
  }
  .list-item {
    display: list-item;
  }
  .hidden {
    display: none;
  }
  .h-full {
    height: 100%;
  }
  .h-24 {
    height: 6rem;
  }
  .h-4 {
    height: 1rem;
  }
  .h-6 {
    height: 1.5rem;
  }
  .h-48 {
    height: 12rem;
  }
  .h-12 {
    height: 3rem;
  }
  .h-screen {
    height: 100vh;
  }
  .h-5 {
    height: 1.25rem;
  }
  .h-10 {
    height: 2.5rem;
  }
  .max-h-60 {
    max-height: 15rem;
  }
  .min-h-screen {
    min-height: 100vh;
  }
  .w-4\\/5 {
    width: 80%;
  }
  .w-full {
    width: 100%;
  }
  .w-1\\/2 {
    width: 50%;
  }
  .w-4 {
    width: 1rem;
  }
  .w-12 {
    width: 3rem;
  }
  .max-w-lg {
    max-width: 32rem;
  }
  .max-w-screen-sm {
    max-width: 680px;
  }
  .max-w-xs {
    max-width: 20rem;
  }
  .max-w-6xl {
    max-width: 72rem;
  }
  .max-w-full {
    max-width: 100%;
  }
  .flex-auto {
    flex: 1 1 auto;
  }
  .grow {
    flex-grow: 1;
  }
  .border-collapse {
    border-collapse: collapse;
  }
  .translate-y-1\\/2 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .-translate-x-1\\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .list-none {
    list-style-type: none;
  }
  .auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .grid-cols-1 {
    grid-template-columns: var(--cols-1);
  }
  .grid-cols-12 {
    grid-template-columns: var(--cols-12);
  }
  .grid-cols-6 {
    grid-template-columns: var(--cols-6);
  }
  .grid-cols-2 {
    grid-template-columns: var(--cols-2);
  }
  .flex-col {
    flex-direction: column;
  }
  .place-content-center {
    place-content: center;
  }
  .place-items-center {
    place-items: center;
  }
  .content-center {
    align-content: center;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-end {
    align-items: flex-end;
  }
  .items-center {
    align-items: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .gap-14 {
    gap: 3.5rem;
  }
  .gap-2 {
    gap: 0.5rem;
  }
  .gap-24 {
    gap: 6rem;
  }
  .gap-6 {
    gap: 1.5rem;
  }
  .gap-1 {
    gap: 0.25rem;
  }
  .gap-8 {
    gap: 2rem;
  }
  .gap-3 {
    gap: 0.75rem;
  }
  .gap-4 {
    gap: 1rem;
  }
  .gap-0\\.5 {
    gap: 0.125rem;
  }
  .gap-0 {
    gap: 0px;
  }
  .gap-x-6 {
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .gap-y-16 {
    row-gap: 4rem;
  }
  .gap-x-8 {
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
  .gap-y-8 {
    row-gap: 2rem;
  }
  .gap-y-18 {
    row-gap: 4.5rem;
  }
  .gap-y-6 {
    row-gap: 1.5rem;
  }
  .gap-y-2 {
    row-gap: 0.5rem;
  }
  .gap-y-1 {
    row-gap: 0.25rem;
  }
  .gap-x-12 {
    -moz-column-gap: 3rem;
    column-gap: 3rem;
  }
  .gap-y-4 {
    row-gap: 1rem;
  }
  .gap-x-0 {
    -moz-column-gap: 0px;
    column-gap: 0px;
  }
  .justify-self-start {
    justify-self: start;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .overflow-y-auto {
    overflow-y: auto;
  }
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .rounded-sm {
    border-radius: 0.25rem;
  }
  .rounded {
    border-radius: 0.5rem;
  }
  .rounded-full {
    border-radius: 9999px;
  }
  .border {
    border-width: 1px;
  }
  .border-t {
    border-top-width: 1px;
  }
  .border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(var(--rgb-gray-100), var(--tw-border-opacity));
  }
  .border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(var(--rgb-gray-200), var(--tw-border-opacity));
  }
  .border-white {
    --tw-border-opacity: 1;
    border-color: rgba(var(--rgb-0), var(--tw-border-opacity));
  }
  .border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--rgb-white), var(--tw-bg-opacity));
  }
  .bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--rgb-blue-50), var(--tw-bg-opacity));
  }
  .bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--rgb-gray-50), var(--tw-bg-opacity));
  }
  .bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--rgb-gray-100), var(--tw-bg-opacity));
  }
  .bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--rgb-blue-800), var(--tw-bg-opacity));
  }
  .bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--rgb-1000), var(--tw-bg-opacity));
  }
  .bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .object-cover {
    -o-object-fit: cover;
    object-fit: cover;
  }
  .object-left-top {
    -o-object-position: left top;
    object-position: left top;
  }
  .py-px {
    padding-top: .0625rem;
    padding-bottom: .0625rem;
  }
  .p-1 {
    padding: 0.25rem;
  }
  .p-3 {
    padding: 0.75rem;
  }
  .py-18 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pr-4 {
    padding-right: 1rem;
  }
  .pb-8 {
    padding-bottom: 2rem;
  }
  .pt-8 {
    padding-top: 2rem;
  }
  .pb-24 {
    padding-bottom: 6rem;
  }
  .pt-0 {
    padding-top: 0px;
  }
  .pb-0 {
    padding-bottom: 0px;
  }
  .pt-24 {
    padding-top: 6rem;
  }
  .pr-10 {
    padding-right: 2.5rem;
  }
  .pr-8 {
    padding-right: 2rem;
  }
  .pt-16 {
    padding-top: 4rem;
  }
  .pb-18 {
    padding-bottom: 4.5rem;
  }
  .pr-6 {
    padding-right: 1.5rem;
  }
  .pb-4 {
    padding-bottom: 1rem;
  }
  .pl-3 {
    padding-left: 0.75rem;
  }
  .pt-3 {
    padding-top: 0.75rem;
  }
  .pb-2 {
    padding-bottom: 0.5rem;
  }
  .pt-4 {
    padding-top: 1rem;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .align-middle {
    vertical-align: middle;
  }
  .text-b-400 {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
  .text-b-100 {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-b-200 {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .text-b-300 {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  .text-b-500 {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.01em;
  }
  .text-b-600 {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.01em;
  }
  .text-h-100 {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
  .text-h-200 {
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .text-h-300 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .text-h-400 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .text-h-500 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  .text-h-600 {
    font-size: 2rem;
    line-height: 2.25rem;
  }
  .text-h-700 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .text-h-800 {
    font-size: 2.75rem;
    line-height: 3rem;
    letter-spacing: -0.01em;
  }
  .text-h-900 {
    font-size: 3.5rem;
    line-height: 3.75rem;
    letter-spacing: -0.02em;
  }
  .text-h-1000 {
    font-size: 4.5rem;
    line-height: 4.5rem;
    letter-spacing: -0.03em;
  }
  .font-medium {
    font-weight: 500;
  }
  .font-book {
    font-weight: 400;
  }
  .font-regular {
    font-weight: 300;
  }
  .font-semibold {
    font-weight: 600;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .lowercase {
    text-transform: lowercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(var(--rgb-blue-600), var(--tw-text-opacity));
  }
  .text-white {
    --tw-text-opacity: 1;
    color: rgba(var(--rgb-0), var(--tw-text-opacity));
  }
  .text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(var(--rgb-blue-800), var(--tw-text-opacity));
  }
  .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(var(--rgb-gray-500), var(--tw-text-opacity));
  }
  .text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(var(--rgb-gray-900), var(--tw-text-opacity));
  }
  .text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(var(--rgb-gray-400), var(--tw-text-opacity));
  }
  .text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .underline {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  .opacity-30 {
    opacity: 0.3;
  }
  .opacity-50 {
    opacity: 0.5;
  }
  .opacity-25 {
    opacity: 0.25;
  }
  .opacity-0 {
    opacity: 0;
  }
  .opacity-100 {
    opacity: 1;
  }
  .outline {
    outline-style: solid;
  }
  .blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
      var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
      var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
      var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .backdrop-filter {
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }
  .transition {
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter,
      -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
      transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
      transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .duration-300 {
    transition-duration: 300ms;
  }
  .duration-200 {
    transition-duration: 200ms;
  }
  .ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .inherit {
    display: inherit;
  }
  .ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .hover\\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(var(--rgb-blue-700), var(--tw-text-opacity));
  }
  .hover\\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(var(--rgb-gray-900), var(--tw-text-opacity));
  }
  @media (min-width: 640px) {
    .sm\\:.col-start-2 {
      grid-column-start: 2;
    }
    .sm\\:items-center {
      align-items: center;
    }
    .sm\\:col-span-9 {
      grid-column: var(--span-9);
    }
    .sm\\:col-span-6 {
      grid-column: var(--span-6);
    }
    .sm\\:col-span-3 {
      grid-column: var(--span-3);
    }
    .sm\\:mt-6 {
      margin-top: 1.5rem;
    }
    .sm\\:ml-6 {
      margin-left: 1.5rem;
    }
    .sm\\:mt-0 {
      margin-top: 0px;
    }
    .sm\\:inline-block {
      display: inline-block;
    }
    .sm\\:grid {
      display: grid;
    }
    .sm\\:translate-y-0 {
      --tw-translate-y: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .sm\\:grid-cols-2 {
      grid-template-columns: var(--cols-2);
    }
    .sm\\:flex-row {
      flex-direction: row;
    }
    .sm\\:gap-4 {
      gap: 1rem;
    }
  }
  @media (min-width: 768px) {
    .md\\:-left-8 {
      left: -2rem;
    }
    .md\\:left-16 {
      left: 4rem;
    }
    .md\\:col-span-2 {
      grid-column: var(--span-2);
    }
    .md\\:col-span-5 {
      grid-column: var(--span-5);
    }
    .md\\:col-span-4 {
      grid-column: var(--span-4);
    }
    .md\\:col-span-8 {
      grid-column: var(--span-8);
    }
    .md\\:col-span-6 {
      grid-column: var(--span-6);
    }
    .md\\:col-span-11 {
      grid-column: var(--span-11);
    }
    .md\\:col-span-9 {
      grid-column: var(--span-9);
    }
    .md\\:col-span-7 {
      grid-column: var(--span-7);
    }
    .md\\:col-span-10 {
      grid-column: var(--span-10);
    }
    .md\\:col-start-9 {
      grid-column-start: 9;
    }
    .md\\:col-start-3 {
      grid-column-start: 3;
    }
    .md\\:col-start-8 {
      grid-column-start: 8;
    }
    .md\\:col-start-7 {
      grid-column-start: 7;
    }
    .md\\:col-start-2 {
      grid-column-start: 2;
    }
    .md\\:col-end-13 {
      grid-column-end: 13;
    }
    .md\\:mt-0 {
      margin-top: 0px;
    }
    .md\\:-mr-8 {
      margin-right: -2rem;
    }
    .md\\:mt-10 {
      margin-top: 2.5rem;
    }
    .md\\:mt-18 {
      margin-top: 4.5rem;
    }
    .md\\:-mt-12 {
      margin-top: -3rem;
    }
    .md\\:block {
      display: block;
    }
    .md\\:inline-block {
      display: inline-block;
    }
    .md\\:flex {
      display: flex;
    }
    .md\\:hidden {
      display: none;
    }
    .md\\:h-8 {
      height: 2rem;
    }
    .md\\:h-96 {
      height: 24rem;
    }
    .md\\:w-full {
      width: 100%;
    }
    .md\\:max-w-sm {
      max-width: 24rem;
    }
    .md\\:translate-x-0 {
      --tw-translate-x: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\\:grid-cols-3 {
      grid-template-columns: var(--cols-3);
    }
    .md\\:grid-cols-2 {
      grid-template-columns: var(--cols-2);
    }
    .md\\:grid-cols-1 {
      grid-template-columns: var(--cols-1);
    }
    .md\\:flex-col {
      flex-direction: column;
    }
    .md\\:items-center {
      align-items: center;
    }
    .md\\:justify-between {
      justify-content: space-between;
    }
    .md\\:gap-20 {
      gap: 5rem;
    }
    .md\\:gap-8 {
      gap: 2rem;
    }
    .md\\:gap-3 {
      gap: 0.75rem;
    }
    .md\\:gap-y-8 {
      row-gap: 2rem;
    }
    .md\\:gap-y-18 {
      row-gap: 4.5rem;
    }
    .md\\:overflow-hidden {
      overflow: hidden;
    }
    .md\\:rounded {
      border-radius: 0.5rem;
    }
    .md\\:py-24 {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
    .md\\:py-18 {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
    }
    .md\\:px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .md\\:pb-64 {
      padding-bottom: 16rem;
    }
    .md\\:pb-32 {
      padding-bottom: 8rem;
    }
    .md\\:pb-16 {
      padding-bottom: 4rem;
    }
    .md\\:pb-24 {
      padding-bottom: 6rem;
    }
    .md\\:text-b-500 {
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: -0.01em;
    }
    .md\\:text-b-300 {
      font-size: 1rem;
      line-height: 1.375rem;
    }
    .md\\:\\[justify-content\\:normal\\] {
      justify-content: normal;
    }
  }
  @media (min-width: 1112px) {
    .lg\\:col-span-5 {
      grid-column: var(--span-5);
    }
    .lg\\:col-span-7 {
      grid-column: var(--span-7);
    }
    .lg\\:col-span-6 {
      grid-column: var(--span-6);
    }
    .lg\\:col-span-8 {
      grid-column: var(--span-8);
    }
    .lg\\:col-start-3 {
      grid-column-start: 3;
    }
    .lg\\:mr-0 {
      margin-right: 0px;
    }
    .lg\\:-mt-12 {
      margin-top: -3rem;
    }
    .lg\\:block {
      display: block;
    }
    .lg\\:flex {
      display: flex;
    }
    .lg\\:flex-shrink {
      flex-shrink: 1;
    }
    .lg\\:grid-cols-3 {
      grid-template-columns: var(--cols-3);
    }
    .lg\\:flex-row {
      flex-direction: row;
    }
    .lg\\:flex-col {
      flex-direction: column;
    }
    .lg\\:items-start {
      align-items: flex-start;
    }
    .lg\\:gap-8 {
      gap: 2rem;
    }
  }
  @media (min-width: 870px) {
    .nav\\:flex {
      display: flex;
    }
    .nav\\:hidden {
      display: none;
    }
    .nav\\:grid-flow-col {
      grid-auto-flow: column;
    }
    .nav\\:grid-cols-3 {
      grid-template-columns: var(--cols-3);
    }
    .nav\\:grid-cols-2 {
      grid-template-columns: var(--cols-2);
    }
  }

  .ach-card {
    width: 340px;
    position: absolute;
    top: 32px;
    left: 50%;
    margin-left: -170px;
    background: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    padding: 28px 32px;
    box-sizing: border-box;
    box-shadow: 0px 41px 33px rgba(0, 0, 0, 0.02), 0px 22px 17px rgba(0, 0, 0, 0.02), 0px 12px 10px rgba(0, 0, 0, 0.03),
      0px 6px 5px rgba(0, 0, 0, 0.04), 0px 3px 2px rgba(0, 0, 0, 0.05);
    max-height: 92px;
    transform: scale(0.8);
    opacity: 0;
    -webkit-animation: ach-show 0.5s ease 0.35s forwards;
    animation: ach-show 0.5s ease 0.35s forwards;
  }

  @media (min-width: 768px) {
    .ach-card {
      left: auto;
      margin-left: 0;
    }
  }

  @-webkit-keyframes ach-show {
    50% {
      max-height: 92px;
    }
    50%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      max-height: 464px;
    }
  }

  @keyframes ach-show {
    50% {
      max-height: 92px;
    }
    50%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      max-height: 464px;
    }
  }

  .ach-card .top {
    display: flex;
  }

  .ach-card .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: -0.004em;
    color: #232730;
    display: block;
    margin-left: 12px;
  }

  .ach-card .amount {
    font-feature-settings: 'tnum';
    background: var(--color-blue-50);
    border-radius: 4px;
    color: var(--color-blue-600);
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 36px;
    padding: 0 8px;
  }

  .ach-card ul {
    padding: 0;
    margin: 36px 0 0 0;
    list-style: none;
  }

  .ach-card ul li {
    --line-scale: 0;
    --path-offset: 30px;
    --tick-offset: 11px;
    --mask-position: 100%;

    position: relative;
    font-size: 12px;
    line-height: 20px;
    color: #575a64;
    padding-left: 44px;
  }

  .ach-card ul li:not(:last-child) {
    padding-bottom: 48px;
  }

  .ach-card ul li:not(:last-child):before {
    content: '';
    top: 20px;
    left: 21px;
    width: 2px;
    position: absolute;
    background-color: #5884f3;
    bottom: -1px;
    transform-origin: 50% 0;
    transform: scale(0.75, var(--line-scale));
  }

  .ach-card ul li div {
    -webkit-mask-position: var(--mask-position) 0%;
    mask-position: var(--mask-position) 0%;
    -webkit-mask-size: 400% 100%;
    mask-size: 400% 100%;
    -webkit-mask-image: linear-gradient(90deg, black 0%, black 25%, transparent 75%, transparent 100%);
    mask-image: linear-gradient(90deg, black 0%, black 25%, transparent 75%, transparent 100%);
  }

  .ach-card ul li svg {
    fill: none;
    position: absolute;
    left: 11px;
    top: 0;
    width: 22px;
    height: 22px;
    stroke: #5884f3;
    stroke-width: 1.5;
  }

  .ach-card ul li svg path:nth-child(1),
  .ach-card ul li svg path:nth-child(2) {
    stroke-dasharray: 30px;
    stroke-dashoffset: var(--path-offset);
  }

  .ach-card ul li svg path:nth-child(3) {
    stroke-dasharray: 11px;
    stroke-dashoffset: var(--tick-offset);
  }

  .ach-card ul li strong {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #232730;
  }

  .product-hero-accounts-list {
    position: absolute;
    top: 58px;
    left: -40px;
    right: 0;
    display: flex;
    overflow: hidden;
    padding: 80px 0 80px 80px;
  }

  @media (max-width: 480px) {
    .product-hero-accounts-list {
      left: 60px;
    }
  }

  .product-hero-accounts-card {
    --b: 1;
    --ry: 0deg;
    --w: 332px;
    --badge-o: 1;
    --name-o: 1;
    --info-o: 1;
    --balance-o: 1;
    --created-o: 1;
    --price-s: 1;

    width: var(--w);
    flex: 0 0 var(--w);
    position: relative;
    will-change: width;
    transform: perspective(800px) rotateY(var(--ry));
  }

  @media (max-width: 480px) {
    .product-hero-accounts-card {
      --w: 292px;
    }
  }

  .product-hero-accounts-card.load:nth-child(1),
  .product-hero-accounts-card.load:nth-child(2) {
    opacity: 0;
    transform: translateX(40px) perspective(800px) rotateY(var(--ry));
    -webkit-animation: fadeIn 0.35s ease 0s forwards;
    animation: fadeIn 0.35s ease 0s forwards;
  }

  .product-hero-accounts-card.load:nth-child(2) {
    -webkit-animation-delay: 0.35s;
    animation-delay: 0.35s;
  }

  @-webkit-keyframes fadeIn {
    to {
      opacity: 1;
      transform: perspective(800px) rotateY(var(--ry));
    }
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: perspective(800px) rotateY(var(--ry));
    }
  }

  .product-hero-accounts-card.init {
    --b: 0;
    --w: 0px;
    --badge-o: 0;
    --name-o: 0;
    --info-o: 0;
    --balance-o: 0;
    --created-o: 0;
  }

  .product-hero-accounts-card:before {
    content: '';
    position: absolute;
    inset: 0 32px 0 0;
    background: rgba(255, 255, 255, var(--b));
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    box-shadow: 0px 41px 33px rgba(0, 0, 0, 0.02), 0px 22px 17px rgba(0, 0, 0, 0.02), 0px 12px 10px rgba(0, 0, 0, 0.03),
      0px 6px 5px rgba(0, 0, 0, 0.04), 0px 3px 2px rgba(0, 0, 0, 0.05);
  }

  .product-hero-accounts-card .inner {
    position: relative;
    z-index: 1;
    height: 260px;
    box-sizing: border-box;
    padding: 16px 92px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 480px) {
    .product-hero-accounts-card .inner {
      padding: 16px 52px 20px 20px;
    }
  }

  .product-hero-accounts-card .badge {
    color: #232730;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    opacity: var(--badge-o);
  }

  .product-hero-accounts-card .label {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.004em;
    color: #a9acb6;
    display: block;
  }

  .product-hero-accounts-card .name {
    opacity: var(--name-o);
  }

  .product-hero-accounts-card .name strong {
    font-weight: 600;
    font-size: 18px;
    line-height: 14px;
    letter-spacing: -0.004em;
    color: #232730;
  }

  .product-hero-accounts-card .info {
    padding: 12px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: grid;
    grid-gap: 20px;
    color: #575a64;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    max-width: 212px;
    opacity: var(--info-o);
  }

  @media (max-width: 480px) {
    .product-hero-accounts-card .info {
      max-width: 100%;
    }
  }

  .product-hero-accounts-card .info .balance {
    opacity: var(--balance-o);
  }

  .product-hero-accounts-card .info .balance strong {
    display: inline-block;
    transform: scale(var(--price-s)) translateZ(0);
  }

  .product-hero-accounts-card .info .created {
    opacity: var(--created-o);
  }

  .product-hero-accounts-card strong {
    font-feature-settings: 'tnum';
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.004em;
    color: #3b3e47;
  }

  .product-hero-instant-list {
    --card-1-y: -50%;
    --card-1-b: 1;
    --card-2-y: -15%;
    --card-2-b-y: -100%;
    --card-2-overflow: hidden;
    --card-2-inner: 0;
    --card-2-shadow: 0;

    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -200px;
  }

  @media (min-width: 768px) {
    .product-hero-instant-list {
      left: auto;
      margin-left: 0;
    }
  }

  .product-hero-instant-card {
    --b: 1;
    --inner-o: 1;
    --shadow: 1;

    position: absolute;
    left: 0;
    top: 0;
    height: 170px;
    width: 400px;
  }

  @media (max-width: 480px) {
    .product-hero-instant-card {
      width: 360px;
      left: 20px;
    }
  }

  .product-hero-instant-card:first-child {
    --b: var(--card-1-b);
    --inner-o: 1;
    --shadow: 1;
    transform: translateY(var(--card-1-y));
    -webkit-animation: show 0.35s ease 0s;
    animation: show 0.35s ease 0s;
  }

  @-webkit-keyframes show {
    from {
      transform: translate(40px, var(--card-1-y)) scale(0.8);
      opacity: 0;
    }
  }

  @keyframes show {
    from {
      transform: translate(40px, var(--card-1-y)) scale(0.8);
      opacity: 0;
    }
  }

  .product-hero-instant-card:last-child {
    --b: 1;
    --inner-o: var(--card-2-inner);
    --shadow: var(--card-2-shadow);
    transform: translateY(var(--card-2-y));
    overflow: var(--card-2-overflow);
  }

  .product-hero-instant-card:before {
    content: '';
    position: absolute;
    inset: 16px 0;
    border-radius: 8px;
    box-shadow: 0px 41px 33px rgba(0, 0, 0, calc(0.02 * var(--shadow))),
      0px 22px 17px rgba(0, 0, 0, calc(0.02 * var(--shadow))), 0px 12px 10px rgba(0, 0, 0, calc(0.03 * var(--shadow))),
      0px 6px 5px rgba(0, 0, 0, calc(0.04 * var(--shadow))), 0px 3px 2px rgba(0, 0, 0, calc(0.05 * var(--shadow)));
  }

  .product-hero-instant-card:after {
    content: '';
    position: absolute;
    inset: 0;
    -webkit-clip-path: url(#clip-path-sent);
    clip-path: url(#clip-path-sent);
    background-color: rgba(255, 255, 255, var(--b));
  }

  .product-hero-instant-card:last-child:after {
    -webkit-clip-path: url(#clip-path-received);
    clip-path: url(#clip-path-received);
    transform: translateY(var(--card-2-b-y));
  }

  .product-hero-instant-card .inner {
    position: absolute;
    inset: 0;
    z-index: 1;
    box-sizing: border-box;
    padding: 36px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: var(--inner-o);
    letter-spacing: -0.004em;
    font-weight: 500;
  }

  .product-hero-instant-card .title {
    display: flex;
    justify-content: space-between;
  }

  .product-hero-instant-card .title strong {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #232730;
    display: block;
  }

  .product-hero-instant-card .title span {
    font-size: 12px;
    line-height: 20px;
    color: #575a64;
    display: block;
  }

  .product-hero-instant-card .info {
    box-shadow: inset 0 0 0 1px #e3e4e8;
    box-sizing: border-box;
    padding: 12px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .product-hero-instant-card .info .balance {
    color: #a41742;
    background: #f7d8da;
    border-radius: 4px;
    padding: 0 8px;
    line-height: 36px;
    font-weight: 500;
    font-size: 24px;
  }

  .product-hero-instant-card .info .balance.received {
    color: #72ac3f;
    background: #e6f3c7;
  }

  .product-hero-instant-card .info .label {
    font-size: 14px;
    line-height: 20px;
    color: #232730;
    display: block;
  }

  .product-hero-bin-element {
    --clip-y: 0px;
    --clip-round: 8px;
    --perspective: 0px;

    --x: 0px;
    --y: 0px;
    --rx: 0deg;
    --ry: 0deg;
    --rz: 0deg;

    --card-top-shadow-x: 0px;
    --card-top-shadow-y: 0px;
    --card-top-shadow-blur: 0px;
    --card-top-shadow-opacity: 0;

    --card-middle-xy: 0px;
    --card-middle-z: 0px;

    --card-bottom-xy: 0px;
    --card-bottom-z: 0px;

    --card-bottom-shadow-x: 0px;
    --card-bottom-shadow-y: 0px;
    --card-bottom-shadow-blur: 0px;
    --card-bottom-shadow-opacity: 0;

    width: 442px;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    perspective: 4000px;

    opacity: 0;
    transform: translateY(-50%) scale(0.75);
    -webkit-animation: scale 0.35s ease forwards 0.35s;
    animation: scale 0.35s ease forwards 0.35s;
  }

  @-webkit-keyframes scale {
    to {
      transform: translateY(-50%) scale(1);
      opacity: 1;
    }
  }

  @keyframes scale {
    to {
      transform: translateY(-50%) scale(1);
      opacity: 1;
    }
  }

  .product-hero-bin-element .inner {
    transform-style: preserve-3d;
    transform: translate(var(--x), var(--y)) rotateX(var(--rx)) rotateY(var(--ry)) rotateZ(var(--rz));
  }

  .product-hero-bin-element .inner .code {
    font-size: 0.75rem;
    line-height: 1.375rem;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background-color: rgba(var(--rgb-blue-800), 1);
    padding: 24px;
    -webkit-clip-path: inset(var(--clip-y) 0 var(--clip-y) 0 round var(--clip-round));
    clip-path: inset(var(--clip-y) 0 var(--clip-y) 0 round var(--clip-round));
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-family: SuisseIntlMono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
      monospace;
  }

  .product-hero-bin-element .inner .code:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 54px;
    z-index: 1;
    background: linear-gradient(270deg, rgb(var(--rgb-blue-800)) 60%, rgba(var(--rgb-blue-800), 0) 100%);
  }

  .product-hero-bin-element .inner .code .line {
    --line-x: 0px;
    --line-y: 0px;
    --line-rotate: 0deg;
    --line-blur: 0px;
    --line-opacity: 1;

    transform: translate(var(--line-x), var(--line-y)) rotate(var(--line-rotate));
    transform-origin: 0 50%;
    filter: blur(var(--line-blur));
    opacity: var(--line-opacity);
  }

  .product-hero-bin-element .inner .code .line:not(:first-child) {
    margin-left: 24px;
  }

  .product-hero-bin-element .inner .code .line .char {
    color: #7c7f88;
  }

  .product-hero-bin-element .inner .code .line .http {
    color: #7ea7e9;
  }

  .product-hero-bin-element .inner .code .line .header {
    color: #c1aaf1;
  }

  .product-hero-bin-element .inner .code .line.brackets {
    margin-left: 32px;
  }

  .product-hero-bin-element .inner .code .line.data {
    display: block;
    margin-left: 40px;
    color: #94efb7;
  }

  .product-hero-bin-element .inner .back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    position: absolute;
    inset: 24px 0;
  }

  .product-hero-bin-element .inner .card {
    position: absolute;
    inset: 0;
    filter: drop-shadow(
      var(--card-top-shadow-x) var(--card-top-shadow-y) var(--card-top-shadow-blur)
        rgba(35, 39, 48, var(--card-top-shadow-opacity))
    );
  }

  .product-hero-bin-element .inner .card:nth-child(2) {
    transform: translate3d(var(--card-middle-xy), var(--card-middle-xy), var(--card-middle-z));
    filter: drop-shadow(
      var(--card-bottom-shadow-x) var(--card-bottom-shadow-y) var(--card-bottom-shadow-blur)
        rgba(35, 39, 48, var(--card-bottom-shadow-opacity))
    );
  }

  .product-hero-bin-element .inner .card:first-child {
    transform: translate3d(var(--card-bottom-xy), var(--card-bottom-xy), var(--card-bottom-z));
    filter: drop-shadow(
      var(--card-bottom-shadow-x) var(--card-bottom-shadow-y) var(--card-bottom-shadow-blur)
        rgba(35, 39, 48, var(--card-bottom-shadow-opacity))
    );
  }

  .product-hero-bin-element .inner .card:before,
  .product-hero-bin-element .inner .card:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 12px;
  }

  .product-hero-bin-element .inner .card:before {
    transform: translate(calc(var(--perspective) * 2), calc(var(--perspective) * 2));
    background: linear-gradient(
        220deg,
        rgba(255, 255, 255, 0.75) 3%,
        rgba(255, 255, 255, 0.45) 20%,
        #ffffff 37.66%,
        rgba(255, 255, 255, 0.6) 42%,
        rgba(255, 255, 255, 0.36) 90%,
        rgba(255, 255, 255, 0.5) 92%
      ),
      #747785;
  }

  .product-hero-bin-element .inner .card:after {
    background: linear-gradient(302.2deg, #ffffff 6.1%, rgba(255, 255, 255, 0) 43.29%, #ffffff 96.18%), #eeeff2;
  }

  .product-hero-bin-element .inner .card .name {
    position: absolute;
    z-index: 1;
    left: 36px;
    top: 32px;
    width: 204px;
    height: 30px;
    fill: #cfd0d8;
    filter: drop-shadow(calc(var(--perspective) * 1) calc(var(--perspective) * 1) 0 rgba(0, 0, 0, 0.55));
    transform-origin: 0 0;
    transform: scale(0.75);
  }

  .product-hero-bin-element .inner .card .chip {
    position: absolute;
    z-index: 1;
    left: 36px;
    top: 96px;
    padding: 5px;
    background-color: #cfd0d8;
    border-radius: 5px;
    box-shadow: calc(var(--perspective) * 0.75) calc(var(--perspective) * 0.75) 0 rgba(0, 0, 0, 0.55);
  }

  .product-hero-bin-element .inner .card .chip svg {
    stroke-width: 0.5;
    stroke-opacity: 0.9;
    stroke: #fff;
    width: 46px;
    height: 30px;
    fill: none;
    display: block;
  }

  .product-hero-bin-element .inner .card .logo {
    position: absolute;
    z-index: 1;
    right: 22px;
    bottom: 22px;
    width: 160px;
    height: 48px;
    fill: #cfd1d8;
    filter: drop-shadow(calc(var(--perspective) * 1) calc(var(--perspective) * 1) 0 rgba(0, 0, 0, 0.55));
    transform-origin: 100% 100%;
    transform: scale(0.75);
  }

  .product-hero-ledger-card {
    --x: 0px;
    --y: 0px;
    --b: 0.8;
    transform: translate(var(--x), var(--y));
    position: absolute;
    top: -134px;
    right: 28px;
    padding: 18px 20px;
    width: 360px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    opacity: 0;
    background: rgba(255, 255, 255, var(--b));
    border-radius: 8px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    box-shadow: 0px 41px 33px rgba(0, 0, 0, 0.02), 0px 22px 17px rgba(0, 0, 0, 0.02), 0px 12px 10px rgba(0, 0, 0, 0.03),
      0px 6px 5px rgba(0, 0, 0, 0.04), 0px 3px 2px rgba(0, 0, 0, 0.05);
  }

  .product-hero-ledger-card .amount {
    font-feature-settings: 'tnum';
    letter-spacing: -0.02em;
    line-height: 26px;
    font-size: 20px;
    font-weight: 500;
    background: rgba(0 0 0 / 7.5%);
    border-radius: 4px;
    padding: 0 6px;
    background: var(--color-blue-50);
    color: var(--color-blue-600);
  }

  .product-hero-ledger-card .type {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-gray-800);
  }

  .product-hero-ledger-card .direction {
    margin-left: auto;
    display: flex;
    gap: 4px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray-500);
  }

  .product-hero-ledger-card .direction svg {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-top: 2px;
    fill: none;
    stroke-width: 1.25;
    stroke: currentColor;
  }
`;
