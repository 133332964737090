import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';

const ProductSection = styled(Section)`
  overflow: hidden;
`;

export const SectionDynamicNumbers: React.FC = () => {
  return (
    <ProductSection pt={{ lg: 32 }}>
      <Container>
        <Grid gridTemplateColumns={12} gap={12}>
          <Column gridColumn={{ _: 12, md: 8 }} gridColumnStart={{ _: 1, md: 3 }} position="relative" zIndex={2}>
            <Heading as="h2" size="700" weight="semibold" textAlign="center">
              Dynamic account numbers
            </Heading>
            <Text size="300" color="gray-500" mt={2} textAlign="center" px={10}>
              Gone are the days of having a single, static account number map to a single account. Create account
              numbers that point to a single bank account and create specific permissions and limits for each one.
            </Text>
          </Column>

          <Column gridColumn={{ _: 10, md: 6 }} gridColumnStart={{ _: 2, md: 4 }} position="relative" zIndex="2">
            <Code title="Create a bank account" mb="var(--space-n48)">
              {`curl 'https://api.column.com/bank-accounts/<bank_account_id>/account-numbers' \\
    -XPOST \\
    -u :<YOUR API KEY> \\
    -d description="Travel Checking Account Number"`}
            </Code>
          </Column>

          <Column gridColumn={{ _: 12, md: 10 }} gridColumnStart={{ _: 1, md: 2 }}>
            <ProductScreen mt={6}>
              <img src="/images/products/bank-accounts/dynamic-numbers.jpg" />
            </ProductScreen>
          </Column>
        </Grid>
      </Container>
    </ProductSection>
  );
};

export default SectionDynamicNumbers;
