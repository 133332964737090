import React from 'react';
import styled from 'styled-components';
import { BigGlobe } from './_big-globe';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Flex } from '~/elements/Flex';
import { ProductScreen } from '~/elements/ProductScreen';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
`;

export const SectionCountries: React.FC = () => {
  return (
    <Section mt={9} overflow="hidden">
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column gridColumn={{ _: 12, md: 8 }} gridColumnStart={{ _: 1, md: 3 }} position="relative" zIndex={2}>
            <Heading as="h2" size="700" weight="semibold" textAlign={{ _: 'left', md: 'center' }}>
              Transfer funds to over 180 countries through the Swift network
            </Heading>
          </Column>

          <Column
            gridColumn={{ _: 12, md: 10 }}
            gridColumnStart={{ _: 1, md: 2 }}
            mb={12}
            mt={8}
            position="relative"
            zIndex={1}
          >
            <Grid gridTemplateColumns={{ _: 1, md: 3 }} gap={6}>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      Direct access
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      Access countries across the globe with a direct Swift connection.
                    </Text>
                  </div>
                </Flex>
              </Column>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      Connect globally
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      Expand into various markets and connect to 11,000+ financial institutions worldwide.
                    </Text>
                  </div>
                </Flex>
              </Column>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      Cross-currency
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      Send funds in over 135+ global currencies, around the clock.
                    </Text>
                  </div>
                </Flex>
              </Column>
            </Grid>
          </Column>

          <Column gridColumn={{ _: 12, md: 12 }} gridColumnStart={{ _: 1, md: 1 }}>
            <BigGlobe />
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionCountries;
