import { css } from 'styled-components';

export const Lending = css`
  .color-palette--lending {
    --bg-gradient-primary: linear-gradient(165deg, var(--color-red-300) 0%, var(--color-orange-500) 92%);
    --bg-gradient-secondary: linear-gradient(
      215deg,
      rgba(var(--rgb-orange-400), 0.4),
      rgba(var(--rgb-red-300), 0.1),
      rgba(var(--rgb-red-300), 0)
    );
  }
`;
