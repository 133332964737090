import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsErrors: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M17.22 15L10.87 4C10.48 3.33 9.52003 3.33 9.13003 4L2.78003 15C2.40003 15.67 2.88003 16.5 3.65003 16.5H16.35C17.12 16.5 17.6 15.67 17.22 15ZM9.94003 7.16C10.37 7.12 10.75 7.44 10.78 7.87V8L10.5 11.5C10.48 11.78 10.24 11.98 9.96003 11.96C9.71003 11.94 9.52003 11.74 9.50003 11.5L9.22003 8C9.18003 7.57 9.51003 7.19 9.94003 7.16ZM10 14.75C9.52003 14.75 9.13003 14.36 9.13003 13.88C9.13003 13.4 9.52003 13 10 13C10.48 13 10.88 13.39 10.88 13.88C10.88 14.37 10.48 14.75 10 14.75Z"
        fill="url(#paint0_linear_3204_754)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_754"
          x1="5.38003"
          y1="22.92"
          x2="14.93"
          y2="7.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
