import React, { FC, useState, FormEvent, ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import { FormMessage } from './_signup';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Button } from '~/elements/Button';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { sm } from '~/ds/mixins/breakpoints';

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 1;
`;

const StyledEyebrow = styled(Heading)`
  background-color: var(--color-cyan-800);
  color: var(--color-white);
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  margin: 0 0 48px 0;
  box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.05);
  line-height: 1;

  .typing {
    &::after {
      display: none;
    }
  }
`;

const SignupForm = styled.form`
  background-color: var(--color-white);
  border-radius: 16px;
  padding: 4px;
  box-shadow: 0 1px 0px rgba(var(--rgb-cyan-800), 0.05), 0 2px 4px rgba(var(--rgb-cyan-800), 0.05),
    0 4px 8px rgba(var(--rgb-cyan-800), 0.05);
  display: flex;
  gap: 2px;
  flex-direction: row;
  position: relative;
`;

const StyledInput = styled.input`
  height: 48px;
  width: 100%;
  font-size: 16px;
  padding: 0 24px;
  margin: 0;
  border-radius: 14px;
  border: none;
  box-shadow: none;
  transition: box-shadow 0.2s, background-color 0.2s, border-color 0.2s;

  &:hover {
    background-color: rgba(var(--rgb-black), 0.03);
    box-shadow: none;
  }
  &:focus {
    background-color: rgba(var(--rgb-black), 0.03);
    box-shadow: inset 0 0 0 2px rgba(var(--rgb-cyan-600), 0.25);
  }
  &:active {
    background-color: rgba(var(--rgb-black), 0.03);
    box-shadow: inset 0 0 0 2px rgba(var(--rgb-cyan-600), 0.25);
  }

  @media ${sm} {
    width: 260px;
  }
`;

const StyledButton = styled(Button)`
  background-color: var(--color-cyan-800);
  color: var(--color-white);
  height: 48px;
  padding: 0 24px;
  margin: 0;
  border-radius: 14px;
  border: none;
  box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.25);
  transition: box-shadow 0.2s, background-color 0.2s, border-color 0.2s;

  span {
    font-size: 16px;
  }
  &:hover {
    background-color: var(--color-cyan-900);
    box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.25);
  }
  &:focus {
    background-color: var(--color-cyan-900);
    box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.25), 0 0 0 2px rgba(var(--rgb-cyan-600), 0.75);
  }
  &:active {
    background-color: var(--color-cyan-900);
    box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.25), 0 0 0 2px rgba(var(--rgb-cyan-600), 0.75);
  }
`;

export const SectionSignupBottom: FC = () => {
  const [currentState, setCurrentState] = useState<'success' | 'error' | ''>('');
  const [email, setEmail] = useState<string>('');

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setCurrentState('');

      fetch('https://api.column.com/newsletter/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          category: 'fednow',
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            setCurrentState('success');
            return;
          }
          setCurrentState('error');
        })
        .catch(() => {
          setCurrentState('error');
        });
    },
    [currentState, email]
  );

  return (
    <Section py={{ _: 12, md: 40 }}>
      <StyledContainer>
        <Grid gridTemplateColumns={12} gapY={6}>
          <Column gridColumn={12}>
            <Flex flexDirection="column" alignItems="center" height="100%">
              <StyledEyebrow size="200" weight="medium">
                Get Access
              </StyledEyebrow>
              <Heading weight="semibold" size="1000" color="cyan-800" as="h2" textAlign="center" pb="12">
                Sign up for early access
              </Heading>
              <SignupForm onSubmit={onSubmit}>
                <StyledInput
                  required
                  type="email"
                  // className="form-input"
                  placeholder="Email address"
                  onChange={handleInputChange}
                />
                <StyledButton disabled={currentState === 'success'} variant="primary" type="submit">
                  Submit
                </StyledButton>
                {currentState.length > 0 && (
                  <FormMessage>
                    {currentState === 'error' && (
                      <Text size="200">
                        <Icon.FormError />
                        <div>Email address already in use.</div>
                      </Text>
                    )}
                    {currentState === 'success' && (
                      <Text size="200">
                        <Icon.FormSuccess />
                        <div>Success! Stay tuned for updates.</div>
                      </Text>
                    )}
                  </FormMessage>
                )}
              </SignupForm>
            </Flex>
          </Column>
        </Grid>
      </StyledContainer>
    </Section>
  );
};

export default SectionSignupBottom;
