import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsGuides: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M15 4.5V12.5H7.5C6.12 12.5 5 13.62 5 15V7C5 5.62 6.12 4.5 7.5 4.5H15Z"
        fill="url(#paint0_linear_3204_758)"
      />
      <path
        d="M15 12.5V17.5H7.5C6.81 17.5 6.18 17.22 5.73 16.77C5.28 16.32 5 15.69 5 15C5 13.62 6.12 12.5 7.5 12.5H15Z"
        fill="url(#paint1_linear_3204_758)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_758"
          x1="4.97"
          y1="9.75"
          x2="14.71"
          y2="9.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0.3" />
          <stop offset="0.19" stop-color="var(--icon-color-docs)" />
          <stop offset="1" stop-color="var(--icon-color-docs)" stop-opacity="0.7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_758"
          x1="12.86"
          y1="10.9"
          x2="5.97"
          y2="22.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
