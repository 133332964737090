import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Link } from '~/elements/Link';
import { Grid } from '~/elements/Grid';
import { Icon } from '~/elements/Icon';
import { Flex } from '~/elements/Flex';
import { Br } from '~/elements/Br';
import { Column } from '~/elements/Column';
import { Typing } from '~/elements/Typing';
import Products, { ProductType } from '~/data/products';

export interface AllProductsProps {
  category?: 'infrastructure' | 'lending';
  excludeHref?: string;
  additionalProducts?: ProductType[];
  background?: string;
  accentColor?: string;
  hoverColor?: 'infrastructure' | 'lending';
}

const Wrapper = styled.section<AllProductsProps>`
  padding: var(--space-18) 0;
  background: ${(props) => props.background || 'transparent'};
  overflow: hidden;
  position: relative;
  z-index: 2;
`;

const ProductLink = styled(Link)`
  position: relative;

  &:hover {
    &:before {
      opacity: 0.25;
      transform: scale(1.2);
    }
  }
`;

const ProductCard = styled.div`
  position: relative;
  z-index: 3;
  backdrop-filter: blur(32px);
  border-radius: 16px;
  padding: var(--space-8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  transition: 0.2s background-color;

  background-color: rgba(var(--rgb-cyan-800), 0.05);
  box-shadow: inset 0 1px 1px rgba(var(--rgb-white), 0.5), inset 0 -1px 1px rgba(var(--rgb-cyan-800), 0.05);

  &:hover {
    background-color: rgba(var(--rgb-cyan-800), 0.15);

    .icon-animated-arrow-right--chevron,
    .icon-animated-arrow-right--stem {
      transform: translateX(0);
    }
    .icon-animated-arrow-right--stem {
      stroke-dashoffset: 0;
    }
  }

  &:active {
    .icon-animated-arrow-right--chevron,
    .icon-animated-arrow-right--stem {
      transform: translateX(2px);
    }
  }
`;

export const AllProducts: React.FC<AllProductsProps> = (props) => {
  return (
    <Wrapper {...props}>
      <Container>
        <Grid gridTemplateColumns={12} gapY={8} mb={8}>
          <Column gridColumn={{ _: 12, sm: 9 }}>
            <Heading as="h2" size="700" weight="semibold">
              Infrastructure products built for <Br display={{ _: 'none', lg: 'block' }} />
              developers in the lightest fo
              <Typing text="rmat possible" />
            </Heading>
          </Column>
        </Grid>
      </Container>

      <Column gridColumn={12} mb={18}>
        <Container>
          <Grid gridTemplateColumns={{ _: 1, md: 2, lg: 3 }} gap={8}>
            {Products[props.category ?? 'infrastructure'].map((item) => (
              <ProductLink to={item.href} key={item.name} {...props}>
                <ProductCard>
                  <div>
                    {item.icon}
                    <Heading as="h3" size="300" weight="medium" color="gray-700" mt={6} mb={2}>
                      {item.name}
                    </Heading>
                    <Text size="200" color="gray-600">
                      {item.description}
                    </Text>
                  </div>
                  <Flex alignItems="center" gap={1}>
                    <Text size="200" weight="medium" color="inherit">
                      Learn more
                    </Text>
                    <Icon.AnimatedArrowRight />
                  </Flex>
                </ProductCard>
              </ProductLink>
            ))}
          </Grid>
        </Container>
      </Column>

      <Container>
        <Grid gridTemplateColumns={12} gapY={8}>
          <Column gridColumn={{ _: 12, sm: 9, lg: 8 }}>
            <Heading as="h2" size="700" weight="semibold">
              Lending products for companies to scale lending programs fa
              <Typing text="ster and cheaper" />
            </Heading>
          </Column>

          <Column gridColumn={12}>
            <Grid gridTemplateColumns={{ _: 1, md: 2, lg: 3 }} gap={8}>
              {Products[props.category ?? 'lending'].map((item) => (
                <ProductLink to={item.href} key={item.name} {...props}>
                  <ProductCard>
                    <div>
                      {item.icon}
                      <Heading as="h3" size="300" weight="medium" color="gray-700" mt={6} mb={2}>
                        {item.name}
                      </Heading>
                      <Text size="200" color="gray-600">
                        {item.description}
                      </Text>
                    </div>
                    <Flex alignItems="center" gap={1}>
                      <Text size="200" weight="medium" color="inherit">
                        Learn more
                      </Text>
                      <Icon.AnimatedArrowRight />
                    </Flex>
                  </ProductCard>
                </ProductLink>
              ))}
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Wrapper>
  );
};

AllProducts.defaultProps = {
  hoverColor: 'infrastructure',
};
