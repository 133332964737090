import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Typing } from '~/elements/Typing';
import ArrowIcon from '~/assets/svg/fednow/beepboop.svg';
import CostIcon from '~/assets/svg/fednow/dollas.svg';
import SpeedIcon from '~/assets/svg/fednow/speeeed.svg';
import { sm } from '~/ds/mixins/breakpoints';

const StyledSectionBox = styled(SectionBox)`
  background-color: rgba(var(--rgb-white), 0.4);
  backdrop-filter: blur(32px);
  box-shadow: inset 0 1px 1px rgba(var(--rgb-white), 1);
  padding: 100px 0;

  /* padding: 120px 0; */
  padding-top: var(--space-18);
  padding-bottom: var(--space-18);

  @media ${sm} {
    /* padding: 140px 0; */
    padding-top: var(--space-32);
    padding-bottom: var(--space-32);
  }
`;

const StyledEyebrow = styled(Heading)`
  background-color: var(--color-cyan-800);
  color: var(--color-white);
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  margin: 0 0 48px 0;
  box-shadow: inset 0 0 0 1px rgba(var(--rgb-black), 0.05);
  line-height: 1;

  .typing {
    &::after {
      display: none;
    }
  }
`;

export const SectionDev: React.FC = () => {
  return (
    <Section>
      <StyledSectionBox>
        <Container>
          <Grid gridTemplateColumns={12} gapY={4}>
            <Column gridColumn={12}>
              <Flex flexDirection="column" alignItems="center" height="100%">
                <StyledEyebrow size="200" weight="medium">
                  Wh
                  <Typing text="y Column" />
                </StyledEyebrow>
                <Heading weight="semibold" size="1000" color="cyan-800" as="h2" textAlign="center">
                  Fed at the speed of Now
                </Heading>
                <Text size="500" weight="semibold" mt={4} color="cyan-800" textAlign="center" maxWidth="720px">
                  With a direct connection to the Federal Reserve, Column offers the full potential of FedNow's
                  capabilities.
                </Text>
              </Flex>
            </Column>
            <Column gridColumn={{ _: 12, md: 10 }} gridColumnStart={{ _: 1, md: 2 }}>
              <Grid gridTemplateColumns={{ _: 1, sm: 5 }} gap={12} mt={6}>
                <Column gridColumn={{ _: 1, sm: 3 }}>
                  <Code title="Create a FedNow transfer">
                    {`curl 'https://api.column.com/transfers/fednow' \\
    -u :prod_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d counterparty_id=cpty_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d bank_account_id=bacc_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d amount=2241 \\
    -d type=SEND \\
    -d currency_code=USD \\
    -d description="Lunch"`}
                  </Code>
                </Column>
                <Column gridColumn={{ _: 1, sm: 2 }}>
                  <Flex gap={8} flexDirection="column">
                    <Flex gap={4} alignItems="start">
                      <SpeedIcon viewportSize={20} />
                      <Text size="300" color="cyan-800" textAlign="start">
                        Programmatically send and request FedNow transfers with one API call.
                      </Text>
                    </Flex>
                    <Flex gap={4} alignItems="start">
                      <CostIcon viewportSize={20} />
                      <Text size="300" color="cyan-800" textAlign="start">
                        Funds are transferred in real-time to any FedNow enabled bank account.
                      </Text>
                    </Flex>
                    <Flex gap={4} alignItems="start">
                      <ArrowIcon viewportSize={20} />
                      <Text size="300" color="cyan-800" textAlign="start">
                        Transmit and receive payments at the speed of FedNow via our APIs.
                      </Text>
                    </Flex>
                  </Flex>
                </Column>
              </Grid>
            </Column>
          </Grid>
        </Container>
      </StyledSectionBox>
    </Section>
  );
};

export default SectionDev;
