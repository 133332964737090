import React from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import styled, { css, keyframes } from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Icon } from '~/elements/Icon';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Box } from '~/elements/Box';
import { Br } from '~/elements/Br';
import { Column } from '~/elements/Column';
import { Typing } from '~/elements/Typing';
import { sm, md } from '~/ds/mixins/breakpoints';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 24px !important;
`;

const CardStyles = css`
  background: rgba(var(--rgb-white), 1);
  border-radius: var(--space-3);
  box-shadow: 0 0 0 1px rgba(var(--rgb-black), 0.05);
`;

const Card = styled.div`
  ${CardStyles}
  padding: var(--space-6);

  @media ${sm} {
    height: 100%;
  }

  @media ${md} {
    height: auto;
    padding: var(--space-8);
  }
`;

const CardLarge = styled.div`
  ${CardStyles}
  padding: var(--space-6);
  height: 100%;
  overflow: hidden;

  @media ${md} {
    padding: var(--space-12);
  }
`;

const FastFunding = styled.div`
  position: relative;
  margin: var(--space-8) 0 var(--space-12);
  min-height: 135px;
  transform: scale(0.8);

  @media ${md} {
    transform: scale(1);
  }

  &:after {
    border-radius: 0%;
    content: '';
    background: rgba(var(--rgb-white), 1);
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -20%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    --glow-offset: 20%;
    top: -10%;
    right: var(--glow-offset);
    bottom: -10%;
    left: var(--glow-offset);
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%);
    filter: blur(30px);
    opacity: 0.3;
    z-index: 0;
  }
`;

const bill2 = keyframes`
  from {
    opacity: 0;
    transform: translateY(0px);
  }

  to {
    opacity: 1;
    transform: translateY(-14px);
  }
`;

const bill3 = keyframes`
  from {
    opacity: 0;
    transform: translateY(-14px);
  }

  to {
    opacity: 1;
    transform: translateY(-24px);
  }
`;

const bill4 = keyframes`
  from {
    opacity: 0;
    transform: translateY(-24px);
  }

  to {
    opacity: 1;
    transform: translateY(-34px);
  }
`;

// Stack

const billStack1 = keyframes`
  from {
    opacity: 0;
    transform: translate(-10px, 10px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
`;

const billStack2 = keyframes`
  from {
    opacity: 0;
    transform: translate(-10px, -25px);
  }

  to {
    opacity: 1;
    transform: translate(0, -35px);
  }
`;

const billStack3 = keyframes`
  from {
    opacity: 0;
    transform: translate(-10px, -70px);
  }

  to {
    opacity: 1;
    transform: translate(0, -70px);
  }
`;

const billStack4 = keyframes`
  from {
    opacity: 0;
    transform: translate(-10px, -105px);
  }

  to {
    opacity: 1;
    transform: translate(0, -105px);
  }
`;

const Bills = styled.div`
  display: flex;
  gap: var(--space-2);
  position: relative;
  z-index: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  transform: translate(-60px, 70px);
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);
`;

const Bill = styled.img`
  max-width: 146px;
`;

const BillStack = styled.div`
  position: relative;
  max-width: 50px;
  opacity: 0;

  .inview--animate & {
    animation: 0.4s ${billStack1} var(--animation-curve) 0.5s forwards;
  }
  &:nth-child(2) {
    z-index: 3;

    .inview--animate & {
      animation: 0.4s ${billStack2} var(--animation-curve) 0.6s forwards;
    }
  }
  &:nth-child(3) {
    z-index: 2;
    transform: translate(0, -70px);

    .inview--animate & {
      animation: 0.4s ${billStack3} var(--animation-curve) 0.7s forwards;
    }
  }
  &:nth-child(4) {
    z-index: 1;
    transform: translate(0, -105px);

    .inview--animate & {
      animation: 0.4s ${billStack4} var(--animation-curve) 0.8s forwards;
    }
  }

  ${Bill}:nth-child(2),
  ${Bill}:nth-child(3),
  ${Bill}:nth-child(4) {
    position: absolute;
    bottom: 0;
  }

  ${Bill}:nth-child(2) {
    transform: translateY(0px);

    .inview--animate & {
      animation: 0.4s ${bill2} var(--animation-curve) 0.6s forwards;
    }
  }

  ${Bill}:nth-child(3) {
    transform: translateY(-14px);

    .inview--animate & {
      animation: 0.4s ${bill3} var(--animation-curve) 0.7s forwards;
    }
  }

  ${Bill}:nth-child(4) {
    transform: translateY(-24px);

    .inview--animate & {
      animation: 0.4s ${bill4} var(--animation-curve) 0.8s forwards;
    }
  }
`;

const slide = keyframes`
  from {
    transform: translate(64px, 0) scale(0.8);
    opacity: 0;
  }

  to {
    transform: translate(0, 0) scale(0.8);
    opacity: .6;
  }
`;

const slidePrimary = keyframes`
  from {
    transform: translate(64px, 0) scale(.8);
    opacity: 0;
  }

  to {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
`;

const CashFlow = styled(FastFunding)`
  text-align: center;
  margin-bottom: var(--space-8);
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);
`;

const Loans = styled.div`
  display: inline-flex;
  position: relative;
  z-index: 1;
  transform: translateX(-50%);
  margin-left: 50%;
`;

const LoanCard = styled.div`
  background: #fff;
  border-radius: 4px;
  width: 120px;
  height: 180px;
  box-shadow: var(--shadow-product), var(--shadow-product-border);
  position: relative;
  margin: 0 auto;
  z-index: 1;
  padding: var(--space-3);
  text-align: left;
  font-size: 8px;
  color: var(--color-gray-300);

  &:nth-child(1),
  &:nth-child(3) {
    transform: scale(0.8);
    opacity: 0;

    .inview--animate & {
      animation: 0.4s ${slide} var(--animation-curve) 0.5s forwards;
    }
  }

  &:nth-child(2) {
    opacity: 0;
    padding: var(--space-5) var(--space-4);
    transform: scale(0.8);

    .inview--animate & {
      animation: 0.4s ${slidePrimary} var(--animation-curve) 0.5s forwards;
    }
  }
`;

export const SectionOverview: React.FC = () => {
  const [animateRef, animateInView] = useInView({
    threshold: 0,
  });

  const animateClasses: ConditionalClasses = {
    'inview--animate': animateInView,
  };

  return (
    <Section overflow="hidden">
      <Container>
        <Grid gridTemplateColumns={12}>
          <Column gridColumn={{ _: 12, md: 9, lg: 7 }}>
            <Column maxWidth="480px">
              <Heading as="h2" weight="semibold" size="700">
                Free up working capital so <Br display={{ _: 'none', lg: 'block' }} />
                you can keep g<Typing text="rowing" />
              </Heading>
            </Column>
          </Column>

          <Column gridColumn={12} mt={12} ref={animateRef}>
            <Grid gridTemplateColumns={{ _: 1, md: 3 }} gap={{ md: 6 }} gapY={6}>
              <Column gridColumn={2}>
                <CardLarge className={classNames(animateClasses)}>
                  <Flex flexDirection="column" justifyContent="center" alignItems="bottom" height="100%">
                    <FastFunding>
                      <Bills>
                        <BillStack>
                          <Bill src="/images/products/loan-purchase/bill-1.png" />
                        </BillStack>
                        <BillStack>
                          <Bill src="/images/products/loan-purchase/bill-1.png" />
                          <Bill src="/images/products/loan-purchase/bill-2.png" />
                        </BillStack>
                        <BillStack>
                          <Bill src="/images/products/loan-purchase/bill-1.png" />
                          <Bill src="/images/products/loan-purchase/bill-2.png" />
                          <Bill src="/images/products/loan-purchase/bill-3.png" />
                        </BillStack>
                        <BillStack>
                          <Bill src="/images/products/loan-purchase/bill-1.png" />
                          <Bill src="/images/products/loan-purchase/bill-2.png" />
                          <Bill src="/images/products/loan-purchase/bill-3.png" />
                          <Bill src="/images/products/loan-purchase/bill-4.png" />
                        </BillStack>
                      </Bills>
                    </FastFunding>
                    <Column>
                      <Box maxWidth="400px" margin="0 auto" position="relative" zIndex="2">
                        <Heading as="h3" weight="semibold" size="400" mt={4} textAlign="center">
                          Faster funding
                        </Heading>
                        <Text size="300" mt={1} textAlign="center">
                          With automatic intraday settlement, reduce funding and working capital friction.
                        </Text>
                      </Box>
                    </Column>
                  </Flex>
                </CardLarge>
              </Column>

              <Column gridColumn={{ _: 2, md: 1 }}>
                <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1 }} gap={6}>
                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Faster funding
                      </Heading>
                      <Text size="200" mt={1}>
                        With automatic intraday settlement, reduce funding and working capital friction.
                      </Text>
                    </Card>
                  </Column>

                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Flexible
                      </Heading>
                      <Text size="200" mt={1}>
                        One size doesn't fit all. We can support various purchase structures and formats to help achieve
                        your goals.
                      </Text>
                    </Card>
                  </Column>
                </Grid>
              </Column>

              <Column gridColumn={{ _: 2, md: 1 }}>
                <Grid gridTemplateColumns={{ _: 1, sm: 2, md: 1 }} gap={6}>
                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Committed or bulk purchase
                      </Heading>
                      <Text size="200" mt={1}>
                        Whether you need to sell a single portfolio or want a long term partner to acquire loans on an
                        ongoing basis, we can provide a solution.
                      </Text>
                    </Card>
                  </Column>

                  <Column>
                    <Card>
                      <CircleCheck />
                      <Heading as="h3" weight="medium" size="300" mt={4}>
                        Team
                      </Heading>
                      <Text size="200" mt={1}>
                        We have experience analyzing billions of dollars of loans. We understand that not all
                        originations are the same, and we will work with you to understand your portfolio.
                      </Text>
                    </Card>
                  </Column>
                </Grid>
              </Column>

              <Column gridColumn={2}>
                <CardLarge ref={animateRef} className={classNames(animateClasses)}>
                  <Flex flexDirection="column" justifyContent="end" height="100%">
                    <CashFlow>
                      <Loans>
                        <LoanCard>
                          <Flex height="100%" justifyContent="center" alignItems="center" mt="-8px">
                            <img src="/images/products/loan-purchase/loan-progress-1.png" />
                          </Flex>
                        </LoanCard>
                        <LoanCard>
                          <Flex height="100%" justifyContent="center" alignItems="center" mt="-8px">
                            <Flex height="100%" justifyContent="center" alignItems="center" mt="-8px">
                              <img src="/images/products/loan-purchase/loan-progress-paid.png" />
                            </Flex>
                          </Flex>
                        </LoanCard>
                        <LoanCard>
                          <Flex height="100%" justifyContent="center" alignItems="center" mt="-8px">
                            <img src="/images/products/loan-purchase/loan-progress-2.png" />
                          </Flex>
                        </LoanCard>
                      </Loans>
                    </CashFlow>

                    <Column>
                      <Box maxWidth="500px" margin="0 auto" position="relative" zIndex="2">
                        <Heading as="h3" weight="semibold" size="400" mt={4} textAlign="center">
                          Increase cash flow
                        </Heading>
                        <Text size="300" mt={1} textAlign="center">
                          Get paid today for the loans you are originating, providing valuable cash flow to enable you
                          to continue to grow.
                        </Text>
                      </Box>
                    </Column>
                  </Flex>
                </CardLarge>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionOverview;
