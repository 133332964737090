import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { compose, space, background, overflow, OverflowProps, SpaceProps, BackgroundProps } from 'styled-system';
import { md, lg } from '~/ds/mixins/breakpoints';
import { PaletteProps, getPaletteClassnames } from '~/utils/getPaletteClassnames';

export interface SectionStyleProps extends PaletteProps, OverflowProps, SpaceProps, BackgroundProps {
  spacing?: 'contained' | 'flush';
  border?: boolean;
  inverted?: boolean;
}

export interface SectionProps extends SectionStyleProps {
  className?: string;
  style?: CSSProperties;
}

const Wrapper = styled.section<SectionStyleProps>`
  ${({ spacing }) =>
    spacing === 'contained' &&
    css<SectionStyleProps>`
      padding-top: var(--space-12);
      padding-bottom: var(--space-12);

      @media ${md} {
        padding-top: var(--space-18);
        padding-bottom: var(--space-18);
        ${compose(space, background)};
      }
    `}

  ${({ border }) =>
    border === true &&
    css<SectionStyleProps>`
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: var(--spacing-18);
        bottom: var(--spacing-18);
        width: 8px;
        border-radius: 0 4px 4px 0;
        display: none;
        @media ${lg} {
          display: block;
        }
      }

      &::before {
        background-image: url(/images/guilloche.svg);
        background-repeat: repeat;
        background-position: top right;
        background-size: 20px 20px;
        z-index: 1;
        mix-blend-mode: overlay;
      }

      &::after {
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),
          var(--bg-gradient-primary);
      }
    `}

  ${compose(space, background, overflow)};
`;

export const Section: React.FC<SectionProps> = (props) => {
  let conditionalClasses: ConditionalClasses = {
    ...getPaletteClassnames(props.palette),
  };

  if (props.inverted === true) {
    conditionalClasses = getPaletteClassnames(props.palette, conditionalClasses, true);
  }

  return (
    <Wrapper {...props} className={classNames(props.className, conditionalClasses)}>
      {props.children}
    </Wrapper>
  );
};

Section.defaultProps = {
  spacing: 'contained',
  palette: 'inherit',
  border: false,
  inverted: false,
};
