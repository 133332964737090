import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Button } from '~/elements/Button';
import { Icon } from '~/elements/Icon';
import { Layout } from '~/components/Layout';

const MigrationImage = styled.img`
  margin-bottom: var(--space-12);
`;

const CTA = styled(Button)`
  color: var(--color-blue-600);
`;

const EmailLink = styled.a`
  color: var(--color-blue-600);
  border-bottom: 1px solid rgba(var(--rgb-blue-300), 0);
  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 1px solid rgba(var(--rgb-blue-300), 1);
  }
`;
const SVBPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>We're here to help — Column</title>
        <meta property="og:title" content="Column - We're here to help" />
        <meta
          name="description"
          content="Silicon Valley Bank is being placed into receivership and facing insolvency, and we are actively
          monitoring the situation while working 24/7 to onboard customers. We are efficient, nimble,
          hyper-responsive, and customer-focused, all while holding ourselves to the highest standards in order to
          safely serve our customers. Whether you relied on Silicon Valley Bank for an FBO account, complex payment
          operations or just an operating account, we’re here to help."
        />
        <link rel="canonical" href="https://column.com/svb-update" />
      </Helmet>
      <Container>
        <Grid py={{ _: 10, md: 18 }} gap={8} gridTemplateColumns={12} maxWidth="1064px">
          <Column gridColumn={{ _: 12, md: 8 }}>
            <MigrationImage src="/images/misc/migration.svg" />
            <Heading as="h2" weight="semibold" size="900" mb={2}>
              We’re here to help
            </Heading>
            <Text as="h4" weight="book" size="400" color="gray-900" mb={3}>
              Column N.A. is an OCC regulated, nationally chartered bank built for complex commercial and payments
              focused customers. We can get an FDIC-insured bank account open for your business swiftly and safely.
            </Text>
          </Column>
        </Grid>
        <Grid pt={8} pb={10} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 6 }}>
            <Heading weight="semibold" as="h4" size="400">
              Overview
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }}>
            <Text color="gray-600">
              Silicon Valley Bank is being placed into receivership and facing insolvency, and we are actively
              monitoring the situation while working 24/7 to onboard customers. We are efficient, nimble,
              hyper-responsive, and customer-focused, all while holding ourselves to the highest standards in order to
              safely serve our customers. Whether you relied on Silicon Valley Bank for an FBO account, complex payment
              operations or just an operating account, we're here to help.
            </Text>
          </Column>
        </Grid>

        <Grid pt={8} pb={10} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 6 }}>
            <Heading weight="semibold" as="h4" size="400">
              FBO Accounts, Payments and <br />
              Money Movement
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }}>
            <Text color="gray-600" mb={6}>
              At Column, you have access to the fastest payment rails, which customers use most often with our core
              product offering: an FBO account for your customers’ money movement needs. We are developer forward, fast
              to market, and built to scale with you, mitigating against many of SVB’s limitations. We are actively
              onboarding FBO customer prospects and would love to prioritize serving your needs.
            </Text>

            <CTA variant="link" type="link" href="https://dashboard.column.com/register">
              Sign up for an FBO Account <Icon.AnimatedArrowRight />
            </CTA>

            <Text size="200" mt={3}>
              To expedite the onboarding, please email{' '}
              <EmailLink href="mailto:onboarding+fbo@column.com">onboarding+fbo@column.com</EmailLink>.
            </Text>
          </Column>
        </Grid>

        <Grid pt={8} pb={10} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 6 }}>
            <Heading weight="semibold" as="h4" size="400">
              Commercial Account
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }}>
            <Text color="gray-600" mb={6}>
              For those in need of a quick migration off of your SVB operating bank account, opening a bank account and
              transferring funds has never been easier. Simply go through Column’s onboarding flow via{' '}
              <EmailLink href="https://dashboard.column.com">our dashboard</EmailLink>. Safely hold your funds in an
              FDIC-insured bank account, with easy access to Column’s full suite of payment rails.
            </Text>

            <CTA variant="link" type="link" href="https://dashboard.column.com/register">
              Sign up for a Commercial Account <Icon.AnimatedArrowRight />
            </CTA>

            <Text size="200" mt={3}>
              To expedite the onboarding, please email{' '}
              <EmailLink href="mailto:onboarding+commercial@column.com">onboarding+commercial@column.com</EmailLink>.
            </Text>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} maxWidth="1064px">
          <Column gridColumn={{ _: 12, md: 8 }}>
            <Heading as="h3" weight="semibold" size="600">
              Latest updates
            </Heading>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Heading weight="medium" as="h4" size="300">
              Monday, 13 March 2023
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 7 }}>
            <Text color="gray-600" mb={6}>
              SVB announced all wires that have not been processed by SVB on Thursday, March 9th and Friday, March 10th
              have been cancelled.
            </Text>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Heading weight="medium" as="h4" size="300">
              Sunday, 12 March 2023
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 7 }}>
            <Text color="gray-600" mb={6}>
              FDIC announced that to protect depositors, all insured and uninsured deposits would be transferred to a
              full-service “bridge bank” operated by the FDIC on Monday, March 13th. This helps safeguard depositors’
              funds and make them whole.
            </Text>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Heading weight="medium" as="h4" size="300">
              Friday, 10 March 2023
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 7 }}>
            <Text color="gray-600" mb={6}>
              Silicon Valley Bank was closed by the California Department of Financial Protection & Innovation and the
              FDIC was named Receiver and seizes SVB’s assets.
            </Text>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Heading weight="medium" as="h4" size="300">
              Thursday, 9 March 2023
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 7 }}>
            <Text color="gray-600" mb={6}>
              Silicon Valley Bank announced their plan to raise capital or search for an acquirer as the bank faces
              insolvency post a significant liquidity crunch.
            </Text>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} maxWidth="1064px">
          <Column gridColumn={{ _: 12, md: 8 }}>
            <Heading as="h3" weight="semibold" size="600">
              Frequently asked questions
            </Heading>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Heading weight="medium" as="h4" size="300">
              What is the first step I should take to migrate my funds to Column?
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 7 }}>
            <Text color="gray-600" mb={6}>
              <EmailLink href="https://dashboard.column.com/register">Create a bank account</EmailLink> at Column to
              begin the process to receive funds.
            </Text>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Heading weight="medium" as="h4" size="300">
              Who can I contact about the state of my deposits or uninsured funds with SVB?
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 7 }}>
            <Text color="gray-600" mb={6}>
              Please contact the FDIC Call Center at <EmailLink href="tel:1-866-799-0959">1-866-799-0959</EmailLink>{' '}
              with any additional questions regarding your deposits at SVB.
            </Text>
          </Column>
        </Grid>

        <Grid py={8} gap={8} gridTemplateColumns={12} borderTop="1px solid var(--color-gray-200)">
          <Column gridColumn={{ _: 12, md: 5 }}>
            <Heading weight="medium" as="h4" size="300">
              I have further questions about account opening, the status of my funds, and/or the transfer process with
              Column. Who can I contact?
            </Heading>
          </Column>
          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 7 }}>
            <Text color="gray-600" mb={6}>
              Please reach out to <EmailLink href="mailto:onboarding@column.com">onboarding@column.com</EmailLink> with
              any questions on how we can assist you and your business, and we’ll do everything we can to help.
            </Text>
          </Column>
        </Grid>
      </Container>
    </Layout>
  );
};

export default SVBPage;
