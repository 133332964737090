import { css } from 'styled-components';

export const BlogThemes = css`
  .blog-theme--gray {
    --blog-50: var(--color-gray-50);
    --blog-100: var(--color-gray-100);
    --blog-200: var(--color-gray-200);
    --blog-300: var(--color-gray-300);
    --blog-400: var(--color-gray-400);
    --blog-500: var(--color-gray-500);
    --blog-600: var(--color-gray-600);
    --blog-700: var(--color-gray-700);
    --blog-800: var(--color-gray-800);
    --blog-900: var(--color-gray-900);
  }

  .blog-theme--blue {
    --blog-50: var(--color-blue-50);
    --blog-100: var(--color-blue-100);
    --blog-200: var(--color-blue-200);
    --blog-300: var(--color-blue-300);
    --blog-400: var(--color-blue-400);
    --blog-500: var(--color-blue-500);
    --blog-600: var(--color-blue-600);
    --blog-700: var(--color-blue-700);
    --blog-800: var(--color-blue-800);
    --blog-900: var(--color-blue-900);
  }

  .blog-theme--cyan {
    --blog-50: var(--color-cyan-50);
    --blog-100: var(--color-cyan-100);
    --blog-200: var(--color-cyan-200);
    --blog-300: var(--color-cyan-300);
    --blog-400: var(--color-cyan-400);
    --blog-500: var(--color-cyan-500);
    --blog-600: var(--color-cyan-600);
    --blog-700: var(--color-cyan-700);
    --blog-800: var(--color-cyan-800);
    --blog-900: var(--color-cyan-900);
  }

  .blog-theme--seafoam {
    --blog-50: var(--color-seafoam-50);
    --blog-100: var(--color-seafoam-100);
    --blog-200: var(--color-seafoam-200);
    --blog-300: var(--color-seafoam-300);
    --blog-400: var(--color-seafoam-400);
    --blog-500: var(--color-seafoam-500);
    --blog-600: var(--color-seafoam-600);
    --blog-700: var(--color-seafoam-700);
    --blog-800: var(--color-seafoam-800);
    --blog-900: var(--color-seafoam-900);
  }

  .blog-theme--green {
    --blog-50: var(--color-green-50);
    --blog-100: var(--color-green-100);
    --blog-200: var(--color-green-200);
    --blog-300: var(--color-green-300);
    --blog-400: var(--color-green-400);
    --blog-500: var(--color-green-500);
    --blog-600: var(--color-green-600);
    --blog-700: var(--color-green-700);
    --blog-800: var(--color-green-800);
    --blog-900: var(--color-green-900);
  }

  .blog-theme--yellow {
    --blog-50: var(--color-yellow-50);
    --blog-100: var(--color-yellow-100);
    --blog-200: var(--color-yellow-200);
    --blog-300: var(--color-yellow-300);
    --blog-400: var(--color-yellow-400);
    --blog-500: var(--color-yellow-500);
    --blog-600: var(--color-yellow-600);
    --blog-700: var(--color-yellow-700);
    --blog-800: var(--color-yellow-800);
    --blog-900: var(--color-yellow-900);
  }

  .blog-theme--orange {
    --blog-50: var(--color-orange-50);
    --blog-100: var(--color-orange-100);
    --blog-200: var(--color-orange-200);
    --blog-300: var(--color-orange-300);
    --blog-400: var(--color-orange-400);
    --blog-500: var(--color-orange-500);
    --blog-600: var(--color-orange-600);
    --blog-700: var(--color-orange-700);
    --blog-800: var(--color-orange-800);
    --blog-900: var(--color-orange-900);
  }

  .blog-theme--red {
    --blog-50: var(--color-red-50);
    --blog-100: var(--color-red-100);
    --blog-200: var(--color-red-200);
    --blog-300: var(--color-red-300);
    --blog-400: var(--color-red-400);
    --blog-500: var(--color-red-500);
    --blog-600: var(--color-red-600);
    --blog-700: var(--color-red-700);
    --blog-800: var(--color-red-800);
    --blog-900: var(--color-red-900);
  }

  .blog-theme--pink {
    --blog-50: var(--color-pink-50);
    --blog-100: var(--color-pink-100);
    --blog-200: var(--color-pink-200);
    --blog-300: var(--color-pink-300);
    --blog-400: var(--color-pink-400);
    --blog-500: var(--color-pink-500);
    --blog-600: var(--color-pink-600);
    --blog-700: var(--color-pink-700);
    --blog-800: var(--color-pink-800);
    --blog-900: var(--color-pink-900);
  }

  .blog-theme--purple {
    --blog-50: var(--color-purple-50);
    --blog-100: var(--color-purple-100);
    --blog-200: var(--color-purple-200);
    --blog-300: var(--color-purple-300);
    --blog-400: var(--color-purple-400);
    --blog-500: var(--color-purple-500);
    --blog-600: var(--color-purple-600);
    --blog-700: var(--color-purple-700);
    --blog-800: var(--color-purple-800);
    --blog-900: var(--color-purple-900);
  }
`;
