import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductCardPrograms: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductCardPrograms {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a3" gradientUnits="userSpaceOnUse" x1="28.13" x2="-10.78" y1="5.37" y2="44.28">
        <stop offset="0" stopColor="#1e4199" />
        <stop offset="1" stopColor="#1e4199" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="b3" x1="5.88" x2="19.48" href="#a3" y1="18.62" y2="5.02" />
      <linearGradient id="c3" x1="44.81" x2="6.59" href="#a3" y1="-14.31" y2="23.91" />
      <linearGradient id="d3" x1="19.15" x2="-42.59" href="#a3" y1="16.85" y2="78.59" />
      <path
        d="m23 18v3h-19v-5c0-1.1.9-2 2-2h3v2c0 1.1.9 2 2 2zm-19 6c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2s-19-.01-19-.01z"
        fill="url(#a3)"
      />
      <rect fill="url(#b3)" height="3" rx="1" width="4" x="11" y="10" />
      <path
        d="m26 6h-15c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm-11 6c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z"
        fill="url(#c3)"
      />
      <path d="m4 21h19v3h-19z" fill="url(#d3)" />
    </IconBase>
  );
};
