import React from 'react';
import { Passport } from './Passport';
import { License } from './DriversLicense';
import { NationalID } from './NationalID';
import { AddressObject } from './AddressObject';

export const BeneficialOwnerDetails = [
  {
    param: 'first_name',
    type: 'string',
    required: true,
    description: <p>First name of the legal person</p>,
  },
  {
    param: 'last_name',
    type: 'string',
    required: true,
    description: <p>Last name of the legal person</p>,
  },
  {
    param: 'middle_name',
    type: 'string',
    required: false,
    description: <p>Middle name of the legal person</p>,
  },
  {
    param: 'ssn',
    type: 'string',
    required: false,
    description: (
      <p>
        Social Security Number is required for US beneficial owners. Passport, driver's license, or national ID is
        required for foreign beneficial owners.{' '}
      </p>
    ),
  },
  {
    param: 'passport',
    type: 'object',
    required: false,
    description: <p>Passport Number. SSN, passport, driver's license, or national ID is required.</p>,
    object: [...Passport],
  },
  {
    param: 'drivers_license',
    type: 'object',
    required: false,
    description: <p>Driver's license. SSN, passport, driver's license, or national ID is required.</p>,
    object: [...License],
  },
  {
    param: 'national_id',
    type: 'object',
    required: false,
    description: <p>National ID. SSN, passport, driver's license, or national ID is required.</p>,
    object: [...NationalID],
  },
  {
    param: 'date_of_birth',
    type: 'string',
    required: true,
    description: <p>Date of birth (YYYY-MM-DD)</p>,
  },
  {
    param: 'email',
    type: 'string',
    required: false,
    description: (
      <p>
        Email of the individual. Optional, but highly encouraged as it will increase likelihood of an automated
        verification.
      </p>
    ),
  },
  {
    param: 'is_control_person',
    type: 'boolean',
    required: true,
    description: <p>Boolean value which specifies if this person is a designated control person.</p>,
  },
  {
    param: 'is_beneficial_owner',
    type: 'boolean',
    required: true,
    description: <p>Boolean value which specifies if this person is a designated beneficial owner.</p>,
  },
  {
    param: 'ownership_percentage',
    type: 'integer',
    required: false,
    description: <p>Percentage ownership, specified as a integer, of the beneficial owner</p>,
  },
  {
    param: 'job_title',
    type: 'string',
    required: false,
    description: <p>Job title of the beneficial owner or control person.</p>,
  },
  ...AddressObject,
];

export const BeneficialOwner = [
  {
    param: 'beneficial_owners',
    type: 'array of objects',
    required: true,
    description: (
      <>
        <p>
          A list of all the persons who have ultimate control over funds in the business, whether through ownership or
          other means.
        </p>
        <p>
          This is anyone who owns owns 25 percent or more of the business and those who control the funds. If no one
          owns more than 25% of the business, just the individual who has ultimate control of the funds must be
          included.
        </p>
      </>
    ),
    object: [...BeneficialOwnerDetails],
  },
];
