import React from 'react';

export const Passport = [
  {
    param: 'number',
    type: 'string',
    required: true,
    description: <p>Passport number</p>,
  },
  {
    param: 'country_code',
    type: 'string',
    required: true,
    description: <p>Country code where the passport was issued. US Passports will be rejected as we require US citizens to submit SSN.</p>,
  },
];
