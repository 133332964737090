import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Layout } from '~/components/Layout';
import { getBlogCategoryColor } from '~/utils/blog';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { List } from '~/elements/List';
import { Icon } from '~/elements/Icon';
import { TextGroup } from '~/elements/TextGroup';
import { Button } from '~/elements/Button';
import { BlogForm } from '~/elements/BlogForm';

interface ChangelogAreaProps {
  list: any;
}

{
  /* Changelog section */
}

const ChangelogArea: React.FC<ChangelogAreaProps> = (props) => (
  <div className="changelog-callout">
    <div className="md:flex md:items-center md:justify-between">
      <Heading as="h2" size="500" weight="semibold">
        Latest from the changelog
      </Heading>
      <Button to="/changelog" variant="link" color="white">
        View all entries <Icon.AnimatedArrowRight />
      </Button>
    </div>
    <div className="grid grid-cols-1 pr-4 mt-8 gap-14 md:grid-cols-3 md:gap-20">
      {props.list.map((entry: any, index: number) => (
        <Link to={`/changelog#${entry.slug}`} key={index} className="changelog-card-preview">
          <Text size="200" className="blog-date">
            {entry.date}
          </Text>
          <TextGroup>
            <Heading as="h3" weight="semibold" size="300" mt={0}>
              {entry.title}
            </Heading>
            <Text size="200" className="mt-2">
              {entry.summary}
            </Text>
            <Button variant="link" className="mt-4 text-white">
              Read more
              <Icon.AnimatedArrowRight />
            </Button>
          </TextGroup>
        </Link>
      ))}
    </div>
  </div>
);

const BlogPage: React.FC = (props: any) => {
  const list = props.data.allContentfulBlogPost.nodes;
  const changelogList = props.data.allContentfulChangelogEntry.nodes;

  return (
    <Layout>
      <Helmet>
        <title>Blog — Column</title>
        <meta name="description" content="News and updates from the Column team." />
      </Helmet>
      <Container className="blog blog-container">
        <Heading as="h1" weight="semibold" size="800" mt={16} color="blue-800" className="col-span-8">
          Blog
        </Heading>

        {/* Navigation */}

        <List className="blog-nav blog-theme--blue">
          <Text as="li" className="blog-nav-blog">
            <Link to="/blog" activeClassName="active">
              Blog
            </Link>
          </Text>
          <Text as="li" className="blog-nav-changelog">
            <Link to="/changelog" activeClassName="active">
              Changelog
            </Link>
          </Text>
        </List>

        {/* Blog posts */}

        <div className="col-span-12 pb-8">
          {list.map((post: any, index: number) => (
            <>
              <Link
                key={index}
                to={`/blog/${post.slug}`}
                className={`blog-preview ${getBlogCategoryColor(post.category.name)}`}
              >
                <div className="md:col-span-2">
                  <Text as="p" size="200" className="col-span-2 mt-1 blog-category">
                    {post.category.name}
                  </Text>
                </div>

                <div className="w-4/5 md:col-span-5 md:w-full">
                  <Heading as="h2" weight="semibold" size="500" mt={0}>
                    {post.title}
                  </Heading>
                  <Text className="block mt-3 md:hidden">{post.summary}</Text>
                  <div className="flex mt-8 place-items-center gap-x-6">
                    <div className="flex gap-2 place-items-center">
                      <GatsbyImage
                        alt={post.author.name}
                        image={post.author.photo.gatsbyImageData}
                        className="blog-author-image"
                      />
                      <Text size="200" className="blog-author-name">
                        {post.author.name}
                      </Text>
                    </div>
                    <Text size="200" className="blog-date">
                      {post.date}
                    </Text>
                  </div>
                </div>

                <div className="md:col-start-9 md:col-span-4">
                  <Text className="hidden md:block">{post.summary}</Text>
                </div>
              </Link>
              {index === 3 && <ChangelogArea list={changelogList} />}
            </>
          ))}
          <ChangelogArea list={changelogList} />
        </div>
        <div className="md:col-span-8 md:col-start-3">
          <BlogForm />
        </div>
      </Container>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [date], order: DESC }, filter: { noIndex: { ne: true } }) {
      nodes {
        title
        slug
        date(formatString: "MMM Do, YYYY")
        summary
        category {
          name
        }
        author {
          name
          photo {
            gatsbyImageData(width: 200)
          }
        }
      }
    }
    allContentfulChangelogEntry(sort: { fields: [date], order: DESC }, limit: 3) {
      nodes {
        title
        slug
        date(formatString: "MMM Do, YYYY")
        summary
      }
    }
  }
`;
