import React from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Flex } from '~/elements/Flex';
import { Column } from '~/elements/Column';
import { Br } from '~/elements/Br';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Button } from '~/elements/Button';
import { Icon } from '~/elements/Icon';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Typing } from '~/elements/Typing';
import { Transfer } from './_transfer';

const ProductHero = styled.section`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -82px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, #edf2f7 0%, rgba(237, 242, 247, 0) 100%);
  }
`;

const Crop = styled.div`
  overflow: hidden;
  padding: var(--space-18) 0;
`;

export const SectionHero: React.FC = () => {
  return (
    <ProductHero>
      <Crop>
        <Container>
          <Grid
            gridTemplateColumns={12}
            alignItems="center"
            minHeight="406px"
            position="relative"
            zIndex="1"
            justifyContent={{ _: 'center', lg: 'start' }}
          >
            <Column gridColumn={{ _: 12, nav: 6 }} position="relative" zIndex="2">
              <Flex gap={2} alignItems="center" justifyContent={{ _: 'center', nav: 'start' }}>
                <Icon.ProductBookTransfers />
                <Heading as="h4" size="300" weight="medium">
                  <Typing text="Book Transfers" />
                </Heading>
              </Flex>

              <Heading as="h1" size="900" weight="semibold" mt={4} textAlign={{ _: 'center', nav: 'left' }}>
                Transfer funds <Br />
                instantly, on us
              </Heading>
              <Text size="400" color="gray-500" mt={2} textAlign={{ _: 'center', nav: 'left' }}>
                When you own both accounts at Column, transfers are real-time every second of the day.
              </Text>

              <ButtonGroup mt={6} justifyContent={{ _: 'center', nav: 'start' }}>
                <Button href="https://dashboard.column.com/register" type="link" variant="primary">
                  Start building <Icon.AnimatedArrowRight />
                </Button>
                <Button to="/docs/api/#book-transfer/object" variant="secondary">
                  Documentation
                </Button>
              </ButtonGroup>
            </Column>

            <Column gridColumn={{ _: 12, nav: 6 }} height={{ _: 'auto', nav: '400px' }}>
              <Flex justifyContent={{ _: 'center', nav: 'end' }} height="100%" alignItems="center">
                <Transfer />
              </Flex>
            </Column>
          </Grid>
        </Container>
      </Crop>
    </ProductHero>
  );
};
