import React from 'react';
import LogoBrex from '~/assets/svg/logos/brex.svg';
import LogoAffirm from '~/assets/svg/logos/affirm.svg';

export const testimonialList = [
  {
    id: 'brex',
    company: 'Brex',
    logo: <LogoBrex />,
    brandColor: '244, 106, 53',
    author: 'Henrique Dubugras',
    title: 'Co-Founder and Co-CEO, Brex',
    quote:
      'We know the ecosystem very well and Column provides the speed, flexible account model and API-first approach that no one else can. These aspects are essential to building certain products that power our customers’ growth.',
  },
] as const;

export type TestimonialID = typeof testimonialList[number]['id'];
