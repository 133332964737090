import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Text } from '~/elements/NewText';
import { Heading } from '~/elements/Heading';
import { Flex } from '~/elements/Flex';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Button } from '~/elements/Button';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Icon } from '~/elements/Icon';
import { Typing } from '~/elements/Typing';

const CloserBox = styled(SectionBox)`
  overflow: hidden;
  position: relative;
  z-index: 10;

  &:before {
    content: '';
    width: 100%;
    height: 60%;
    background-image: linear-gradient(133deg, var(--color-blue-600) 12%, var(--color-seafoam-400) 97%);
    opacity: 0.4;
    filter: blur(100px);
    position: absolute;
    left: 50%;
    margin-left: -50%;
    bottom: -50%;
  }

  &:after {
    content: '';
    background-color: var(--color-blue-800);
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    mask-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.4;
  }
`;

const CloserLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
  padding-left: var(--space-6);
  padding-right: var(--space-18);
  color: var(--color-gray-900);
  background-color: rgba(var(--rgb-white), 0.05);
  border-radius: var(--radii-default);
  box-shadow: 0 0 0 1px rgba(var(--rgb-white), 0.1), 0px 2px 4px rgba(0, 0, 0, 0.15);
  gap: var(--space-3);
  transition: 0.2s background-color ease-out, 0.2s box-shadow ease-out;

  @supports (backdrop-filter: blur(2px)) or (-webkit-backdrop-filter: blur(2px)) {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }

  &:hover,
  &:focus {
    background-color: rgba(var(--rgb-white), 0.07);
    box-shadow: 0 0 0 1px rgba(var(--rgb-white), 0.12);

    .icon-animated-arrow-right--chevron,
    .icon-animated-arrow-right--stem {
      transform: translateX(0);
    }
    .icon-animated-arrow-right--stem {
      stroke-dashoffset: 0;
    }
  }

  &:active {
    .icon-animated-arrow-right--chevron,
    .icon-animated-arrow-right--stem {
      transform: translateX(2px);
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-gray-900);
    z-index: 1;
  }

  .icon-closer {
    margin-top: 4px;
    width: 16px;
    color: var(--color-blue-500);
  }

  .icon--stroke {
    color: var(--color-white);
    position: absolute;
    right: var(--space-6);
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
`;

const links = [
  {
    icon: <Icon.Documentation className="icon-closer" />,
    name: 'Documentation',
    description: 'Explore and familiarize yourself with our APIs',
    href: '/docs',
  },
  {
    icon: <Icon.Guides className="icon-closer" />,
    name: 'Getting Started',
    description: 'Quickly get up to speed',
    href: '/docs/guides/getting-started',
  },
];

export const SectionCloser: React.FC = () => {
  const [gradientRef, heroInView] = useInView({
    threshold: 0,
  });

  const heroClasses: ConditionalClasses = {
    'inview--gradient': heroInView,
  };

  return (
    <Section>
      <CloserBox theme="dark">
        <Container position="relative" zIndex="1">
          <Grid as="div" gridTemplateColumns={12} position="relative">
            <Column gridColumn={{ _: 12, md: 6 }}>
              <Heading as="h2" size="700" mb={1} color="white">
                Start building t
                <Typing text="oday" />
              </Heading>
              <Text size="300" color="rgba(var(--rgb-white), .7)">
                Unmatched access to money movement, bank accounts, card networks, and bank capital. You're in the
                driver's seat to build your own products, on your terms, without layers in between.
              </Text>

              <ButtonGroup mt={4} flexDirection={{ _: 'column', sm: 'row' }}>
                <Button href="https://dashboard.column.com/register" variant="primary" type="link" inverted>
                  Start building <Icon.AnimatedArrowRight />
                </Button>
                <Button href="https://dashboard.column.com/contact" variant="secondary" type="link" inverted>
                  Contact Sales
                </Button>
              </ButtonGroup>
            </Column>

            <Column gridColumn={{ _: 12, md: 5 }} gridColumnStart={{ md: 8 }}>
              <Grid
                as="ul"
                alignContent="center"
                height="100%"
                gap={4}
                mt={{ _: 8, md: 0 }}
                gridTemplateColumns={{ sm: 2, md: 1 }}
              >
                {links.map((item) => (
                  <li key={item.name}>
                    <CloserLink to={item.href}>
                      <Flex gap={2}>
                        {item.icon}
                        <div>
                          <Text as="h3" color="white" weight="medium">
                            {item.name}
                          </Text>
                          <Text size="200" color="rgba(var(--rgb-white),.7)">
                            {item.description}
                          </Text>
                        </div>
                      </Flex>
                      <Icon.AnimatedArrowRight className="w-4 h-4" />
                    </CloserLink>
                  </li>
                ))}
              </Grid>
            </Column>
          </Grid>
        </Container>
      </CloserBox>
    </Section>
  );
};

export default SectionCloser;
