import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsCheck: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M16.12 7.5H13.31L8.6 12.22L4.85 13.72L6.35 9.97L8.82 7.5H3.88C3.39 7.5 3 7.95 3 8.5V14.5C3 15.05 3.39 15.5 3.88 15.5H16.12C16.61 15.5 17 15.05 17 14.5V8.5C17 7.95 16.61 7.5 16.12 7.5Z"
        fill="url(#paint0_linear_3204_746)"
      />
      <path d="M8.59998 12.22L4.84998 13.72L6.34998 9.97L8.59998 12.22Z" fill="url(#paint1_linear_3204_746)" />
      <path
        d="M15.53 5.28L8.59003 12.22L6.34003 9.97L13.28 3.03C13.9 2.41 14.91 2.41 15.53 3.03C16.15 3.65 16.15 4.66 15.53 5.28Z"
        fill="url(#paint2_linear_3204_746)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_746"
          x1="-4.79"
          y1="-3.29"
          x2="15.48"
          y2="16.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_746"
          x1="5.43998"
          y1="11.69"
          x2="10.23"
          y2="16.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_746"
          x1="9.57003"
          y1="5.12"
          x2="15.48"
          y2="11.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
