import styled, { css } from 'styled-components';
import { compose, space, layout, position, SpaceProps, LayoutProps, PositionProps } from 'styled-system';

export interface ContainerProps extends SpaceProps, LayoutProps, PositionProps {
  width?: 'default' | 'full' | 'xxlarge' | 'xlarge' | 'medium' | 'small';
  align?: 'left' | 'center';
}

export const Container = styled.div<ContainerProps>`
  padding-right: var(--space-5);
  padding-left: var(--space-5);
  width: 100%;

  @media ${({ theme }) => theme.screens['bp-md']} {
    padding-right: var(--space-6);
    padding-left: var(--space-6);
  }

  ${({ width }) =>
    width === 'full' &&
    css<ContainerProps>`
      width: 100%;
    `}

  ${({ width }) =>
    width === 'xxlarge' &&
    css<ContainerProps>`
      max-width: var(--screen-xxl);
    `}

  ${({ width }) =>
    width === 'xlarge' &&
    css<ContainerProps>`
      max-width: var(--screen-xl);
    `}

  ${({ width }) =>
    width === 'default' &&
    css<ContainerProps>`
      max-width: var(--screen-lg);
    `}

  ${({ width }) =>
    width === 'medium' &&
    css<ContainerProps>`
      max-width: var(--screen-md);
    `}

  ${({ width }) =>
    width === 'small' &&
    css<ContainerProps>`
      max-width: var(--screen-sm);
    `}

  ${({ align }) =>
    align === 'center' &&
    css<ContainerProps>`
      margin-top: 0;
      margin-right: auto;
      margin-bottom: 0;
      margin-left: auto;
    `}

  ${({ align }) =>
    align === 'left' &&
    css<ContainerProps>`
      margin-right: 0;
      margin-left: 0;
    `}

  ${compose(space, layout, position)};
`;

Container.defaultProps = {
  width: 'default',
  align: 'center',
};
