import { css } from 'styled-components';

export const Primary = css`
  :root,
  .color-palette--primary {
    --list-item-bullet: var(--color-blue-300);
    --list-item-number: var(--color-blue-300);
  }

  .color-palette-inverted--primary {
    --list-item-bullet: var(--color-gray-600);
    --list-item-number: var(--color-gray-600);
  }
`;
