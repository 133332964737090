import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { MarkdownInfo } from '../Markdown/Info';
import { Container } from '~/elements/Container';
import { MarkdownStyles } from '~/elements/Markdown';
import { Heading } from '~/elements/Heading';
import { Layout } from '~/components/legal/Layout';
import { sm } from '~/ds/mixins/breakpoints';

const components = { Info: MarkdownInfo };

const LegalDocContainer = styled(Container)`
  padding: var(--space-4) var(--space-4) 0 0;

  @media ${sm} {
    padding: 86px var(--space-16) 0 0;
  }
`;

const LegalDoc: React.FC = (props: any) => {
  return (
    <Layout>
      <Helmet>
        <title>{props.data.mdx.frontmatter.title} - Column Documentation</title>
      </Helmet>

      <LegalDocContainer width="xlarge" className="text">
        <Heading as="h1" size="600" weight="semibold">
          {props.data.mdx.frontmatter.title}
        </Heading>
        <MarkdownStyles mt={12} mb={18}>
          <MDXProvider components={components}>
            <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </MarkdownStyles>
      </LegalDocContainer>
    </Layout>
  );
};

export default LegalDoc;

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
