import { css } from 'styled-components';

export const Seafoam = css`
  .color-palette--seafoam {
    --bg-color-primary: var(--color-seafoam-700);
    --bg-color-secondary: var(--color-seafoam-800);
    --bg-gradient-primary: linear-gradient(165deg, var(--color-seafoam-600) 0%, var(--color-seafoam-500) 92%);
  }

  .color-palette-inverted--seafoam {
    --bg-color-primary: var(--color-seafoam-700);
    --bg-color-secondary: var(--color-seafoam-800);
    --bg-gradient-primary: linear-gradient(165deg, var(--color-seafoam-800) 0%, var(--color-seafoam-700) 92%);
  }
`;
