import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsWorkflows: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M6 17.5C7.65685 17.5 9 16.1569 9 14.5C9 12.8431 7.65685 11.5 6 11.5C4.34315 11.5 3 12.8431 3 14.5C3 16.1569 4.34315 17.5 6 17.5Z"
        fill="url(#paint0_linear_3204_797)"
      />
      <path
        d="M14 17.5C15.6569 17.5 17 16.1569 17 14.5C17 12.8431 15.6569 11.5 14 11.5C12.3431 11.5 11 12.8431 11 14.5C11 16.1569 12.3431 17.5 14 17.5Z"
        fill="url(#paint1_linear_3204_797)"
      />
      <path
        d="M7 8.5V11.68C6.69 11.56 6.35 11.5 6 11.5C5.65 11.5 5.31 11.56 5 11.68V8.45C5.16 8.48 5.33 8.5 5.5 8.5H7Z"
        fill="url(#paint2_linear_3204_797)"
      />
      <path
        d="M10.9999 14.5C10.9999 14.85 11.0599 15.19 11.1799 15.5H8.81995C8.93995 15.19 8.99995 14.85 8.99995 14.5C8.99995 14.15 8.93995 13.81 8.81995 13.5H11.1799C11.0599 13.81 10.9999 14.15 10.9999 14.5Z"
        fill="url(#paint3_linear_3204_797)"
      />
      <path
        d="M10.5 3.5H5.5C4.11929 3.5 3 4.61929 3 6C3 7.38071 4.11929 8.5 5.5 8.5H10.5C11.8807 8.5 13 7.38071 13 6C13 4.61929 11.8807 3.5 10.5 3.5Z"
        fill="url(#paint4_linear_3204_797)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_797"
          x1="3.78"
          y1="21.02"
          x2="6.56"
          y2="12.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_797"
          x1="11.78"
          y1="21.02"
          x2="14.56"
          y2="12.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3204_797"
          x1="4.48"
          y1="13.98"
          x2="9.27"
          y2="1.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3204_797"
          x1="8.87995"
          y1="18.58"
          x2="12.1499"
          y2="6.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3204_797"
          x1="2.63"
          y1="17.58"
          x2="8.87"
          y2="4.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
