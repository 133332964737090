import React from 'react';
import styled from 'styled-components';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Typing } from '~/elements/Typing';
import { sm, md } from '~/ds/mixins/breakpoints';

const Numbers = styled.img`
  width: 800px;
  max-width: 800px;
  margin: 48px 0 0 -102px;
  
  @media ${sm} {
    width: 1000px;
    max-width: 1000px;
    margin: 48px 0 0 -128px;
  }

  @media ${md} {
    width: 1440px;
    max-width: 1440px;
    margin: 48px 0 0 -184px;
  }
`;

export const SectionSafety: React.FC = () => {
  return (
    <Section overflow="hidden" pb={{ md: 32 }}>
      <Container>
        <Grid gridTemplateColumns={12} gap={8}>
          <Column gridColumn={{ _: 12, sm: 9, md: 6 }}>
            <Heading as="h2" size="900" weight="semibold" color="purple-800">
              Safety in nu
              <Typing text="mbers" />
            </Heading>
            <Text size="300" mt={2} pr={{ _: 0, md: 16 }} color="rgba(var(--rgb-purple-800),0.7)">
              Funds are distributed across various financial institutions nationwide, with up to $250,000 allocated to each account. Funds will
              have no exposure to a single bank, industry, or macro event — and will have full FDIC insurance coverage
              across the totality of deposits.
            </Text>
          </Column>
        </Grid>
        <Numbers src="/images/products/protected-deposits/safety.svg" />
      </Container>
    </Section>
  );
};
