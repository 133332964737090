import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductChecks: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="26" height="22">
        <path d="m19 12-8 8-5 2 2-5 5-5H3v13h26V12H19Zm8 7h-8v-2h8v2Z" fill="url(#check-dark__a)" />
        <path d="m11 20-5 2 2-5 3 3Z" fill="url(#check-dark__b)" />
        <path d="M23.5 7.5 11 20l-3-3L20.5 4.5a2.122 2.122 0 0 1 3 3Z" fill="url(#check-dark__c)" />
        <path d="M27 17h-8v2h8v-2Z" fill="url(#check-dark__d)" />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M32 0H0v32h32V0Z" fill="url(#check-dark__f)" />
      </g>
      <defs>
        <linearGradient id="check-dark__a" x1="6.313" y1="8.813" x2="25.54" y2="28.04" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="check-dark__b" x1="6.83" y1="19.33" x2="9.531" y2="22.031" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="check-dark__c"
          x1="14.976"
          y1="9.976"
          x2="17.748"
          y2="12.748"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="check-dark__d"
          x1="17.156"
          y1="12.156"
          x2="27.602"
          y2="22.602"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="check-dark__f" x1="32" y1="0" x2="0" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-gradient-stop-1, #FFFFFF)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #FFFFFF)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
