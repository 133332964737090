import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductChecks: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductChecks {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a4">
        <stop offset="0" stopColor="#1e4199" stopOpacity="0" />
        <stop offset="1" stopColor="#1e4199" />
      </linearGradient>
      <linearGradient id="b4" gradientUnits="userSpaceOnUse" x1="-.99" x2="25.37" href="#a4" y1="2.01" y2="28.37" />
      <linearGradient id="c4" gradientUnits="userSpaceOnUse" x1="13.5" x2="6.56" y1="26" y2="19.06">
        <stop offset="0" stopColor="#1e4199" />
        <stop offset="1" stopColor="#1e4199" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="d4" gradientUnits="userSpaceOnUse" x1="14.55" x2="21.16" href="#a4" y1="9.55" y2="16.16" />
      <linearGradient id="e4" gradientUnits="userSpaceOnUse" x1="14.82" x2="27.97" href="#a4" y1="10.32" y2="23.47" />
      <path
        d="m19 12-8 8-5 2 2-5 5-5h-7c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm6.5 7h-6c-.28 0-.5-.22-.5-.5v-1c0-.28.22-.5.5-.5h6c.28 0 .5.22.5.5v1c0 .28-.22.5-.5.5z"
        fill="url(#b4)"
      />
      <path d="m11 20-5 2 2-5z" fill="url(#c4)" />
      <path d="m23.5 7.5-12.5 12.5-3-3 12.5-12.5c.83-.83 2.17-.83 3 0 .83.83.83 2.17 0 3z" fill="url(#d4)" />
      <rect fill="url(#e4)" height="2" rx=".5" width="7" x="19" y="17" />
    </IconBase>
  );
};
