import React from 'react';

export const NationalID = [
  {
    param: 'number',
    type: 'string',
    required: true,
    description: <p>National ID number</p>,
  },
  {
    param: 'country_code',
    type: 'string',
    required: true,
    description: <p>Country code where the national ID was issued. ISO 3166-1 Alpha-2 Country Code (e.g., <code>US, FR, UK, DE, ...</code>).</p>,
  },
];
