import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconRight: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke', props.className];

  return (
    <IconBase {...props} className={classNames(staticClasses)}>
      <path
        d="m8 13 5-5-5-5"
        stroke="var(--icon-base-color)"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M12 8H2" stroke="var(--icon-base-color)" strokeWidth="1.5" />
    </IconBase>
  );
};
