import { css } from 'styled-components';
import { Icon } from './Icon';
import { List } from './List';
import { TextGroup } from './TextGroup';

export const Components = css`
    ${TextGroup}
    ${Icon}
    ${List}
  }
`;
