import React from 'react';
import styled, { css } from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Link } from '~/elements/Link';
import { Grid } from '~/elements/Grid';
import { Icon } from '~/elements/Icon';
import { Flex } from '~/elements/Flex';
import { Br } from '~/elements/Br';
import { Column } from '~/elements/Column';
import { Typing } from '~/elements/Typing';
import Products, { ProductType } from '~/data/products';

export interface AllProductsProps {
  category?: 'infrastructure' | 'lending';
  excludeHref?: string;
  additionalProducts?: ProductType[];
  background?: string;
  accentColor?: string;
  hoverColor?: 'infrastructure' | 'lending';
}

const Wrapper = styled.section<AllProductsProps>`
  padding: var(--space-18) 0;
  background: ${(props) => props.background || 'transparent'};
  overflow: hidden;
  position: relative;
  z-index: 2;
`;

const Waves = styled(Column)<AllProductsProps>`
  position: relative;
  padding: 1px 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 150px 200px ${(props) => props.background || '#fff'} inset,
      0 -200px 200px ${(props) => props.background || '#fff'} inset;
    z-index: 2;
  }

  &:after {
    border-radius: 0%;
    content: '';
    background-color: ${(props) => props.accentColor || 'rgba(210, 207, 216, 0.3)'};
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
`;

const Chip = styled(Text)`
  background-color: rgba(var(--rgb-gray-50), 0.25);
  padding: var(--space-0-5) var(--space-3);
  border-radius: 36px;
  box-shadow: 0 0 0 1px rgba(var(--rgb-black), 0.05);
  font-size: var(--text-100);
  color: var(--color-gray-400);
  transition: 0.2s color, 0.2s box-shadow, 0.2s background-color;
`;

const ProductLink = styled(Link)`
  position: relative;

  &:hover {
    &:before {
      opacity: 0.25;
      transform: scale(1.2);
    }
  }

  &:before {
    content: '';
    border-radius: 0%;
    filter: blur(30px);
    opacity: 0;
    position: absolute;
    --pattern-offset: -1%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
    z-index: 2;
    transform: scale(0.8);
    transition: 0.75s opacity, 0.75s transform;
    pointer-events: none;
    background: linear-gradient(132deg, rgba(68, 180, 139, 0.5) 12%, rgba(47, 143, 197, 0.5) 97%);

    ${({ hoverColor }) =>
      hoverColor === 'infrastructure' &&
      css<AllProductsProps>`
        background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
      `}
  }
`;

const ProductCard = styled.div`
  position: relative;
  z-index: 3;
  background-color: rgba(var(--rgb-white), 0.5);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(var(--rgb-black), 0.05), 0 1px 3px rgba(var(--rgb-black), 0.05);
  padding: var(--space-8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  transition: 0.75s box-shadow, 0.4s background-color;

  &:hover {
    background-color: rgba(var(--rgb-white), 1);
    box-shadow: 0 0 0 1px rgba(var(--rgb-black), 0.05), var(--shadow-product);

    .icon-animated-arrow-right--chevron,
    .icon-animated-arrow-right--stem {
      transform: translateX(0);
    }
    .icon-animated-arrow-right--stem {
      stroke-dashoffset: 0;
    }

    &:before {
      opacity: 1;
      transform: scale(1.1);
    }

    ${Chip} {
      background-color: rgba(var(--rgb-blue-50), 0.5);
      color: var(--color-blue-400);
      box-shadow: 0 0 0 1px rgba(var(--rgb-blue-100), 1);
    }
  }

  &:active {
    .icon-animated-arrow-right--chevron,
    .icon-animated-arrow-right--stem {
      transform: translateX(2px);
    }
  }
`;

export const AllProducts: React.FC<AllProductsProps> = (props) => {
  return (
    <Wrapper {...props}>
      <Container>
        <Grid gridTemplateColumns={12} gap={8} mb={8}>
          <Column gridColumn={{ _: 12, sm: 9 }}>
            <Heading as="h2" size="700" weight="semibold">
              Infrastructure products built for <Br display={{ _: 'none', lg: 'block' }} />
              developers in the lightest fo
              <Typing text="rmat possible" />
            </Heading>
          </Column>
        </Grid>
      </Container>

      <Waves gridColumn={12} mb={18} {...props}>
        <Container>
          <Grid gridTemplateColumns={{ _: 1, md: 2, lg: 3 }} gap={8}>
            {Products[props.category ?? 'infrastructure'].map((item) => (
              <ProductLink to={item.href} key={item.name} {...props}>
                <ProductCard>
                  <div>
                    {item.icon}
                    <Heading as="h3" size="300" weight="medium" color="gray-700" mt={6} mb={2}>
                      {item.name}
                    </Heading>
                    <Text size="200" color="gray-600">
                      {item.description}
                    </Text>
                  </div>
                  <Flex alignItems="center" gap={1}>
                    <Text size="200" weight="medium" color="inherit">
                      Learn more
                    </Text>
                    <Icon.AnimatedArrowRight />
                  </Flex>
                </ProductCard>
              </ProductLink>
            ))}
          </Grid>
        </Container>
      </Waves>

      <Container>
        <Grid gridTemplateColumns={12} gap={8}>
          <Column gridColumn={{ _: 12, sm: 9, lg: 8 }}>
            <Heading as="h2" size="700" weight="semibold">
              Lending products for companies to scale lending programs fa
              <Typing text="ster and cheaper" />
            </Heading>
          </Column>

          <Column gridColumn={12}>
            <Grid gridTemplateColumns={{ _: 1, md: 2, lg: 3 }} gap={8}>
              {Products[props.category ?? 'lending'].map((item) => (
                <ProductLink to={item.href} key={item.name} {...props}>
                  <ProductCard>
                    <div>
                      {item.icon}
                      <Heading as="h3" size="300" weight="medium" color="gray-700" mt={6} mb={2}>
                        {item.name}
                      </Heading>
                      <Text size="200" color="gray-600">
                        {item.description}
                      </Text>
                    </div>
                    <Flex alignItems="center" gap={1}>
                      <Text size="200" weight="medium" color="inherit">
                        Learn more
                      </Text>
                      <Icon.AnimatedArrowRight />
                    </Flex>
                  </ProductCard>
                </ProductLink>
              ))}
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Wrapper>
  );
};

AllProducts.defaultProps = {
  hoverColor: 'infrastructure',
};
