import { css } from 'styled-components';

export const Red = css`
  .color-palette--red {
    /* Primary */
    --btn-text-color-primary: var(--color-0);

    --btn-bg-primary: var(--color-red-600);
    --btn-bg-primary-hover: var(--color-red-700);
    --btn-bg-primary-active: var(--color-red-800);

    --btn-border-primary: var(--color-red-700);
    --btn-border-primary-hover: var(--color-red-800);
    --btn-border-primary-active: var(--color-red-900);

    --btn-focus-ring-primary: var(--color-red-200);

    /* Secondary */
    --btn-text-color-secondary: var(--color-red-700);

    --btn-bg-secondary: var(--color-red-100);
    --btn-bg-secondary-hover: var(--color-red-100);
    --btn-bg-secondary-active: var(--color-red-100);

    --btn-border-secondary: var(--color-red-200);
    --btn-border-secondary-hover: var(--color-red-300);
    --btn-border-secondary-active: var(--color-red-300);

    --btn-focus-ring-secondary: var(--color-red-200);
  }

  .color-palette-inverted--red {
    /* Primary */
    --btn-text-color-primary: var(--color-red-900);

    --btn-bg-primary: var(--color-0);
    --btn-bg-primary-hover: var(--color-0);
    --btn-bg-primary-active: var(--color-0);

    --btn-border-primary: var(--color-red-800);
    --btn-border-primary-hover: rgba(var(--rgb-red-900), 1);
    --btn-border-primary-active: rgba(var(--rgb-red-900), 1);

    --btn-focus-ring-primary: var(--color-red-500);

    /* Secondary */
    --btn-text-color-secondary: var(--color-0);

    --btn-bg-secondary: rgba(var(--rgb-red-600), 0.75);
    --btn-bg-secondary-hover: var(--color-red-600);
    --btn-bg-secondary-active: var(--color-red-700);

    --btn-border-secondary: rgba(var(--rgb-red-800));
    --btn-border-secondary-hover: var(--color-red-900);
    --btn-border-secondary-active: var(--color-red-900);

    --btn-focus-ring-secondary: var(--color-red-500);
  }
`;
