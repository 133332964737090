import styled from 'styled-components';
import { system, compose, flexbox, space, layout, FlexboxProps, SpaceProps, LayoutProps } from 'styled-system';

interface ButtonGroupProps extends FlexboxProps, SpaceProps, LayoutProps {
  gap?: any;
  gapX?: any;
  gapY?: any;
}

export const ButtonGroup = styled.div<ButtonGroupProps>`
  display: flex;
  gap: var(--space-3);

  ${compose(flexbox, space, layout)};

  ${system({
    gap: {
      property: 'gridGap',
      scale: 'space',
    },
    gapX: {
      property: 'gridColumnGap',
      scale: 'space',
    },
    gapY: {
      property: 'gridRowGap',
      scale: 'space',
    },
  })}
`;
