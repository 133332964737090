import { IconUp } from './Up';
import { IconRight } from './Right';
import { IconDown } from './Down';
import { IconLeft } from './Left';

export const IconChevron = {
  ChevronUp: IconUp,
  ChevronRight: IconRight,
  ChevronDown: IconDown,
  ChevronLeft: IconLeft,
};
