import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '~/elements/Button';
import { Flex } from '~/elements/Flex';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Box } from '~/elements/Box';
import { Link } from '~/elements/Link';
import { Icon } from '~/elements/Icon';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

interface ProductPriceProps {
  child?: boolean;
}

const PricingCard = styled.div`
  background: var(--color-gray-50);
  padding: var(--space-5);
  border-radius: var(--radii-default);

  @media ${({ theme }) => theme.screens['bp-md']} {
    padding: var(--space-8);
  }
`;

const ProductPrice = styled.div<ProductPriceProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-6);
  padding: var(--space-3) 0;
  border-top: 1px solid var(--color-gray-200);

  ${({ child }) =>
    child === true &&
    css<ProductPriceProps>`
      padding: var(--space-3) 0 var(--space-3) var(--space-4);
    `};
`;

const PricingInfo = styled(Flex)`
  border-top: 1px solid var(--color-gray-200);
`;

export const SectionLoanOrigination: React.FC = () => {
  return (
    <PricingCard id="loan-origination" className="color-palette--lending">
      <Grid alignItems="flex-start" justifyContent="space-between" gap={{ _: 4, md: 8 }}>
        <Column gridColumn={{ _: 12, md: 8 }}>
          <Flex gap={4} flexDirection={{ _: 'column', md: 'row' }} justifyContent="flex-start">
            <Icon.ProductLoanOrigination />
            <Column>
              <Heading as="h2" size="500" weight="semibold">
                <Link to="/loan-origination">Loan Origination</Link>
              </Heading>
              <Text color="gray-500" mt={2}>
                Partner with Column on lending to provide a variety of credit products to your customers.
              </Text>
            </Column>
          </Flex>
        </Column>
        <Column gridColumn={{ _: 12, md: 4 }}>
          <Flex justifyContent={{ _: 'flex-start', md: 'flex-end' }}>
            <Button size="large" variant="primary" to="/loan-origination">
              Learn more
              <Icon.AnimatedArrowRight />
            </Button>
          </Flex>
        </Column>
      </Grid>
      <Grid>
        <Column gridColumn={12} pl={{ _: 0, md: 11 }}>
          <PricingInfo flexDirection={{ _: 'column', md: 'row' }} mt={6} pt={8} pb={2} gap={{ _: 8, lg: 16 }}>
            <Column>
              <Flex
                gap={4}
                flexDirection={{ _: 'column', sm: 'row', md: 'column', lg: 'row' }}
                justifyContent={{ _: 'flex-start', sm: 'space-between', md: 'flex-start', lg: 'space-between' }}
              >
                <Column>
                  <Flex gap={2}>
                    <Icon.Testing />
                    <div>
                      <Heading as="h4" weight="semibold">
                        Test loan origination in sandbox
                      </Heading>
                      <Text size="200" mt={1} color="gray-500">
                        Get started testing loan origination in sandbox with Column's APIs.
                      </Text>
                    </div>
                  </Flex>
                </Column>
                <Column pl={{ _: 6, lg: 0 }}>
                  <Button variant="secondary" size="small" type="link" href="https://dashboard.column.com/register">
                    Build in Sandbox
                  </Button>
                </Column>
              </Flex>
            </Column>

            <Column>
              <Flex
                gap={4}
                flexDirection={{ _: 'column', sm: 'row', md: 'column', lg: 'row' }}
                justifyContent={{ _: 'flex-start', sm: 'space-between', md: 'flex-start', lg: 'space-between' }}
              >
                <Column>
                  <Flex gap={2}>
                    <Icon.Chat />
                    <div>
                      <Heading as="h4" weight="semibold">
                        Ready to talk?
                      </Heading>
                      <Text size="200" mt={1} color="gray-500">
                        Contact us to learn more about partnering with Column.
                      </Text>
                    </div>
                  </Flex>
                </Column>
                <Column pl={{ _: 6, lg: 0 }}>
                  <Button variant="secondary" size="small" type="link" href="https://dashboard.column.com/contact">
                    Contact us
                    <Icon.AnimatedArrowRight />
                  </Button>
                </Column>
              </Flex>
            </Column>
          </PricingInfo>
        </Column>
      </Grid>
    </PricingCard>
  );
};

export default SectionLoanOrigination;
