import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { Flex } from '~/elements/Flex';
import { Br } from '~/elements/Br';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
`;

export const SectionDeveloperFirst: React.FC = () => {
  return (
    <Section mt={9} overflow="hidden">
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column
            gridColumn={{ _: 12, md: 8, lg: 6 }}
            gridColumnStart={{ _: 1, md: 3, lg: 4 }}
            position="relative"
            zIndex={2}
          >
            <Heading as="h2" size="700" weight="semibold" textAlign={{ _: 'left', md: 'center' }}>
              The developer-first
              <Br display={{ _: 'none', md: 'block' }} /> card program
            </Heading>
            <Text size="300" color="gray-500" mt={2} textAlign={{ _: 'left', md: 'center' }} px={{ _: 0, md: 10 }}>
              We are developers at our core. From settlement to clearing you control the process from our dashboard or
              API.
            </Text>
          </Column>

          <Column
            gridColumn={{ _: 10, md: 10 }}
            gridColumnStart={{ _: 2, md: 2 }}
            mb={12}
            mt={8}
            position="relative"
            zIndex={1}
          >
            <Grid gridTemplateColumns={{ _: 1, md: 3 }} gap={6}>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      Direct integration
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      Integrate directly to the source. Without middleware providers you have full flexibility and
                      ownership.
                    </Text>
                  </div>
                </Flex>
              </Column>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      Speed of execution
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      Our relationships across the card ecosystem, from networks to processors, enable you to ship on
                      your timeline.
                    </Text>
                  </div>
                </Flex>
              </Column>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      A program for developers
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      We are engineers and operators at our core, unlike legacy banks we move at your speed.
                    </Text>
                  </div>
                </Flex>
              </Column>
            </Grid>
          </Column>

          <Column gridColumn={{ _: 10, md: 6 }} gridColumnStart={{ _: 2, md: 4 }} position="relative" zIndex="2">
            <Code title="Clear a book transfer" mb="var(--space-n48)">
              {`curl 'https://api.column.com/transfers/book/<book_transfer_id>/clear' \\
    -u :<YOUR API KEY> \\
    -d amount="15000" \\
    -d currency_code=USD`}
            </Code>
          </Column>

          <Column gridColumn={10} gridColumnStart={{ _: 2 }}>
            <ProductScreen mt={6}>
              <img src="/images/products/card-programs/transfers.png" />
            </ProductScreen>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionDeveloperFirst;
