import { css } from 'styled-components';
import { Primary } from './primary/icon';
import { Blue } from './blue/icon';
import { Gray } from './gray/icon';
import { Orange } from './orange/icon';
import { Products } from './products/icon';
import { Lending } from './lending/icon';
import { Infrastructure } from './infrastructure/icon';

export const Icon = css`
  ${Primary}
  ${Blue}
  ${Orange}
  ${Gray}
  ${Products}
  ${Lending}
  ${Infrastructure}
`;
