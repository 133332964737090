import { css } from 'styled-components';

export const Gray = css`
  .color-palette--gray {
    --icon-fill-highlight: var(--color-gray-400);
    --icon-fill-shadow: var(--color-gray-800);

    --icon-gradient-stop-1: var(--color-gray-400);
    --icon-gradient-stop-2: var(--color-gray-800);
  }

  .color-palette--light-gray {
    --icon-fill-highlight: var(--color-gray-400);
    --icon-fill-shadow: var(--color-gray-600);

    --icon-gradient-stop-1: var(--color-gray-400);
    --icon-gradient-stop-2: var(--color-gray-600);
  }

  .color-palette-inverted--gray {
    --icon-fill-highlight: var(--color-gray-200);
    --icon-fill-shadow: var(--color-gray-500);

    --icon-gradient-stop-1: var(--color-0);
    --icon-gradient-stop-2: var(--color-0);
  }
`;
