import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconTesting: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const id2 = generateRandomString();
  const staticClasses: StaticClasses = ['icon icon--testing', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 3C10.55 3 11 2.55 11 2C11 1.45 10.55 1 10 1H6C5.45 1 5 1.45 5 2C5 2.54998 5.44997 2.99997 5.99995 3V6L1.71995 13.55C1.38995 14.22 1.86995 15 2.61995 15H13.38C14.13 15 14.61 14.22 14.28 13.55L9.99995 6L10 3ZM8.99995 12C9.54995 12 9.99995 11.55 9.99995 11V10C9.99995 9.45 9.54995 9 8.99995 9H6.99995C6.44995 9 5.99995 9.45 5.99995 10V11C5.99995 11.55 6.44995 12 6.99995 12H8.99995Z"
          fill="url(#paint0_linear_2477_529)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path fill={`url(#${id2})`} d="M15.9993 0H-0.000732422V16H15.9993V0Z" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2477_529"
          x1="3.85196"
          y1="-4.39361"
          x2="12.8772"
          y2="12.0948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id={`${id2}`} x1="-0.000732422" y1="0" x2="15.9993" y2="16" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--icon-gradient-stop-1)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
