import React from 'react';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Button } from '~/elements/Button';
import { List } from '~/elements/List';
import { Icon } from '~/elements/Icon';
import { Typing } from '~/elements/Typing';

export const SectionWhyColumn: React.FC = () => {
  return (
    <Section inverted palette="cyan">
      <SectionBox pb={{ _: 18, md: 24 }} pattern patternShade="dark" theme="dark">
        <Container className="relative z-10">
          <div className="grid grid-cols-12">
            <div className="col-span-12 sm:col-span-9 lg:col-span-7">
              <Heading as="h4" size="300" color="white" eyebrow>
                Why Column
              </Heading>

              <Heading as="h2" size="600" weight="semibold" color="white" mt={6}>
                Created by engineers and founders
                <Heading as="span" size="600" color="rgba(var(--rgb-white),.5)">
                  , we built Column from the ground up to solve our own p
                  <Typing text="roblem" />
                </Heading>
              </Heading>

              <Button mt={4} inverted to="/company" variant="link" fontSize="16px">
                About our company <Icon.AnimatedArrowRight />
              </Button>
            </div>
          </div>
          <List
            as="ol"
            bullet="numbered"
            className="grid grid-cols-1 gap-8 mt-12 md:grid-cols-3 md:gap-3 lg:gap-8 mb-12 md:mt-12 stylized-list-numbered"
          >
            <li className="list-item">
              <Heading as="h3" size="400" color="white" weight="semibold">
                Close to the metal
              </Heading>
              <Text size="300" color="rgba(var(--rgb-white), .5)" mt={2}>
                We connect directly to the Federal Reserve with dedicated hardware, built our own ledger and core, and
                don't use any middleware. We then expose this directly to you.
              </Text>
            </li>

            <li className="list-item">
              <Heading as="h3" size="400" color="white" weight="semibold">
                Efficient compliance
              </Heading>
              <Text size="300" color="rgba(var(--rgb-white), .5)" mt={2}>
                We are a team of experienced compliance experts focused on helping you ship product. We expose tools to
                give you transparency and visibility into our compliance program and are focused on moving fast and
                efficiently, no matter the complexity of your program.
              </Text>
            </li>

            <li className="list-item">
              <Heading as="h3" size="400" color="white" weight="semibold">
                Dedicated partners
              </Heading>
              <Text size="300" color="rgba(var(--rgb-white), .5)" mt={2}>
                We are the only bank that is dedicated to providing services to developers and builders, and nothing
                else. We do not operate competing programs nor are we distracted by other business lines.
              </Text>
            </li>
          </List>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionWhyColumn;
