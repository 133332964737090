import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { FootnoteContainer } from './RichText/FootnoteContainer';
import { options, createOptionsWithFootnotes } from './RichText/RichTextOptions';
import { Text } from '~/elements/Text';
import { Container } from '~/elements/Container';
import { Link } from '~/elements/Link';
import { Icon } from '~/elements/Icon';
import { BlogForm } from '~/elements/BlogForm';
import { Layout } from '~/components/Layout';
import TwitterIcon from '~/assets/svg/blog/twitter.svg';
import LinkedinIcon from '~/assets/svg/blog/linkedin.svg';
import { getBlogCategoryColor } from '~/utils/blog';

const BlogPost: React.FC = (props: any) => {
  const prev = props.data.prev;
  const next = props.data.next;
  const data = props.data.contentfulBlogPost;
  const richTextOptions = createOptionsWithFootnotes(data.footnotes);

  const [shareRef, shareInView] = useInView({
    threshold: 0,
  });

  const url = typeof window !== 'undefined' ? window.location.href : 'https://column.com/blog';

  const shareClasses: ConditionalClasses = {
    'inview--share': shareInView,
  };

  return (
    <Layout>
      <Helmet>
        <title>Column Blog — {data.title}</title>
        <meta property="twitter:site_name" content={`Column Blog — ${data.title}`} />
        {data.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>

      {/* Sticky share */}
      <div className={classNames('social-share social-share-follow', shareClasses)}>
        <Text size="200">Share</Text>
        <Link href={`https://twitter.com/share?url=${url}`} className="twitter" target="_blank">
          <TwitterIcon />
        </Link>
        <Link href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`} className="linkedin" target="_blank">
          <LinkedinIcon />
        </Link>
      </div>
      <Container className={`blog blog-container ${getBlogCategoryColor(data.category.name)}`}>
        {/* Navigation */}
        <div className="blog-post-nav">
          <Text as="a" to="../" size="200" className="blog-home blog-theme--gray">
            <Icon.BlogHome />
            Blog Home
          </Text>
        </div>

        {/* Blog content */}
        <div className="px-4 pb-8 md:col-span-8 md:col-start-3 md:px-8">
          <div className="pt-24 pb-4">
            <div className="flex gap-4 place-items-center" ref={shareRef}>
              <Text size="200" className="blog-category">
                {data.category.name}
              </Text>
              <Text size="200" className="blog-date">
                {data.date}
              </Text>
            </div>
            <Text as="h1" variant="heading" size="700" className="blog-title">
              {data.title}
            </Text>
            <div className="flex gap-2 mt-16 place-items-center">
              <GatsbyImage
                alt={data.author.name}
                image={data.author.photo.gatsbyImageData}
                className="blog-author-image"
              />
              <div>
                <Text size="200" className="blog-author-name">
                  {data.author.name}
                </Text>
                <Text size="200" className="blog-author-title">
                  {data.author.title}
                </Text>
              </div>
            </div>
          </div>
          <div className="blog-post blog-content">{data.content && renderRichText(data.content, richTextOptions)}</div>

          {/* Footnotes */}
          {data.footnotes && data.footnotes.length > 0 && (
            <div className="footnote-wrap blog-content">
              <FootnoteContainer footnotes={data.footnotes} />
            </div>
          )}

          <div className="blog-closer">
            <div className="flex gap-2 place-items-center">
              <GatsbyImage
                alt={data.author.name}
                image={data.author.photo.gatsbyImageData}
                className="blog-author-image"
              />
              <div>
                <Text size="200" className="blog-author-name">
                  {data.author.name}
                </Text>
                <Text size="200" className="blog-author-title">
                  {data.author.title}
                </Text>
              </div>
            </div>

            {/* Share bottom */}
            <div className="social-share" ref={shareRef}>
              <Text size="200">Share</Text>
              <Link href={`https://twitter.com/share?url=${url}`} className="twitter" target="_blank">
                <TwitterIcon />
              </Link>
              <Link
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                className="linkedin"
                target="_blank"
              >
                <LinkedinIcon />
              </Link>
            </div>
          </div>

          {/* Blog sign up */}
          <BlogForm />

          {/* Navigate to prev/next */}
          <div className="blog-continue">
            {prev && (
              <div>
                <Text size="200">Previous post</Text>
                <Link to={`/blog/${prev.slug}`}>
                  <Text as="h4" variant="heading">
                    {prev.title}
                  </Text>
                </Link>
              </div>
            )}
            {next && (
              <div className="ml-auto text-right">
                <Text size="200">Next post</Text>
                <Link to={`/blog/${next.slug}`}>
                  <Text as="h4" variant="heading">
                    {next.title}
                  </Text>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $prev: String, $next: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      noIndex
      author {
        name
        title
        photo {
          gatsbyImageData(width: 200)
        }
      }
      date(formatString: "MMM Do, YYYY")
      rawDate: date
      content {
        raw
        references {
          ... on Node {
            ... on ContentfulAuthor {
              contentful_id
              __typename
              name
              title
              photo {
                gatsbyImageData
              }
            }
            ... on ContentfulBlogPost {
              contentful_id
              __typename
              title
              slug
            }
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData
              file {
                url
                contentType
              }
            }
          }
        }
      }
      footnotes {
        id
        referenceNumber
        text {
          raw
        }
      }
      summary
      category {
        name
      }
      video {
        ... on ContentfulVideo {
          title
          length
          file {
            file {
              url
              contentType
            }
          }
        }
      }
    }
    prev: contentfulBlogPost(slug: { eq: $prev }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $next }) {
      slug
      title
    }
  }
`;
