import React from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Hero } from '~/components/Hero';
import { Column } from '~/elements/Column';
import { Grid } from '~/elements/Grid';
import { Typing } from '~/elements/Typing';
import { Heading } from '~/elements/Heading';

export const SectionHero: React.FC = () => {
  const [gradientRef, heroInView] = useInView({
    threshold: 0,
  });

  const heroClasses: ConditionalClasses = {
    'inview--gradient': heroInView,
  };

  return (
    <Hero className={classNames(heroClasses)}>
      <Grid alignItems="center" gap={8} zIndex="10" position="relative">
        <Column gridColumn={{ _: '1 / span 12', md: '2 / span 8' }} pr={{ _: 0, lg: 12 }}>
          <Heading as="h4" weight="semibold" size="400" mb={8}>
            Security
          </Heading>

          <Heading as="h1" weight="semibold" size="600">
            We're committed to providing innovative and modern financial services without sacrificing the safety of your
            personal
            <Typing text=" info" />
          </Heading>
        </Column>
      </Grid>

      <div className="absolute bottom-0 left-0 right-0 overflow-hidden -top-96" style={{ height: 1200 }}>
        <div className="background-gradient" ref={gradientRef}>
          <div className="background-gradient-pattern" />
        </div>
      </div>
    </Hero>
  );
};

export default SectionHero;
