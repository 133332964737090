import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsSandboxTesting: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M16.28 16.05L12 7.5V4.5H7.99995V7.5L3.71995 16.05C3.38995 16.72 3.86995 17.5 4.61995 17.5H15.38C16.13 17.5 16.61 16.72 16.28 16.05ZM12 13.5C12 14.05 11.55 14.5 11 14.5H8.99995C8.44995 14.5 7.99995 14.05 7.99995 13.5V12.5C7.99995 11.95 8.44995 11.5 8.99995 11.5H11C11.55 11.5 12 11.95 12 12.5V13.5Z"
        fill="url(#paint0_linear_3204_786)"
      />
      <path
        d="M13 3.5C13 4.05 12.55 4.5 12 4.5H8C7.45 4.5 7 4.05 7 3.5C7 2.95 7.45 2.5 8 2.5H12C12.55 2.5 13 2.95 13 3.5Z"
        fill="url(#paint1_linear_3204_786)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3204_786"
          x1="3.15995"
          y1="0.93"
          x2="10.81"
          y2="16.87"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3204_786"
          x1="8"
          y1="-2.09"
          x2="10.74"
          y2="5.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--icon-color-docs)" stop-opacity="0" />
          <stop offset="1" stop-color="var(--icon-color-docs)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
