import styled from 'styled-components';

export interface MarkdownInfoProps {
  type?: 'default' | 'warning';
}

export const MarkdownInfo = styled.div<MarkdownInfoProps>`
  background: ${({ theme, type }) => (type === 'warning' && theme.colors['yellow-50']) || theme.colors['gray-50']};
  padding: var(--space-6);
  padding-left: var(--space-12);
  border-radius: var(--radii-default);
  position: relative;
  margin: 1em 0;

  &:before {
    position: absolute;
    content: '';
    overflow: hidden;
    background: url('/images/icons/icon-arrow-right.svg') no-repeat;
    background: ${({ type }) =>
        (type === 'warning' && "url('/images/icons/icon-warning.svg') no-repeat") ||
        "url('/images/icons/icon-arrow-right.svg')"}
      no-repeat;
    width: var(--space-4);
    height: var(--space-4);
    top: 1.64em;
    left: var(--space-6);
  }

  h4 {
    margin-top: 0 !important;
    margin-bottom: var(--space-1) !important;
  }

  p {
    font-size: var(--text-200);
    &:last-of-type {
      margin: 0;

      &:not([class]) {
        margin-bottom: 0;
      }
    }
  }
`;

MarkdownInfo.defaultProps = {
  type: 'default',
};
