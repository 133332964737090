import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { SectionBox } from '~/elements/SectionBox';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
  margin-bottom: var(--space-2);
`;

export const SectionOwn: React.FC = () => {
  return (
    <Section py={{ _: 0, md: 0, lg: 0 }}>
      <SectionBox theme="light">
        <Container>
          <Grid gridTemplateColumns={12} gap={6}>
            <Column gridColumn={{ _: 12, md: 6 }} position="relative" zIndex={2}>
              <Grid gridTemplateColumns={6} gapX={7} gapY={8} mb={8}>
                <Column gridColumn={{ _: 6, md: 5 }}>
                  <Heading as="h2" weight="semibold" size="700">
                    Own check issuing
                  </Heading>
                  <Text size="300" color="gray-500" mt={2}>
                    Unlock full access to Federal Reserve check processing
                  </Text>
                </Column>

                <Column gridColumn={{ _: 6, md: 3 }}>
                  <CircleCheck />
                  <Heading as="h3" size="300" weight="medium">
                    Own your check issuance
                  </Heading>
                  <Text size="200" color="gray-500" mt={1}>
                    Simply issue a check with a single API request and take control of the end to end check flows.
                  </Text>
                </Column>

                <Column gridColumn={{ _: 6, md: 3 }}>
                  <CircleCheck />
                  <Heading as="h3" size="300" weight="medium">
                    Certainty and security
                  </Heading>
                  <Text size="200" color="gray-500" mt={1}>
                    Peace of mind over check issuance with positive pay verification and stop payment functionality.
                  </Text>
                </Column>
              </Grid>

              <Code title="Issue a check" mt={6}>
                {`curl 'https://api.column.com/transfers/checks/issue' \\
     -XPOST \\
     -u :<YOUR API KEY> \\
     -d bank_account_id="<bank_account_id>" \\
     -d positive_pay_amount="100000" \\
     -d currency_code="USD"`}
              </Code>
            </Column>

            <Column gridColumn={{ _: 12, md: 5 }} gridColumnStart={{ _: 0, md: 8 }}>
              <ProductScreen width="900px" height="560px" mb={'var(--space-n24)'} backgroundColor="#fbfcfd">
                <img src="/images/products/checks/transfers.png" />
              </ProductScreen>
            </Column>
          </Grid>
        </Container>
      </SectionBox>
    </Section>
  );
};

export default SectionOwn;
