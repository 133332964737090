import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

interface AssetBlockProps {
  data: any;
}

export const AssetBlock: React.FC<AssetBlockProps> = (props) => {
  if (props.data.gatsbyImageData) {
    return <GatsbyImage alt="" image={props.data.gatsbyImageData} />;
  }

  if (props.data.file.contentType.startsWith('video')) {
    return (
      <video controls>
        <source src={props.data.file.url} type={props.data.file.contentType} />
      </video>
    );
  }

  return <pre>asset{JSON.stringify(props, undefined, 2)}</pre>;
};
