import React from 'react';
import styled, { keyframes } from 'styled-components';
import { sm, md, lg } from '~/ds/mixins/breakpoints';

const fadeInHalf = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: .5;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: var(--space-8) 0;
  transform: scale(0.9);
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  @media ${lg} {
    margin: 0;
    transform: scale(1);
  }

  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(253, 246, 240, 1) 0%,
      rgba(253, 246, 240, 0) 90%,
      rgba(253, 246, 240, 1) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -50%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    --glow-offset: -5%;
    top: var(--glow-offset);
    right: var(--glow-offset);
    bottom: var(--glow-offset);
    left: var(--glow-offset);
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%);
    filter: blur(30px);
    opacity: 0;
    z-index: 0;
    animation: 2.5s ${fadeInHalf} forwards;
  }
`;

const GraphCard = styled.div`
  position: relative;

  img {
    background: #fff;
    border-radius: 12px;
    box-shadow: var(--shadow-product-border), 0px 48px 64px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 3;
    transform: translate(0, 0);

    @media ${md} {
      transform: translate(-32px, 0);
    }
  }

  &:after {
    content: '';
    background: rgba(243, 243, 252, 0.8);
    box-shadow: var(--shadow-product-border), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 1px 3px rgba(0, 0, 0, 0.03);
    position: absolute;
    width: 100%;
    height: 80%;
    border-radius: 12px;
    z-index: 2;
    top: -32px;
    transform: scale(0.9);

    @media ${md} {
      transform: scale(1);
      width: 367px;
      height: 270px;
    }
  }
`;

export const Graph: React.FC = () => {
  return (
    <Wrapper>
      <GraphCard>
        <img src="/images/products/debt-financing/graph.svg" />
      </GraphCard>
    </Wrapper>
  );
};
