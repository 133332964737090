import './src/stylesheets/fonts.css';

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    const element = document.getElementById(location.hash.replace('#', ''));

    if (element) {
      element.scrollIntoView(true);
    }

    return false;
  }

  return true;
};
