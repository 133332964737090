import React from 'react';
import styled, { keyframes } from 'styled-components';

const moveStar = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0) translateY(1vmax) scale(0.1);
    background-color: rgba(var(--rgb-blue-500), 0);
  }
  40% {
    opacity: 0.8;
  }
  100% {
    transform: translateX(0) translateY(110vmax) scale(0.5);
    opacity: 1;
    background-color: rgba(var(--rgb-blue-500), 1);
  }
`;

const moveShade = keyframes`
0% {
    transform: scaleY(0);
    opacity: 0;
  }
  100% {
    transform: scaleY(30);
    opacity: 1;
  }
`;

const Universe = styled.div`
  background: var(--color-white);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 500px rgba(var(--rgb-blue-500), 0.05) inset;
`;

const Star = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 1em;
  width: 10em;
  margin-left: -5em;
  margin-top: -0.5em;
  background: none;
  animationduration: 3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
`;

const Point = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1em;
  height: 4em;
  margin-left: -0.5em;
  margin-top: -0.7em;
  font-size: 1em;
  background-color: rgba(var(--rgb-blue-500), 1);
  border-radius: 10%;
  box-shadow: 0 0 0.5em 0.1em rgba(var(--rgb-blue-500), 1);
  opacity: 0;

  .inview--animate & {
    animation: ${moveStar};
    animation-timing-function: cubic-bezier(0.98, 0, 1, 1);
    animation-duration: inherit;
    animation-delay: inherit;
    animation-iteration-count: inherit;
  }

  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    width: 0em;
    height: 1em;
    margin-top: 1em;
    margin-left: 0.5em;
    border-radius: 50% 50% 0 0;
    background-color: rgba(var(--rgb-blue-500), 1);
    box-shadow: 0 -1em 0.5em 0.2em rgba(var(--rgb-blue-500), 1);

    .inview--animate & {
      animation: ${moveShade};
      animation-timing-function: linear;
      animation-duration: inherit;
      animation-delay: inherit;
      animation-iteration-count: inherit;
    }
  }
`;

export const WarpSpeed: React.FC = () => {
  return (
    <Universe>
      <Star style={{ transform: 'rotateZ(178deg)', animationDuration: '2.766s', animationDelay: '2.702s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(52deg)', animationDuration: '2.431s', animationDelay: '2.172s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(246deg)', animationDuration: '2.272s', animationDelay: '0.343s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(280deg)', animationDuration: '2.797s', animationDelay: '0.618s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(186deg)', animationDuration: '2.977s', animationDelay: '2.533s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(262deg)', animationDuration: '1.32s', animationDelay: '1.758s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(12deg)', animationDuration: '1.25s', animationDelay: '2.261s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(200deg)', animationDuration: '1.513s', animationDelay: '0.627s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(104deg)', animationDuration: '1.458s', animationDelay: '1.773s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(318deg)', animationDuration: '2.096s', animationDelay: '0.264s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(313deg)', animationDuration: '2.364s', animationDelay: '2.901s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(232deg)', animationDuration: '2.919s', animationDelay: '1.761s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(85deg)', animationDuration: '1.542s', animationDelay: '1.96s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(291deg)', animationDuration: '1.398s', animationDelay: '2.907s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(270deg)', animationDuration: '2.986s', animationDelay: '1.139s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(106deg)', animationDuration: '2.003s', animationDelay: '2.411s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(337deg)', animationDuration: '2.8s', animationDelay: '1.526s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(197deg)', animationDuration: '2.744s', animationDelay: '2.997s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(281deg)', animationDuration: '1.732s', animationDelay: '0.788s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(194deg)', animationDuration: '1.647s', animationDelay: '2.334s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(185deg)', animationDuration: '1.404s', animationDelay: '2.757s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(195deg)', animationDuration: '1.821s', animationDelay: '1.265s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(277deg)', animationDuration: '1.887s', animationDelay: '0.803s' }}>
        <Point />
      </Star>
      <Star style={{ transform: 'rotateZ(120deg)', animationDuration: '1.382s', animationDelay: '0.357s' }}>
        <Point />
      </Star>
    </Universe>
  );
};

export default WarpSpeed;
