import React, { createRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Flex } from '~/elements/Flex';
import { PerspectiveCamera, AmbientLight, DirectionalLight, Color, Scene, WebGLRenderer } from 'three';
import earthData from './assets/earth-data.json';
import data from './assets/arcs.json';
import { lg } from '~/ds/mixins/breakpoints';

const fadeIn = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: 1;
  }
`;

const Wrapper = styled(Flex)`
  height: 400px;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: scale(0.9) translateX(-50px);
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  @media ${lg} {
    margin: 0;
    transform: scale(1) translateX(0);
  }

  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 575px;
    height: 575px;
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
    filter: blur(30px);
    transform: translate(50px, 50px);
    z-index: 0;
    animation: 5s ${fadeIn} forwards;
  }

  &:before {
    content: '';
    box-shadow: -150px -100px 100px 100px #f6f7fa inset;
    filter: blur(50px);
    border-radius: 50%;
    position: absolute;
    top: -400px;
    right: -200px;
    bottom: -300px;
    left: -300px;
    z-index: 3;
    transform: translate(50px, 50px);
  }

  canvas {
    transform: translate(50px, 50px);
    position: relative;
    z-index: 2;
  }
`;

const Waves = styled.div`
  content: '';
  background: radial-gradient(
    circle,
    rgba(246, 247, 250, 1) 0%,
    rgba(246, 247, 250, 0) 90%,
    rgba(246, 247, 250, 1) 100%
  );
  mask-image: url(/images/waves.svg);
  mask-size: 64px 32px;
  mask-repeat: repeat;
  position: absolute;
  --pattern-offset: -50%;
  top: var(--pattern-offset);
  right: var(--pattern-offset);
  bottom: var(--pattern-offset);
  left: var(--pattern-offset);
  z-index: 1;
`;

export const Globe: React.FC = () => {
  const canvasRef = createRef<HTMLCanvasElement>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const ThreeGlobe = (await import('three-globe')).default;

      const renderer = new WebGLRenderer({ antialias: true, alpha: true, canvas: canvasRef.current as HTMLElement });

      renderer.setPixelRatio(2);
      renderer.setSize(1050, 1050);

      const scene = new Scene();
      scene.add(new AmbientLight(0xffffff, 0.975));

      const camera = new PerspectiveCamera();
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      camera.aspect = 1000 / 1000;
      camera.updateProjectionMatrix();

      const light = new DirectionalLight(0xffffff, 0.1);
      light.position.set(0, 1500, 2000);
      camera.add(light);

      camera.position.z = 400;
      camera.position.x = 0;
      camera.position.y = 0;

      scene.add(camera);

      const globe = new ThreeGlobe().showAtmosphere(false);

      globe
        .arcsData(data.data)
        .arcColor((e: any) => {
          if (e.reverseColor) {
            return ['#f7d8da', '#7ea7e9'].reverse();
          }
          return ['#f7d8da', '#7ea7e9'];
        })
        .arcDashLength(0.5)
        .arcDashGap(12)
        .arcDashInitialGap((e: any) => e.order)
        .arcStroke(0.5)
        .arcDashAnimateTime(1200)
        .hexPolygonsData(earthData.features)
        .hexPolygonResolution(3)
        .hexPolygonMargin(0.7)
        .showAtmosphere(false)
        .hexPolygonColor((e) => {
          return 'rgba(0,0,0, 0.15)';
        });

      const globeMaterial: any = globe.globeMaterial();
      globeMaterial.color = new Color(0xf6f7fa);
      globeMaterial.emissive = new Color(0xf6f7fa);
      globeMaterial.emissiveIntensity = 0;
      globeMaterial.shininess = 0.7;

      scene.add(globe);

      globe.rotation.x = 1.75;
      globe.rotation.y = 1.658;
      globe.rotation.z = -1.309;

      globe.onGlobeReady(() => {
        setLoading(false);
      });

      let speedY = 0.0005;
      let speedX = 0.0002;

      const animate = () => {
        camera.lookAt(scene.position);
        renderer.render(scene, camera);

        if (globe.rotation.y > 2 || globe.rotation.y < 1.4) {
          speedY = speedY * -1;
        }

        globe.rotation.y += speedY;

        if (globe.rotation.x > 2.1 || globe.rotation.x < 1.6) {
          speedX = speedX * -1;
        }

        globe.rotation.x += speedX;

        requestAnimationFrame(animate);
      };

      animate();
    })();
  }, []);

  return (
    <Wrapper>
      <canvas className={`globe ${loading ? '' : 'show'}`} ref={canvasRef} />
      <Waves />
    </Wrapper>
  );
};
