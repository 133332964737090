import { IconDocsHome } from './home';
import { IconDocsGuides } from './guides';
import { IconDocsSandboxTesting } from './sandbox-testing';
import { IconDocsDataModels } from './data-models';
import { IconDocsAuthentication } from './authentication';
import { IconDocsPagination } from './pagination';
import { IconDocsIdempotency } from './idempotency';
import { IconDocsACH } from './ACH';
import { IconDocsWire } from './wire';
import { IconDocsCheck } from './checks';
import { IconDocsHolds } from './holds';
import { IconDocsOverdrafts } from './overdrafts';
import { IconDocsErrors } from './errors';
import { IconDocsEventsWebhooks } from './events-webhooks';
import { IconDocsChangelog } from './changelog';
import { IconDocsFaq } from './faq';
import { IconDocsWorkflows } from './workflows';
import { IconDocsAPI } from './API';
import { IconDocsInternationalWire } from './international-wire';
import { IconDocsInterest } from './interest';

export const IconDocs = {
  DocsHome: IconDocsHome,
  DocsGuides: IconDocsGuides,
  DocsSandboxTesting: IconDocsSandboxTesting,
  DocsDataModels: IconDocsDataModels,
  DocsAuthentication: IconDocsAuthentication,
  DocsPagination: IconDocsPagination,
  DocsIdempotency: IconDocsIdempotency,
  DocsACH: IconDocsACH,
  DocsWire: IconDocsWire,
  DocsHolds: IconDocsHolds,
  DocsOverdrafts: IconDocsOverdrafts,
  DocsErrors: IconDocsErrors,
  DocsEventsWebhooks: IconDocsEventsWebhooks,
  DocsChangelog: IconDocsChangelog,
  DocsFaq: IconDocsFaq,
  DocsWorkflows: IconDocsWorkflows,
  DocsAPI: IconDocsAPI,
  DocsCheck: IconDocsCheck,
  DocsInternationalWire: IconDocsInternationalWire,
  DocsInterest: IconDocsInterest,
};
