import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductACH: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  return (
    <IconBase viewportSize={32} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="4" width="26" height="24">
        <path d="M13 17v11L3 23V12l10 5z" fill="url(#ach-invert__paint0_linear_1773:55470)" />
        <path d="M29 9v11l-16 8V17l16-8z" fill="url(#ach-invert__paint1_linear_1773:55470)" />
        <path d="M13 21v3l16-8v-3l-16 8z" fill="url(#ach-invert__paint2_linear_1773:55470)" />
        <path
          d="M16 12.499c2.205 0 3.992-.895 3.992-1.999S18.205 8.501 16 8.501s-3.992.895-3.992 1.999 1.787 1.999 3.992 1.999z"
          fill="url(#ach-invert__paint3_linear_1773:55470)"
        />
        <path d="M13 21v3L3 19v-3l10 5z" fill="url(#ach-invert__paint4_linear_1773:55470)" />
        <path
          d="M19 4L3 12l10 5 16-8-10-5zm-.17 7.91c-1.56.79-4.1.79-5.66 0-1.56-.78-1.56-2.04 0-2.82 1.56-.79 4.1-.79 5.66 0 1.56.78 1.56 2.04 0 2.82z"
          fill="url(#ach-invert__paint5_linear_1773:55470)"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M31.976 0h-32v32h32V0z" fill="url(#ach-invert__paint6_linear_1773:55470)" />
      </g>
      <defs>
        <linearGradient
          id="ach-invert__paint0_linear_1773:55470"
          x1="12.416"
          y1="17.817"
          x2="1.059"
          y2="23.431"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="ach-invert__paint1_linear_1773:55470"
          x1="9.195"
          y1="18.41"
          x2="72.819"
          y2="18.894"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="ach-invert__paint2_linear_1773:55470"
          x1="7.612"
          y1="21.747"
          x2="50.779"
          y2="11.277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="ach-invert__paint3_linear_1773:55470"
          x1="22.296"
          y1="14.041"
          x2="14.564"
          y2="9.692"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="ach-invert__paint4_linear_1773:55470"
          x1="13.48"
          y1="20"
          x2="-.211"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="ach-invert__paint5_linear_1773:55470"
          x1="27.639"
          y1="10.43"
          x2=".74"
          y2="10.591"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="ach-invert__paint6_linear_1773:55470"
          x1="31.976"
          y1="0"
          x2="-.024"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #fff)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #fff)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
