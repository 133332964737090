import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Section } from '~/elements/Section';
import { Code } from '~/elements/Code/Code';
import { Grid } from '~/elements/Grid';
import { ProductScreen } from '~/elements/ProductScreen';
import { Column } from '~/elements/Column';
import { Br } from '~/elements/Br';

const ProductSection = styled(Section)`
  overflow: hidden;
`;

export const SectionRecord: React.FC = () => {
  return (
    <ProductSection pt={{ lg: 32 }}>
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column gridColumn={{ _: 12, md: 6 }} gridColumnStart={{ _: 1, md: 4 }} position="relative" zIndex={2}>
            <Heading as="h2" size="700" weight="semibold" textAlign="center">
              Access a historical
              <Br display={{ _: 'none', md: 'block' }} /> record of transfers
            </Heading>
            <Text size="300" color="gray-500" mt={2} textAlign="center">
              Having your ledger linked to your bank accounts ensures you can authenticate each transaction on accurate
              information.
            </Text>
          </Column>

          <Column gridColumn={{ _: 10, md: 6 }} gridColumnStart={{ _: 2, md: 4 }} position="relative" zIndex="2">
            <Code title="List all transfers" mb="var(--space-n48)">
              {`curl 'https://api.column.com/transfers' \\
  -u :<YOUR API KEY>`}
            </Code>
          </Column>

          <Column gridColumn={{ _: 12, md: 10 }} gridColumnStart={{ _: 1, md: 2 }}>
            <ProductScreen mt={6}>
              <img src="/images/products/ledger/transfers.png" />
            </ProductScreen>
          </Column>
        </Grid>
      </Container>
    </ProductSection>
  );
};

export default SectionRecord;
