import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { sm } from '~/ds/mixins/breakpoints';
import { Text } from '~/elements/NewText';
import { Button } from '~/elements/Button';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Column } from '~/elements/Column';
import { getGAOutput, setGAOptout } from '~/utils/gaOptout';
import { PaletteProps, getPaletteClassnames } from '~/utils/getPaletteClassnames';

export interface CookieStyleProps extends PaletteProps {
  inverted?: boolean;
}

export interface CookieProps extends CookieStyleProps {
  className?: string;
}

const Wrapper = styled.div`
  position: fixed;
  bottom: var(--space-4);
  left: var(--space-4);
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
  padding-right: var(--space-6);
  padding-left: var(--space-6);
  border-radius: var(--radii-default);
  background-color: var(--color-white);
  box-shadow: 0 0 0 1px var(--btn-border-secondary), 0 4px 8px rgba(var(--rgb-black), 0.1);
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  z-index: 99998;
  max-width: calc(100vw - 32px);

  @media ${sm} {
    flex-direction: row;
  }

  .btn-link .btn-text {
    justify-content: center;
  }
`;

export const Cookie: React.FC<CookieProps> = (props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const styleProps: CookieStyleProps = {
    palette: props.palette ?? 'inherit',
    inverted: props.inverted ?? false,
  };

  let conditionalClasses: ConditionalClasses = {
    ...getPaletteClassnames(styleProps.palette),
  };

  if (styleProps.inverted === true) {
    conditionalClasses = getPaletteClassnames(styleProps.palette, conditionalClasses, true);
  }

  const handleClick = (accept: boolean) => {
    if (wrapperRef.current) {
      gsap.to(wrapperRef.current, {
        y: 8,
        opacity: 0,
        duration: 0.3,
      });
    }
    if (!accept) {
      setGAOptout();
    }
    localStorage.setItem('cookieAllowed', accept ? 'true' : 'false');
  };

  if (typeof document === 'undefined') {
    return <></>;
  }

  useEffect(() => {
    console.info(getGAOutput());
  }, []);

  return localStorage.getItem('cookieAllowed') ? (
    <></>
  ) : (
    <Wrapper ref={wrapperRef}>
      <Column maxWidth="320px" gridColumn={1}>
        <Text weight="medium">
          <Text as="span" color="gray-900" weight="inherit">
            We use cookies
          </Text>{' '}
          to analyze our traffic and create a smooth user experience.
        </Text>
      </Column>
      <Column gridColumn={1}>
        <ButtonGroup mt={{ _: 6, sm: 0 }} ml={{ sm: 6 }}>
          <Button onClick={() => handleClick(false)} variant="secondary">
            Reject
          </Button>
          <Button onClick={() => handleClick(true)} variant="primary">
            Accept
          </Button>
        </ButtonGroup>
      </Column>
    </Wrapper>
  );
};
