import React, { useState, FormEvent, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Text } from '~/elements/Text';
import { Button } from '~/elements/Button';
import { Icon } from '~/elements/Icon';

export const BlogForm: React.FC = (props: any) => {
  const [currentState, setCurrentState] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const staticClasses: StaticClasses = ['blog-sign-up', props.className];

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setCurrentState('');

    fetch('https://api.column.com/newsletter/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => {
        if (response.status === 200) {
          setCurrentState('success');
          return;
        }
        setCurrentState('error');
      })
      .catch(() => {
        setCurrentState('error');
      });
  };

  const conditionalClasses: ConditionalClasses = {
    'blog-sign-up-success': currentState === 'success',
    'blog-sign-up-error': currentState === 'error',
  };

  return (
    <form className={classNames(staticClasses, conditionalClasses)} onSubmit={onSubmit}>
      <Text as="h2" size="500" variant="heading">
        Sign up for updates
      </Text>
      <div className="mt-6">
        <Text size="200" className="email-error">
          <Icon.FormError className="mr-1" />
          Email address already in use.
        </Text>
        <Text size="200" className="email-success">
          <Icon.FormSuccess className="mr-1" />
          Success! Stay tuned for updates.
        </Text>
        <div className="md:flex gap-2">
          <input
            required
            type="email"
            className="flex-auto form-input md:inline-block"
            placeholder="marie@france.com"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          />
          <Button disabled={currentState === 'success'} variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};
