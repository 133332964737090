import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductProtectedDeposits: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductACH {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <path
        opacity="0.5"
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        fill="url(#paint0_linear_1123_55769)"
      />
      <path
        opacity="0.5"
        d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
        fill="url(#paint1_linear_1123_55769)"
      />
      <path
        d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
        fill="url(#paint2_linear_1123_55769)"
      />
      <defs>
        <linearGradient id="paint0_linear_1123_55769" x1="4" y1="16" x2="28" y2="16" gradientUnits="userSpaceOnUse">
          <stop stopColor="CurrentColor" stopOpacity="0.25" />
          <stop offset="0.99" stopColor="CurrentColor" />
        </linearGradient>
        <linearGradient id="paint1_linear_1123_55769" x1="8" y1="0" x2="24" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="CurrentColor" stopOpacity="0.25" />
          <stop offset="0.99" stopColor="CurrentColor" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1123_55769"
          x1="7.51"
          y1="16"
          x2="18.57"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="CurrentColor" stopOpacity="0" />
          <stop offset="0.99" stopColor="CurrentColor" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
