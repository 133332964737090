import { css } from 'styled-components';

export const Cyan = css`
  .color-palette--cyan {
    --bg-color-primary: var(--color-cyan-400);
    --bg-color-secondary: var(--color-cyan-500);
    --bg-gradient-primary: linear-gradient(165deg, var(--color-cyan-400) 0%, var(--color-cyan-500) 92%);
  }

  .color-palette-inverted--cyan {
    --bg-color-primary: var(--color-blue-800);
    --bg-color-secondary: var(--color-cyan-500);
    --bg-gradient-primary: linear-gradient(165deg, var(--color-cyan-300) 0%, var(--color-cyan-500) 92%);
  }
`;
