import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { IconProductInverted } from './Inverted';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconProductBankAccounts: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon--product', props.className];

  const allProps: IconProps = {
    viewportSize: 32,
    ...props,
    className: classNames(staticClasses),
  };

  if (props.inverted) {
    return <IconProductInverted.ProductBankAccounts {...allProps} />;
  }

  return (
    <IconBase {...allProps}>
      <linearGradient id="a1" gradientUnits="userSpaceOnUse" x1="34.29" x2="3.33" y1="34.29" y2="3.33">
        <stop offset="0" stopColor="#1e4199" />
        <stop offset="1" stopColor="#1e4199" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="b1" x1="36.98" x2="6.01" href="#a1" y1="35.18" y2="4.22" />
      <linearGradient id="c1" x1="16.83" x2="5.41" href="#a1" y1="18.83" y2="7.41" />
      <linearGradient id="d1" x1="13.17" x2="20.59" href="#a1" y1="15.17" y2="22.59" />
      <linearGradient id="e1" x1="28.68" x2="21" href="#a1" y1="16.91" y2="14.7" />
      <path
        d="m8 11v10c0 .55.45 1 1 1h18v1c0 1.1-.9 2-2 2h-18c-1.1 0-2-.9-2-2v-14c0-1.1.9-2 2-2h18c1.1 0 2 .9 2 2v1h-18c-.55 0-1 .45-1 1z"
        fill="url(#a1)"
      />
      <path d="m27 22h-18c-.55 0-1-.45-1-1v-10c0-.55.45-1 1-1h18z" fill="url(#b1)" />
      <path
        d="m14 12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
        fill="url(#c1)"
      />
      <circle cx="14" cy="16" fill="url(#d1)" r="2" />
      <path d="m24 10h3v12h-3z" fill="url(#e1)" />
    </IconBase>
  );
};
