import styled from 'styled-components';
import { layout, color, space } from 'styled-system';

export const MarkdownStyles = styled.div<any>`
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  color: var(--text-color-body);
  font-size: ${(props) =>
    ({ theme }) =>
      theme.fontSizes[props.baseSize] || (() => theme.fontSizes['300'])};
  line-height: 1.5;
  word-wrap: break-word;

  --text-color-heading: var(--color-blue-900);
  --color-fg-subtle: #6e7781;
  --color-canvas-default: #ffffff;
  --color-canvas-subtle: #f6f8fa;
  --color-bg-subtle: var(--color-blue-50);
  --color-bg-muted: var(--color-gray-50);
  --color-bg-code: var(--color-blue-800);
  --color-text-code: var(--color-0);
  --color-border-primary: var(--color-gray-400);
  --color-border-default: var(--color-gray-200);
  --color-border-muted: var(--color-gray-100);
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-danger-fg: #cf222e;

  .octicon {
    display: inline-block;
    fill: currentColor;
    vertical-align: text-bottom;
  }

  details,
  figcaption,
  figure {
    display: block;
  }

  summary {
    display: list-item;
  }

  iframe {
    margin: var(--space-6) 0;
  }

  .video-wrapper {
    position: relative;
    padding-top: 56%;
    margin: var(--space-6) 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--space-3);
      margin: 0;
    }
  }

  a {
    background-color: transparent;
    color: var(--color-blue-600);
    text-decoration: none;
    transition: color 0.2s;

    &:hover,
    &:active {
      color: var(--color-blue-800);
      outline-width: 0;
    }
  }

  p,
  li {
    a {
      font-weight: var(--font-medium);
    }
  }

  abbr[title] {
    border-bottom: none;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: var(--font-medium);
    color: var(--text-color-heading);
  }

  dfn {
    font-style: italic;
  }

  h1:not([class]) {
    margin: 0.67em 0;
    font-weight: var(--font-semibold);
    padding-bottom: 0.3em;
    font-size: 2em;
    border-bottom: 1px solid var(--color-border-muted);
  }

  mark {
    background-color: #ff0;
    color: var(--color-text-primary);
  }

  small {
    font-size: 90%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  img {
    border-style: none;
    width: 100%;
    box-sizing: content-box;
    background-color: var(--color-canvas-default);
    border-radius: var(--radii-default);
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  figure {
    margin: 1em 40px;
  }

  hr {
    box-sizing: content-box;
    overflow: hidden;
    background: transparent;
    border-bottom: 1px solid var(--color-border-muted);
    height: 1px;
    padding: 0;
    margin: 0 0 16px 0;
    background-color: var(--color-border-default);
    border: 0;
  }

  html [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted;
  }

  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  a:hover {
    text-decoration: underline;
  }

  hr::before {
    display: table;
    content: '';
  }

  hr::after {
    display: table;
    clear: both;
    content: '';
  }

  table {
    display: table;
    width: max-content;
    max-width: 100%;
    width: 100%;
    overflow: auto;
    border-collapse: collapse;
    border-spacing: 0;

    img {
      max-width: 20px;
    }
  }

  td,
  th {
    padding: 0;
    text-align: left;
    vertical-align: top;
  }

  details summary {
    cursor: pointer;
  }

  details:not([open]) > *:not(summary) {
    display: none !important;
  }

  kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 12px SFMono, SF Mono, ui-monospace, Menlo, Consolas, Liberation Mono, monospace;
    font-weight: 500;
    line-height: 10px;
    color: var(--text-color-heading);
    vertical-align: middle;
    background-color: var(--color-canvas-subtle);
    border: solid 1px var(--rgb-neutral-muted);
    border-bottom-color: var(--color-neutral-muted);
    border-radius: 6px;
    box-shadow: inset 0 -1px 0 var(--rgb-neutral-muted);
  }

  h1:not([class]),
  h2:not([class]),
  h3:not([class]),
  h4:not([class]),
  h5:not([class]),
  h6:not([class]) {
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: var(--font-medium);
    letter-spacing: -0.01em;
    color: var(--text-color-heading);
    line-height: 1.25;
    a {
      color: var(--text-color-heading);
    }
  }

  h2:not([class]) {
    font-weight: var(--font-medium);
    padding-bottom: 0.3em;
    font-size: 1.75em;
  }

  h3:not([class]) {
    font-weight: var(--font-medium);
    font-size: 1.25em;
  }

  h4:not([class]) {
    font-weight: var(--font-medium);
    font-size: 1em;
  }

  h5:not([class]) {
    font-weight: var(--font-medium);
    font-size: 0.875em;
  }

  h6:not([class]) {
    font-weight: var(--font-medium);
    font-size: 0.85em;
    color: var(--text-color-body);
  }

  p:not([class]) {
    margin-top: 0;
    margin-bottom: var(--space-5);
    max-width: 72ch;
    font-weight: var(--font-regular);
  }
  p img {
    max-width: 760px;
  }

  blockquote {
    margin: 0;
    padding: 0 1em;
    color: var(--text-color-body);
    border-left: 0.25em solid var(--color-border-default);
    position: relative;
    max-width: 72ch;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 2em;
    list-style: none;
  }

  ol ol,
  ul ol {
    list-style: none;
    counter-reset: ordered-items;
    > li {
      counter-increment: ordered-items;
      position: relative;
      &::before {
        content: counter(ordered-items, upper-alpha) ' ';
        color: var(--color-blue-300);
        left: var(--space-6);
        top: 0;
        position: absolute;
      }
    }
  }

  ul ul ol,
  ul ol ol,
  ol ul ol,
  ol ol ol {
    list-style-type: lower-roman;
  }

  dd {
    margin-left: 0;
  }

  tt,
  code {
    font-family: SFMono, SF Mono, ui-monospace, Menlo, Consolas, Liberation Mono, monospace;
    font-weight: 500;
    font-size: 12px;
  }

  pre {
    margin-top: 0;
    margin-bottom: 0;
    font-family: SFMono, SF Mono, ui-monospace, Menlo, Consolas, Liberation Mono, monospace;
    font-weight: 500;
    font-size: 12px;
    word-wrap: normal;
  }

  :-ms-input-placeholder {
    color: var(--color-fg-subtle);
    opacity: 1;
  }

  ::-ms-input-placeholder {
    color: var(--color-fg-subtle);
    opacity: 1;
  }

  ::placeholder {
    color: var(--color-fg-subtle);
    opacity: 1;
  }

  [data-catalyst] {
    display: block;
  }

  g-emoji {
    font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 1em;
    font-style: normal !important;
    font-weight: 400;
    line-height: 1;
    vertical-align: -0.075em;
  }

  g-emoji img {
    width: 1em;
    height: 1em;
  }

  &::before {
    display: table;
    content: '';
  }

  &::after {
    display: table;
    clear: both;
    content: '';
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  a:not([href]) {
    color: inherit;
    text-decoration: none;
  }

  .absent {
    color: var(--color-danger-fg);
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  details {
    margin-top: 0;
    margin-bottom: 16px;
  }

  blockquote > :first-child {
    margin-top: 0;
  }

  blockquote > :last-child {
    margin-bottom: 0;
  }

  sup > a::before {
    content: '[';
  }

  sup > a::after {
    content: ']';
  }

  h1 tt,
  h1 code,
  h2 tt,
  h2 code,
  h3 tt,
  h3 code,
  h4 tt,
  h4 code,
  h5 tt,
  h5 code,
  h6 tt,
  h6 code {
    padding: 0 0.2em;
    font-size: inherit;
  }

  ul.no-list,
  ol.no-list {
    padding: 0;
    list-style-type: none;
  }

  ol[type='1'] {
    list-style-type: decimal;
  }

  ol[type='a'] {
    list-style-type: lower-alpha;
  }

  ol[type='i'] {
    list-style-type: lower-roman;
  }

  ul ul,
  ul ol,
  ol ol,
  ol ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  ul,
  ol {
    max-width: 72ch;
  }

  ul > li {
    position: relative;

    &::before {
      background-color: var(--color-blue-300);
      content: '';
      display: block;
      height: 1px;
      width: var(--space-4);
      position: absolute;
      top: 0.7em;
      right: calc(100% + var(--space-2));
    }
  }

  ol {
    list-style: none;
    counter-reset: ordered-items;
    > li {
      counter-increment: ordered-items;
      position: relative;
      &::before {
        content: counter(ordered-items) ' ';
        color: var(--color-blue-300);
        left: var(--space-n6);
        top: 0;
        position: absolute;
      }
    }
  }

  ul > li,
  ol > li {
    margin: 0.25em 0 0.5rem;
  }

  li {
    > p {
      margin-top: var(--space-4);
    }
  }

  li + li {
    margin-top: 0.5em;
  }

  li img {
    margin-top: var(--space-4);
    display: block;
  }

  dl {
    padding: 0;
  }

  dl dt {
    padding: 0;
    margin-top: var(--space-4);
    font-size: 1em;
    font-style: italic;
    font-weight: var(--font-semibold);
  }

  dl dd {
    padding: 0 16px;
    margin-bottom: 16px;
  }

  table th,
  table td {
    padding: var(--space-2);
    border-right: 1px solid var(--color-border-default);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }

  table th {
    font-weight: var(--font-medium);
    color: var(--text-color-heading);
    border-bottom: 1px solid var(--color-border-primary);
    border-right: 0;
    vertical-align: bottom;
    line-height: 1.25;
    white-space: nowrap;
  }

  table td {
    border-top: 1px solid var(--color-border-muted);
  }

  table img {
    background-color: transparent;
  }

  tbody > tr:first-child td {
    border-top: 0;
  }

  img[align='right'] {
    padding-left: 20px;
  }

  img[align='left'] {
    padding-right: 20px;
  }

  .emoji {
    max-width: none;
    vertical-align: text-top;
    background-color: transparent;
  }

  span.frame {
    display: block;
    overflow: hidden;
  }

  span.frame > span {
    display: block;
    float: left;
    width: auto;
    padding: 7px;
    margin: 13px 0 0;
    overflow: hidden;
    border: 1px solid var(--color-border-default);
  }

  span.frame span img {
    display: block;
    float: left;
  }

  span.frame span span {
    display: block;
    padding: 5px 0 0;
    clear: both;
    color: var(--text-color-heading);
  }

  span.align-center {
    display: block;
    overflow: hidden;
    clear: both;
  }

  span.align-center > span {
    display: block;
    margin: 13px auto 0;
    overflow: hidden;
    text-align: center;
  }

  span.align-center span img {
    margin: 0 auto;
    text-align: center;
  }

  span.align-right {
    display: block;
    overflow: hidden;
    clear: both;
  }

  span.align-right > span {
    display: block;
    margin: 13px 0 0;
    overflow: hidden;
    text-align: right;
  }

  span.align-right span img {
    margin: 0;
    text-align: right;
  }

  span.float-left {
    display: block;
    float: left;
    margin-right: 13px;
    overflow: hidden;
  }

  span.float-left span {
    margin: 13px 0 0;
  }

  span.float-right {
    display: block;
    float: right;
    margin-left: 13px;
    overflow: hidden;
  }

  span.float-right > span {
    display: block;
    margin: 13px auto 0;
    overflow: hidden;
    text-align: right;
  }

  code,
  tt {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: var(--color-bg-subtle);
    border-radius: 4px;
    color: var(--text-color-heading);
  }

  code br,
  tt br {
    display: none;
  }

  del code {
    text-decoration: inherit;
  }

  pre code {
    font-size: 100%;
  }

  pre > code {
    padding: 0;
    margin: 0;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
    color: var(--color-text-code);
  }

  pre code,
  pre tt {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
  }

  .csv-data td,
  .csv-data th {
    padding: 5px;
    overflow: hidden;
    font-size: 12px;
    line-height: 1;
    text-align: left;
    white-space: nowrap;
  }

  .csv-data .blob-num {
    padding: 10px 8px 9px;
    text-align: right;
    background: var(--color-canvas-default);
    border: 0;
  }

  .csv-data tr {
    border-top: 0;
  }

  .csv-data th {
    font-weight: var(--font-semibold);
    background: var(--color-canvas-subtle);
    border-top: 0;
  }

  .footnotes {
    font-size: 12px;
    color: var(--text-color-body);
    border-top: 1px solid var(--color-border-default);
  }

  .footnotes ol {
    padding-left: 16px;
  }

  .footnotes li {
    position: relative;
  }

  .footnotes li:target::before {
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -24px;
    pointer-events: none;
    content: '';
    border: 2px solid var(--rgb-accent-emphasis);
    border-radius: 6px;
  }

  .footnotes li:target {
    color: var(--text-color-heading);
  }

  .footnotes .data-footnote-backref g-emoji {
    font-family: monospace;
  }

  [hidden] {
    display: none !important;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(50%);
  }

  ${layout}
  ${color}
  ${space}
`;
