import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsInterest: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M12 14C12 12.9 12.9 12 14 12C15.1 12 16 12.9 16 14C16 15.1 15.1 16 14 16C12.9 16 12 15.1 12 14ZM6 8C7.1 8 8 7.1 8 6C8 4.9 7.1 4 6 4C4.9 4 4 4.9 4 6C4 7.1 4.9 8 6 8ZM3.5 16H7.5L16.5 4H12.5L3.5 16Z"
        fill="url(#paint0_linear_19_23)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_19_23"
          x1="18.87"
          y1="1.13"
          x2="5.74"
          y2="14.26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E4199" stop-opacity="0" />
          <stop offset="0.99" stop-color="#1E4199" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
