import React from 'react';
import { AddressObject } from './AddressObject';

export const TransferMonitoringDetails = [
  {
    param: 'details',
    type: 'object',
    required: false,
    description: <p>Transfer monitoring details</p>,
    object: [
      {
        param: 'sender_name',
        type: 'string',
        required: false,
        description: <p>Name of the sender</p>,
      },
      {
        param: 'merchant_name',
        type: 'string',
        required: false,
        description: <p>Name of the merchant for this transaction</p>,
      },
      {
        param: 'merchant_category_code',
        type: 'string',
        required: false,
        description: <p>Category code for the merchant for this transaction</p>,
      },
      {
        param: 'authorization_method',
        type: 'string',
        required: false,
        description: <p>Authorization method for this transaction</p>,
      },
      {
        param: 'website',
        type: 'string',
        required: false,
        description: <p>Website for this transaction</p>,
      },
      {
        param: 'internal_transfer_type',
        type: 'string',
        required: false,
        description: <p>Transfer type in non-column systems</p>,
      },
      {
        param: 'statement_description',
        type: 'string',
        required: false,
        description: <p>Line item on the customer statement for the transfer</p>,
      },
      ...AddressObject,
    ],
  },
];
