import { css } from 'styled-components';

export const Green = css`
  .color-palette--green {
    --icon-color: var(--color-green-400);
  }

  .color-palette-inverted--green {
    --icon-color: var(--color-green-300);
  }
`;
