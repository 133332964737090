import React from 'react';
import { Helmet } from 'react-helmet';
import { SectionHero } from './products/international-wires/_hero';
import { SectionUseCases } from './products/international-wires/_use-cases';
import { SectionCountries } from './products/international-wires/_countries';
import { SectionCloser } from './index/_closer';
import { Layout } from '~/components/Layout';
import { Footer } from '~/components/Footer';
import { Testimonials } from '~/components/Testimonials';
import { AllProducts } from '~/components/AllProducts';

const ACHPage: React.FC = () => {
  return (
    <Layout noFooter bg="#F6F7FA">
      <Helmet>
        <title>International Wires — Column</title>
        <meta property="og:title" content="Column - International Wires" />
        <meta name="description" content="Direct access to Swift and international routing" />
      </Helmet>
      <SectionHero />
      <SectionUseCases />
      <SectionCountries />
      <Testimonials />
      <AllProducts background="#F6F7FA" accentColor="#E7E9EE" />

      <div className="relative overflow-hidden">
        <SectionCloser />
        <Footer />
      </div>
    </Layout>
  );
};

export default ACHPage;
