import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import styled, { css } from 'styled-components';
import { Container } from '~/elements/Container';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Grid } from '~/elements/Grid';
import { Box } from '~/elements/Box';
import { TestimonialID, testimonialList } from '~/data/testimonials';
import { xl } from '~/ds/mixins/breakpoints';

interface TestimonialsProps {
  ids?: TestimonialID[];
  randomize?: boolean;
  accentColor?: string;
  showTabs?: boolean;
  brandColor?: string;
}

const TestimonialSection = styled(Container)`
  padding: 72px 48px;

  @media ${xl} {
    padding: 72px 0;
  }
`;

const TestimonialWrapper = styled.div<TestimonialsProps>`
  position: relative;
  grid-column: span 12 / span 12;
`;

const TestimonialLogo = styled.div`
  svg {
    color: var(--color-gray-900);
    height: var(--space-12);
    margin: 0;

    @media ${xl} {
      margin: var(--space-4) 0;
    }
  }
`;

const TestimonialDivider = styled.div`
  display: none;
  width: 1px;
  height: 36px;
  background-color: rgba(var(--rgb-black), 0.15);

  @media ${xl} {
    display: block;
  }
`;

const TestimonialBody = styled.div<TestimonialsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding-bottom: var(--space-14);
  box-shadow: inset 0 -1px 0 rgba(var(--rgb-black), 0.1);
  background: radial-gradient(
    ellipse farthest-side at 50% 100%,
    rgba(${(props) => props.brandColor}, 0.15) 0%,
    rgba(${(props) => props.brandColor}, 0) 100%
  );

  @media ${xl} {
    display: block;
    text-align: left;
  }

  &:before {
    content: '';
    background: radial-gradient(
      ellipse farthest-side at 50% 100%,
      rgba(var(--rgb-black), 0.1) 0%,
      rgba(var(--rgb-black), 0) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 48px 24px;
    mask-repeat: repeat;
    position: absolute;
    z-index: 0;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;

const TestimonialQuote = styled(Text)<TestimonialsProps>`
  position: relative;
  max-width: 36ch;

  &::before {
    content: '“';
    display: inline-block;
    margin-left: -0.75rem;
  }
  &::after {
    content: '”';
    display: inline-block;
  }
`;

const TestimonialAuthor = styled.figcaption`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--space-8);
  margin-top: var(--space-12);
  color: rgb(var(--rgb-gray-500));

  @media ${xl} {
    text-align: left;
    flex-direction: row;
  }
`;

const Author = styled(Heading)`
  display: block;
`;

const TestimonialTabs = styled(Tabs.List)<TestimonialsProps>`
  list-style: none;
  display: flex;
  justify-content: center;
  grid-column: span 12 / span 12;
  gap: var(--space-8);

  @media ${xl} {
    justify-content: start;
    gap: var(--space-12);
  }
`;

const TestimonialTabsLink = styled(Tabs.Trigger)`
  padding-top: var(--space-4);
  color: rgb(var(--rgb-gray-400));
  transition: color 0.2s;
  background-color: transparent;

  &:hover {
    color: rgb(var(--rgb-gray-500));
  }
  svg {
    height: var(--space-7);
  }
  &[data-state='active'] {
    color: rgb(var(--rgb-gray-900));
    box-shadow: 0 -2px 0 rgb(var(--rgb-gray-900));
  }

  @media ${xl} {
    padding-top: var(--space-6);
    svg {
      height: var(--space-10);
    }
  }
`;

export const Testimonials: React.FC<TestimonialsProps> = (props) => {
  let list = testimonialList.filter((e) => (props.ids ? props.ids.includes(e.id) : true)).slice(0, 3);

  if (props.randomize) {
    list = list.sort(() => 0.5 - Math.random());
  }

  return (
    <Box as="section" py={9}>
      <TestimonialSection>
        <figure>
          <Box px="4" pt="3" pb="2">
            <Tabs.Root defaultValue={`testimonial-${list[0].id}`}>
              <Grid gridTemplateColumns={12}>
                <TestimonialWrapper {...props}>
                  {list.map((item) => {
                    return (
                      <Tabs.Content value={`testimonial-${item.id}`} key={item.id}>
                        <TestimonialBody brandColor={item.brandColor}>
                          <TestimonialQuote as="blockquote" weight="medium" size="600" color="gray-900" {...props}>
                            {item.quote}
                          </TestimonialQuote>

                          <TestimonialAuthor>
                            {!props.showTabs && (
                              <>
                                <TestimonialLogo>{item.logo}</TestimonialLogo>
                                <TestimonialDivider />
                              </>
                            )}
                            <div>
                              <Author as="span" size="300" weight="medium">
                                {item.author}
                              </Author>
                              <Heading as="span" size="300" opacity={0.5} weight="medium">
                                {item.title}
                              </Heading>
                            </div>
                          </TestimonialAuthor>
                        </TestimonialBody>
                      </Tabs.Content>
                    );
                  })}
                </TestimonialWrapper>
                {props.showTabs && (
                  <TestimonialTabs {...props}>
                    {list.map((item) => {
                      return (
                        <li key={`entry-${item.id}`}>
                          <TestimonialTabsLink value={`testimonial-${item.id}`}>{item.logo}</TestimonialTabsLink>
                        </li>
                      );
                    })}
                  </TestimonialTabs>
                )}
              </Grid>
            </Tabs.Root>
          </Box>
        </figure>
      </TestimonialSection>
    </Box>
  );
};

export default Testimonials;
