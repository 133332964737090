import React from 'react';
import styled from 'styled-components';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Container } from '~/elements/Container';
import { Icon } from '~/elements/Icon';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Flex } from '~/elements/Flex';

const CircleCheck = styled(Icon.CircleCheck)`
  color: var(--color-blue-500);
  height: 20px !important;
`;

export const SectionSafety: React.FC = () => {
  return (
    <Section mt={9} overflow="hidden">
      <Container>
        <Grid gridTemplateColumns={12} gap={6}>
          <Column
            gridColumn={{ _: 12, md: 8, lg: 6 }}
            gridColumnStart={{ _: 1, md: 3, lg: 4 }}
            position="relative"
            zIndex={2}
          >
            <Heading as="h2" size="700" weight="semibold" textAlign={{ _: 'left', md: 'center' }}>
              Accounts designed
              <br /> to fit every need
            </Heading>
            <Text size="300" color="gray-500" mt={2} textAlign={{ _: 'left', md: 'center' }}>
              Move money with the flexibility of a checking or savings account, or open and manage funds on behalf of
              your customers. Column provides the tools to help you build new financial services.{' '}
            </Text>
          </Column>

          <Column gridColumn={12} mb={12} mt={8} position="relative" zIndex={1}>
            <Grid gridTemplateColumns={{ _: 1, md: 3 }} gap={6}>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      Demand Deposit Accounts
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      Easy access to funds. Make deposits and withdrawals whenever the FED is open. Always comes with
                      FDIC insurance up to $250,000.
                    </Text>
                  </div>
                </Flex>
              </Column>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      Protected Accounts
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      FDIC insurance up to $150,000,000. Yep, that's 9 digits. Build on top of industry leading account
                      protection and offer your customers unparalleled peace of mind.
                    </Text>
                  </div>
                </Flex>
              </Column>
              <Column>
                <Flex flexDirection="column" gap={2} alignItems="flex-start">
                  <CircleCheck />
                  <div>
                    <Heading as="h3" weight="medium" size="300">
                      FBO Accounts
                    </Heading>
                    <Text size="200" color="gray-500" mt={1}>
                      Open accounts on behalf of others. Our "for the benefit of" accounts leverage virtual sub-accounts
                      to offer even more flexibility in what you build.
                    </Text>
                  </div>
                </Flex>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionSafety;
