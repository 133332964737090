import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconGuides: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <path
        d="M13 1.5V9.5H5.5C4.12 9.5 3 10.62 3 12V4C3 2.62 4.12 1.5 5.5 1.5H13Z"
        fill="url(#paint0_linear_3208_731)"
      />
      <path
        d="M13 9.5V14.5H5.5C4.81 14.5 4.18 14.22 3.73 13.77C3.28 13.32 3 12.69 3 12C3 10.62 4.12 9.5 5.5 9.5H13Z"
        fill="url(#paint1_linear_3208_731)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3208_731"
          x1="2.97"
          y1="6.75"
          x2="12.71"
          y2="6.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="currentColor" stop-opacity="0.3" />
          <stop offset="0.19" stop-color="currentColor" />
          <stop offset="1" stop-color="currentColor" stop-opacity="0.7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3208_731"
          x1="10.86"
          y1="7.9"
          x2="3.97"
          y2="19.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="currentColor" stop-opacity="0" />
          <stop offset="1" stop-color="currentColor" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
