import React from 'react';
import styled, { keyframes } from 'styled-components';
import GraphGraphic from '~/assets/svg/loan-purchase/hero.svg';
import { lg } from '~/ds/mixins/breakpoints';

const fadeInHalf = keyframes`
 0% {
  opacity: 0;
  }
 100% {
  opacity: .5;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: var(--space-8) 0;
  transform: scale(0.9);
  --animation-curve: cubic-bezier(0.23, 1, 0.32, 1);

  @media ${lg} {
    margin: 0;
    transform: scale(1);
  }

  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(253, 246, 240, 1) 0%,
      rgba(253, 246, 240, 0) 90%,
      rgba(253, 246, 240, 1) 100%
    );
    mask-image: url(/images/waves.svg);
    mask-size: 64px 32px;
    mask-repeat: repeat;
    position: absolute;
    --pattern-offset: -50%;
    top: var(--pattern-offset);
    right: var(--pattern-offset);
    bottom: var(--pattern-offset);
    left: var(--pattern-offset);
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    --glow-offset: -5%;
    top: var(--glow-offset);
    right: var(--glow-offset);
    bottom: var(--glow-offset);
    left: var(--glow-offset);
    position: absolute;
    background: linear-gradient(132deg, #f7d8da 12%, #7ea7e9 97%), linear-gradient(132deg, #d4e3f7 12%, #c5f2d4 97%);
    filter: blur(30px);
    opacity: 0;
    z-index: 0;
    animation: 2.5s ${fadeInHalf} forwards;
  }

  img {
    position: relative;
    width: 100%;
    max-width: 370px;
    z-index: 3;
  }
`;

export const Graph: React.FC = () => {
  return (
    <Wrapper>
      <img src="/images/products/loan-purchase/hero.png" />
    </Wrapper>
  );
};
