import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconFormError: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <path
        d="m15.22 12.502-6.35-11c-.39-.67-1.35-.67-1.74 0l-6.35 11c-.38.67.1 1.5.87 1.5h12.7c.77 0 1.25-.83.87-1.5Z"
        fill="currentColor"
      />
      <path
        d="M7.94 4.662c.43-.04.81.28.84.71v.13l-.28 3.5c-.02.28-.26.48-.54.46a.503.503 0 0 1-.46-.46l-.28-3.5c-.04-.43.29-.81.72-.84Zm.06 7.59a.87.87 0 0 1-.87-.87c0-.49.39-.88.87-.88s.88.39.88.88c0 .48-.4.87-.88.87Z"
        fill="#fff"
      />
    </IconBase>
  );
};
