import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';
import { generateRandomString } from '~/utils/generateRandomString';

export const IconDocumentation: React.FC<IconProps> = (props) => {
  const id = generateRandomString();
  const staticClasses: StaticClasses = ['icon', props.className];

  return (
    <IconBase viewportSize={16} {...props} className={classNames(staticClasses)}>
      <mask id={`${id}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="0" width="13" height="16">
        <path
          d="M7.171 0H3.999c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6.828a2 2 0 00-.586-1.414L8.585.586A2 2 0 007.171 0zm5.418 6h-2.59c-1.1 0-2-.9-2-2V1.41L12.589 6z"
          fill="url(#16_Documentation__paint0_linear)"
          stroke="none"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path fill="url(#16_Documentation__paint1_linear)" d="M15.999 0h-16v16h16V0z" />
      </g>
      <defs>
        <linearGradient
          id="16_Documentation__paint0_linear"
          x1="4.723"
          y1="2.707"
          x2="13.132"
          y2="22.484"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="16_Documentation__paint1_linear"
          x1="32"
          x2="0"
          y1="0"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--icon-gradient-stop-1, #12161E)" />
          <stop offset="1" stopColor="var(--icon-gradient-stop-2, #12161E)" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
