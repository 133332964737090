import React from 'react';
import { Icon } from '~/elements/Icon';

export const Lending = [
  {
    icon: <Icon.ProductLoanOrigination />,
    iconInverted: <Icon.ProductLoanOrigination inverted />,
    name: 'Loan Origination',
    shortDescription: 'Flexible lending partnerships',
    description: 'Build a modern national lending program in partnership with Column.',
    href: '/loan-origination',
  },
  {
    icon: <Icon.ProductDebtFinancing />,
    iconInverted: <Icon.ProductDebtFinancing inverted />,
    name: 'Debt Financing',
    shortDescription: 'Capital to scale your lending business',
    description:
      'Grow your lending program more efficiently with Column capital, whether you are at scale or on your way.',
    href: '/debt-financing',
  },
  {
    icon: <Icon.ProductLoanPurchase />,
    iconInverted: <Icon.ProductLoanPurchase inverted />,
    name: 'Loan Purchase',
    shortDescription: 'Outright loan sale to Column',
    description: 'Programmatically sell the loans you originate to Column.',
    href: '/loan-purchase',
  },
];
