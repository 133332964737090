import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '~/elements/Button';
import { Flex } from '~/elements/Flex';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Box } from '~/elements/Box';
import { Link } from '~/elements/Link';
import { Icon } from '~/elements/Icon';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

interface ProductPriceProps {
  child?: boolean;
}

const PricingCard = styled.div`
  background: var(--color-gray-50);
  padding: var(--space-5);
  border-radius: var(--radii-default);

  @media ${({ theme }) => theme.screens['bp-md']} {
    padding: var(--space-8);
  }
`;

const ProductPrice = styled.div<ProductPriceProps>`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: var(--space-6);
  padding: var(--space-3) 0;
  border-top: 1px solid var(--color-gray-200);

  ${({ child }) =>
    child === true &&
    css<ProductPriceProps>`
      padding: var(--space-3) 0 var(--space-3) var(--space-4);
    `};
`;

const PricingInfo = styled(Flex)`
  border-top: 1px solid var(--color-gray-200);
`;

export const SectionDebtFinancing: React.FC = () => {
  return (
    <PricingCard id="debt-financing" className="color-palette--lending">
      <Grid alignItems="flex-start" justifyContent="space-between" gap={{ _: 4, md: 8 }}>
        <Column gridColumn={{ _: 12, md: 8 }}>
          <Flex gap={4} flexDirection={{ _: 'column', md: 'row' }} justifyContent="flex-start">
            <Icon.ProductDebtFinancing />
            <Column>
              <Heading as="h2" size="500" weight="semibold">
                <Link to="/debt-financing">Debt Financing</Link>
              </Heading>
              <Text color="gray-500" mt={2}>
                Grow your lending program more efficiently with Column capital.
              </Text>
            </Column>
          </Flex>
        </Column>
        <Column gridColumn={{ _: 12, md: 4 }}>
          <Flex justifyContent={{ _: 'flex-start', md: 'flex-end' }}>
            <Button size="large" variant="primary" to="/debt-financing">
              Learn more
              <Icon.AnimatedArrowRight />
            </Button>
          </Flex>
        </Column>
      </Grid>

      <Grid mt={8}>
        <Column gridColumn={12} pl={{ _: 0, md: 11 }}>
          <ul>
            <li>
              <ProductPrice>
                <Link to="/debt-financing">
                  <Heading as="h3" weight="semibold">
                    Debt Financing
                  </Heading>
                  <Box maxWidth="56ch">
                    <Text color="gray-500" mt={1} size="200">
                      Warehouse financing to scale your lending business.
                    </Text>
                  </Box>
                </Link>
              </ProductPrice>
            </li>

            <li>
              <ProductPrice>
                <Link to="/loan-purchase">
                  <Heading as="h3" weight="semibold">
                    Loan Purchase
                  </Heading>
                  <Box maxWidth="56ch">
                    <Text color="gray-500" mt={1} size="200">
                      We will buy loans you originate, either in a discrete transaction or through forward flow.
                    </Text>
                  </Box>
                </Link>
              </ProductPrice>
            </li>
          </ul>
        </Column>
      </Grid>

      <Box pl={{ _: 0, md: 11 }}>
        <PricingInfo mt={6} pt={8} pb={2}>
          <Flex
            gap={4}
            flexDirection={{ _: 'column', sm: 'row', md: 'column', lg: 'row' }}
            justifyContent={{ _: 'flex-start', sm: 'space-between', md: 'flex-start', lg: 'space-between' }}
            width="100%"
          >
            <Column>
              <Flex gap={2}>
                <Icon.Chat />
                <div>
                  <Heading as="h4" weight="semibold">
                    Ready to talk?
                  </Heading>
                  <Text size="200" mt={1} color="gray-500">
                    Contact us to learn more about partnering with Column.
                  </Text>
                </div>
              </Flex>
            </Column>
            <Column pl={{ _: 6, lg: 0 }}>
              <Button variant="secondary" size="small" type="link" href="https://dashboard.column.com/contact">
                Contact us
                <Icon.AnimatedArrowRight />
              </Button>
            </Column>
          </Flex>
        </PricingInfo>
      </Box>
    </PricingCard>
  );
};

export default SectionDebtFinancing;
